import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//library
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//components
import Label from "components/UI/Label";

//slices
import { getSlidesState } from "store/Slides/slidesSlice";
import { updatePresentationEdit } from "store/Poll/pollSlice";
import { getPresentationDetails } from "store/Presentations/presentationSlice";

const CommentModal = (props, ref) => {
  const { closeModal, activePlaylist } = props;

  //useselector
  const { sideImages, presentationUI } = useSelector(getSlidesState);
  const { activeSlide } = presentationUI ?? {};

  const dispatch = useDispatch();
  const { presentationId } = useParams();

  //usestate
  const [prevNote, setPrevNote] = useState(null);
  const [isUpdateSlideLoading, setIsUpdateSlideLoading] = useState(false);

  useEffect(() => {
    if (activeSlide) {
      setPrevNote(activeSlide?.note);
    }
  }, [activeSlide]);

  useImperativeHandle(ref, () => ({
    resetModal() {},
  }));

  const attachNote = (e) => {
    e.preventDefault();
    const note = e.target?.note?.value;

    if (activeSlide) {
      let newSlides = [...sideImages].map((slides) => {
        if (slides.id === activeSlide.id) {
          return { ...slides, note: note };
        }
        return slides;
      });

      const positions = newSlides.map((slide, index) => {
        let newPosition = index + 1;
        return {
          documentId: slide.id,
          position: newPosition,
          note: slide.note,
          title: `Slide_${newPosition}`,
        };
      });

      const data = {
        documentId: activePlaylist?.documentId,
        position: activePlaylist?.position,
        positions,
      };
      setIsUpdateSlideLoading(true);
      dispatch(updatePresentationEdit({ presentationId, data })).then(
        (payload) => {
          if (payload?.meta?.requestStatus === "fulfilled") {
            toast.success("Update successful");
            e.target.note.value = "";
            closeModal();

            dispatch(getPresentationDetails(presentationId));
          }

          setIsUpdateSlideLoading(false);
        }
      );
    }
  };


  return (
    <section>
      <form onSubmit={attachNote}>
        <div className="my-3">
          <Label text="Note:" />
          <textarea
            defaultValue={prevNote}
            className="flex justify-between w-full px-4 py-2 mt-2 rounded-md outline-none text-gray700 bg-orange10"
            name="note"
            id=""
            rows="5"
          ></textarea>
        </div>

        <div>
          <button className="w-full py-3 text-sm text-white rounded-lg bg-primary">
            {isUpdateSlideLoading ? (
              <ClipLoader color="white" size="12px" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};
export default forwardRef(CommentModal);
