import addIcon from 'assets/svg/add-square-outline.svg';
import pollImage from 'assets/image/poll-image.png';
import { Poll } from 'interfaces/PollSetup';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  polls: Poll[];
  currentDisplayedPollIndex: number;
  setCurrentDisplayedPollIndex: Dispatch<SetStateAction<number>>;
  setPolls: Dispatch<SetStateAction<Poll[]>>;
}

export default function Sidebar({
  polls,
  currentDisplayedPollIndex,
  setCurrentDisplayedPollIndex,
  setPolls,
}: Props) {
  const newPoll = {
    pollType: '',
    question: '',
    questionsList: [],
    duration: {
      mins: '',
      secs: '',
    },
    settings: {
      result_percentage: false,
    },
  };
  return (
    <section className='w-[12%] h-screen fix top-0 left-0 bg-white pt-[80px]'>
      <section className='pt-2 px-2'>
        <button
          className='bg-black px-2 py-1 flex items-center gap-x-2 rounded-md align-bottom w-full'
          onClick={() => {
            setPolls([...polls, newPoll]);
          }}
        >
          <img src={addIcon} alt='add new poll' className='w-6' />
          <p className='text-white text-xs'>Add new</p>
        </button>

        <section className='mt-3 grid grid-cols-1 gap-y-3'>
          {polls.map((poll, index) => (
            <div
              className='flex items-center justify-between cursor-pointer'
              onClick={() => {
                setCurrentDisplayedPollIndex(index);
              }}
            >
              <div
                className={`flex w-5 h-5 items-center justify-center rounded-full  text-xs ${
                  currentDisplayedPollIndex === index
                    ? 'bg-primary text-white'
                    : 'bg-[#E8E8E8] text-gray-600'
                }`}
              >
                <p>{index + 1}</p>
              </div>
              <img src={pollImage} alt='' />
            </div>
          ))}
        </section>
      </section>
    </section>
  );
}
