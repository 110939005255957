import React from "react";

//assets
import pollHeader from "assets/svg/pollHeader.svg";

export default function WordCloudMetricsTemplate({ details }) {
  const { question } = details ?? {};
  return (
    <section className="px-6 py-4 bg-gray20 rounded-xl">
      <section className="py-4 ">
        <div className="flex items-center justify-center mt-3 mb-4 text-4xl font-bold text-gray600 gap-x-2">
          <img src={pollHeader} alt="" />
          <h3>{question}</h3>
        </div>

        <section className="mt-3">
          <section>
            <p className="font-semibold text-primary">Most popular answer</p>
            <div className="flex items-center gap-x-1 text-gray40">
              <h2 className="text-[2rem] text-gray600 font-semibold">GREAT</h2>
              <div className=" flex justify-center items-center w-[2.4375rem] h-[2.4375rem] rounded-lg">
                15
              </div>
            </div>
          </section>

          <section className="my-4">
            <p className="font-semibold text-primary">Other answers</p>
            <section className="flex mt-3 gap-x-4">
              <div className="flex items-center px-3 py-2 font-semibold border-2 rounded-lg text-gray700 gap-x-2">
                <p className="">Moody</p>

                <p className="px-2 rounded bg-gray130">5</p>
              </div>

              <div className="flex items-center px-3 py-2 font-semibold border-2 rounded-lg text-gray700 gap-x-2">
                <p className="">Moody</p>

                <p className="px-2 rounded bg-gray130">5</p>
              </div>

              <div className="flex items-center px-3 py-2 font-semibold border-2 rounded-lg text-gray700 gap-x-2">
                <p className="">Moody</p>

                <p className="px-2 rounded bg-gray130">5</p>
              </div>

              <div className="flex items-center px-3 py-2 font-semibold border-2 rounded-lg text-gray700 gap-x-2">
                <p className="">Moody</p>

                <p className="px-2 rounded bg-gray130">5</p>
              </div>

              <div className="flex items-center px-3 py-2 font-semibold border-2 rounded-lg text-gray700 gap-x-2">
                <p className="">Moody</p>

                <p className="px-2 rounded bg-gray130">5</p>
              </div>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
