// Assets
import chevronDown from 'assets/svg/chevron-down.svg';
import quizIconBlue from 'assets/svg/quiz-optionIcon-blue.svg';
import quizIconGreen from 'assets/svg/quiz-optionIcon-green.svg';
import markIcon from 'assets/svg/mark-icon.svg';
import imageIcon from 'assets/svg/image-icon.svg';
import { QuestionsList } from 'interfaces/PollSetup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ColorPicker } from 'antd';

interface Props {
  questionsList: QuestionsList[];
  question: QuestionsList;
  index: number;
  setQuestionsList: Dispatch<SetStateAction<QuestionsList[]>>;
}

export default function QuestionOption({
  questionsList,
  question,
  index,
  setQuestionsList,
}: Props) {
  // State
  const [currentTagColour, setCurrentTagColour] = useState('#3AA76D');
  const [markColour, setMarkColour] = useState<'#333333' | '#3AA76D'>(
    '#333333'
  );

  useEffect(() => {
    setMarkColour(question.markColour);
  }, [question.markColour]);

  return (
    <section className='w-full'>
      <label
        htmlFor=''
        className='flex gap-x-3 h-11 items-center justify- w-full px-3 rounded-lg border border-[#CBCBCB] mt-2 overflow-hidden'
      >
        <ColorPicker
          children={
            <div className='w-5 h-[21px] cursor-pointer'>
              <svg
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.66871 17.2388L9.66869 17.2388L9.66469 17.2424C8.87003 17.9621 7.82192 18.3964 6.66699 18.3964C4.1848 18.3964 2.16699 16.3785 2.16699 13.8964C2.16699 12.424 2.8824 11.1129 3.98393 10.2923C3.98703 10.2959 3.98994 10.3001 3.99239 10.3048C4.85129 11.9847 6.39014 13.2403 8.21622 13.7364C8.78721 13.8992 9.38251 13.9797 10.0003 13.9797C10.4031 13.9797 10.7905 13.9445 11.1669 13.8795C11.167 13.8851 11.167 13.8907 11.167 13.8964C11.167 15.224 10.5899 16.4225 9.66871 17.2388Z'
                  fill={currentTagColour}
                  stroke={currentTagColour}
                />
                <path
                  d='M14.1853 8.88371L14.1852 8.88392C13.6677 10.1889 12.557 11.1943 11.1849 11.5692L11.1849 11.5692L11.1819 11.5701C10.812 11.6736 10.4159 11.7266 10 11.7266C9.58411 11.7266 9.18802 11.6736 8.81815 11.5701L8.81512 11.5692C7.44296 11.1943 6.33227 10.1889 5.81479 8.88392L5.81471 8.88371C5.61271 8.37498 5.5 7.81253 5.5 7.22656C5.5 4.74437 7.51781 2.72656 10 2.72656C12.4822 2.72656 14.5 4.74437 14.5 7.22656C14.5 7.81253 14.3873 8.37498 14.1853 8.88371Z'
                  fill={currentTagColour}
                  stroke={currentTagColour}
                />
                <path
                  d='M13.417 13.8964C13.417 13.6171 13.395 13.3401 13.358 13.0777C14.4855 12.4344 15.412 11.4739 16.0086 10.3054C16.0111 10.3005 16.0141 10.2963 16.0172 10.2927C17.1185 11.1133 17.8337 12.4243 17.8337 13.8964C17.8337 16.3785 15.8158 18.3964 13.3337 18.3964C12.8285 18.3964 12.3419 18.3132 11.8901 18.1594C11.8921 18.1556 11.8946 18.1517 11.8977 18.148C12.8768 16.9609 13.417 15.4609 13.417 13.8964Z'
                  fill={currentTagColour}
                  stroke={currentTagColour}
                />
              </svg>
            </div>
          }
          onChange={(value, colour) => {
            console.log(value, colour);
            setCurrentTagColour(colour);
            setQuestionsList((prev) => {
              return prev.map((prevQuestion, idx) => {
                if (idx === index) {
                  return { ...prevQuestion, tagColor: colour };
                }
                return prevQuestion;
              });
            });
          }}
        />

        <input
          type='text'
          className='h-full w-full outline-none border-none'
          placeholder='Type another choice '
          value={question.value}
          onChange={(e) => {
            setQuestionsList((prev) => {
              return prev.map((prevQuestion, idx) => {
                if (idx === index) {
                  return { ...prevQuestion, value: e.target.value };
                }
                return prevQuestion;
              });
            });
          }}
        />
        <div
          onClick={() => {
            setQuestionsList((prev) => {
              return prev.map((prevQuestion, idx) => {
                if (idx === index) {
                  return {
                    ...prevQuestion,
                    correctAnswer: true,
                    markColour: '#3AA76D',
                  };
                }
                return {
                  ...prevQuestion,
                  correctAnswer: false,
                  markColour: '#333333',
                };
              });
            });
          }}
          className='cursor-pointer'
        >
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M16.7045 4.71597C17.034 4.967 17.0976 5.43759 16.8466 5.76707L8.84657 16.2671C8.71541 16.4392 8.51627 16.5463 8.30033 16.5608C8.08439 16.5754 7.87271 16.4959 7.71967 16.3429L3.21967 11.8429C2.92678 11.55 2.92678 11.0751 3.21967 10.7822C3.51256 10.4893 3.98744 10.4893 4.28033 10.7822L8.17351 14.6754L15.6534 4.85801C15.9045 4.52853 16.3751 4.46493 16.7045 4.71597Z'
              fill={markColour}
            />
          </svg>
        </div>
        <img src={imageIcon} alt='type another choice' />
      </label>
    </section>
  );
}
