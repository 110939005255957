import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

//assets
import dots from "assets/svg/dots.svg";
import folder from "assets/svg/folderImg.svg";
import empty from "assets/svg/emptyDocument.svg";

//components
import Loader from "components/UI/Loader";
import AdminNavbar from "components/UI/AdminLayout/Navbar";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//slices
import { openPollDrawer } from "store/UI";

export default function MyDocuments() {
  const dispatch = useDispatch();
  // const { presentations, loadingStatus } = useSelector(getPresentationState);

  //usestates
  const [navProps, setNavProps] = useState({});
  const [loadingStatus, setLoadingStatus] = useState("success");
  // const [docFilter, setDocFilter] = useState("presentations");

  //useeffects
  useEffect(() => {
    setNavProps({
      title: "My documents",
      subTitle: `Explore your documents`,
      // modalTitle: "Create new doc",
    });

    //   dispatch(getAllPresentations());
  }, [dispatch]);

  const docList = Array(5).fill({
    name: " RCCG presentation",
    counts: "20",
  });

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="mt-20">
        {/* loader */}
        {loadingStatus === "loading" && <Loader />}
        {/*  */}

        {loadingStatus === "success" && (
          <section>
            {docList?.length < 1 && (
              <section className="flex justify-center w-full ">
                <img src={empty} alt="No presentation" className="mt-20" />
              </section>
            )}

            {docList?.length > 0 && (
              <section className="grid grid-cols-1 my-10 lg:gap-y-4 gap-y-6 lg:grid-cols-3 gap-x-6">
                {docList.map((el, index) => {
                  return (
                    <section
                      className="p-4 border rounded-lg cursor-pointer font-grotesk bg-gray100"
                      onClick={() => dispatch(openPollDrawer(index))}
                    >
                      <FlexContainer>
                        <div className="flex gap-x-4">
                          <img src={folder} alt="document" />

                          <div>
                            <h2 className="text-sm font-semibold">
                              RCCG presentation
                            </h2>
                            <p className="mt-1 text-sm">20 items</p>
                          </div>
                        </div>

                        <div>
                          <img
                            src={dots}
                            alt="options"
                            className="cursor-pointer"
                          />
                        </div>
                      </FlexContainer>
                    </section>
                  );
                })}
              </section>
            )}
          </section>
        )}
      </section>
    </section>
  );
}
