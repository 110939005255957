import AdminNavbar from "components/UI/AdminLayout/Navbar";
import React from "react";
import { navProps } from "../Presentation/data";
import ReactWordcloud from "react-wordcloud-fork";

export default function WordCloudTest() {
  const words = [
    {
      text: "told",
      value: 64,
    },
    {
      text: "toldd",
      value: 64,
    },
    {
      text: "toldw",
      value: 84,
    },
    {
      text: "toldq",
      value: 44,
    },
    {
      text: "toldr",
      value: 34,
    },
    {
      text: "toldt",
      value: 62,
    },
    {
      text: "mistake",
      value: 310,
    },
    {
      text: "thought",
      value: 26,
    },
    {
      text: "bad",
      value: 17,
    },
  ];

  const options = {
    rotations: 5,
    rotationAngles: [-90, 0],
    fontSizes: [15, 60],
    // colors: ['#f0c', '#0cf', '#fc0', '#0fc'],
  };

  return (
    <section>
      <section className="relative">
        <AdminNavbar navProps={navProps} />
      </section>

      <section className="mt-20">
        <section className="mx-auto max-w-[50%] bg-red-50">
          <ReactWordcloud words={words}options={options} />
        </section>
      </section>
    </section>
  );
}
