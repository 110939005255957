import React, { Dispatch, SetStateAction, useEffect } from 'react';

//assets
import addPlus from 'assets/svg/add-square.svg';
import tagIcon from 'assets/svg/tag-icon.svg';

//libraries
import { ClipLoader } from 'react-spinners';

interface Props {
  closeModal: () => void;
  func: () => void;
  isLoading: boolean;
  previousTitle: string;
  setTitleName: Dispatch<SetStateAction<string>>;
  titleName: string;
  heading: string;
  subHeading: string;
  buttonText: string;
}

export default function RenameModal(props: Props) {
  const {
    isLoading,
    func,
    closeModal,
    previousTitle = '',
    setTitleName,
    titleName,
    heading,
    subHeading,
    buttonText,
  } = props ?? {};

  //usestate

  //useeffect === this can be moved to the individual places this component would be used.
  useEffect(() => {
    setTitleName(previousTitle);
  }, [previousTitle]);

  return (
    <section className='py-3'>
      <div className='mt-[-20px]'>
        <img src={tagIcon} alt='' />
      </div>

      <section>
        <div className='mt-4'>
          <h1 className='text-2xl font-semibold'>{heading}</h1>
        </div>

        <section>
          <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'></section>

          {/* form  */}
          <section className='mt-4'>
            <div className='flex flex-col gap-y-1'>
              <label htmlFor='space' className='font-normal text-gray700'>
                {subHeading}
              </label>
              <input
                type='text'
                placeholder='e.g design'
                value={titleName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitleName(e.target.value)
                }
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              />
            </div>

            <section className='flex my-4 mt-6 gap-x-4'>
              <button
                disabled={isLoading}
                onClick={func}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading ? 'opacity-40 cursor-not-allowed' : 'opacity-100'
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt='' />
                    {buttonText}
                  </>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
