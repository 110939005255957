import React from "react";

export default function Banner() {
  return (
    <section className="w-[80%] mx-auto">
      <div className="text-center">
        <h1 className="  [ lg:text-[4rem] lg:leading-tight font-medium ] ">
          Transparent pricing for everyone
        </h1>

        <p className="w-[70%] text-lg mx-auto mt-5 text-black30">
          No surprise fees, cancel anytime
        </p>
      </div>
    </section>
  );
}
