import React from "react";

//libraries
import { ClipLoader } from "react-spinners";

export default function SubmitButton({ extraClass, isActive, isLoading, title , onClick}) {
  return (
    <button
    onClick={onClick}
      disabled={!isActive}
      className={`${extraClass} w-full py-3  text-sm text-white bg-primary rounded-md ${
        isActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
      }`}
    >
      {isLoading ? <ClipLoader color="white" size={12} /> : title}
    </button>
  );
}
