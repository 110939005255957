import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useHasViewerBeenRemoved = (connection, presentationId, bool) => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleEventResponse = (presentationId, bool) => {
      console.log(bool, "youve been removed");
      if (bool) {
        localStorage.clear();
        navigate("/removed");
      }
    };

    if (connection) {
      connection.on("Removed", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off("Removed", handleEventResponse);
      }
    };
  }, [connection, presentationId, navigate]);
};
