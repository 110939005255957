import { getUserDetails } from 'utils/localStorageServices';
import moment from 'moment';

export const validateToken = () => {
  const tokenObject = getUserDetails();
  const tokenExpiry = tokenObject?.expiresIn;
  const token = tokenObject?.token;
  if (!tokenObject || !token || moment(tokenExpiry).isBefore(moment())) {
    return false;
  }
  return true;
};
