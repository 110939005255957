// import React from "react";
// import { Navigate, useSearchParams } from "react-router-dom";
// import { toast } from "react-toastify";

// export default function AuthRoute({ children }) {
//   const [searchParams] = useSearchParams();

//   const getToken = JSON.parse(localStorage.getItem("user"))?.token;

//   let token = searchParams.get("token") ?? getToken;
//   let error = searchParams.get("error")

//   if (!token) {
//     toast.error(error)
//     return children;
//   }

//   if (token && searchParams.get("token")) {
//     let userDetails = {
//       token: searchParams.get("token"),
//       user: searchParams.get("a"),
//       exp: searchParams.get("vl"),
//       phone: searchParams.get("p"),
//     };
//     localStorage.setItem("user", JSON.stringify(userDetails));
//     return <Navigate to="/admin/dashboard" replace />;
//   } else {
//     return <Navigate to="/admin/dashboard" replace />;
//   }
// }

import axios from "axios";
import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";

export default function AuthRoute({ children }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const storedToken = JSON.parse(localStorage.getItem("user"))?.token;

  let OAuthCode = searchParams.get("code");

  if (storedToken) {
    // navigate("/admin/dashboard");
    navigate("/admin/mypresentations");
  }
  if (!storedToken && !OAuthCode) {
    return children;
  }

  if (OAuthCode) {
    axios
      .post(
        `${baseUrl}/Account/OAuth/Token?code=${encodeURIComponent(
          OAuthCode
        )}&decoded=${true}`
      )
      .then((res) => {
        const result = res?.data;
        const { fullName, phone, roles, token, validity } = result ?? {};
        if (result) {
          let userDetails = {
            token,
            user: fullName,
            exp: validity,
            phone,
            roles,
          };
          localStorage.setItem("user", JSON.stringify(userDetails));
          // navigate("/admin/dashboard");

          navigate("/admin/mypresentations");
          
        }
      })
      .catch((err) => {
        console.log(err, "Oauth");
      });
  }

  return children;

  // if (token && searchParams.get("token")) {
  //   let userDetails = {
  //     token: searchParams.get("token"),
  //     user: searchParams.get("a"),
  //     exp: searchParams.get("vl"),
  //     phone: searchParams.get("p"),
  //   };
  //   localStorage.setItem("user", JSON.stringify(userDetails));
  //   return <Navigate to="/admin/dashboard" replace />;
  // } else {
  //   return <Navigate to="/admin/dashboard" replace />;
  // }
}
