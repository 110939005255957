import React, {useState} from "react";
import roundedArrowLeft from "assets/svg/roundedArrowLeft.svg";
import smallIcon from "assets/svg/smallIcon.svg";
import {InnerPresentationInterface} from "interfaces/interfaces-data";
import presentationEnd from "assets/svg/presenationEnd.svg"
import {HubConnection} from "@microsoft/signalr";
import {Modal} from "antd";
import EndPresentations from "../../../../views/Admin/ActivePresentation/Modal";
import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";

export const DesktopPresentationHeader = ({
                                              loading,
                                              presentationDetails,
                                              connection,
                                              presentationId
                                          }: {
    loading: boolean;
    presentationDetails?: InnerPresentationInterface;
    connection?: HubConnection | null;
    presentationId?: string
}) => {
    const [endModalOpen, setEndModalOpen] = useState(false);
    const endPresentation = () => {
        connection?.invoke("EndPresentation", presentationId).then(() => {
        });
        window.location.href = `/admin/presentation-details_?q=${presentationId}`
    }
    return (
        <div>
            <Modal
                open={endModalOpen}
                onCancel={() => setEndModalOpen(false)}
                footer={null}
            >
                <EndPresentations
                    endPresentation={endPresentation}
                    closeModal={() => setEndModalOpen(false)}
                />
            </Modal>
            <div
                className="border-[#E5E7EB] flex justify-between item-center pr-4 items-center border-b border-r w-full ">

                <div className="flex items-center py-3 px-4">
                    <div className="pr-4 cursor-pointer">
                        <img alt="" src={roundedArrowLeft}/>
                    </div>
                    <div className="border-l pl-4 border-[#E5E7EB]">
                        <p className="text-[#757575] text-sm ">Team Workspace</p>
                        <p className="text-[#111827] text-base font-semibold">
                            {loading ? "_" : presentationDetails?.presentation.title}
                        </p>
                    </div>
                </div>
                <CopyToClipBoard text={presentationDetails?.presentation.friendlyId ?? ""}>
                    <div className="bg-[#F6F6F6] p-3 rounded-2xl flex items-center gap-2">
                        <div>
                            <img src={smallIcon} alt=""/>
                        </div>
                        <p className="text-[#333333] font-semibold text-base">
                            Meeting code:
                        </p>
                        <p className="text-[#333333] font-semibold text-base">
                            {loading ? "_" : presentationDetails?.presentation.friendlyId}
                        </p>
                    </div>
                </CopyToClipBoard>
                {connection && connection.state === "Connected" && <button onClick={() => setEndModalOpen(true)}>
                    <img alt="" src={presentationEnd}/>
                </button>}

            </div>
        </div>

    );
};
