import React, {useEffect, useRef, useState} from "react";
import noteActive from "assets/svg/notesActive.svg";
import noteInactive from "assets/svg/notesInactive.svg";
import peopleActive from "assets/svg/peopleActive.svg";
import peopleInactive from "assets/svg/peopleInactive.svg";
import questionInactive from "assets/svg/questionInactive.svg";
import pollsInactive from "assets/svg/pollInactive.svg";
import pollsActive from "assets/svg/pollActive.svg";
import shareActive from "assets/svg/shareActive.svg";
import shareInactive from "assets/svg/shareInactive.svg";
import {DesktopViewers} from "components/UI/Presentation/desktop/desktop-viewers";
import {InnerPresentationInterface, SlideInterface, UserInterface} from "../../../../interfaces/interfaces-data";
import {HubConnection} from "@microsoft/signalr";
import {GetUsersListNew} from "../../../../hooks/SignalR/Invokers/Presenter/GetUserListNew";
import {DesktopShare} from "./DesktopShare";
import {DesktopQuestion} from "./DesktopQuestion";
import {useGetQuestions} from "../../../../hooks/SignalR/Listeners/Presenter/useGetQuestions";
import {GetQuestions} from "../../../../hooks/SignalR/Invokers/Presenter/GetQuestions";
import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";


export const DesktopMenu = ({activeSlide, connection, presentationId, connected, friendlyId, presentationDetails}: {
    activeSlide?: SlideInterface,
    connection?: HubConnection | null,
    presentationId?: string
    connected: boolean
    friendlyId?: string
    presentationDetails?: InnerPresentationInterface
}) => {
    const [user, setUser] = useState([
        "walt@gmail.com",
        "bobbyAdams@gmail.com",
        "walter@gmail.com",
        "shawn@gmail.com",
        "christian@mailinator.com",
    ]);

    const [activeState, setActiveState] = useState<
        "note" | "people" | "question" | "poll" | "share"
    >("note");
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [requests, setRequests] = useState<UserInterface[]>([])
    const [questionCount, setQuestionCount] = useState(0);
    const [latestQuestion, setLatestQuestion] = useState(null);
    const [showNotif, setShowNotif] = useState(false);
    const [notifAnimation, setNotifAnimation] = useState("");


    const isMountedRef = useRef(true);


    // Handler for question
    useGetQuestions(connection, presentationId, (questions: any) => {
        if (!questions) return;
        const questionLength = questions?.length;
        setQuestionCount(questionLength);
        let storedLength;

        if (localStorage.getItem("questionLength")) {
            storedLength = JSON.parse(
                localStorage.getItem("questionLength") ?? ""
            );
        }

        const getLatestQuestion = questions.sort((a: any, b: any) =>
            a?.askTime > b?.askTime ? 1 : -1
        );

        if (isMountedRef.current) {
            localStorage.setItem("questionLength", questionLength);
            isMountedRef.current = false;
        } else if (questionLength !== storedLength) {
            setLatestQuestion(getLatestQuestion[questionLength - 1]);

            localStorage.setItem("questionLength", questionLength);
            setTimeout(() => {
                setShowNotif(true);
                setNotifAnimation("bounce-in");
                setTimeout(() => {
                    setNotifAnimation("bounce-out");
                    setTimeout(() => {
                        setShowNotif(false);
                    }, 500);
                }, 2000);
            }, 2000);
        }
    });

    useEffect(() => {
        if (connected) {
            const handleEventResponse = (id: string, users: UserInterface[]) => {
                setUsers((prev) => users.filter((item) => item.status === 1));
                setRequests((prev) => users.filter((item) => item.status === 0));
            };

            if (connection) {
                connection.on("UserList", handleEventResponse);
                // Fetch initial user list when connection is activec
                console.log("Got Outside here", connection)
                if (presentationId) {
                    GetUsersListNew(presentationId, connection);
                }
            }

            // Cleanup event listener on component unmount or when connection changes
            return () => {
                connection?.off("UserList", handleEventResponse);
            };
        }
    }, [connected]);


    useEffect(() => {
        if (connected) {
            GetQuestions(connection, presentationId);
        }
    }, [connected]);


    return (
        <div
            className="border border-[#E5E7EB] overflow-y-scroll h-screen min-h-screen max-h-screen mb-10 p-4 ml-2 rounded-lg">
            <div className="bg-[#F6F6F6] p-2 rounded-lg flex justify-between">
                <button
                    onClick={() => setActiveState("note")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img src={activeState === "note" ? noteActive : noteInactive} alt=""/>
                    <p
                        className={`${
                            activeState === "note" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Notes
                    </p>
                </button>
                <button
                    onClick={() => setActiveState("people")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={activeState === "people" ? peopleActive : peopleInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState == "people" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        People
                    </p>
                </button>
                <button
                    onClick={() => setActiveState("question")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={
                            activeState == "question" ? questionInactive : questionInactive
                        }
                        alt=""
                    />
                    <p
                        className={`${
                            activeState == "question" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Questions
                    </p>
                </button>
                <button
                    onClick={() => setActiveState("poll")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={activeState == "poll" ? pollsActive : pollsInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "poll" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Polls
                    </p>
                </button>
                <button
                    onClick={() => setActiveState("share")}
                    className="flex justify-center items-center flex-col cursor-pointer"
                >
                    <img
                        src={activeState === "share" ? shareActive : shareInactive}
                        alt=""
                    />
                    <p
                        className={`${
                            activeState === "share" ? "text-[#FF6929]" : "text-[#667084]"
                        }  text-xs `}
                    >
                        Share
                    </p>
                </button>
            </div>
            {/* Options selected  */}
            <div className=" border-t mt-4 py-4">
                {activeState === "note" && (
                    <div className="bg-[#F6F6F6] p-4 rounded-xl ">
                        <p className="text-[#545454] text-sm">
                            {activeSlide?.note ? <CopyToClipBoard text={activeSlide?.note}>
                                {activeSlide?.note}
                            </CopyToClipBoard> : <p className="text-center">
                                No note for the slide
                            </p>}
                        </p>
                    </div>
                )}
                {activeState === "people" && connected &&
                    <DesktopViewers connection={connection} presentationId={presentationId} users={users}
                                    requests={requests}/>}
                {activeState === 'share' && connected &&
                    <DesktopShare presentationDetails={presentationDetails} presentationId={presentationId}
                                  friendlyId={friendlyId}/>
                }
                {
                    activeState === "question" && connected &&
                    <div >

                        <DesktopQuestion presentationId={presentationId} connection={connection}/>
                    </div>
                }
            </div>
        </div>
    )
        ;
}



