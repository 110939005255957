import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { generateRandomColor } from "utils/colorGenerator";
import { ActiveElement, ChartEvent } from "chart.js";
import arrowLeft from "assets/svg/leftArrow.svg";

export default function MultiLineChart({
  label,
  dataOne,
  dataTwo,
  dataThree,
  overallPayload,
}: {
  label?: string[];
  dataOne?: number[];
  dataTwo?: number[];
  dataThree?: number[];
  overallPayload?: any[];
}) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [data1, setData1] = useState<number[]>(dataOne ?? []);
  const [data2, setData2] = useState<number[]>(dataTwo ?? []);
  const [data3, setData3] = useState<number[]>(dataThree ?? []);
  const [cLabel, setCLabel] = useState<string[]>(label ?? []);
  const [dataSwitched, setDataSwitched] = useState(false);
  const [prevData1, setPrevData1] = useState<number[]>([]);
  const [prevData2, setPrevData2] = useState<number[]>([]);
  const [prevData3, setPrevData3] = useState<number[]>([]);
  const [prevLabel, setPrevLabel] = useState<string[]>([]);

  const doReCalibrate = () => {
    setData1(prevData1);
    setData2(prevData2);
    setData3(prevData3)
    setCLabel(prevLabel);
    setDataSwitched(false);
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: cLabel,
      datasets: [
        {
          label: "No of downloads",
          data: data1,
          fill: false,
          borderColor: "#E86025",
          tension: 0.4,
        },
        {
          label: "Attention(%)",
          data: data2,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          tension: 0.4,
        },
        {
          label: "Time spent",
          data: data3,
          fill: false,
          borderColor: "#000",
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      onClick: (event: ChartEvent, elements: ActiveElement[]) => {
        if (elements.length > 0) {
          const datasetIndex = elements[0].datasetIndex;
          const dataIndex = elements[0].index;
          // Check if there is any data property from the data
          if (
            overallPayload?.length &&
            overallPayload[dataIndex]?.data?.length &&
            !dataSwitched
          ) {
            // Do Something
            const newData = overallPayload?.length
              ? overallPayload[dataIndex].data
              : [];
            // get the downloads as it is the first index
            const cDataOne = newData.map((item: any) => item.value[0]);
            const cDataTwo = newData.map((item: any) => item.value[1]);
            const cDataThree = newData.map((item: any) => item.value[2]);
            const cLabel_ = newData.map((item: any) => item.key);
            setPrevData1(data1);
            setPrevData2(data2);
            setPrevData3(data3);
            setPrevLabel(cLabel);
            setData1(cDataOne);
            setData2(cDataTwo);
            setData3(cDataThree);
            setCLabel(cLabel_);
            setDataSwitched(true);
          } else {
            // Do nothing
          }
          // const datasetLabel = data.datasets[datasetIndex].label;
          // const clickedValue = data.datasets[datasetIndex].data[dataIndex];

          // console.log(
          //   `Clicked on dataset: ${datasetLabel}, label: ${label[dataIndex]}, value: ${clickedValue}`
          // );
        }
      },
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          offset: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data1, data2, data3, cLabel]);

  return (
    <div className="card">
      {dataSwitched && (
        <div onClick={doReCalibrate} className="cursor-pointer">
          <img src={arrowLeft} />
        </div>
      )}
      <Chart type="line" data={chartData} options={chartOptions} />
    </div>
  );
}
