import React from "react";

//assets
import card11 from "assets/svg/card11.svg";
import card12 from "assets/svg/card12.svg";
import card13 from "assets/svg/card13.svg";
import card21 from "assets/svg/card21.svg";
import card22 from "assets/svg/card22.svg";
import card3 from "assets/svg/card3.svg";

export default function StackCard() {
  return (
    <section className="w-full [ lg:my-20 my-10 ]">
      {/* header */}
      <section className="[ lg:w-[80%] w-[99%] mx-auto ]">
        <div className="text-center">
          <h1 className="  [ lg:text-[3rem] text-2xl lg:leading-tight font-medium ] ">
            Maximize Engagement with Audience
            <br className="[ hidden lg:block ]" />
            <span className=" text-primary  [ lg:ml-0 ml-2 ]">
              Attention Metrics
            </span>{" "}
          </h1>
        </div>
      </section>
      {/*  */}

      {/* cards */}
      <section className="sticky top-0 flex flex-col w-full mx-auto  overflow-y-scroll [ lg:gap-y-20  ] [ lg:my-8  ]">
        {/* card1 */}
        <section className="  bg-white rounded-2xl mx-auto sticky top-10   [ lg:w-[80%] w-[90%] ]  [ lg:py-10 py-4 ] ">
          <section className="sticky [ flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 ] justify-between w-11/12 mx-auto mt-6 top-10 ">
            <section className="[ lg:w-[50%]  w-[95%] mx-auto ]">
              {" "}
              <h2 className=" font-medium leading-tight  [ lg:text-5xl text-2xl ]  [ lg:text-left text-center ]">
                Track Group <br className="[ hidden lg:block ]" /> Engagement
              </h2>
              <p className="mt-3 [ text-sm lg:text-lg ] [ lg:w-[70%]  w-[95%] mx-auto lg:ml-0 ] [ lg:text-left text-center ]">
                Understand the overall engagement levels of your audience
                throughout your presentation. Identify peaks and dips in
                attention to tailor your delivery for maximum impact.
              </p>
            </section>

            <section className="[ lg:w-[50%]  w-[90%] mx-auto ] flex flex-col gap-y-4">
              <div className="flex justify-center lg:justify-start">
                <img src={card11} alt="" />
              </div>

              <div>
                <img src={card12} alt="" />
              </div>

              <div>
                <img src={card13} alt="" />
              </div>
            </section>
          </section>
        </section>
        {/*  */}

        {/* card2 */}
        <section className="  bg-white rounded-2xl mx-auto sticky top-10   [ lg:w-[80%] w-[90%] ]  [ lg:py-10 py-4 ] [  mt-16 mb-8 lg:my-0 ] ">
          <section className="sticky [ flex flex-col lg:flex-row gap-y-16 lg:gap-y-0 ] justify-between w-11/12 mx-auto mt-6 top-10 ">
            <section className="[ lg:w-[50%]  w-[95%] mx-auto ]">
              {" "}
              <h2 className="  font-medium leading-tight [ lg:text-5xl text-2xl ]   [ lg:text-left text-center ]">
                Individual Attention <br /> Insights
              </h2>
              <p className="mt-3 [ text-sm lg:text-lg ] [ lg:w-[70%]  w-[95%] mx-auto lg:ml-0 ] [ lg:text-left text-center ]">
                Dive deeper into individual audience members' attention levels.
                Identify who might need additional follow-up or clarification
                based on their engagement with your content.
              </p>
            </section>

            <section className=" flex flex-col gap-y-4 relative [ lg:w-[50%]  w-[90%] mx-auto ] ">
              <div>
                <img src={card21} alt="" className="[ w-40 lg:w-[299px] ]" />
              </div>

              <div className="absolute top-[-30px] right-0">
                <img src={card22} alt="" className="[ w-40 lg:w-full ]" />
              </div>
            </section>
          </section>
        </section>
        {/*  */}

        {/* card3 */}
        <section className="  bg-white rounded-2xl mx-auto sticky top-10   [ lg:w-[80%] w-[90%] ]  [ lg:py-10 py-4 ] ">
          <section className="sticky [ flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 ] justify-between w-11/12 mx-auto mt-6 top-10 ">
            <section className="[ lg:w-[50%]  w-[95%] mx-auto ]">
              {" "}
              <h2 className=" font-medium leading-tight  [ lg:text-5xl text-2xl ]  [ lg:text-left text-center ]">
                Page-Level <br className="[ hidden lg:block ]" /> Engagement{" "}
                <br /> Analysis
              </h2>
              <p className="mt-3 [ text-sm lg:text-lg ] [ lg:w-[70%]  w-[95%] mx-auto lg:ml-0 ] [ lg:text-left text-center ]">
                Pinpoint which pages or slides of your presentation engage your
                audience the least. Armed with this knowledge, you can refine
                and improve those sections to captivate your audience from start
                to finish.
              </p>
            </section>

            <section className=" flex flex-col gap-y-4  [ lg:w-[50%]  w-[90%] mx-auto ]">
              <div>
                <img src={card3} alt="" />
              </div>
            </section>
          </section>
        </section>
        {/*  */}
      </section>
      {/*  */}
    </section>
  );
}
