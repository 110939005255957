import { v4 as uuidv4 } from "uuid";

export const storePollState = (pollId, status) => {
  const pollState = { pollId, status };

  localStorage.setItem("pollStarted", JSON.stringify(pollState));
};

export const getActivePoll = () => {
  const activePoll = localStorage.getItem("pollStarted");
  return activePoll ? JSON.parse(activePoll) : null;
};

export const getPollsObject = () => {
  const getPoll = localStorage.getItem("pollCreationTemplate");
  const polls = getPoll
    ? JSON.parse(localStorage.getItem("pollCreationTemplate"))
    : {};
  return polls;
};

// Poll creation template CRUD
const storePollCreationTemplate = (template) => {
  localStorage.setItem("pollCreationTemplate", JSON.stringify(template));
};

export const pushPoll = (title, poll) => {
  let mergePoll;
  const pollId = poll?.id;
  let polls = getStoredPolls();
  const foundPoll = polls?.findIndex((poll) => poll?.id === pollId);

  if (foundPoll === -1 || foundPoll === undefined) {
    mergePoll = { title, polls: [poll, ...getStoredPolls()] };
  } else {
    polls[foundPoll] = poll;

    mergePoll = { title, polls: [...polls] };
  }

  localStorage.setItem("pollCreationTemplate", JSON.stringify(mergePoll));
  window.dispatchEvent(new Event("storage"));
};

export const getStoredPolls = () => {
  const getPoll = localStorage.getItem("pollCreationTemplate");
  const polls = getPoll ? JSON.parse(getPoll)?.polls : [];
  return polls;
};

export const removePollCreationTemplate = (template) => {
  localStorage.removeItem("pollCreationTemplate");
};

//

export const updatePollTitle = (title) => {
  let polls = getStoredPolls();
  const updatedPoll = { title, polls };
  storePollCreationTemplate(updatedPoll);
  window.dispatchEvent(new Event("storage"));
};

export const editQuestionType = (id) => {
  const polls = getStoredPolls();

  if (!polls) {
    return;
  }

  const foundPoll = polls.find((poll) => poll?.id === id);

  window.dispatchEvent(new CustomEvent("edit", { detail: foundPoll }));
  return foundPoll;
};

export const duplicateQuestionType = (id) => {
  const title = getPollsObject()?.title;
  const polls = getStoredPolls();

  if (!polls) {
    return;
  }

  const pollDuplicate = polls.find((poll) => poll?.id === id);
  const newId = uuidv4();
  pollDuplicate.id = newId;
  pollDuplicate.rank = polls?.length;
  const mergePoll = { title, polls: [pollDuplicate, ...getStoredPolls()] };

  storePollCreationTemplate(mergePoll);
  window.dispatchEvent(new Event("storage"));
};

export const deleteQuestionType = (id) => {
  const title = getPollsObject()?.title;
  const polls = getStoredPolls();

  if (!polls) {
    return;
  }

  const newPolls = polls.filter((poll) => poll?.id !== id);

  const mergePoll = { title, polls: newPolls };
  storePollCreationTemplate(mergePoll);
  window.dispatchEvent(new Event("storage"));
};
