import React, {useState} from "react";
import logoReduced from "assets/svg/logoReduced.svg";
import {Col, Row} from "antd";
import {TextInput} from "components/UI/Inputs/text-type-input";
import {CButton} from "components/UI/Button/Buttons";
import logo from "assets/svg/fullLogo.svg";
import {useNavigate} from "react-router-dom";
import {MeetConfigInterface, UserContextInterface, ZoomConfigInterface,} from "interfaces/interfaces-data";
import {toast} from "sonner";
import {validateToken} from "utils/checkAuthorization";
import instance from "lib/axiosInterceptor";
import {baseUrl} from "utils/baseUrl";
import externalCheck from "assets/svg/externalCheck.svg";
import analyticsIcon from "assets/svg/analyticsIcon.svg";
import greyDot from "assets/svg/greyDot.svg";
import blackDot from "assets/svg/blackDot.svg";

const ExLandingPage = () => {
    const [meetingCode, setMeetingCode] = useState("0");
    const navigate = useNavigate();
    const [zoomResponse, setZoomResponse] = useState<ZoomConfigInterface>();
    const [loading, setLoading] = useState(true);
    const [testData, setTestData] = useState("");
    const [meetingId, setMeetingId] = useState("");
    const [zoomMeetingId, setZoomMeetingId] = useState("");
    const [meetConfig, setMeetConfig] = useState<MeetConfigInterface>();
    const [zoomPayload, setZoomPayload] = useState<{
        code: string;
        state: string;
        href: string;
    }>();

    const sideContent = [
        "Instant Access to Key Metrics",
        "Customizable Data Dashboards",
        "Comprehensive Reporting",
    ];

    const navigateToJoinMeeting = () => {
        if (!meetingId) {
            toast.error("Meeting id is required");
            return;
        } else if (meetingId.length !== 10 || isNaN(+meetingId)) {
            toast.error("Invalid meeting id entered");
            return;
        }
        navigate(`/join-presentation/${meetingId}`);
    };

    const startZoomMeeting = async () => {
        try {
            const response = await zoomSdk.launchAppInMeeting();
        } catch (error) {
            console.log("error result", error);
        }
    };

    const getConfiguration = async () => {
        try {
            setLoading(true);
            const configResponse = await zoomSdk.config({
                popoutSize: {width: 480, height: 360},
                capabilities: [
                    "shareApp",
                    "getMeetingParticipants",
                    "getUserContext",
                    "getRunningContext",
                    "getMeetingContext",
                    "onMyUserContextChange",
                    "openUrl",
                    "authorize",
                    "onAuthorized",
                    "getAppContext",
                    "getMeetingUUID",
                    "onCollaborateChange"
                ],
            });

            zoomSdk.onCollaborateChange((event:any) => {
                console.log("Zoom Sdk Collaboration Change", event)
            })

            setZoomResponse(configResponse);
            zoomSdk.onRunningContextChange((event: any) => {
                // toast.success("Got running Cotext");
            })

            zoomSdk.onAuthorized(async (event: any) => {
                const {code, state, redirectUri} = event;

                const userContext: UserContextInterface =
                    await zoomSdk.getUserContext();

                const getAuthOptions_ = await getAuthorizationOptions();

                setZoomPayload({code, state, href: redirectUri});

                try {
                    const zoomMeetId_ = await getZoomMeetingId();
                    let userContextResult = {
                        screenName: "",
                        participantUUID: "",
                        role: "",
                        status: "",
                        id: "",
                    };
                    // try {
                    //   const getUserContext = await fetch(
                    //     "https://api.zoom.us/v2/users/me"
                    //   );
                    //   userContextResult = await getUserContext.json();
                    //   userContextResult = await zoomSdk.getAppContext();
                    //   console.log(userContextResult, "User Context result");
                    // } catch (e) {
                    //   console.log(e);
                    //   toast.error("Error Occured fetching user context");
                    // }

                    const response = await instance.post("/MeetApps/onauthorize", {
                        code,
                        state,
                        href: redirectUri,
                        meetingId: zoomMeetId_ ?? "",
                        sessionId: getAuthOptions_?.sessionId ?? "",
                    });
                    const zoomUrl = `${
                        window.location.origin
                    }/login?app=zoom&redirect=${baseUrl}/deeplink?sid=${
                        getAuthOptions_?.sessionId ?? ""
                    }`;

                    zoomSdk.openUrl({
                        url: zoomUrl,
                    });
                } catch (e) {
                    toast.error("Error Occured on 'OnAuthorize'");
                }
            });
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const getAuthorizationOptions = async () => {
        const userContextResult: { context: string } =
            await zoomSdk.getAppContext();

        const result = await instance.get("/MeetApps/authorize", {
            headers: {
                "x-zoom-app-context": userContextResult.context,
            },
        });
        return result.data;
    };

    const getMeetApps = async () => {
        try {
            const userContextResult: { context: string } =
                await zoomSdk.getAppContext();

            const result = await instance.get("/MeetApps/authorize", {
                headers: {
                    "x-zoom-app-context": userContextResult.context,
                },
            });
            // const
            const authorizeOptions = result.data;
            setMeetConfig(authorizeOptions);
            try {
                const zoomAuthResponse = await zoomSdk.authorize(authorizeOptions);
            } catch (e) {
                console.error(e);
            }
        } catch (e) {
            toast.error("Config Error");
        } finally {
            toast.success("config done");
        }
    };

    // meetapps / home / aoom;

    const getZoomMeetApps = async () => {
        try {
            const result = await instance.get("/meetapps/home/zoom");
        } catch (e) {
            console.log(e);
        }
    };

    const getZoomMeetingId = async () => {
        try {
            const meetingContext = await zoomSdk.getMeetingUUID();
            if (meetingContext) {
                // setZoomMeetingId(meetingContext.meetingID);
            } else {
                toast.error("could not get zoom meeting id");
            }
            setZoomMeetingId(meetingContext.meetingUUID);
            return meetingContext.meetingUUID;
        } catch (e) {
            toast.error(JSON.stringify(e));
            return null;
        }
    };

    // useEffect(() => {
    //   toast.info("Use effect triggered");
    //   console.log("Use Effect triggered");
    //   const loadZoomSdk = () => {
    //     toast.info("Started After page load ");
    //     const script = document.createElement("script");
    //     script.src = "https://appssdk.zoom.us/sdk.js";
    //     script.async = true;

    //     script.onload = async () => {
    //       // SDK is loaded
    //       toast.info("Script loaded");
    //       await getConfiguration();
    //     };
    //     document.body.appendChild(script);
    //   };
    //   loadZoomSdk();
    // }, []);

    window.addEventListener("load", async () => {
        getConfiguration();
        // Log the cookie

        const stringValue = document.cookie.split(";");
        const foundValue = stringValue.find((item) =>
            item.toLowerCase().includes("presenta-token")
        );
        if (foundValue) {
            const payload = {
                email: "",
                expiresIn: "",
                fullName: "",
                phone: "",
                roles: "",
                token: foundValue?.split("=")[1],
            };
            localStorage.setItem("user", JSON.stringify(payload));
            try {
                const result = await instance.get("/Account/GetProfile");
                localStorage.setItem(
                    "user",
                    JSON.stringify({...result.data, token: foundValue?.split("=")[1]})
                );
                // navigate("/external/presentations");
                if (zoomResponse?.runningContext == "inMainClient") {
                    navigate("/external/presentations");
                }
                // window.location.href = "/external/presentations";
            } catch (e) {
                toast.error("Unexpected error occure");
            }
        }
    });

    return (
        <Row
            justify="center"
            className="h-screen overflow-hidden max-h-screen min-h-screen items-center justify-center bg-[#F2F2F2]"
        >
            <Col className="h-full" lg={20} xs={22}>
                <Row justify={"center"} className="h-full" align={"middle"}>
                    <Col className="min-h-[85%] pr-4" lg={12} xl={10} md={18} xs={24}>
                        <div className="lg:bg-[#fff] h-full p-4 rounded-lg">
                            <div className="lg:flex hidden  justify-center">
                                <img src={logoReduced} alt=""/>
                            </div>
                            <div className="flex justify-center lg:hidden">
                                <img src={logo} alt=""/>
                            </div>
                            <div className="mt-8">
                                <p className="lg:text-xl text-lg  font-grotesk font-semibold">
                                    Hi, what will you like to do ?
                                </p>
                            </div>
                            {loading ? (
                                "Loading..."
                            ) : zoomResponse?.runningContext == "inMainClient" ? (
                                <div>
                                    <CButton
                                        text="Start a zoom meeting"
                                        variant="orangeBg"
                                        fullWidth
                                        action={() => {
                                            startZoomMeeting();
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-between w-full">
                                    <div className="border-[#E2E4E9] w-full my-8 border bg-[#FFFFFF] rounded-2xl p-2">
                                        <div className="bg-[#F6F6F6] rounded-2xl p-4">
                                            <p className="text-[#474747] font-grotesk font-bold mb-3 text-sm lg:text-base">
                                                Host a meeting
                                            </p>

                                            <CButton
                                                action={async () => {
                                                    if (!validateToken()) {
                                                        // const
                                                        const zoomMeetId = await getZoomMeetingId();

                                                        if (zoomMeetId) {
                                                            const meetConfig_ = await getMeetApps();
                                                            return;
                                                            const zoomUrl = `${window.location.origin}/login?redirect=true&app=zoom&meetingId=${zoomMeetId}`;

                                                            zoomSdk.openUrl({
                                                                url: zoomUrl,
                                                            });
                                                        } else {
                                                            toast.error(
                                                                "Ooops! Could not redirect to login page"
                                                            );
                                                        }
                                                    } else {
                                                        navigate("/external/presentations");
                                                    }
                                                }}
                                                variant="orangeBg"
                                                fullWidth
                                                text="Start"
                                            />
                                        </div>
                                    </div>

                                    <p className="justify-center font-grotesk lg:text-lg text-base font-semibold">
                                        Or
                                    </p>
                                    <div className="border-[#E2E4E9] w-full my-8 border bg-[#FFFFFF] rounded-2xl p-2">
                                        <div className="bg-[#F6F6F6] rounded-2xl p-4">
                                            <p className="text-[#474747] font-grotesk font-bold text-sm lg:text-base">
                                                Join a meeting
                                            </p>
                                            <div className="mt-2">
                                                <TextInput
                                                    name="meetingCode"
                                                    placeholder="000-000"
                                                    label="Enter your meeting code"
                                                    change={setMeetingId}
                                                />
                                                <div className="mt-4">
                                                    <CButton
                                                        variant="dark"
                                                        fullWidth
                                                        text="Join a meeting"
                                                        action={() => navigateToJoinMeeting()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col
                        className="external-landing-bg hidden lg:block pl-4 min-h-[85%] h-[85%] "
                        xs={12}
                        xl={10}
                    >
                        <div
                            className="w-full flex flex-col justify-between  h-full  border bg-[#EAEBEF8A] py-8 px-6  rounded-2xl border-[#fff]">
                            <div>
                                <div>
                                    <p className="text-[#0A0A0A] text-4xl">Present in Zoom</p>
                                    <p className="text-[#0A0A0A63] text-lg my-8">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Ipsa totam quis harum explicabo officiis, ex a iure
                                        accusantium reiciendis eveniet id quisquam repellat error
                                        magnam possimus ut commodi nam iusto.
                                    </p>
                                </div>

                                <div>
                                    {sideContent.map((item) => {
                                        return (
                                            <div key={item} className="flex items-center my-3 gap-3">
                        <span>
                          <img
                              width={20}
                              height={20}
                              src={externalCheck}
                              alt=""
                          />
                        </span>
                                                <p className="text-base text-[#0A0A0AB5]">{item}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>
                <span>
                  <img src={analyticsIcon}/>
                </span>
                            </div>
                        </div>
                        <div className="flex mt-2 text-right justify-end w-full ">
              <span className=" flex  items-center border-[green]">
                <span>
                  <img width={20} height={20} src={blackDot}/>
                </span>
                <span>
                  <img width={20} height={20} src={greyDot}/>
                </span>
              </span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ExLandingPage;
