import { useNavigate, useParams } from 'react-router-dom';

// Assets
import arrowRight from 'assets/svg/arrowRight.svg';
import menu from 'assets/svg/hamburger.svg';
import { Tooltip } from 'antd';
import DocumentDetails from 'components/Admin/Workspace/DocumentDetails';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocument,
  getDocumentAnalytics,
  getDocumentUsage,
  getDocumentVisits,
} from 'store/Workspace/workspaceSlice';

export default function WorkspaceDocumentDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentId } = useParams();

  // Store
  const document = useSelector((state) => state.workspace.document.data);
  const documentUsage = useSelector((state) => state.workspace.document.usage);
  const documentAnalytics = useSelector(
    (state) => state.workspace.document.analytics
  );
  const documentVisits = useSelector(
    (state) => state.workspace.document.visits
  );

  useEffect(() => {
    dispatch(getDocument(documentId));
    dispatch(getDocumentAnalytics(documentId));
    dispatch(getDocumentVisits(documentId));
    dispatch(getDocumentUsage(documentId));
  }, []);

  return (
    <section>
      {/* <nav className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'> */}
      <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
        <section className='w-[95%] mx-auto'>
          <section className='flex items-center justify-between w-full lg:w-[35%] overflow-hidden'>
            <img src={menu} alt='open sidenav' className='block lg:hidden' />

            {/* <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'> */}
            <div className='leading-tight text-right lg:text-left justify-items-end lg:justify-items-start'>
              <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                Library
              </h1>

              <div className='flex items-center mt-[-3px]'>
                <p
                  className='lg:text-sm text-xs cursor-pointer'
                  onClick={() => {
                    navigate(`/admin/workspace`);
                  }}
                >
                  Library
                </p>

                <img src={arrowRight} alt='next' className='w-3' />
                {document?.title.length > 30 ? (
                  <Tooltip
                    title={
                      <p className='text-black capitalize'>{document?.title}</p>
                    }
                    color='white'
                    placement='top'
                  >
                    <p className=''>{`${document?.title.slice(0, 30)}${
                      document?.title.length > 30 ? '...' : ''
                    }`}</p>
                  </Tooltip>
                ) : (
                  <p className=''>{document?.title}</p>
                )}
              </div>
            </div>
          </section>
        </section>
      </nav>

      {document && documentAnalytics && (
        <DocumentDetails
          document={document}
          // members={document.members}
          documentVisits={documentVisits}
          documentUsage={documentUsage}
          documentAnalytics={documentAnalytics}
        />
      )}
    </section>
  );
}
