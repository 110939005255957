import React from 'react';
import emptyT from 'assets/image/empty-table-placeholder.png';

export const TableEmpty = () => {
  return (
    <div className='h-[200px] rounded-lg my-2 flex flex-col justify-center items-center'>
      <img src={emptyT} alt='No data found' />
      <p className='text-sm'>No record found or created</p>
    </div>
  );
};
