import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//assets
import logo from "assets/svg/fullLogo.svg";
import twitter from "assets/svg/twitter.svg";
import youtube from "assets/svg/youtubeOrange.svg";
import linkedIn from "assets/svg/linkedInOrange.svg";
import facebook from "assets/svg/facebookOrange.svg";
import instagram from "assets/svg/instagramOrange.svg";

export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <footer className="my-8 w-[80%] mx-auto">
      <section className=" py-10 [ flex justify-between ] [ flex-col lg:flex-row ] [ gap-y-4 ] ">
        <div>
          <img src={logo} alt="Presenta Logo" className="w-24" />

          <p className="mt-4 font-semibold">Transforming Presentations</p>
        </div>

        <section className="flex [ lg:gap-x-40  gap-y-8 ] [ flex-col lg:flex-row ]">
          <div className=" [ flex flex-col ] gap-y-4  ">
            <h4 className="font-bold">Company</h4>

            <Link to="/about-us">
              <p className="text-sm font-light">About us</p>
            </Link>

            <Link to="/about-us">
              <p className="text-sm font-light">Privacy policy</p>
            </Link>

            <Link to="/pricing">
              <p className="text-sm font-light">Pricing</p>
            </Link>
          </div>

          <div className=" [ flex flex-col ] gap-y-4 ">
            <h4 className="font-bold">Products</h4>

            <Link to="/about-us">
              <p className="text-sm font-light">Presentation</p>
            </Link>

            <Link to="/about-us">
              <p className="text-sm font-light">Data room</p>
            </Link>

            <Link to="/about-us">
              <p className="text-sm font-light">File sharing</p>
            </Link>
          </div>

          <div className=" [ flex flex-col ] gap-y-4 ">
            <h4 className="font-bold">Contact Us</h4>

            <Link to="/about-us">
              <p className="text-sm font-light">Hello@thepresenta.com</p>
            </Link>

            <div className="flex items-center gap-x-4">
              <Link to="https://twitter.com/thepresenta">
                <img src={twitter} alt="twitter" />
              </Link>
              <img src={linkedIn} alt="linkedIn" />
              <img src={facebook} alt="facebook" />
              <img src={instagram} alt="instagram" />
            
              <Link to="https://www.youtube.com/@the_presenta">
                <img src={youtube} alt="youtube" />
              </Link>
            </div>
          </div>
        </section>
      </section>
    </footer>
  );
}
