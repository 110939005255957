import React from "react";

//component
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//assets
import sand from "assets/svg/sand.svg";
import globe from "assets/svg/globe.svg";
import avatar from "assets/svg/avatar.svg";
import logo from "assets/svg/fullLogo.svg";
import bigSlide from "assets/svg/bigSlide2.svg";

export default function WaitingArea() {
  return (
    <section className="relative">
      {/* nav */}
      <nav className="fixed top-0 z-30 w-full h-[4rem] py-2 bg-white border-b ">
        <section className="flex">
          <div className="px-6 py-3 border-r">
            <img src={logo} alt="Presenta Logo" className="w-24" />
          </div>

          <FlexContainer extraClass="px-4 w-full items-center">
            <div className="flex gap-x-4">
              <h2 className="font-bold font-grotesk">Syne pitch deck</h2>

              <section className="flex gap-x-4">
                <div className="flex items-center text-sm font-semibold gap-x-1 text-primary">
                  <img src={globe} alt="change status" />
                  <p className="mt-[-1px]">Public</p>
                </div>
              </section>
            </div>

            <div className="flex items-center gap-x-2">
              <img
                src={avatar}
                alt="user's avatar"
                className="w-8 h-8 rounded-full"
              />
              <div>
                <h3 className="text-xs font-bold">Olaitan akinlade</h3>
                <p className="text-xs ">Olaitan@presenta.com</p>
              </div>
            </div>
          </FlexContainer>
        </section>
      </nav>
      {/*  */}

      {/* main */}
      <section className=" mt-[4rem] absolute bg-orange30 h-[89vh] flex flex-col items-center w-full  ">
        <section className=" w-[80%]   my-10 mx-auto flex items-center lg:flex-row flex-col-reverse justify-between gap-x-4">
          <div className="w-1/2 ">
            <img
              src={bigSlide}
              alt="presentation slide"
              className={` rounded cursor-pointer  `}
            />
          </div>

          <div className="flex flex-col justify-center ">
            <img
              src={sand}
              alt="presentation slide"
              className={` spin mx-auto rounded cursor-pointer w-10 `}
            />

            <div className="my-8 text-center">
              <h3 className="text-lg font-semibold">
                Hang on, This presentation will start soon....
              </h3>
              <p className="text-sm">
                you can only upload a PowerPoint or pdf format
              </p>
            </div>
          </div>

          <div></div>
        </section>
      </section>
    </section>
  );
}
