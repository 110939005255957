import React from "react";

//assets
import image1 from "assets/image/loginImg4.png";

export default function FourthSlide() {
  return (
    <div className="flex items-center justify-center ">
      <img src={image1} alt="login page" className="w-[82%] z-50 " />
    </div>
  );
}
