import React from "react";

//assets
import image2 from "assets/image/loginImg2.png";

export default function SecondSlide() {
  return (
    <div className="flex ">
      <img src={image2} alt="login page" className="w-[75%] mx-auto lh-ss" />
    </div>
  );
}
