import React from "react";

//components
import BlackBackground from "components/Website/About/BlackBackground";
import Banner from "components/Website/Pricing/Banner";
import PricePlans from "components/Website/Pricing/PricePlans";

export default function Pricing() {
  return (
    <section>
      <Banner />
      <PricePlans/>
      <BlackBackground />
    </section>
  );
}
