import { useState } from 'react';

type DraggedItemType = 'section' | 'document' | 'null';

interface Props {
  dropAreaPosition: number;
  onDropSection: () => void;
  draggedItemType: DraggedItemType;
}

export default function DropAreaSection({
  onDropSection,
  draggedItemType,
}: Props) {
  const [showDrop, setshowDrop] = useState(false);
  return (
    <section className='relative'>
      <section
        onDragEnter={() => draggedItemType === 'section' && setshowDrop(true)}
        onDragLeave={() => setshowDrop(false)}
        className={`${
          showDrop
            ? 'w-full h-24 border border-dashed border-gray-300 rounded-lg p-4 mb-4 opacity-100 transition-all duration-200 ease-in-out'
            : 'opacity-0'
        }`}
        onDrop={() => {
          onDropSection();
          setshowDrop(false);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        Drop here
      </section>
    </section>
  );
}
