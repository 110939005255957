import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';

//assets
import presentNow from 'assets/svg/presentNow.svg';
import transitionIcon from 'assets/svg/transitionIcon.svg';
import activeTransitionIcon from 'assets/svg/activeTransitionIcon.svg';

//libraries
import { Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { imageStream } from 'utils/imageStream';
import { getSlidesState } from 'store/Slides/slidesSlice';

export default function DragDropCard({
  card,
  indexId,
  setActive,
  dragEnter,
  imageCache,
  activeImage,
  transitionDragID,
  setTransitionDragID,
}) {
  const { id, questionType, question, rowKey } = card;

  const [img, setImg] = useState(null);

  const { presentationUI } = useSelector(getSlidesState);
  const { activeSlide } = presentationUI ?? {};

  //USEEFFECT
  useEffect(() => {
    const getStream = async () => {
      const cachedImg = imageCache[id];
      if (cachedImg) {
        setImg(cachedImg);
      } else {
        const url = await imageStream(id);
        imageCache[id] = url;
        setImg(url);
      }
    };
    getStream();
  }, [id, imageCache]);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  //usestates
  // const [closeOptions, setCloseOptions] = useState(true);
  const [setIsFileUploadModalOpen] = useState(false);

  const items = [
    {
      key: '1',
      label: (
        <div className='flex gap-x-2'>
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Fade in</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Rotate </p>
        </div>
      ),
    },

    {
      key: '3',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Slide-in left</p>
        </div>
      ),
    },

    {
      key: '3',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Slide-in right</p>
        </div>
      ),
    },

    {
      key: '4',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Slide-in top</p>
        </div>
      ),
    },

    {
      key: '5',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={presentNow} alt='create new folder' className='w-4' />
          <p>Slide-in bottom</p>
        </div>
      ),
    },
  ];

  return (
    <section
      className='relative touch-none'
      onDragEnter={(e) => dragEnter(e, id)}
    >
      {/* {transitionDragID === id && (
        <div className="font-grotesk absolute top-[-20%]  shadow-lg right-[-10%] z-50  py-2 text-sm w-24 rounded-md  overflow-y-scroll bg-white ">
          <section className="relative pt-1 ">
            <div className="">
              <p className="px-1 cursor-pointer hover:bg-gray-100">Fade in</p>
              <p className="px-1 cursor-pointer hover:bg-gray-100">Fade out</p>
              <p className="px-1 cursor-pointer hover:bg-gray-100">Ease in</p>
              <p className="px-1 cursor-pointer hover:bg-gray-100">Ease out</p>
            </div>

            <div className="absolute top-[-8px] right-0 z-30 bg-white cursor-pointer">
              <i
                className="pi pi-times"
                style={{ fontSize: "0.7rem" }}
                onClick={() => setTransitionDragID(0)}
              ></i>
            </div>
          </section>
        </div>
      )} */}
      <div
        onClick={() => setActive(card)}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
        className={`flex items-center justify-center w-full gap-x-1 ${
          activeSlide?.id === id ? '' : ' '
        } `}
      >
        <div className=''>
          <p
            className={`p-1 px-2 text-[10px]  rounded-full  font-bold  ${
              (!rowKey && activeSlide?.id === id) ||
              (rowKey && activeImage?.rowKey === rowKey)
                ? ' text-white bg-primary'
                : 'text-gray500 bg-gray10'
            } `}
          >
            {indexId}
          </p>
        </div>

        <div className='relative'>
          <div className='relative'>
            <div className='absolute top-0 right-0 w-full h-full bg-[#00000010] rounded-lg'></div>

            {questionType && (
              <section
                className={` text-center flex flex-col items-center justify-center gap-x-1 w-[10rem] h-[6.125rem] rounded-lg cursor-pointer  ${
                  activeImage?.id === id
                    ? ' border-2 border-primary p-1'
                    : 'shadow-lg '
                }`}
              >
                <p className='font-semibold'> {question}</p>{' '}
                <small>({questionType})</small>
              </section>
            )}

            {!questionType && (
              <div>
                {img ? (
                  <img
                    src={img}
                    alt='presentation slide'
                    className={`w-[10rem] h-[6.125rem] rounded-lg cursor-pointer object-cover   ${
                      activeSlide?.id === id ? ' bg-primary p-1' : 'shadow-lg '
                    }`}
                  />
                ) : (
                  <div
                    className={`w-[10rem] h-[6.125rem] rounded-lg cursor-pointer flex justify-center items-center`}
                  >
                    <ClipLoader size={12} color='gray' />
                  </div>
                )}
              </div>
            )}
          </div>

          {/* <div>
            <Dropdown
              dropdownRender={(menu) => (
                <Menu>
                  {items.map((item) => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                  ))}
                </Menu>
              )}
              trigger={["click"]}
            >
              <img
                src={card?.transition ? activeTransitionIcon : transitionIcon}
                alt="insert transition"
                className="absolute z-40 top-1 right-1"
              />
            </Dropdown>
          </div> */}
        </div>
      </div>
    </section>
  );
}
