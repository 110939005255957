import { useEffect, useState } from 'react';

// Assets
import logo from 'assets/svg/fullLogo.svg';
import emailIcon from 'assets/svg/sms-icon2.svg';

// Slices
import { acceptTeamInvite } from 'store/Teams/teamsSlice';

// Components
import Label from 'components/UI/Label';
import LoginCarousel from 'components/Authentication/Login/Carousel';
import AuthInput from 'components/UI/Inputs/AuthInput';
import Loader from 'components/UI/Loader';

// Utils
import { baseUrl } from 'utils/baseUrl';
import emailValidation from 'utils/emailValidation';

// Libraries
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import axios from 'axios';

export default function AcceptTeamInvite() {
  const { friendlyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useState
  const [isNonAlphanumeric, setIsNonAlphanumeric] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    teamId: '',
  });

  //useeffect
  useEffect(() => {
    const { name, email, password } = userData;
    setIsButtonActive(name !== '' && email !== '' && password !== '');
  }, [userData]);

  useEffect(() => {
    const acceptInvitation = async () => {
      if (friendlyId) {
        console.log('First', friendlyId);
        const encodedURI = encodeURIComponent(friendlyId);
        console.log('Second', encodedURI);

        const { type, payload } = await dispatch(acceptTeamInvite(encodedURI));
        if (type.includes('fulfilled')) {
          if (payload.teamId && payload.email) {
            setUserData({
              teamId: payload.teamId,
              email: payload.email,
            });
            setIsLoading(false);
          } else {
            toast.success("You've successfully join a Team");

            setTimeout(() => {
              navigate('/login');
            }, 3000);
          }
        } else {
          toast.error('Network Error');
          navigate('/');
        }
      }
    };
    acceptInvitation();
  }, [dispatch, friendlyId, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });

    if (name === 'password') {
      setIsDigit(/\d/.test(value));
      setIsLengthValid(value.length >= 8);
      setIsNonAlphanumeric(/[^a-zA-Z0-9]/.test(value));
    }
  };

  const registerUser = (e) => {
    e.preventDefault();

    const { name, email, password, teamId } = userData;
    if (!name) {
      toast.error('Enter your fullname');
      return;
    }

    if (!email) {
      toast.error('Enter your email address');
      return;
    }

    if (!emailValidation.checkEmail(email)) {
      return;
    }

    if (!password) {
      toast.error('Enter a password');
      return;
    }

    setIsButtonActive(false);
    setIsLoading(true);
    const data = {
      email,
      password,
      fullName: name,
      teamId,
    };

    axios
      .post(`${baseUrl}/Invite/Register`, data)
      .then((response) => {
        if (response) {
          console.log(response);
          localStorage.setItem('email', email);
          localStorage.setItem('ref', response?.data?.reference);

          toast.success("You've successfully join a Team");
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
      })
      .catch((err) => {
        let errorMsg =
          err?.response?.data?.message ??
          err.response?.data?.title ??
          err?.message;

        toast.error(errorMsg);
        console.log(errorMsg, 'registration');
      })
      .finally(() => {
        setIsLoading(false);
        setIsButtonActive(true);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section className='flex justify-between h-screen overflow-hidden font-grotesk '>
          <section className='lg:w-[40%] w-full px-10 lg:py-2 py-10 overflow-y-scroll  hide-scrollbar'>
            <section className=' mt-[3%] w-[90%] mx-auto  '>
              <Link to='/'>
                <div>
                  <img
                    src={logo}
                    alt='Presenta Logo'
                    className='w-20 lg:w-24'
                  />
                </div>
              </Link>

              <h1 className='font-bold lg:text-[2rem] text-2xl  text-left  mt-6 '>
                Create a password to join your team
              </h1>

              <section className='mt-4'>
                <section className='mb-4 bg-[#FAFAFA] border border-[#EBF1FF] rounded-xl p-3'>
                  <div className='bg-[#F6F6F6] border border-[#D3D3D3] px-2 w-fit rounded-full py-1 mb-3 flex gap-x-2'>
                    <img src={emailIcon} alt='email' />
                    <p className='text-black font-medium'>{userData.email}</p>
                  </div>
                  <p className='text-[#1D1E1F]'>
                    Welcome to presenta, your team members are waiting to
                    collaborate with you, kindly create a password to access
                    your team
                  </p>
                </section>

                <form className='' onSubmit={registerUser}>
                  <div>
                    <Label text='Full name' />
                    <AuthInput
                      type='text'
                      placeholder='Enter your full name'
                      name='name'
                      value={userData.name || ''}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      autoComplete={false}
                    />
                  </div>

                  <div className='mb-2'>
                    <Label text='Password' />
                    <AuthInput
                      placeholder='Enter your password'
                      isPassword={true}
                      name='password'
                      value={userData.name || ''}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      autoComplete={false}
                    />
                    <ul className='mt-2'>
                      <li
                        className={
                          isLengthValid ? 'text-green-500' : 'text-red-500'
                        }
                      >
                        Passwords must be at least 8 characters.
                      </li>
                      <li
                        className={isDigit ? 'text-green-500' : 'text-red-500'}
                      >
                        Passwords must have at least one digit ('0'-'9').
                      </li>
                      <li
                        className={
                          isNonAlphanumeric ? 'text-green-500' : 'text-red-500'
                        }
                      >
                        Passwords must have at least one non-alphanumeric
                        character.
                      </li>
                    </ul>
                  </div>

                  <button
                    className={`w-full py-3  mt-2 text-sm text-white bg-black rounded-md ${
                      isButtonActive
                        ? 'opacity-100'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                    disabled={!isButtonActive}
                    type='submit'
                  >
                    {isLoading ? (
                      <ClipLoader color='white' size={12} />
                    ) : (
                      'Sign up'
                    )}
                  </button>
                </form>
              </section>
            </section>
          </section>

          {/* carousel */}
          <section className='lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 '>
            <LoginCarousel />
          </section>
        </section>
      )}
    </>
  );
}
