import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Assets
import GroupRow from './GroupRow';
import DropArea from './DropArea';

// Interface & types
import {
  ActiveCard,
  BriefcaseFolder,
  BriefcaseGroupDocument,
  SelectedDocument,
} from 'interfaces/Briefcase';
import GroupHeader from './GroupHeader';
import { getFolder } from 'store/Briefcase/briefcaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

interface ImageCache {
  [id: string]: string;
}

interface ActiveSection {
  // fromSectionId: string;
  fromPosition: number;
}
type DraggedItemType = 'section' | 'document' | 'null';

interface Props {
  setActiveCard: Dispatch<SetStateAction<ActiveCard | null>>;
  setActiveSection: Dispatch<SetStateAction<ActiveSection | null>>;
  onDropDocument: (id: string, index: number) => void;
  folder: BriefcaseFolder;
  folderIndex: number;
  spaceId: string;
  imageCache: ImageCache;
  setConfirmRemoveDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedDocument: Dispatch<SetStateAction<BriefcaseGroupDocument | null>>;
  setDraggedItemType: Dispatch<SetStateAction<DraggedItemType>>;
  draggedItemType: DraggedItemType;
  setConfirmRenameDocument: Dispatch<SetStateAction<boolean>>;
  handleDownload: (doc: BriefcaseGroupDocument) => void;
}

export default function GroupSection({
  folder,
  setActiveCard,
  setActiveSection,
  onDropDocument,
  folderIndex,
  spaceId,
  imageCache,
  setConfirmRemoveDocument,
  setSelectedDocument,
  setDraggedItemType,
  draggedItemType,
  setConfirmRenameDocument,
  handleDownload,
}: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [viewFolderDocuments, setViewFolderDocuments] = useState(true);
  const [documents, setDocuments] = useState<BriefcaseGroupDocument[]>([]);

  const briefcaseFoldersDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseFolders.documents
  );

  // useEffects
  useEffect(() => {
    if (folder.id === 'root_folder') return;
    const groupId = folder.id;
    dispatch(getFolder({ spaceId, groupId }));
  }, [dispatch, folder.id, spaceId]);

  useEffect(() => {
    briefcaseFoldersDocuments.forEach((group) => {
      if (group.id === folder.id) setDocuments(group.documents);
    });
  }, [briefcaseFoldersDocuments, folder.id]);

  // variables

  // functions

  return (
    <section
      className='mb-'
      draggable
      onDragStart={() => {
        setDraggedItemType('section');
        setActiveSection({
          fromPosition: folderIndex,
        });
      }}
      onDragEnd={() => setActiveCard(null)}
    >
      <GroupHeader
        folder={folder}
        folderIndex={folderIndex}
        setViewFolderDocuments={setViewFolderDocuments}
        viewFolderDocuments={viewFolderDocuments}
        spaceId={spaceId}
      />

      {viewFolderDocuments && (
        <section>
          <DropArea
            onDropDocument={() => onDropDocument(folder.id, 0)}
            dropAreaIndex={0}
            draggedItemType={draggedItemType}
          />
          {documents.map((doc, index) => (
            <React.Fragment key={index}>
              <GroupRow
                key={index}
                index={index}
                setActiveCard={setActiveCard}
                doc={doc}
                folderId={folder.id}
                imageCache={imageCache}
                spaceId={spaceId}
                setConfirmRemoveDocument={setConfirmRemoveDocument}
                setSelectedDocument={setSelectedDocument}
                setDraggedItemType={setDraggedItemType}
                setConfirmRenameDocument={setConfirmRenameDocument}
                handleDownload={handleDownload}
              />
              <DropArea
                onDropDocument={() => onDropDocument(folder.id, index + 1)}
                dropAreaIndex={index + 1}
                draggedItemType={draggedItemType}
              />
            </React.Fragment>
          ))}
        </section>
      )}
    </section>
  );
}
