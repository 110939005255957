import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";

//slices
import { getTeamMembers, inviteTeam } from "store/Teams/teamsSlice";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//utils
import emailValidation from "utils/emailValidation";

//components
import Label from "components/UI/Label";

const InviteTeamMember = (props, ref) => {
  const { closeModal } = props;
  const dispatch = useDispatch();

  // useref
  const emailRef = useRef();
  const roleRef = useRef();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  useImperativeHandle(ref, () => ({
    resetModal() {
      if (emailRef.current) {
        emailRef.current.value = '';
      }
      if (roleRef.current) {
        roleRef.current.value = '';
      }
      setIsLoading(false);
      setIsButtonActive(false);
    },
  }));

  //function
  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email?.value;
    const role = e.target.role?.value;

    if (!role || !email) {
      toast.error("Fill all details");
      return;
    }

    if (!emailValidation.checkEmail(email)) {
      return;
    }

    const data = {
      email,
      role,
    };

    setIsLoading(true);
    setIsButtonActive(true);
    dispatch(inviteTeam(data)).then(({ type, payload }) => {
      setIsLoading(false);
      if (type?.includes("fulfilled")) {
        toast.success(`${email} added successfully`);

        setIsButtonActive(false);
        dispatch(getTeamMembers());
        closeModal();
      }
    });
  };
  return (
    <section>
      <h2 className="mt-4 text-2xl font-semibold">Invite Team Member</h2>

      <form className="my-8" onSubmit={handleSubmit}>
        <div className="">
          <Label text="Email" />
          <input
            type="email"
            name="email"
            ref={emailRef}
            className="block w-full px-4 py-2 my-2 border rounded-lg outline-none"
          />
        </div>

        <div className="mt-4">
          <Label text="Role" />

          <select
            ref={roleRef}
            name="role"
            id=""
            className="block w-full px-4 py-2 my-2 border rounded-lg outline-none"
          >
            <option value="" disabled selected>
              Select a role
            </option>
            {/* <option value="owner">Owner</option> */}
            <option value="editor">Editor</option>
            <option value="viewer">Viewer</option>
          </select>
        </div>

        <button
          disabled={isButtonActive}
          className={`flex mt-8 items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            !isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading && <ClipLoader size={15} color="white" />}

          {!isLoading && (
            <>
              <p>Submit</p>
            </>
          )}
        </button>
      </form>
    </section>
  );
};

export default forwardRef(InviteTeamMember);
