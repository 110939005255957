import React from "react";

//assets
import avatar from "assets/svg/avatar.svg";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";

export default function Onboarding() {
  //functions
  const onboardingFunc = (e) => {
    e.preventDefault();
  };

  return (
    <section className="h-full py-10 bg-orange10">
      <section className="w-1/2 p-8 mx-auto bg-white rounded-md font-grotesk">
        <section className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <img
              src={avatar}
              alt="user's avatar"
              className="w-10 rounded-full"
            />

            <p className="text-lg font-bold">Welcome back, olaitan</p>
          </div>

          <div className="flex items-center h-8 px-4 text-sm rounded-md border-[1.5px] gap-x-2 ">
            <p className="font-semibold">Logout</p>
            <i className="pi pi-arrow-right"></i>
          </div>
        </section>

        <section className="w-full mt-12">
          <section className="w-[80%] mx-auto">
            <section className="my-4 text-center">
              <h1 className="text-3xl font-medium font-clashDisplay">
                Personal Information
              </h1>
              <p className="mt-1 text-sm">
                Fill up the form to continue your journey with us
              </p>
            </section>

            <form onSubmit={onboardingFunc} className="my-2 lg:pb-20">
              <div>
                <Label className="text-black" text="Job description" />
                <AuthInput
                  type="text"
                  name="job"
                  placeholder="What is your job description"
                />
              </div>

              <div className="my-4">
                <Label className="text-black" text="Phone number" />
                <AuthInput
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                />
              </div>

              <div className="my-4">
                <Label className="text-black" text="DOB" />
                <AuthInput
                  type="date"
                  placeholder="Enter your DOB"
                  name="dob"
                />
              </div>

              <button className="w-full py-3 my-4 text-sm text-white bg-black rounded-md">
                Continue
              </button>
            </form>
          </section>
        </section>
      </section>
    </section>
  );
}
