import React, {useEffect, useRef} from 'react'
import {PresentationDisplayInterface} from "../../../../interfaces/interfaces-data";
import {SlideIndicator} from "./SlideIndicator";
import useScreenWidth from "../../../../hooks/UseScreenWidth";
import {ImageCache} from "../../../User/SharedBriefcase/SlidesPreview";


export const DesktopSlider = ({activeDocumentId, presentationDetails, activeDocumentIndex, setActiveDocIndex}: {
    activeDocumentId: string,
    presentationDetails?: PresentationDisplayInterface,
    activeDocumentIndex: number,
    setActiveDocIndex: (val: number) => void
}) => {
    // const [imageCache, setImageCache] = useState<{ [key: string]: string }>({})
    const screenWidth = useScreenWidth();
    const minScreenWidth = 2000;
    const imageCache = useRef<ImageCache>({})
    useEffect(() => {
        if (activeDocumentId) {
            //Generate Image ref to be suitable enough for what sixtus has for his component
            if (presentationDetails) {
                // Now , we basically convert it to what it is supposed to be
                const payload: { [key: string]: string } = {}
                for (const val of presentationDetails[activeDocumentId]) {
                    imageCache.current[val.id] = val.imgUrl
                    payload[val.id] = val.imgUrl
                }

            }
        }
    }, [activeDocumentId, presentationDetails])
    return (
        <div className="absolute bottom-[30px] w-full">
            {presentationDetails && Object.keys(imageCache).length &&
                <SlideIndicator
                    imageCache={imageCache} slides={presentationDetails[activeDocumentId]}
                    screenWidth={screenWidth}
                    minScreenWidth={minScreenWidth}
                    activeDocumentIndex={activeDocumentIndex} setActiveDocumentIndex={setActiveDocIndex}/>}
        </div>

    )
}
