import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//slices
import {
  getAllWorkspace,
  getWorkspaceFolders,
  updateFolder,
} from "store/Workspace/workspaceSlice";

export default function RenameFolderTemplate({ folder }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const folderId = folder.id ?? folder.Id;

  //usestate
  const [isLoading, setIsLoading] = useState(false);

  //useeffect
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  //function

  const renameFolder = (e) => {
    if (e.key === "Enter") {
      // setNewFolder(false);
      setIsLoading(true);
      const newTitle = e.target.value;

      if (!newTitle) {
        toast.error("Enter folder's name");
        return;
      }

      if (!folderId) {
        toast.error("Folder's ID not present");
        return;
      }

      //dispatch function
      dispatch(updateFolder({ folderId, newTitle }))
        .then(({ type }) => {
          if (type.includes("fulfilled")) {
            toast.success("Folder renamed successfully");
            // to be looked at
            return dispatch(getAllWorkspace()).then(({ payload }) => {
              if (payload) {
                const id = payload.filter(
                  (el) => el?.title?.toLowerCase() === "personal"
                )?.[0]?.workspaceId;
                dispatch(getWorkspaceFolders(id));
              }
            });
          }
        })
        .finally(() => {
          setIsLoading(false); // Executes once after the entire promise chain
        });
    }
  };

  return (
    <section className="flex items-center justify-between w-full my-1 ">
      <div className="flex items-center justify-center gap-x-2">
        <input
          type="text"
          ref={inputRef}
          placeholder="Folder name"
          defaultValue={folder?.title ?? folder?.Title}
          className="outline-none text-sm font-semibold [ placeholder:text-xs ] bg-transparent "
          onKeyDown={renameFolder}
        />
        {isLoading && <ClipLoader size={12} color="gray" />}
      </div>
    </section>
  );
}
