import { Dispatch, SetStateAction } from 'react';

// Assets
import dragNdropIcon from 'assets/svg/drag-drop-icon.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import presentationIcon from 'assets/svg/presention-chart-icon.svg';
import eyeIcon from 'assets/svg/eye.svg';
import inboxIcon from 'assets/svg/inbox-arrow-down.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
import link from 'assets/svg/link.svg';
import downloadIcon from 'assets/svg/download-icon.svg';
import detailsIcon from 'assets/svg/details-icon.svg';

// Libraries
import { Dropdown, Menu, Tooltip } from 'antd';
import { ActiveCard, BriefcaseGroupDocument } from 'interfaces/Briefcase';
import IsPrivate from 'components/Admin/BriefCase/IsPrivate';
import { useNavigate } from 'react-router-dom';

// Utils
import FileThumbnailCache from 'components/Admin/BriefCase/FileThumbnailCache';
interface ImageCache {
  [id: string]: string;
}

type DraggedItemType = 'section' | 'document' | 'null';

interface Props {
  doc: BriefcaseGroupDocument;
  index: number;
  setActiveCard: Dispatch<SetStateAction<ActiveCard | null>>;
  folderId: string;
  imageCache: ImageCache;
  spaceId: string;
  setConfirmRemoveDocument: Dispatch<SetStateAction<boolean>>;
  setSelectedDocument: Dispatch<SetStateAction<BriefcaseGroupDocument | null>>;
  setDraggedItemType: Dispatch<SetStateAction<DraggedItemType>>;
  setConfirmRenameDocument: Dispatch<SetStateAction<boolean>>;
  handleDownload: (doc: BriefcaseGroupDocument) => void;
}

export default function GroupRow({
  doc: document,
  index,
  setActiveCard,
  folderId,
  imageCache,
  spaceId,
  setConfirmRemoveDocument,
  setSelectedDocument,
  setDraggedItemType,
  setConfirmRenameDocument,
  handleDownload,
}: Props) {
  const navigate = useNavigate();

  const items = [
    {
      key: '1',
      action: 'details',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={() => {
            // setSelectedDocument(document);
            // setConfirmRenameDocument(true);
          }}
        >
          <img src={detailsIcon} alt='file details' className='w-5' />
          <p>Details</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'download',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={() => {
            handleDownload(document);
          }}
        >
          <img src={downloadIcon} alt='download file' className='w-5' />
          <p>Download</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={() => {
            setSelectedDocument(document);
            setConfirmRenameDocument(true);
          }}
        >
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },

    {
      key: '5',
      action: 'share',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={link} alt='share file' className='w-5' />
          <p>Share</p>
        </div>
      ),
    },

    {
      key: '6',
      action: 'remove',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={() => {
            // consider adding this two in a function
            // setDocumentToBeRemovedId(document.documentId);
            // setDocumentToBeRemovedGroupId(folderId);
            setSelectedDocument(document);
            setConfirmRemoveDocument(true);
          }}
        >
          <img src={trash} alt='delete file' className='w-5' />
          <p>Remove</p>
        </div>
      ),
    },
  ];

  return (
    <article
      className=''
      draggable
      onDragStart={(e) => {
        e.stopPropagation();
        setDraggedItemType('document');
        setActiveCard({
          fromFolderId: folderId,
          fromIndex: index,
        });
      }}
      onDragEnd={() => setActiveCard(null)}
    >
      {/* Rows */}
      <section className='h-[36px] flex text-sm'>
        {/* table data 1  */}
        <section className={` w-[5%] flex h-full items-center justify-center`}>
          <div className='cursor-grab w-12 flex items-center pl-4'>
            <img
              src={dragNdropIcon}
              alt='drag'
              className='w-4 pointer-events-none'
            />
          </div>
        </section>
        {/* table data 2  Name */}
        <section className={`w-[45%]`}>
          <section
            className='w-[400px] cursor-pointer h-full flex items-center pl-5'
            onClick={() => {
              navigate(
                `/admin/mybriefcase/details/${spaceId}/${document.documentId}`
              );
            }}
          >
            <section className='flex items-center gap-x-4'>
              <div className='text-white text-xs rounded-lg overflow-hidden w-[36px] h-full flex items-center justify-center'>
                <FileThumbnailCache
                  imageCache={imageCache}
                  file={document}
                  height={36}
                  width={36}
                  imgTagheight={36}
                  imgTagwidth={36}
                />
              </div>

              {document.title.length > 50 ? (
                <Tooltip
                  title={
                    <p className='text-black capitalize'>{document.title}</p>
                  }
                  color='white'
                  placement='top'
                >
                  <p className='text-sm'>{`${document.title.slice(0, 50)}${
                    document.title.length > 50 ? '...' : ''
                  }`}</p>
                </Tooltip>
              ) : (
                <p className='text-sm'>{document.title}</p>
              )}
            </section>
          </section>
        </section>
        {/* Table data 3 */}
        <section className={`flex items-center justify-center h-full w-[22%]`}>
          <section className='flex gap-x-2 '>
            <div className='flex gap-x-2'>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-1 py-0.5 min-w-14 justify-center'>
                <img src={eyeIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{document.views}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-1 py-0.5 min-w-14 justify-center'>
                <img src={inboxIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{document.downloads}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-1 py-0.5 min-w-14 justify-center'>
                <img src={presentationIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{document.eventCount}</p>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* Table data 4 */}
        <section className={`w-[14%] h-full`}>
          <section className='flex gap-x-5 items-center justify-center h-full'>
            <p className='text-sm text-black'>Hidden</p>
            <IsPrivate
              isPrivate={document.isPrivate}
              spaceId={spaceId}
              documentId={document.documentId}
            />
          </section>
        </section>
        {/* table data 5  */}
        <section className={`w-[14%] pr-10`}>
          <div className='w-[100px flex justify-end items-center cursor-pointer h-full'>
            <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            // setFileIndex(options.rowIndex);
                            // handleOptionsClick(options.rowIndex, item.action);
                          }}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer py-1'>
                  <img src={ellipsis} alt='options' />
                </div>
              </Dropdown>
            </section>
          </div>
        </section>
      </section>
    </article>
  );
}
