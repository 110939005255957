import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalWorkspaceId: null,
  mostActiveFolder: null,
  openedFolders: [],
  fileToggled: false,
};

export const fileStructureSlice = createSlice({
  name: "fileStructure",
  initialState,
  reducers: {
    setPersonalWorkspaceId(state, { payload }) {
      state.personalWorkspaceId = payload;
    },

    setMostActiveFolder(state, { payload }) {
      state.mostActiveFolder = payload;
    },

    pushOpenedFolder(state, { payload }) {
      const folders = state.openedFolders;

      const isExist = folders?.filter((folder) => {
        return folder?.id === payload?.id;
      });
      if (isExist?.length > 0) {
        return;
      }

      const openedFolders = [...folders, payload];
      state.openedFolders = [...new Set(openedFolders)];
    },
    updateOpenedFolder(state, { payload }) {
      state.openedFolders = payload;
    },
    clearOpenedFolders(state) {
      state.openedFolders = [];
    },
    setFileToggled(state, payload) {
      state.fileToggled = payload.payload;
    },
  },
});

export const getFileStructureState = (state) => state.fileStructure;
export const {
  pushOpenedFolder,
  clearOpenedFolders,
  updateOpenedFolder,
  setMostActiveFolder,
  setPersonalWorkspaceId,
  setFileToggled,
} = fileStructureSlice.actions;

export default fileStructureSlice.reducer;
