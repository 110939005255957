import {PresentationHeader} from "components/UI/mobile/headers/presentation-header";
import {PresentationMobileWrapper} from "components/UI/wrapper/presentation-mobile-wrapper";
import fullScreenIcon from "assets/svg/fullScreenIcon.svg";
import React, {useEffect, useRef, useState} from "react";
import exclain from "assets/svg/exclaim.svg";
import fileBlack from "assets/svg/fileBlack.svg";
import arrowSquareLeft from "assets/svg/arrowLeftSquare.svg";
import arrowSquareRight from "assets/svg/arrowRightSquare.svg";
import instance from "lib/axiosInterceptor";
import {
    DocDetailsInterface,
    InnerPresentationInterface,
    PresentationDisplayInterface,
    PresentationDocInterface,
    SlideInterface,
} from "interfaces/interfaces-data";
import {getConnectionId,} from "utils/localStorageServices";
import {usePresentationConnectSignalR} from "hooks/SignalR/usePresentationConnectSignalR";
import {toast} from "sonner";
import {imageStream} from "utils/imageStream";
import {BottomDrawer} from "components/UI/Drawers/bottom-drawer";
import modalIcon from "assets/svg/briefCaseModalIcon.svg";
import {Col, Row, Switch} from "antd";
import pptActive from "assets/svg/pptActive.svg";
import settingsColored from "assets/svg/settingsColored.svg";
import {MoveSlide} from "hooks/SignalR/Invokers/Presenter/MoveSlide";
import {documentHasSlides} from "utils/documentHasSlides";
import {DesktopPresentationHeader} from "components/UI/Presentation/desktop/desktop-presentationHeader";
import {DesktopPrentationScreen} from "components/UI/Presentation/desktop/desktop-presentation-screen";
import {DesktopSlider} from "components/UI/Presentation/desktop/desktop-slider";
import {DesktopControl} from "components/UI/Presentation/desktop/desktop-control";
import {useParams} from "react-router-dom";
import {DesktopMenu} from "components/UI/Presentation/desktop/desktop-menu"

export const MobilePresentation = () => {
    const {urlPresentationId} = useParams();
    console.log("URL PARAM", urlPresentationId)
    const [presentationId, setPresentationId] = useState("");
    const documentRef = useRef<SlideInterface[]>([]);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [detailsError, setDetailsError] = useState(false);
    const [gridViewActive, setGridViewActive] = useState(false)

    // note that we need to save the active document id , so that we can know what document to load , if there isn't any , we load the first document
    const [activeDocumentId, setActiveDocumentId] = useState("");
    const [activeDocLoading, setActiveDocLoading] = useState(true);
    const [activeDocError, setActiveDocError] = useState(false);
    const [currentDocumentLoaded, setCurrentDocumentLoaded] = useState<{
        loaded: boolean;
        docId?: string;
    }>({loaded: false, docId: ""});
    const [activeSlideIndex, setActiveSlidIndex] = useState(0);

    const [activeDocument, setActiveDocument] =
        useState<PresentationDocInterface>();
    const [presentationDisplayDetails, setPresentationDisplayDetails] = useState<PresentationDisplayInterface>();
    const [activeDocumentDetails, setActiveDocumentDetails] =
        useState<DocDetailsInterface>();
    const [presentationDetails, setPresentationDetails] =
        useState<InnerPresentationInterface>();
    // const presentationId = getPresentationId.get()
    const {connected, connecting, reconnecting, error, connection} =
        usePresentationConnectSignalR();

    const handleChangeSlide = (item: number, type ?: "prev" | "next") => {
        setActiveSlidIndex(item);
        if (connected && presentationDisplayDetails) {
            connection?.invoke("MoveSlide", presentationId, item, activeDocumentId);
        }
    };

    // Make Api Call to get all the files
    const getImageUrl = async (id: string) => {
        try {
            const result = await imageStream(id);
            return result;
        } catch (e) {
            return "";
        } finally {
        }
    };

    const updateCurrentDocumentList = () => {
        // This is basically to get the details of the presentation

        if (!currentDocumentLoaded) {
            const prevData = {...presentationDisplayDetails};
            prevData[activeDocumentId] = documentRef.current;
            setPresentationDisplayDetails(prevData);
        }
    };

    const getDocumentDetail = async (docId?: string) => {
        if (!docId) {
            setActiveDocLoading(true);
        }
        try {
            const result = await instance.get(
                `/Document/${docId ? docId : activeDocumentId}`
            );
            // Now , all i have to do is get the slides and then , i have to process the slides in batches
            // This only applies to pptx, pdf , and excel files, docx
            if (result.data.slides.length && documentHasSlides(result.data.url)) {
                // Get the Slides and process in batches of 4
                // if they are slides basically , we then try to sort the document
                const reArrangedData = result.data.slides.sort(
                    (a: SlideInterface, b: SlideInterface) => {
                        if (a.position > b.position) {
                            return 1;
                        } else if (a.position < b.position) {
                            return -1;
                        }
                        return 0;
                    }
                );
                if (!docId) {
                    setActiveDocumentDetails(reArrangedData);
                }
                // Now we need to fetch the document Slides
                let index = 0;
                for (const slide of reArrangedData) {
                    reArrangedData[index]["imgUrl"] = await getImageUrl(slide.id);
                    documentRef.current.push(reArrangedData[index]);
                    index++;
                    // what i want to do is to basically show something to the user once the data is loaded , if five slides are ready then show it to the user
                    if (reArrangedData.length > 5 && index === 4) {
                        setActiveDocLoading(false);
                        setPresentationDisplayDetails((prev) => ({
                            ...prev,
                            [docId ? docId : activeDocumentId]: [
                                ...reArrangedData.slice(0, 5),
                            ],
                        }));
                    }
                }
                setCurrentDocumentLoaded({loaded: true, docId: docId ?? ""});
            } else {
                // we still need to get the document url , but in the case , ideally we would always only have on image
                // we need to form a new object from this , so that it conforms with the existing flow
                const slideObject: SlideInterface[] = [
                    {
                        id: "Generate_random_idxyseff",
                        url: "",
                        position: 1,
                        title: "File_1",
                        note: result.data.note ?? "",
                        imgUrl: "",
                    },
                ];
                try {
                    slideObject[0]["imgUrl"] = (await getImageUrl(result.data.id)) ?? "";
                    documentRef.current.push(slideObject[0]);
                    setActiveDocLoading(false);
                    setCurrentDocumentLoaded({
                        loaded: true,
                        docId: docId ? docId : activeDocumentId,
                    });
                } catch (e) {
                    toast.error("FE: Error Loading document");
                }
                // get the document imageStream
            }
        } catch (e) {
            setActiveDocError(true);
        } finally {
            setActiveDocLoading(false);
        }
    };

    const getPresentationDetails = async () => {
        setDetailsLoading(true);
        try {
            const result = await instance.get(`/presentation/${urlPresentationId}`);
            setPresentationDetails(result.data);
            setPresentationId(result.data.presentation.id)
            // bsed off what is gotten here , set the first document as the default document or the document that is saved on the localStorage

            if (result.data.doc[0].documentId) {
                // setPresenterActiveDocumentId(result.data.doc[0].documentId);
                setActiveDocumentId(result.data.doc[0].documentId);
                setActiveDocument(result.data.doc[0]);
            } else {
                toast.error("Active document id not gotten");
            }
        } catch (e) {
            setDetailsError(true);
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleDocumentSwitch = (prev: string, val: string) => {
        // Save the last page of the document or set it to the original one
        const originalObject = {...presentationDetails};
        const activeDoc = presentationDetails?.doc.find(
            (item) => item.documentId == val
        );
        const prevIndex = presentationDetails?.doc.findIndex(
            (item) => item.documentId == prev
        );
        if (originalObject.doc && prevIndex && prevIndex !== -1) {
            originalObject.doc[prevIndex].activeIndex = activeSlideIndex;
            //@ts-ignore
            setPresentationDetails(originalObject);
        }
        setActiveDocumentId(val);
        setActiveDocument(activeDoc);
        // Right here try to ensure that the previous index or page is the active one when the page switches
        setActiveSlidIndex(activeDoc?.activeIndex ?? 0);
    };
    useEffect(() => {
        // Start connecttion immediately the file is connected
        if (connected) {
            connection
                ?.invoke("Attend", presentationId, 0, getConnectionId())
                .then((res) => {
                    console.log(res, "Attend success");
                })
                .catch((e) => {
                    console.log(e, "Attend Failed");
                });
        }
    }, [connected]);

    // i don't think i really need to see if it is connected or not , so , i'll go on the limb here and not check it and then see what happens

    // useEffect(() => {
    //   console.log(isConnected, connectionStatus, "connected to signal R");
    //   if (!isConnected || !connectionStatus) {
    //     dispatch(connectSignalR()).then((response) => {
    //       console.log(response);
    //     });
    //   }
    // }, [isConnected, connectionStatus]);

    useEffect(() => {
        getPresentationDetails();
    }, []);

    useEffect(() => {
        if (
            activeDocumentId &&
            !Object.keys(presentationDisplayDetails ?? {}).includes(activeDocumentId)
        ) {
            getDocumentDetail();
        }
    }, [activeDocumentId]);

    useEffect(() => {
        if (currentDocumentLoaded.loaded) {
            // get active document id then basically update with the correct value and after all of this is done , we set every other value back to its original form
            const existingDetails = {...presentationDisplayDetails};
            // const docKeys = Object.keys(presentationDisplayDetails);
            // const activeIndex = docKeys.findIndex(
            //   (item) => item == activeDocumentId
            // );
            // existingDetails[existingDetails[activeIndex]] = documentRef.current;
            console.log("before details", existingDetails);
            existingDetails[
                currentDocumentLoaded.docId
                    ? currentDocumentLoaded.docId
                    : activeDocumentId
                ] = documentRef.current;
            setPresentationDisplayDetails((prev) => ({
                ...prev,
                ...existingDetails,
            }));
            // setCurrentDocumentLoaded({ loaded: true, docId: "" });
            documentRef.current = [];

            // After this first one is done , then there is a need to save for the remaining documents, now trigger the rest of the docs
            if (presentationDetails && presentationDetails?.doc?.length > 1) {
                const foundIndex = presentationDetails?.doc.findIndex(
                    (item) =>
                        item.documentId ==
                        (currentDocumentLoaded.docId
                            ? currentDocumentLoaded.docId
                            : activeDocumentId)
                );
                console.log(foundIndex);
                if (foundIndex < presentationDetails.doc.length - 1) {
                    const newDocId = presentationDetails.doc[foundIndex + 1].documentId;
                    getDocumentDetail(newDocId);
                    setCurrentDocumentLoaded({loaded: false, docId: newDocId});
                }
            }
        }
    }, [currentDocumentLoaded]);

    return (
        <>
            <div className="block lg:hidden">
                <PresentationMobileWrapper>
                    {connection?.state === "Connected" && (
                        <PresentationHeader
                            meetingId={presentationDetails?.presentation.friendlyId}
                            connection={connection}
                            presentationId={presentationId ?? ""}
                        />
                    )}

                    <PresentationScreen
                        activeDocument={activeDocument}
                        activeSlide={
                            presentationDisplayDetails?.[activeDocumentId][activeSlideIndex]
                        }
                        error={activeDocError}
                        loading={activeDocLoading}
                    />
                    {presentationDisplayDetails && (
                        <Sliders
                            handleActiveSlideIndex={setActiveSlidIndex}
                            activeSlideIndex={activeSlideIndex}
                            slides={presentationDisplayDetails[activeDocumentId]}
                            updateList={updateCurrentDocumentList}
                            handleMoveSlide={handleChangeSlide}
                        />
                    )}

                    {connected && presentationDisplayDetails && !error && (
                        <Controls
                            handleMoveSlide={handleChangeSlide}
                            slides={presentationDisplayDetails[activeDocumentId]}
                            activeSlideIndex={activeSlideIndex}
                            updateList={updateCurrentDocumentList}
                            canUpdateList={currentDocumentLoaded.loaded}
                            docs={presentationDetails?.doc}
                            activePresentations={presentationDisplayDetails}
                            activeDocumentId={activeDocumentId}
                            handleActiveDocument={handleDocumentSwitch}
                            docLength={presentationDetails?.doc.length ?? 0}

                        />
                    )}
                </PresentationMobileWrapper>
            </div>
            {/*For Desktop Screens */}
            <div className="hidden overflow-hidden h-screen max-h-screen relative  lg:block">
                <Row>
                    <Col xs={18}>
                        <div className="flex flex-col h-screen">
                            <DesktopPresentationHeader
                                loading={detailsLoading}
                                presentationDetails={presentationDetails}
                                connection={connection}
                                presentationId={presentationId}
                            />
                            <DesktopPrentationScreen
                                activeSlide={
                                    presentationDisplayDetails?.[activeDocumentId][
                                        activeSlideIndex
                                        ]
                                }
                                loading={activeDocLoading}
                                handleGridModeActive={setGridViewActive}
                                gridModeActive={gridViewActive}
                                slides={presentationDisplayDetails?.[activeDocumentId]}
                                handleChangeSlide={handleChangeSlide}
                                activeSlideIndex={activeSlideIndex}


                            />
                            {presentationDisplayDetails && presentationDisplayDetails[activeDocumentId] &&
                                <DesktopSlider setActiveDocIndex={setActiveSlidIndex}
                                               activeDocumentIndex={activeSlideIndex}
                                               presentationDetails={presentationDisplayDetails}
                                               activeDocumentId={activeDocumentId}/>}

                            {connected && presentationDisplayDetails && !error && (
                                <DesktopControl
                                    handleMoveSlide={handleChangeSlide}
                                    slides={presentationDisplayDetails[activeDocumentId]}
                                    activeSlideIndex={activeSlideIndex}
                                    updateList={updateCurrentDocumentList}
                                    canUpdateList={currentDocumentLoaded.loaded}
                                    docs={presentationDetails?.doc}
                                    activePresentations={presentationDisplayDetails}
                                    activeDocumentId={activeDocumentId}
                                    handleActiveDocument={handleDocumentSwitch}
                                    activeSlide={
                                        presentationDisplayDetails?.[activeDocumentId][
                                            activeSlideIndex
                                            ]
                                    }
                                    handleGridModeActive={setGridViewActive}
                                    gridModeActive={gridViewActive}
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={6}>
                        <DesktopMenu
                            presentationId={presentationId}
                            activeSlide={presentationDisplayDetails?.[activeDocumentId][activeSlideIndex]}
                            connection={connection}
                            connected={connected}
                            friendlyId={presentationDetails?.presentation.friendlyId}
                            presentationDetails={presentationDetails}

                        />

                    </Col>
                </Row>
            </div>
        </>
    )
        ;
};

const PresentationScreen = ({
                                loading,
                                error,
                                activeSlide,
                                activeDocument,
                            }: {
    loading: boolean;
    error: boolean;
    activeSlide?: SlideInterface;
    activeDocument?: PresentationDocInterface;
}) => {
    return (
        <>
            {loading ? (
                <div className="h-[200px] min-h-[200px] rounded-2xl bg-white"></div>
            ) : error ? (
                <div className="h-[200px] min-h-[200px] flex justify-center items-center rounded-2xl bg-white">
                    Error Occured
                </div>
            ) : (
                <div className="bg-white my-3 pb-4 rounded-2xl overflow-hidden">
                    <div>
                        <img
                            alt={""}
                            src={activeSlide?.imgUrl}
                            className="w-full object-contain  h-[300px] min-h-[300px] min-w-full"
                        />
                    </div>
                    <div className="p-4 pb-0">
                        <p className=" text-xs text-[#545454] font-grotesk ">
                            {activeDocument?.note}
                        </p>
                        <div className="flex justify-end ">
                            <img
                                src={fullScreenIcon}
                                alt="fullscreen icon"
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const Sliders = ({
                     slides,
                     activeSlideIndex,
                     handleActiveSlideIndex,
                     updateList,
                     handleMoveSlide,
                 }: {
    slides: SlideInterface[];
    activeSlideIndex: number;
    handleActiveSlideIndex: (item: number) => void;
    updateList: () => void;
    handleMoveSlide: (index: number, type: "next" | "prev") => void;
}) => {
    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const scrollableRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        itemRefs.current?.[activeSlideIndex]?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
        });
    }, [activeSlideIndex]);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            const element = scrollableRef.current;
            if (element) {
                // Calculate the maximum scrollable width
                const maxScrollLeft = element.scrollWidth - element.clientWidth;

                // Check if we've reached the end of the scrollable div
                if (element.scrollLeft >= maxScrollLeft - 1) {
                    updateList();

                    // Add additional functionality here if needed
                }
            }
        };

        const element = scrollableRef.current;
        // Attach the scroll event listener if element exists
        if (element) {
            element.addEventListener("scroll", handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <div
            ref={scrollableRef}
            className="overflow-x-scroll hideScroll flex gap-2"
        >
            {slides.length
                ? slides.map((item, index) => {
                    return (
                        <div
                            // style={{
                            //   backgroundImage: `url(${item.imgUrl})`,
                            //   backgroundPosition: "center",
                            //   backgroundSize: "cover",
                            //   backgroundRepeat: "no-repeat",
                            // }}

                            ref={(element) => (itemRefs.current[index] = element)}
                            onClick={() => {
                                handleMoveSlide(index, "prev");
                                handleActiveSlideIndex(index);
                            }}
                            key={item.id}
                            className={`min-w-[200px] overflow-hidden object-fill max-h-[100px] cursor-pointer min-h-[100px] h-[100px] rounded-2xl w-[200px] ${
                                index === activeSlideIndex ? "border-[#FF6929] border-2" : ""
                            } `}
                        >
                            <img src={item.imgUrl} className="w-full h-full object-fill"/>
                        </div>
                    );
                })
                : ""}
        </div>
    );
};

const Controls = ({
                      handleMoveSlide,
                      slides,
                      activeSlideIndex,
                      canUpdateList,
                      updateList,
                      docs,
                      activePresentations,
                      activeDocumentId,
                      handleActiveDocument,
                      docLength
                  }: {
    handleMoveSlide: (item: number, type: "prev" | "next") => void;
    slides: SlideInterface[];
    activeSlideIndex: number;
    canUpdateList: boolean;
    updateList: () => void;
    docs?: PresentationDocInterface[];
    activePresentations: { [key: string]: SlideInterface[] };
    activeDocumentId: string;
    handleActiveDocument: (prev: string, val: string) => void;
    docLength: number

}) => {
    const [drawerActive, setShowDrawerActive] = useState(false);
    const [settingDrawerActive, setSettingsDrawerActive] = useState(false);
    return (
        <div className="min-h-[200px]  mt-4  flex justify-between h-[200px] ">
            <BottomDrawer
                title="Playlist"
                icon={modalIcon}
                handleClose={setShowDrawerActive}
                modalActive={drawerActive}
            >
                <Row gutter={12}>
                    {Object.keys(activePresentations)?.length &&
                        Object.keys(activePresentations)?.map((item) => {
                            return (
                                <Col key={item} xs={24}>
                                    <div
                                        onClick={() => handleActiveDocument(activeDocumentId, item)}
                                        className={`w-full p-3 py-3 border cursor-pointer border-[#F6F6F6] rounded-lg flex items-center ${
                                            activeDocumentId === item ? "bg-[#F6F6F6]" : ""
                                        }  my- 1`}
                                    >
                                        <div className="flex items-center  gap-2">
                                            <span className="">
                                                <img alt="" src={pptActive}/>
                                            </span>
                                            <p
                                                className={`${
                                                    activeDocumentId !== item
                                                        ? "text-[#333]"
                                                        : "text-[#FF6929]"
                                                }  text-xs`}
                                            >
                                                {" "}
                                                {docs?.find((item_) => item_.documentId === item)?.title}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}

                    {Object.keys(activePresentations)?.length < docLength &&
                        <Col xs={24}>
                            <div className="flex justify-center my-4">
                                <p className="text-xs text-[#667084]">Hold on a bit!, we are fetching your document,</p>
                            </div>
                        </Col>
                    }
                </Row>
            </BottomDrawer>

            <BottomDrawer
                modalActive={settingDrawerActive}
                icon={settingsColored}
                handleClose={setSettingsDrawerActive}
                title="Presentation Settings"
            >
                <div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Allow Questions</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Display navigation</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Display Instruction QR</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <p className="">Dispaly thumbnail</p>
                        <span>
                            <Switch checked={true}/>
                        </span>
                    </div>
                </div>
            </BottomDrawer>
            <button
                onClick={(e) => {
                    if (activeSlideIndex > 0) {
                        handleMoveSlide(activeSlideIndex - 1, "prev");
                        const button = e.currentTarget;
                        const ripple = document.createElement("span");
                        ripple.classList.add("ripple");

                        // Calculate size and position of the ripple
                        const rect = button.getBoundingClientRect();
                        ripple.style.width = ripple.style.height =
                            Math.max(rect.width, rect.height) + "px";
                        ripple.style.left =
                            e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                        ripple.style.top =
                            e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                        // Append the ripple to the button and remove it after animation
                        button.appendChild(ripple);
                        ripple.addEventListener("animationend", () => ripple.remove());
                    }
                }}
                className="bg-white ripple-button h-full justify-center rounded-2xl cursor-pointer flex w-full items-center"
            >
                <img src={arrowSquareLeft} alt=""/>
            </button>
            <div className="flex w-full gap-3 mx-3 flex-col ">
                <div
                    onClick={() => setShowDrawerActive(true)}
                    className="h-full cursor-pointer flex justify-center items-center bg-white rounded-xl"
                >
                    <img src={fileBlack}/>
                </div>
                <div
                    onClick={() => setSettingsDrawerActive(true)}
                    className=" h-full cursor-pointer flex justify-center items-center bg-white rounded-xl"
                >
                    <img alt={""} src={exclain}/>
                </div>
            </div>
            <button
                onClick={(e) => {
                    if (activeSlideIndex < slides.length - 1) {
                        const slideLength = slides.length;
                        if (slideLength - activeSlideIndex === 2) {
                            // update the list before the user navigates to that point
                            updateList();
                        }
                        handleMoveSlide(activeSlideIndex + 1, "next");
                        const button = e.currentTarget;
                        const ripple = document.createElement("span");
                        ripple.classList.add("ripple");

                        // Calculate size and position of the ripple
                        const rect = button.getBoundingClientRect();
                        ripple.style.width = ripple.style.height =
                            Math.max(rect.width, rect.height) + "px";
                        ripple.style.left =
                            e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                        ripple.style.top =
                            e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                        // Append the ripple to the button and remove it after animation
                        button.appendChild(ripple);
                        ripple.addEventListener("animationend", () => ripple.remove());
                    }
                }}
                className="bg-white ripple-button  h-full rounded-2xl cursor-pointer flex w-full  justify-center items-center"
            >
                <img src={arrowSquareRight} alt=""/>
            </button>
        </div>
    );
};
