import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

// Assets
import chevronDown from 'assets/svg/chevron-down-black.svg';
import sectionIcon from 'assets/svg/section-rectangle-group.svg';
import imageTypeIcon from 'assets/svg/imagetype-icon-jpeg-png.svg';

// Slice
import { Tooltip } from 'antd';

// Interface
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}

// Interface for each item in the main array
export interface PreviewPayload {
  id: string | null;
  title: string | null;
  docs: PreviewDocument[];
  subs: PreviewPayload[];
}

interface Props {
  doc: PreviewDocument;
  displayDocumentIndex: number;
  setDisplayDocumentIndex: Dispatch<SetStateAction<number>>;
  flattedDisplayDocuments: PreviewDocument[];
}

export default function FolderChildren({
  doc,
  displayDocumentIndex,
  setDisplayDocumentIndex,
  flattedDisplayDocuments,
}: Props) {
  // State

  //   const textRef = useRef<HTMLDivElement>(null);
  //   const [isOverflow, setIsOverflow] = useState(false);

  //   useEffect(() => {
  //     const checkOverflow = () => {
  //       if (textRef.current) {
  //         setIsOverflow(
  //           textRef.current.scrollWidth > textRef.current.clientWidth
  //         );
  //       }
  //     };

  //     // Check overflow on component mount and on window resize
  //     checkOverflow();
  //     window.addEventListener('resize', checkOverflow);

  //     return () => {
  //       window.removeEventListener('resize', checkOverflow);
  //     };
  //   }, []);

  return (
    <div
      className={`py-1 px-1 cursor-pointer flex gap-x-2 items-center my-2 ${
        flattedDisplayDocuments[displayDocumentIndex].id === doc.id
          ? 'bg-[#F6F3EF] border border-[#D7D7D7] rounded-md'
          : ''
      }`}
      key={doc.id}
      onClick={() => {
        const docIndex = flattedDisplayDocuments.findIndex(
          (document) => document.id === doc.id
        );
        setDisplayDocumentIndex(docIndex);
      }}
    >
      <img src={imageTypeIcon} alt='type' className='w-5' />
      <Tooltip
        title={
          <p className=' text-black text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
            {doc.title}
          </p>
        }
        color='white'
        placement='top'
      >
        <p className='truncate max-w-full text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
          {doc.title}
        </p>
      </Tooltip>
      {/* {isOverflow ? (
        <Tooltip
          title={
            <p className=' text-black text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
              {doc.title}
            </p>
          }
          color='white'
          placement='top'
        >
          <p className='truncate max-w-full text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
            {doc.title}
          </p>
        </Tooltip>
      ) : (
        <p className='truncate max-w-full text-sm twoK:text-lg fourK:text-xl eightK:text-3xl'>
          {doc.title}
        </p>
      )} */}
      {/* <Tooltip
        title={<p className='text-black capitalize'>{doc.title}</p>}
        color='white'
        placement='top'
      >
        {doc.title && (
          <p className='text-sm'>{`${doc.title.slice(0, 17)}${
            doc.title.length > 17 ? '...' : ''
          }`}</p>
        )}
      </Tooltip> */}
    </div>
  );
}
