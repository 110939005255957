import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  _createBriefcase,
  _getBriefcases,
  _updateBriefcase,
  _getBriefcaseDocuments,
  _addBriefcaseFile,
  _setBriefcaseDocumentPermission,
  _removeBriefcase,
  _removeBriefcaseFile,
  _getBriefcase,
  _getBriefcasePresentationHistory,
  _getBriefcaseShareHistory,
  _getDocumentVisits,
  _shareNewBriefcasePresentation,
  _addPresentationDocuments,
  _getDocumentAnalytics,
  _getDocumentUsage,
  _getDocument,
  _createFolder,
  _getFolders,
  _getFolder,
  _updateFolder,
  _deleteFolder,
  _updateDocuments,
  _getPreviewDocuments,
  _getSlides,
  _addMember,
  _updateFoldersPositions,
  _updatePresentation,
  _getPresentationDetails,
  _getPresentationGuests,
  _getPresentationDetailsAuth,
  _addGuests,
  // _getUserImage,
  _renameDocument,
  // _getDocumentDetails,
  _getDocumentPerformanceSummary,
  _removeMember,
} from 'services/Briefcase/briefcaseServices';
// import { _getPresentationDetails } from 'services/Presentations/presentationServices';
import {
  AddBriefcaseFilePayload,
  AddGuests,
  AddPresentationDocuments,
  BriefcaseDocument,
  BriefcaseDocumentAnalytics,
  BriefcaseDocumentUsage,
  BriefcaseDocumentVisits,
  BriefcaseFolder,
  BriefcaseGroupDocument,
  BriefcaseGroupFolder,
  BriefcaseItemsType,
  CreateFolder,
  DeleteFolder,
  DocumentShare,
  DraggedItem,
  GetFolder,
  InitialState,
  PresentationPayload,
  PreviewDocument,
  PreviewPayload,
  PreviewSlidePayload,
  SetBriefcaseDocumentPermissionPayload,
  ShareHistory,
  UpdateDocuments,
  UpdateFolder,
  UpdateFoldersPositions,
  UpdatePresentationPayload,
  RenameDocument,
  Slide,
} from 'types/Admin/Briefcase/BriefcaseTypes';

const initialState: InitialState = {
  briefcases: {
    isLoading: false,
    data: [],
  },
  briefcase: {
    isLoading: false,
    data: null,
  },
  briefcaseDocument: {
    isLoading: false,
    data: null,
    visits: [],
    analytics: null,
    usage: [],
    performance: [],
  },
  briefcaseDocuments: {
    isLoading: false,
    data: [],
  },
  briefcaseHistory: {
    isLoading: false,
    shareHistory: [],
    presentationHistory: null,
  },
  presentationDetails: {
    isLoading: false,
    data: null,
  },
  briefcaseFolders: {
    isLoading: false,
    folders: [],
    documents: [],
  },
  draggedItem: null,
  preview: {
    isLoading: false,
    data: [],
    displayDocument: null,
    flattedDisplayDocuments: [],
    displayDocIndex: 0,
    slides: {
      isLoading: false,
      data: [],
      displayDocIndex: 0,
      displayDocument: null,
    },
  },
  presentationsGuests: {
    isLoading: false,
    data: [],
  },
};

//get
export const getBriefcases = createAsyncThunk(
  'briefcase/getBriefcases',
  async (teamId: string) => {
    const response = await _getBriefcases(teamId);
    return response.data;
  }
);

export const getShareHistory = createAsyncThunk(
  'briefcase/getShareHistory',
  async (spaceId: string) => {
    const response = await _getBriefcaseShareHistory(spaceId);
    return response.data;
  }
);

export const getPresentationHistory = createAsyncThunk(
  'briefcase/getPresentationHistory',
  async (spaceId: string) => {
    const response = await _getBriefcasePresentationHistory(spaceId);
    return response.data;
  }
);

export const getBriefcase = createAsyncThunk(
  'briefcase/getBriefcase',
  async ({ spaceId, teamId }: { spaceId: string; teamId: string }) => {
    const response = await _getBriefcase(spaceId, teamId);
    return response.data;
  }
);

export const getDocumentVisits = createAsyncThunk(
  'briefcase/getDocumentVisits',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentVisits(spaceId, documentId);
    return response.data;
  }
);
export const getDocumentAnalytics = createAsyncThunk(
  'briefcase/getDocumentAnalytics',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentAnalytics(spaceId, documentId);
    return response.data;
  }
);
export const getDocumentUsage = createAsyncThunk(
  'briefcase/getDocumentUsage',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentUsage(spaceId, documentId);
    return response.data;
  }
);
export const getDocumentPerformanceSummary = createAsyncThunk(
  'briefcase/getDocumentPerformance',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentPerformanceSummary(spaceId, documentId);
    return response.data;
  }
);

export const getBriefcaseDocument = createAsyncThunk(
  'briefcase/getDocument',
  async (documentId: string) => {
    const response = await _getDocument(documentId);
    return response.data;
  }
);
export const getBriefcaseDocumentPreview = createAsyncThunk(
  'briefcase/getDocumentPreview',
  async (documentId: string) => {
    const response = await _getDocument(documentId);
    return response.data;
  }
);

// export const getBriefcaseDocumentDetails = createAsyncThunk(
//   'briefcase/getBriefcaseDocumentDetails',
//   async (documentId: string) => {
//     const response = await _getDocumentDetails(documentId);
//     return response.data;
//   }
// );

export const getBriefcaseDocuments = createAsyncThunk(
  'briefcase/getBriefcaseDocuments',
  async (spaceId: string) => {
    const response = await _getBriefcaseDocuments(spaceId);
    return response.data;
  }
);

export const getPreview = createAsyncThunk(
  'presentations/getBriefcasePreview',
  async (id: string) => {
    const response = await _getPreviewDocuments(id);
    return response.data;
  }
);

export const getPresentationDetails = createAsyncThunk(
  'briefcase/getPresentationDetails',
  async (presentaionId: string) => {
    const response = await _getPresentationDetails(presentaionId);
    return response.data;
  }
);

export const getPresentationDetailsAuth = createAsyncThunk(
  'briefcase/getPresentationDetailsAuth',
  async (presentaionId: string) => {
    const response = await _getPresentationDetailsAuth(presentaionId);
    return response.data;
  }
);

export const getPresentationGuests = createAsyncThunk(
  'briefcase/getPresentationGuests',
  async (presentaionId: string) => {
    const response = await _getPresentationGuests(presentaionId);
    return response.data;
  }
);

// export const getUserImage = createAsyncThunk(
//   'briefcase/getUserImage',
//   async (userId: string) => {
//     const response = await _getUserImage(userId);
//     return response.data;
//   }
// );

//POST
export const createBriefcase = createAsyncThunk(
  'briefcase/createBriefcase',
  async (data: FormData) => {
    const response = await _createBriefcase(data);
    return response.data;
  }
);

export const updateDocuments = createAsyncThunk(
  'briefcase/updateDocuments',
  async ({ spaceId, data }: UpdateDocuments) => {
    const response = await _updateDocuments(spaceId, data);
    return response.data;
  }
);

export const updateFoldersPositions = createAsyncThunk(
  'briefcase/updateFoldersPositions',
  async ({ spaceId, data }: UpdateFoldersPositions) => {
    const response = await _updateFoldersPositions(spaceId, data);
    return response.data;
  }
);

export const updateBriefcase = createAsyncThunk(
  'briefcase/updateBriefcase',
  async (data: FormData) => {
    const response = await _updateBriefcase(data);
    return response.data;
  }
);
export const addBriefcaseFile = createAsyncThunk(
  'briefcase/addBriefcaseFile',
  async ({ spaceId, formData }: AddBriefcaseFilePayload) => {
    const response = await _addBriefcaseFile(spaceId, formData);
    return response.data;
  }
);
export const setBriefcaseDocumentPermission = createAsyncThunk(
  'briefcase/setBriefcasePermission',
  async ({ spaceId, data }: SetBriefcaseDocumentPermissionPayload) => {
    const response = await _setBriefcaseDocumentPermission(spaceId, data);
    return response.data;
  }
);
export const removeBriefcase = createAsyncThunk(
  'briefcase/removeBriefcase',
  async ({ spaceId, archive }: { spaceId: string; archive: boolean }) => {
    const response = await _removeBriefcase(spaceId, archive);
    return response.data;
  }
);

export const removeBriefcaseFile = createAsyncThunk(
  'briefcase/removeBriefcaseFile',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _removeBriefcaseFile(spaceId, documentId);
    return response.data;
  }
);

export const shareNewPresentation = createAsyncThunk(
  'briefcase/shareNewPresentation',
  async (data: DocumentShare) => {
    const response = await _shareNewBriefcasePresentation(data);
    return response.data;
  }
);
export const addPresentationDocuments = createAsyncThunk(
  'briefcase/addPresentationDocuments',
  async (data: AddPresentationDocuments) => {
    const response = await _addPresentationDocuments(data);
    return response.data;
  }
);

export const createFolder = createAsyncThunk(
  'briefcase/createFolder',
  async ({ spaceId, title, parentId }: CreateFolder) => {
    const response = await _createFolder(spaceId, title, parentId);
    return response.data;
  }
);

export const updateFolder = createAsyncThunk(
  'briefcase/updateFolder',
  async ({ spaceId, groupId, title, parentId, position }: UpdateFolder) => {
    const response = await _updateFolder(
      spaceId,
      groupId,
      title,
      parentId,
      position
    );
    return response.data;
  }
);

export const deleteFolder = createAsyncThunk(
  'briefcase/deleteFolder',
  async ({ spaceId, groupId }: DeleteFolder) => {
    const response = await _deleteFolder(spaceId, groupId);
    return response.data;
  }
);

export const getFolders = createAsyncThunk(
  'briefcase/getFolders',
  async (spaceId: string) => {
    const response = await _getFolders(spaceId);
    return response.data;
  }
);
export const getFolder = createAsyncThunk(
  'briefcase/getFolder',
  async ({ spaceId, groupId }: GetFolder) => {
    const response = await _getFolder(spaceId, groupId);
    return response.data;
  }
);
export const getSlides = createAsyncThunk(
  'briefcase/getSlides',
  async (documentId: string) => {
    const response = await _getSlides(documentId);
    return response.data;
  }
);

// export const downloadDocument = createAsyncThunk(
//   'briefcase/downloadDocument',
//   async (documentId: string) => {
//     const response = await _downloadDocument(documentId);
//     return response.data;
//   }
// );

export const addMember = createAsyncThunk(
  'briefcase/addMember',
  async ({ spaceId, memberId }: { spaceId: string; memberId: string }) => {
    const response = await _addMember(spaceId, memberId);
    return response.data;
  }
);
export const removeMember = createAsyncThunk(
  'briefcase/addMember',
  async ({ spaceId, memberId }: { spaceId: string; memberId: string }) => {
    const response = await _removeMember(spaceId, memberId);
    return response.data;
  }
);

export const updatePresentation = createAsyncThunk(
  'briefcase/updatePresentation',
  async ({ presentationId, data }: UpdatePresentationPayload) => {
    const response = await _updatePresentation(presentationId, data);
    return response.data;
  }
);
export const addGuests = createAsyncThunk(
  'briefcase/addGuests',
  async ({ presentationId, data }: AddGuests) => {
    const response = await _addGuests(presentationId, data);
    return response.data;
  }
);

export const renameDocument = createAsyncThunk(
  'briefcase/updateDocument',
  async ({ documentId, data }: RenameDocument) => {
    const response = await _renameDocument(documentId, data);
    return response.data;
  }
);

export const briefcaseSlice = createSlice({
  name: 'briefcase',
  initialState,
  reducers: {
    setFolders(state, action: PayloadAction<BriefcaseGroupFolder[]>) {
      state.briefcaseFolders.documents = action.payload;
    },
    setFolderDocuments(state, action: PayloadAction<BriefcaseFolder[]>) {
      state.briefcaseFolders.folders = action.payload;
    },
    setDraggedItem(state, action: PayloadAction<DraggedItem | null>) {
      state.draggedItem = action.payload;
    },
    setNextDisplayDoc(state) {
      if (
        state.preview.displayDocIndex ===
        state.preview.flattedDisplayDocuments.length - 1
      ) {
        // state.preview.displayDocIndex = 0;
        return;
      } else {
        state.preview.displayDocIndex = state.preview.displayDocIndex + 1;
      }
    },
    setPrevDisplayDoc(state) {
      // Function to handle Previous click
      if (state.preview.displayDocIndex === 0) {
        return;
      } else {
        state.preview.displayDocIndex = state.preview.displayDocIndex - 1;
      }
    },
    setDisplayDoc(state, action: PayloadAction<string>) {
      const doc = state.preview.flattedDisplayDocuments.find(
        (doc, index) => doc.id === action.payload
      );
      if (doc) {
        state.preview.displayDocument = doc;
        const docIndex = state.preview.flattedDisplayDocuments.findIndex(
          (doc_) => doc.id === doc_.id
        );
        state.preview.displayDocIndex = docIndex;
      }
    },
    //  SLides
    // slides(state) {state.preview.slides}
    resetSlide(state) {
      state.preview.slides.data = [];
    },
    setNextSlideDisplayDoc(state) {
      if (
        state.preview.slides.displayDocIndex ===
        state.preview.slides.data.length - 1
      ) {
        return;
      } else {
        state.preview.slides.displayDocIndex =
          state.preview.slides.displayDocIndex + 1;
      }
    },
    setPrevSlideDisplayDoc(state) {
      // Function to handle Previous click
      if (state.preview.slides.displayDocIndex === 0) {
        return;
      } else {
        state.preview.slides.displayDocIndex =
          state.preview.slides.displayDocIndex - 1;
      }
    },

    setSlideDisplayDoc(state, action: PayloadAction<string>) {
      const doc = state.preview.slides.data.find(
        (doc, index) => doc.id === action.payload
      );
      if (doc) {
        state.preview.slides.displayDocument = doc;
        const docIndex = state.preview.slides.data.findIndex(
          (doc_) => doc.id === doc_.id
        );
        state.preview.slides.displayDocIndex = docIndex;
      }
    },

    resetBriefcaseFolderDocuments(state) {
      state.briefcaseFolders.documents = [];
      state.briefcaseFolders.folders = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBriefcases.pending, (state) => {
        state.briefcases.isLoading = true;
        state.briefcases.data = [];
      })
      .addCase(
        getBriefcases.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcases.isLoading = false;
          state.briefcases.data = action.payload;
        }
      )
      .addCase(getBriefcases.rejected, (state) => {
        state.briefcases.isLoading = false;
      });

    // briefcase
    builder
      .addCase(getBriefcase.pending, (state) => {
        state.briefcase.isLoading = true;
        state.briefcase.data = null;
      })
      .addCase(
        getBriefcase.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType>) => {
          state.briefcase.isLoading = false;
          state.briefcase.data = action.payload;
        }
      )
      .addCase(getBriefcase.rejected, (state) => {
        state.briefcase.isLoading = false;
      });

    builder
      .addCase(getBriefcaseDocuments.pending, (state) => {
        state.briefcaseDocuments.isLoading = true;
        state.briefcaseDocuments.data = [];
      })
      .addCase(
        getBriefcaseDocuments.fulfilled,
        (state, action: PayloadAction<BriefcaseGroupDocument[]>) => {
          state.briefcaseDocuments.isLoading = false;
          state.briefcaseDocuments.data = action.payload;

          // Appending the briefcase documents without any group to the group documents
          let docsWithNoGroupFolderDocument = {
            id: 'root_folder',
            title: '',
            dateCreated: '',
            subGroups: [],
            documents: action.payload
              .filter((doc) => doc.group === null)
              .sort((a, b) => a.position - b.position),
          };

          // Appending the briefcase folder without any group to the group documents
          let docsWithNoGroupFolderFolder = {
            id: 'root_folder',
            title: '',
            dateCreated: '',
            spaceId: '',
            parentId: '',
            deleted: false,
            position: 0,
          };

          // Check if a document with id 'root_folder' already exists in the array
          const existingDocumentIndex =
            state.briefcaseFolders.documents.findIndex(
              (doc) => doc.id === 'root_folder'
            );

          if (existingDocumentIndex !== -1) {
            // Update the existing document's 'documents' property
            state.briefcaseFolders.documents[existingDocumentIndex].documents =
              docsWithNoGroupFolderDocument.documents;
          } else {
            // If it doesn't exist, add it to the beginning of the array
            state.briefcaseFolders.documents.unshift(
              docsWithNoGroupFolderDocument
            );
          }

          // Check if a folder with id 'root_folder' already exists in the folders array
          const existingFolderIndex = state.briefcaseFolders.folders.findIndex(
            (folder) => folder.id === 'root_folder'
          );

          if (existingFolderIndex === -1) {
            // If it doesn't exist, add it to the beginning of the array
            state.briefcaseFolders.folders.unshift(docsWithNoGroupFolderFolder);
          }
        }
      )
      .addCase(getBriefcaseDocuments.rejected, (state) => {
        state.briefcaseDocuments.isLoading = false;
      });

    // getPresentationHistory
    builder
      .addCase(getPresentationHistory.pending, (state) => {
        state.briefcaseHistory.isLoading = true;
        state.briefcaseHistory.presentationHistory = [];
      })
      .addCase(
        getPresentationHistory.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcaseHistory.isLoading = false;
          const sortedItems = action.payload.sort((a, b) => {
            const unixTimeA = Date.parse(a.dateCreated); // Convert to milliseconds since the UNIX epoch
            const unixTimeB = Date.parse(b.dateCreated);

            return unixTimeB - unixTimeA; // Sort in ascending order
          });
          state.briefcaseHistory.presentationHistory = sortedItems;
        }
      )
      .addCase(getPresentationHistory.rejected, (state) => {
        state.briefcaseHistory.isLoading = false;
      });

    // getShareHistory
    builder
      .addCase(getShareHistory.pending, (state) => {
        state.briefcaseHistory.isLoading = true;
        state.briefcaseHistory.shareHistory = [];
      })
      .addCase(
        getShareHistory.fulfilled,
        (state, action: PayloadAction<ShareHistory[]>) => {
          state.briefcaseHistory.isLoading = false;
          const sortedItems = action.payload.sort((a, b) => {
            const unixTimeA = Date.parse(a.dateCreated); // Convert to milliseconds since the UNIX epoch
            const unixTimeB = Date.parse(b.dateCreated);

            return unixTimeB - unixTimeA; // Sort in ascending order
          });
          state.briefcaseHistory.shareHistory = sortedItems;
        }
      )
      .addCase(getShareHistory.rejected, (state) => {
        state.briefcaseHistory.isLoading = false;
      });

    // getDocument
    builder
      .addCase(getBriefcaseDocument.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.data = null;
      })
      .addCase(
        getBriefcaseDocument.fulfilled,
        (state, action: PayloadAction<BriefcaseDocument>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.data = action.payload;
        }
      )
      .addCase(getBriefcaseDocument.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // get document visits
    builder
      .addCase(getDocumentVisits.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.visits = [];
      })
      .addCase(
        getDocumentVisits.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentVisits[]>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.visits = action.payload;
        }
      )
      .addCase(getDocumentVisits.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // get document analytics
    builder
      .addCase(getDocumentAnalytics.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.analytics = null;
      })
      .addCase(
        getDocumentAnalytics.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentAnalytics>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.analytics = action.payload;
        }
      )
      .addCase(getDocumentAnalytics.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // get document usage
    builder
      .addCase(getDocumentUsage.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.usage = [];
      })
      .addCase(
        getDocumentUsage.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentUsage[]>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.usage = action.payload;
        }
      )
      .addCase(getDocumentUsage.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // get document performance summary
    builder
      .addCase(getDocumentPerformanceSummary.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.performance = [];
      })
      .addCase(
        getDocumentPerformanceSummary.fulfilled,
        (state, action: PayloadAction<Slide[]>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.performance = action.payload;
        }
      )
      .addCase(getDocumentPerformanceSummary.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // Get folders
    builder
      .addCase(getFolders.pending, (state) => {
        state.briefcaseFolders.isLoading = true;
        state.briefcaseFolders.folders = [...state.briefcaseFolders.folders];
      })
      .addCase(
        getFolders.fulfilled,
        (state, action: PayloadAction<BriefcaseFolder[]>) => {
          state.briefcaseFolders.isLoading = false;

          // Mapping through two lists and updating
          function updateDocuments(
            existingList: BriefcaseFolder[],
            newPayload: BriefcaseFolder[]
          ): BriefcaseFolder[] {
            const documentMap = new Map(
              existingList.map((doc) => [doc.id, doc])
            );

            newPayload.forEach((newDoc) => {
              if (documentMap.has(newDoc.id)) {
                // Update the existing document
                documentMap.set(newDoc.id, {
                  ...documentMap.get(newDoc.id),
                  ...newDoc,
                });
              } else {
                // Add the new document
                documentMap.set(newDoc.id, newDoc);
              }
            });

            // Convert the map back to an array
            return Array.from(documentMap.values());
          }

          state.briefcaseFolders.folders = updateDocuments(
            state.briefcaseFolders.folders,
            action.payload
          ).sort((a, b) => a.position - b.position);
        }
      )
      .addCase(getFolders.rejected, (state) => {
        state.briefcaseFolders.isLoading = false;
      });

    // Get folder
    builder
      .addCase(getFolder.pending, (state) => {
        // state.briefcaseFolders.isLoading = true;
        state.briefcaseFolders.documents = [
          ...state.briefcaseFolders.documents,
        ];
      })
      .addCase(
        getFolder.fulfilled,
        (state, action: PayloadAction<BriefcaseGroupFolder>) => {
          const existingDocuments = state.briefcaseFolders.documents;
          // sorting the fetch documents by position
          const newDocument = {
            ...action.payload,
            documents: action.payload.documents
              ? action.payload.documents.sort((a, b) => a.position - b.position)
              : [],
          };

          // Find if a document with the same id already exists
          const existingIndex = existingDocuments.findIndex(
            (doc) => doc.id === newDocument.id
          );

          if (existingIndex !== -1) {
            // If it exists, update the document at that index
            existingDocuments[existingIndex] = newDocument;
          } else {
            // If it doesn't exist, add the new document to the list
            existingDocuments.push(newDocument);
          }

          // Update the state with the modified documents list
          state.briefcaseFolders.documents = existingDocuments;
        }
      )
      .addCase(getFolder.rejected, (state) => {
        // state.briefcaseFolders.isLoading = false;
      });

    // For briefcase preview
    builder
      .addCase(getPreview.pending, (state) => {
        state.preview.isLoading = true;
        state.preview.data = null;
      })
      .addCase(
        getPreview.fulfilled,
        (state, action: PayloadAction<PreviewPayload[]>) => {
          state.preview.isLoading = false;
          // let displayDoc;
          if (action.payload.length > 0) {
            state.preview.displayDocument = action.payload[0].docs[0];
          }

          // moving groups without id to index 0
          const indexOfGroupWithNoId = action.payload.findIndex(
            (item) => item.id === null
          );

          const [item] = action.payload.splice(indexOfGroupWithNoId, 1);

          // Add the removed item to the front of the array
          action.payload.unshift(item);

          state.preview.data = action.payload;

          //////////////////////////////
          // Helper function to recursively extract all documents, including sub-documents
          const extractAllDocuments = (
            categories: PreviewPayload[],
            parentFolderId: string | null = null
          ): PreviewDocument[] => {
            const docs = categories.flatMap((category) => {
              // Combine the documents in the current category with those in its subcategories
              const currentDocsWithFolder = category.docs.map((doc) => ({
                ...doc,
                folderId: parentFolderId || category.id, // Add folderId reference to each document
              }));

              const allSubDocs =
                category.subs.length > 0
                  ? extractAllDocuments(category.subs, category.id)
                  : [];

              return [...currentDocsWithFolder, ...allSubDocs];
            });

            return docs;
          };

          state.preview.flattedDisplayDocuments = extractAllDocuments(
            action.payload
          );
        }
      )
      .addCase(getPreview.rejected, (state) => {
        state.preview.isLoading = false;
      });

    // get single preview document
    builder
      .addCase(getBriefcaseDocumentPreview.pending, (state) => {
        state.preview.isLoading = true;
        state.preview.data = null;
      })
      .addCase(
        getBriefcaseDocumentPreview.fulfilled,
        (state, action: PayloadAction<BriefcaseDocument>) => {
          state.preview.isLoading = false;

          if (action.payload.slides) {
            const sortedSlides = action.payload.slides.sort(
              (slideA, slideB) => slideA.position - slideB.position
            );
            state.preview.slides.data = sortedSlides;
          }

          const doc = [
            {
              sessionId: '',
              sessionType: 0,
              documentId: action.payload.id,
              title: action.payload.title,
              note: null,
              position: 0,
              url: action.payload.url,
              type: '',
              deleted: false,
              id: '',
              folderId: action.payload.folderId,
            },
          ];

          state.preview.flattedDisplayDocuments = doc;
        }
      )
      .addCase(getBriefcaseDocumentPreview.rejected, (state) => {
        state.preview.isLoading = false;
      });

    // get preview slides
    builder
      .addCase(getSlides.pending, (state) => {
        state.preview.slides.isLoading = true;
        state.preview.slides.data = [];
      })
      .addCase(
        getSlides.fulfilled,
        (state, action: PayloadAction<PreviewSlidePayload>) => {
          state.preview.slides.isLoading = false;

          const sortedSlides = action.payload.slides.sort(
            (slideA, slideB) => slideA.position - slideB.position
          );

          // state.preview.slides.data = action.payload.slides;
          state.preview.slides.data = sortedSlides;
        }
      )
      .addCase(getSlides.rejected, (state) => {
        state.preview.slides.isLoading = false;
      });

    // get presentation details
    builder
      .addCase(getPresentationDetails.pending, (state) => {
        state.presentationDetails.isLoading = true;
        state.presentationDetails.data = null;
      })
      .addCase(
        getPresentationDetails.fulfilled,
        (state, action: PayloadAction<PresentationPayload>) => {
          state.presentationDetails.isLoading = false;

          state.presentationDetails.data = action.payload;
        }
      )
      .addCase(getPresentationDetails.rejected, (state) => {
        state.presentationDetails.isLoading = false;
      });

    // get presentation Bearer - Review later
    builder
      .addCase(getPresentationDetailsAuth.pending, (state) => {
        state.presentationDetails.isLoading = true;
        state.presentationDetails.data = null;
      })
      .addCase(
        getPresentationDetailsAuth.fulfilled,
        (state, action: PayloadAction<PresentationPayload>) => {
          state.presentationDetails.isLoading = false;

          state.presentationDetails.data = action.payload;
        }
      )
      .addCase(getPresentationDetailsAuth.rejected, (state) => {
        state.presentationDetails.isLoading = false;
      });

    // get presentation guests
    builder
      .addCase(getPresentationGuests.pending, (state) => {
        state.presentationsGuests.isLoading = true;
        state.presentationsGuests.data = [];
      })
      .addCase(
        getPresentationGuests.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.presentationsGuests.isLoading = false;

          state.presentationsGuests.data = action.payload;
        }
      )
      .addCase(getPresentationGuests.rejected, (state) => {
        state.presentationsGuests.isLoading = false;
      });
  },
});

export const {
  setFolders,
  setFolderDocuments,
  setDraggedItem,
  setDisplayDoc,
  setNextDisplayDoc,
  setPrevDisplayDoc,
  resetSlide,
  setNextSlideDisplayDoc,
  setPrevSlideDisplayDoc,
  setSlideDisplayDoc,
  resetBriefcaseFolderDocuments,
} = briefcaseSlice.actions;
export default briefcaseSlice.reducer;
