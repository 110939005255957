import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  _changePassword,
  _getProfilePic,
  _updateProfile,
} from "services/Settings/settingsServices";

const initialState = {
  loadingStatus: "idle",
  profilePic: {
    isLoading: true,
    data: null,
  },
};

//GET

export const getProfilePic = createAsyncThunk(
  "settings/getProfilePic",
  async () => {
    const response = await _getProfilePic();
    const image =
      response.data?.size > 0 ? URL.createObjectURL(response.data) : null;

    return image;
  }
);

//POST
export const changePassword = createAsyncThunk(
  "settings/changePassword",
  async (data) => {
    const response = await _changePassword(data);
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  "settings/updateProfile",
  async (data) => {
    const response = await _updateProfile(data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProfilePic.pending, (state) => {
        state.profilePic.data = null;
        state.profilePic.isLoading = true;
      })
      .addCase(getProfilePic.fulfilled, (state, { payload }) => {
        state.profilePic.isLoading = false;
        state.profilePic.data = payload;
      })
      .addCase(getProfilePic.rejected, (state) => {
        state.profilePic.isLoading = false;
      });
  },
});

export const getSettingsState = (state) => state.settings;

export default settingsSlice.reducer;
