export default function PollTemplates() {
  return (
    <section>
        <h1>Poll templates</h1>
      <section>
        <div></div>
      </section>
    </section>
  );
}
