import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//slices
import { getPollTemplates, getPolls } from "store/Poll/pollSlice";

//libraries
import { ClipLoader } from "react-spinners";

//assets
import edit from "assets/svg/edit.svg";
import template from "assets/svg/template.svg";
import whitePoll from "assets/svg/whitePoll.svg";

//components
import {
  getPollsObject,
  getStoredPolls,
  updatePollTitle,
} from "utils/pollStateManagement";
import QuestionTypeTemplate from "components/Admin/Presentation/PollCreation/PollTemplates/QuestionTypeTemplate";

export default function PollList() {
  const dispatch = useDispatch();
  const { presentationId } = useParams();

  //useref
  const titleRef = useRef();

  //usestate
  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isPollTitle, setIsPollTitle] = useState();
  const [reversedPolls, setReversedPolls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTitleEntered, setIsTitleEntered] = useState();

  //useselector

  //useeffects
  useEffect(() => {
    const pollTitle = getPollsObject()?.title;
    setIsPollTitle(pollTitle);
  }, []);

  useEffect(() => {
    dispatch(getPolls(presentationId));
  }, [dispatch, presentationId]);

  useEffect(() => {
    isEdit && titleRef.current.focus();
  }, [isEdit]);

  useEffect(() => {
    const handleStorageChange = () => {
      updateStoredPolls();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (tab === 0) {
      const storedPolls = getStoredPolls();

      if (storedPolls?.length > 0) {
        setReversedPolls(storedPolls.reverse());
      } else {
        setReversedPolls([]);
      }
      setIsLoading(false);
    } else if (tab === 1) {
      setIsLoading(true);
      dispatch(getPollTemplates()).then(({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          const polls = payload;
          if (polls.length > 0) {
            setReversedPolls(
              [...polls].sort((a, b) => {
                let first = a?.launchDate;
                let second = b?.launchDate;
                return first > second ? 1 : -1;
              })
            );
          } else {
            setReversedPolls([]);
          }
        }
      });
    }
  }, [tab, dispatch]);

  //functions
  const updateStoredPolls = () => {
    const storedPolls = getStoredPolls();
    const savedTitle = getPollsObject()?.title;
    setIsTitleEntered(savedTitle);

    if (storedPolls?.length > 0) {
      setReversedPolls(storedPolls.reverse());
    }
  };

  const changePollTitle = () => {
    updatePollTitle(isTitleEntered);
    setIsEdit(false);
  };

  const handleKeyEvent = (e) => {
    if (e.key === "Enter") {
      changePollTitle();
    }
  };
  return (
    <section className="flex flex-col lg:w-6/12 w-full h-[85vh] py-4 overflow-y-scroll rounded-lg hide-scrollbar gap-y-4 bg-orange10">
      {/* toggle tab  */}

      <section className="w-full px-4 flex-between">
        <section>
          {isPollTitle && (
            <section className="w-11/12 mx-auto mb=[-10px]">
              <div className="flex items-center font-semibold gap-x-2 ">
                {!isEdit ? (
                  <p>{isTitleEntered}</p>
                ) : (
                  <input
                    type="text"
                    ref={titleRef}
                    defaultValue={isTitleEntered}
                    className="w-auto bg-transparent outline-none"
                    onKeyDown={handleKeyEvent}
                    onChange={(e) => setIsTitleEntered(e.target?.value)}
                  />
                )}

                {!isEdit ? (
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  />
                ) : (
                  <div className="flex items-center gap-x-2">
                    <button onClick={() => setIsEdit(false)}>
                      <i className=" pi pi-times"></i>
                    </button>
                    <button onClick={changePollTitle}>
                      <i className="rotate-[40deg] pi pi-send"></i>
                    </button>
                  </div>
                )}
              </div>
            </section>
          )}
        </section>

        <section className="flex self-end justify-end">
          <section className="w-[19rem] border rounded-lg px-4 py-2 text-xs flex justify-between">
            <div
              onClick={() => setTab(0)}
              className={`[ flex items-center gap-x-1 ] ${
                !tab ? " [ text-white  bg-primary ] " : "text-black"
              } [ cursor-pointer w-1/2 px-2 py-1 rounded-md ]`}
            >
              <img src={whitePoll} alt="new poll" />
              <p>Created Polls</p>
            </div>

            <div
              onClick={() => setTab(1)}
              className={`[ flex items-center gap-x-1 ]  [ cursor-pointer w-38 px-2 py-1 rounded-md ] ${
                tab ? " [ text-white  bg-primary ] " : "text-black"
              } `}
            >
              <img src={template} alt="my template" />
              <p>My templates</p>
            </div>
          </section>
        </section>
      </section>

      {/*  */}

      {isLoading && (
        <section className="flex items-center justify-center w-full h-full">
          <ClipLoader size={20} color="gray" />
        </section>
      )}

      {!isLoading && (
        <>
          {tab === 0 && (
            <section className="flex flex-col gap-y-4 ">
              {reversedPolls?.length > 0 && (
                <section className="flex flex-col gap-y-4">
                  {reversedPolls?.map((poll, index) => {
                    const { title } = poll ?? {};

                    return (
                      <section key={index}>
                        <QuestionTypeTemplate
                          tab={tab}
                          order={index}
                          details={poll}
                          title={title}
                        />
                      </section>
                    );
                  })}
                </section>
              )}

              {reversedPolls?.length < 1 && (
                <div className="flex items-center justify-center w-full h-[70vh] font-semibold lg:text-lg text-gray350 italic">
                  <p>Polls will appear here </p>
                </div>
              )}
            </section>
          )}

          {tab === 1 && (
            <section className="flex flex-col gap-y-4 ">
              {reversedPolls?.length > 0 &&
                reversedPolls.map((survey, key) => {
                  return (
                    <section key={key}>
                      {[...survey?.questions]?.reverse()?.map((poll, index) => {
                        return (
                          <section
                            key={index}
                            className="border border-orange40 rounded-2xl"
                          >
                            <QuestionTypeTemplate
                              tab={tab}
                              order={index}
                              details={poll}
                              title={survey?.title}
                            />
                          </section>
                        );
                      })}
                    </section>
                  );
                })}

              {reversedPolls?.length < 1 && (
                <div className="flex items-center justify-center w-full h-[70vh] font-semibold lg:text-lg text-gray350 italic">
                  <p>Polls will appear here </p>
                </div>
              )}
            </section>
          )}
        </>
      )}
    </section>
  );
}
