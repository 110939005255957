import { useCallback, useEffect, useRef, useState } from 'react';

// Assets
import rightNav from 'assets/svg/right-nav-arrow.svg';
import leftNav from 'assets/svg/left-nav-arrow.svg';
//utils
import { imageStream } from 'utils/imageStream';

// Liberies
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import {
  getPreview,
  getSlides,
  getBriefcase,
  getBriefcaseDocumentPreview,
} from 'store/Briefcase/briefcaseSlice';
import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';

// Components
import useScreenWidth from 'hooks/UseScreenWidth';
import SidebarWrapper from 'components/User/SharedBriefcase/SidebarWrapper';
import Header from 'components/User/SharedBriefcase/Header';
import Sidebar from 'components/User/SharedBriefcase/Sidebar';
import ControllerParent from 'components/User/SharedBriefcase/ControllerParent';
import DocumentDisplay from 'components/User/SharedBriefcase/DocumentDisplay';

interface TeamInfoData {
  id: string;
}

export default function BriefcasePreview() {
  const { spaceId, documentId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const screenWidth = useScreenWidth();
  const minScreenWidth = 2000;
  const activeImgRef = useRef<HTMLImageElement | null>(null);

  // State
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const [isSlides, setIsSlides] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [displayDocumentIndex, setDisplayDocumentIndex] = useState<number>(0);
  const [slideDocumentIndex, setSlideDocumentIndex] = useState<number>(0);
  const [isGridDisplayActive, setIsGridDisplayActive] = useState(false);
  const [isFullscreen, setIsFullScreen] = useState(false);

  // Store

  const { teamInfo } = useSelector(getTeamsState);
  const fullScreenContainerRef = useRef<HTMLElement | null>(null);

  const previewIsLoading = useSelector(
    (state: RootState) => state.briefcase.preview.isLoading
  );

  const flattedDisplayDocuments = useSelector(
    (state: RootState) => state.briefcase.preview.flattedDisplayDocuments
  );

  const slides = useSelector(
    (state: RootState) => state.briefcase.preview.slides.data
  );

  const { data: briefcaseDetails } = useSelector(
    (state: RootState) => state.briefcase.briefcase
  );

  const previewDocs = useSelector(
    (state: RootState) => state.briefcase.preview.data
  );

  interface ImageCache {
    [id: string]: string;
  }

  // useRef for caching images across renders
  const imageCache = useRef<ImageCache>({});

  // Preload image function
  const preloadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  };

  // Fetch and display the image
  const getStream = useCallback(async (id: string) => {
    // Set loading state
    setIsLoading(true);

    // Check if the image is cached
    const cachedImg = imageCache.current[id];

    if (cachedImg) {
      setActiveImage(cachedImg); // Directly use cached image
      setIsLoading(false);
    } else {
      try {
        // Fetch new image and preload it
        const url = await imageStream(id);
        if (url) {
          await preloadImage(url);
          setActiveImage(url);
          imageCache.current[id] = url; // Cache the loaded image
        }
      } catch (error) {
        console.error('Error loading image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // useeffects
  useEffect(() => {
    dispatch(getTeamInfo());

    // Fetches the preview docuemnts
    if (documentId) {
      dispatch(getBriefcaseDocumentPreview(documentId));
    } else {
      dispatch(getTeamInfo());
      spaceId && dispatch(getPreview(spaceId));
    }
  }, [dispatch, spaceId, documentId]);

  useEffect(() => {
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.id;
      dispatch(getBriefcase({ spaceId, teamId }));
    }
  }, [dispatch, spaceId, teamInfo.data]);

  useEffect(() => {
    setIsLoading(true);
    setActiveImage(null); //setting active image to null before handling the if statement.
    if (
      flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pptx') ||
      flattedDisplayDocuments[displayDocumentIndex]?.url?.endsWith('.pdf')
    ) {
      //only fetch slides on briefcase preview
      if (documentId) {
        setSlideDocumentIndex(0);
        setIsSlides(true);
        return;
      }

      dispatch(
        getSlides(flattedDisplayDocuments[displayDocumentIndex]?.documentId)
      ).then(() => {
        setSlideDocumentIndex(0); //resets slide doc index
        // setIsLoading(false);
        setIsSlides(true);
      }); // fetches the slides of a pptx document

      //check if this should be moved up or add await so as to wait for the fetching of the slides to complete before changing state
    } else {
      setIsSlides(false);
      setIsGridDisplayActive(false);
    }
  }, [displayDocumentIndex, flattedDisplayDocuments, dispatch]);

  // For setting active slide or document, handles getting the stream when the slideindex and document index changes
  useEffect(() => {
    setIsLoading(true);
    setActiveImage(null);
    const loadDoc = async () => {
      if (flattedDisplayDocuments && !isSlides) {
        await getStream(
          flattedDisplayDocuments[displayDocumentIndex]?.documentId
        );
      } else if (flattedDisplayDocuments && isSlides && slides.length > 0) {
        await getStream(slides[slideDocumentIndex]?.id ?? ''); // incase of null
      }
    };
    loadDoc();
  }, [
    flattedDisplayDocuments,
    displayDocumentIndex,
    getStream,
    isSlides,
    slides,
    slideDocumentIndex,
  ]);

  const [isOpen] = useState(false);

  const handleNextDoc = () => {
    if (displayDocumentIndex === flattedDisplayDocuments.length - 1) {
      return;
    } else {
      setDisplayDocumentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handlePrevDoc = () => {
    if (displayDocumentIndex === 0) {
      return;
    } else {
      setDisplayDocumentIndex((prevIndex) => prevIndex - 1);
    }
  };
  const handleNextSlideDoc = () => {
    // console.log(slides.length, 'Slides length');
    setSlideDocumentIndex((prevIndex) =>
      Math.min(prevIndex + 1, slides.length - 1)
    );
  };

  const handlePrevSlideDoc = () => {
    setSlideDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Assuming activeImgRef is defined as a ref for an HTML element
  const enterFullscreen = () => {
    const elem = document.documentElement; // Entire document (browser fullscreen)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any)?.mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any)?.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      (elem as any).webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      // Firefox
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      (document as any).webkitExitFullscreen();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    // Ensuring this only run only when the there is slides
    if (slides.length === 0) {
      return;
    }

    if (e.key === 'ArrowLeft') {
      handlePrevSlideDoc();
    } else if (e.key === 'ArrowRight') {
      handleNextSlideDoc();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [slides]); //ensures to reattach eventListener when slide changes

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
      setIsFullScreen(true);
    } else {
      exitFullscreen();
    }
  };

  const handleGridDisplay = () => {
    setIsGridDisplayActive(!isGridDisplayActive);
  };

  const itemRefs = useRef<(HTMLElement | null)[]>([]); // Store references to all items

  useEffect(() => {
    const currentItem = itemRefs.current[slideDocumentIndex];

    if (currentItem) {
      currentItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center', // Center alignment for better view
      });
    }
  }, [slideDocumentIndex, itemRefs]);

  return (
    <section className='relative h-screen max-h-screen'>
      <SidebarWrapper
        isOpen={isOpen}
        flattedDisplayDocuments={flattedDisplayDocuments}
      >
        {previewDocs && (
          <Sidebar
            displayDocumentIndex={displayDocumentIndex}
            setDisplayDocumentIndex={setDisplayDocumentIndex}
            previewDocs={previewDocs}
            flattedDisplayDocuments={flattedDisplayDocuments}
          />
        )}
      </SidebarWrapper>

      <section
        className={`absolute right-0 ${
          flattedDisplayDocuments.length === 1 ? 'w-[100%]' : 'w-[82%]'
        } px-5 bg-white h-screen max-h-screen overflow-hidden flex flex-col`}
      >
        {/* User Profile */}
        <Header
          ownerName={teamInfo?.data?.title ?? "Creator's name"}
          title={briefcaseDetails?.title}
        />
        {/* Display image content */}
        <DocumentDisplay
          isLoading={isLoading}
          activeImage={activeImage}
          activeImgRef={activeImgRef}
          isPresentationDetailsLoading={previewIsLoading}
          minScreenWidth={minScreenWidth}
          screenWidth={screenWidth}
          slides={slides}
          imageCache={imageCache}
          slideDocumentIndex={slideDocumentIndex}
          setSlideDocumentIndex={setSlideDocumentIndex}
          isGridDisplayActive={isGridDisplayActive}
          setIsGridDisplayActive={setIsGridDisplayActive}
          isSlides={isSlides}
        />
        {/* controler */}
        <ControllerParent
          screenWidth={screenWidth}
          isSlides={isSlides}
          slides={slides}
          slideDocumentIndex={slideDocumentIndex}
          handlePrevSlideDoc={handlePrevSlideDoc}
          displayDocumentIndex={displayDocumentIndex}
          flattedDisplayDocuments={flattedDisplayDocuments}
          handleNextSlideDoc={handleNextSlideDoc}
          handlePrevDoc={handlePrevDoc}
          handleNextDoc={handleNextDoc}
          imageCache={imageCache}
          itemRefs={itemRefs}
          minScreenWidth={minScreenWidth}
          setSlideDocumentIndex={setSlideDocumentIndex}
          // enterFullscreen={enterFullscreen}
          // exitFullscreen={exitFullscreen}
          handleFullscreen={handleFullscreen}
          // handleFullscreen={toggleFullscreen}
          handleGridDisplay={handleGridDisplay}
          isGridDisplayActive={isGridDisplayActive}
        />
      </section>

      {/* large display image */}
      {isFullscreen && (
        <section className='absolute top-0 left-0 w-screen h-screen z-50'>
          <section className='relative w-screen h-screen'>
            <section
              className=' bg-white w-full h-full'
              ref={fullScreenContainerRef}
            >
              {activeImage && (
                <img
                  ref={activeImgRef}
                  src={activeImage}
                  alt='file'
                  className='rounded-lg object-contain'
                  style={{
                    maxWidth: `100%`,
                    maxHeight: `100%`,
                    width: '100%',
                    height: 'auto',
                  }}
                />
              )}
            </section>

            {/* control BTN */}
            {isSlides && (
              <section className='flex items-center gap-x-5 absolute left-10 bottom-10 bg-[#E8E8E87A] border border-[#F5F5F5] rounded-full h-[40px] justify-between px-2'>
                <div
                  className={`flex gap-x-3 items-center rounded-lg ${
                    slideDocumentIndex === 0
                      ? 'cursor-default text-[#E2E2E2]'
                      : 'cursor-pointer text-black'
                  }`}
                  onClick={handlePrevSlideDoc}
                >
                  <img
                    // src={slideDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
                    src={leftNav}
                    alt='previous slide'
                    className='w-5'
                  />
                  {/* <p className=''>Previous slide</p> */}
                </div>

                <div className='rounded-[20px] text-black px-2 h-[44px] flex items-center '>
                  <p className='font-medium text-sm'>{`${
                    slideDocumentIndex + 1
                  } of ${slides.length}`}</p>
                </div>

                <div
                  className='flex gap-x-3 items-center rounded-lg cursor-pointer'
                  onClick={handleNextSlideDoc}
                >
                  {/* <p>Next slide</p> */}
                  <img
                    src={rightNav}
                    // src={
                    //   slideDocumentIndex === slides.length - 1
                    //     ? arrowRightGray
                    //     : arrowRight
                    // }
                    alt='next slide'
                    className='w-5'
                  />
                </div>
              </section>
            )}
          </section>
        </section>
      )}
    </section>
  );
}
