import Loader from "components/UI/Loader";
import React from "react";

export const TableLoading = () => {
  return (
    <div className="border-b rounded-lg flex justify-center w-full items-center h-[300px]">
      <Loader />
    </div>
  );
};
