import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//utils
import { getUserDetails } from "utils/localStorageServices";

//assets
import caution from "assets/svg/whiteCaution.svg";

//components
import Loader from "components/UI/Loader";
import AdminNavbar from "components/UI/AdminLayout/Navbar";

//slices
import {
  getAllPresentations,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import {
  getWorkspaceFolders,
  getWorkspaceState,
} from "store/Workspace/workspaceSlice";
import ProfileCompletion from "components/Admin/Dashboard/ProfileCompletion";
import LineChart from "components/Admin/Dashboard/LineChart";
import ShortPresentationList from "./Tables/ShortPresentationList";
import RecentPresentationVisits from "./Tables/RecentPresentationVisits";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { workspaceFolders } = useSelector(getWorkspaceState);
  const { loadingStatus, presentations } = useSelector(getPresentationState);

  //usestates
  const [navProps, setNavProps] = useState({});
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  //useeffects
  useEffect(() => {
    const user = getUserDetails();
    const userName = user?.user ?? user?.fullName;
    setNavProps({
      title: "Overview",
      subTitle: `Welcome back ${userName}`,
      // modalTitle: "Upload new slide",
    });

    dispatch(getWorkspaceFolders());
    dispatch(getAllPresentations());
  }, [dispatch]);

  let stoppedLoading;
  let wizardComplete;
  if (!workspaceFolders?.loadingStatus && !loadingStatus) {
    stoppedLoading = true;
  } else {
    stoppedLoading = false;
  }

  //update profile check pending (backend)
  if (presentations.length > 0 && workspaceFolders?.length > 0) {
    wizardComplete = true;
  } else {
    wizardComplete = false;
  }

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="my-20">
        {/* loader */}
        {presentations?.isLoading && <Loader />}
        {/*  */}

        {!presentations?.isLoading && (
          <section>
            {/* Complete your account */}
            {!wizardComplete && !isProfileComplete && (
              <div className="flex items-center justify-between px-4 py-2 mt-10 text-sm text-white rounded-lg bg-gray600">
                <div className="flex items-center gap-x-3">
                  <img src={caution} alt="" className="w-5" />
                  <p className="pt-1">Complete your account set up</p>
                </div>

                <i
                  className="text-sm cursor-pointer pi pi-times "
                  onClick={() => setIsProfileComplete(true)}
                ></i>
              </div>
            )}
            {/*  */}

            <ProfileCompletion presentations={presentations} />

            <ShortPresentationList presentations={presentations} />

            <RecentPresentationVisits />

            <LineChart />
          </section>
        )}
      </section>
    </section>
  );
}
