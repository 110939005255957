import React, { useState } from "react";

//components
import PollForm from "./PollForm";
import PollList from "./PollList";
// import PollTemplates from "./PollTemplates";

//assets
// import template from "assets/svg/template.svg";
// import whitePoll from "assets/svg/whitePoll.svg";

const PollCreationForm = () => {
  //usestate
  const [tab, setTab] = useState(0);

  return (
    <section>
      <main className="[ w-[80%] mx-auto lg:h-[80vh] ]   [ flex justify-center  gap-y-4 gap-x-4 ] [ absolute top-16  inset-x-0 ] [ lg:flex-row flex-col ] [ font-grotesk ] ">
        {/*  */}
        {/* poll creation  */}
        <section className="w-full lg:w-6/12">
        
          <PollForm />
          {/* tab  */}
          {/* <section className="w-[19rem] border rounded-lg px-4 py-2 text-xs flex justify-between">
            <div
              onClick={() => setTab(0)}
              className={`[ flex items-center gap-x-1 ] ${
                !tab ? " [ text-white  bg-primary ] " : "text-black"
              } [ cursor-pointer w-28 px-2 py-1 rounded-md ]`}
            >
              <img src={whitePoll} alt="new poll" />
              <p>New poll</p>
            </div>

            <div
              onClick={() => setTab(1)}
              className={`[ flex items-center gap-x-1 ]  [ cursor-pointer w-38 px-2 py-1 rounded-md ] ${
                tab ? " [ text-white  bg-primary ] " : "text-black"
              } `}
            >
              <img src={template} alt="my template" />
              <p>My templates</p>
            </div>
          </section> */}

          {/* {!tab && <PollForm />}
          {tab === 1 && <PollTemplates />} */}
        </section>

        {/*  */}

        {/* poll preview  */}
        <PollList />

        {/*  */}
      </main>
    </section>
  );
};
export default PollCreationForm;
