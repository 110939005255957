import React from "react";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <section className="h-full my-10  [ flex items-center  justify-center ] [ lg:w-[80%] w-[90%] mx-auto ] [ lg:flex-row flex-col ]  [ lg:gap-x-6 gap-y-4 ]">
      <section className="">
        <ContactInfo />
      </section>
      <section className="lg:w-[50%] w-full">
        <ContactForm />
      </section>
    </section>
  );
}
