import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import TitleBar from "./TitleBar";

//slices
import { getFolderFiles } from "store/Workspace/workspaceSlice";
import {
  getFileStructureState,
  pushOpenedFolder,
  setMostActiveFolder,
  updateOpenedFolder,
} from "store/FileStructure/fileStructureSlice";

export default function Folder({ folders }) {
  const dispatch = useDispatch();
  const subFolders = folders?.subFolders ?? folders?.SubFolders;

  //useselector
  //highlight
  const { openedFolders } = useSelector(getFileStructureState);

  //usestate
  const [openDropdown, setOpenDropdown] = useState(false);
  const [activeFolder, setActiveFolder] = useState(null);

  const showDropdown = (folder) => {
    const { Id, Title } = folder ?? {};
    const subFolderId = folder?.Id;

    setActiveFolder(subFolderId);
    dispatch(setMostActiveFolder({ id: Id, title: Title }));
    setOpenDropdown((openDropdown) => !openDropdown);
    dispatch(getFolderFiles(subFolderId));

    //highlight

    const findFolder = openedFolders?.findIndex((opened) => opened?.id === Id);
    if (findFolder > -1) {
      dispatch(updateOpenedFolder(openedFolders.slice(0, findFolder + 1)));
    }
    
    dispatch(pushOpenedFolder({ id: subFolderId, title: Title }));
  };

  return (
    <section className="w-full cursor-pointer">
      {subFolders?.map((folder, index) => {
        // highlight
        const folderId = folder?.id ?? folder?.Id;
        const foundFolder = openedFolders?.find((opened) => {
          return opened?.id === folderId;
        });

        const isExist = foundFolder ? true : false;
        //
        return (
          <section key={index} className="w-[100%] mt-[2px] ">
            <section>
              <TitleBar
                folder={folder}
                showDropdown={showDropdown}
                openDropdown={openDropdown}
                activeFolder={activeFolder}
              />
            </section>
            {/* highlight */}
            {(openDropdown || isExist) && (
              <div
                className={`pl-4   flex items-center  text-sm  rounded-md cursor-pointer font-grotesk `}
              >
                <Folder folders={folder} />
              </div>
            )}
          </section>
        );
      })}
    </section>
  );
}
