import { Col, Row } from "antd";
import React, { ReactNode } from "react";

export const PreviewMobileWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="previewMobileBackground h-screen min-h-screen">
      <Row justify={"center"}>
        <Col xs={22}>{children}</Col>
      </Row>
    </div>
  );
};
