import React from "react";

//assets
import banner from "assets/svg/aboutBanner.svg";
import { TypeAnimation } from "react-type-animation";

export default function Banner() {
  return (
    <section className=" mx-auto [lg:w-[80%] w-full ]">
      <div className="text-center">
        <h1 className="  [ lg:text-[4rem]  text-2xl  lg:leading-tight font-medium ] ">
          This is where{" "}
          <span className="text-primary">
            {" "}
            <TypeAnimation
              sequence={[" Ideas", 1000, "Plans", 2000, "Proposal"]}
              speed={20}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
            />{" "}
          </span>{" "}
          meet Impact
        </h1>

        <p className="mx-auto  text-black30 [ lg:w-[70%] w-[90%] ] [ lg:text-[1.2rem] text-xs ] [ lg:mt-6 mt-4 ] [ lg:leading-[2rem] leading-[1rem] ]">
          At Presenta, we are dedicated to empowering individuals , businesses
          and educators worldwide with the tools they need to excel in
          communication. Our innovative platform enables seamless engagement for
          presenters, whether they're delivering presentations in person or
          online.
        </p>
      </div>

      <div className="flex justify-center [ lg:mt-10  mt-8 ] [ lg:w-full w-[80%] ] mx-auto">
        <img src={banner} alt="" className="" />
      </div>
    </section>
  );
}
