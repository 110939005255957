import React from "react";

export const AnalyticsCard = ({
  statValue,
  text,
  icon,
}: {
  statValue: number;
  text: string;
  icon: string;
}) => {
  return (
    <div className="border rounded-xl border-[#EEEEEE] pt-4 pb-6 px-4">
      <span>
        <img src={icon} />
      </span>
      <p className="my-3 text-sm text-[#545454] ">{text}</p>
      <p className="text-xl font-semibold">{statValue}</p>
    </div>
  );
};
