import React, { useCallback, useEffect, useRef, useState } from "react";
//libraries
import { Drawer, Dropdown, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

//assets
import link from "assets/svg/link.svg";
import exit from '../../../assets/svg/exit.svg'
import people from "assets/svg/peopleGroup.svg";
import msgFav from "assets/svg/msgFav.svg";
import logo from "assets/svg/fullLogo.svg";
import settings from "assets/svg/settings.svg";
import more from "assets/svg/more.svg";
import messagenotif from "assets/svg/messagenotif.svg";

//component
import AskQuestion from "views/User/ViewerMode/Modal/AskQuestion";
import AllViewers from "views/User/ViewerMode/Drawers/AllViewers";
import AllQuestions from "views/User/ViewerMode/Drawers/AllQuestions";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import AttentionModal from "views/User/ViewerMode/Modal/AttentionModal";
// import { RemoveGuest } from "hooks/SignalR/Invokers/Presenter/RemoveGuest";
// import { beginViewerJourney } from "utils/localStorageServices";

//listeners
import { getConnectionState } from "store/SignalR/signalRSlice";
import { useGetViewersList } from "hooks/SignalR/Listeners/Presenter/useGetViewersList";

//invokers
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { GetQuestions } from "hooks/SignalR/Invokers/Presenter/GetQuestions";
import { useGetQuestions } from "hooks/SignalR/Listeners/Presenter/useGetQuestions";
import Timer from "components/Admin/ActivePresentation/Timer";
import { RemoveGuest } from "hooks/SignalR/Invokers/Presenter/RemoveGuest";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";
import { getConnectionId } from "utils/localStorageServices";
import { ClipLoader } from "react-spinners";
import instance from "../../../lib/axiosInterceptor";
import { toast } from "sonner";
import { errorToast, successToast } from "utils/toastUtils";
import { trimText } from "utils/formatter";
import {
  getDocumentFiles,
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { useSelector } from "react-redux";

export default function BottomBar({
  title,
  connection,
  slideId,
  activeDocument,
  activeImage,
  slideNumber,
  presentationId,
  friendlyId,
}) {
  const connectionState = getConnectionState();
  const navigate = useNavigate();
  const firstCalc = 10;
  const secondCalc = 20;
  const thirdCalc = 30;
  const inactivityTimeDuration = 45 * 1000;
  const activityTimeDuration = 10 * 1000;
  const events = ["mousemove", "keydown", "mousedown", "touchstart"];
  const { documentFiles, presentationDetails } =
    useSelector(getPresentationState);

  const modalAutomaticCloseDuration = 30 * 1000;

  //ref
  const askQuestionRef = useRef();
  const moreDropdownRef = useRef(null);
  const activeTimerRef = useRef(0);
  const checkActivity = useRef(false);
  const attentionModalRef = useRef(false);

  // usestate
  const [isLoading, setIsLoading] = useState(false);
  const [viewersCount, setViewersCount] = useState(0);
  const [modalPosition, setModalPosition] = useState();
  const [questionCount, setQuestionCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attentionModal, setAttentionModal] = useState(false);
  const [openViewersDrawer, setOpenViewersDrawer] = useState(false);
  const [openQuestionsDrawer, setOpenQuestionsDrawer] = useState(false);
  const [openMoreOptionsDropdown, setOpenMoreOptionsDropdown] = useState(false);
  const [activeRecorded, setActiveRecorded] = useState(false);

  const [modalOpenTime, setModalOpenTime] = useState(null);
  const [modalCloseScore, setModalCloseScore] = useState(null);
  const [activeSeconds, setActiveSeconds] = useState(null);
  const [activityTriggered, setActivityTriggered] = useState(0);
  const [slidesScored, setSlidesScored] = useState([]);
  const [sendReport, setSendReport] = useState(false);

  //api call

  const getWidth = () => {
    if (window.innerWidth < 768) {
      return "90%";
    } else {
      return "600px";
    }
  };

  const handleSendActivityReport = async (score) => {
    if (!presentationId) return;
    try {
      const result = await instance.post(
        `/Presentation/RecordAttention/${presentationId}`,
        { presentaConnectionId: getConnectionId(), score, slideId }
      );
      setSlidesScored((prev) => [...prev, slideId]);
      clearTimeout(window.inactivityTimer);
      clearInterval(window.activityInterval);
    } catch (e) {
      console.log(e);
    } finally {
      console.log("Api call made");
      // This is to basically restart the timer , although implicitly , when the user moves their mouse to click on continue for the attention modal , the timer restarts , but just to be save and to accurately measure the activity from the last time the api call was made
      // resetTimer();
      activeTimerRef.current = 0;
      checkActivity.current = false;
      removeEventsFromWindow();
    }
  };

  //hook
  useGetQuestions(connection, presentationId, (questions) => {
    setQuestionCount(questions.length);
  });

  useEffect(() => {
    // Restart the timer and then start listening for activity based on the constraints that have been defined
    if (!slidesScored.includes(slideId)) {
      // Now start the normal timer for activity and the timer for inactivity
      addEventsToWindow();
      window.activityInterval = setInterval(() => {
        activeTimerRef.current += 1;

        // if activity timer has passed 10 seconds and activity has been detected then do the below
        if (activeTimerRef.current == 10 && checkActivity.current) {
          handleSendActivityReport(100);
          clearInterval(window.activityInterval);
          checkActivity.current = false;
        } else if (
          activeTimerRef.current > 10 &&
          checkActivity.current &&
          inactivityTimeDuration / 1000 > activeTimerRef.current
        ) {
          // so, the inactivity modal is only going to be considered to be triggered after it is sure that the user has been inactive within the activity timeline which is 10 seconds in this case
          activeTimerRef.current = -1;
          handleSendActivityReport(100);
          checkActivity.current = false;
          // resetTimer();
          clearInterval(window.activityInterval);
        } else if (activeTimerRef.current >= inactivityTimeDuration / 1000) {
          randomModalPosition();
          setAttentionModal(true);
          activeTimerRef.current = 0;
          attentionModalRef.current = true;
          setModalOpenTime(Date.now());
          clearInterval(window.activityInterval);
        }
      }, 1000);
      // resetTimer();
    } else {
      removeEventsFromWindow();
      clearTimeout(window.inactivityTimer);
      clearInterval(window.activityInterval);
    }

    return () => {
      checkActivity.current = false;
      activeTimerRef.current = 0;
      clearTimeout(window.inactivityTimer);
      clearInterval(window.activityInterval);
    };
  }, [slideId]);

  // handle Reset or call the new timer

  useGetViewersList(connection, presentationId, connectionState, (users) => {
    const totalViewers = users.filter((user) => {
      return user.status === 1;
    })?.length;
    setViewersCount(totalViewers);
  });

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();
      const msg = message?.toLowerCase();
      setIsLoading(false);
      if (event === "removeuser" && "user has been removed") {
        localStorage.clear();
        navigate(`/join-presentation/${friendlyId}`);
      }
    }
  );

  useEffect(() => {
    if (connection && presentationId) {
      GetUsersList(connection, presentationId);
    }
  }, [connection, connectionState, presentationId, openViewersDrawer]);

  useEffect(() => {
    if (connection && presentationId) {
      GetQuestions(connection, presentationId, getConnectionId());
    }
  }, [presentationId, connection, openQuestionsDrawer, connectionState]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        moreDropdownRef.current &&
        !moreDropdownRef.current.contains(event.target)
      ) {
        setOpenMoreOptionsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (sendReport) {
      console.log("Triggered , inside hereeee");
      handleSendActivityReport(100);
      setSendReport(false);
    }
  }, [sendReport]);

  const resetTimer = useCallback(async () => {
    // if (window.inactivityTimer) {
    checkActivity.current = true;
    // clearTimeout(window.inactivityTimer);
    // if (activeTimerRef.current == -1 && !attentionModalRef.current) {
    // console.log("this caused the trigger");
    // removeEventsFromWindow();
    // clearTimeout(window.inactivityTimer);
    // setSendReport(true);
    // }
    // }

    // Check for if there has been an activity , then keep an awareness of that activeness

    // window.inactivityTimer = setTimeout(() => {
    //   console.log("timer out got triggered");
    //   randomModalPosition();
    //   setAttentionModal(true);
    //   activeTimerRef.current = 0;
    //   attentionModalRef.current = true;
    //   setModalOpenTime(Date.now());
    // window.closeModalTimer = setTimeout(() => {
    //   modalAutoClose();
    // }, modalAutomaticCloseDuration);
    // }, 30000);
  }, []);

  // Technically the effect below only runs once

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Initialize inactivityTimer
    // resetTimer();

    return () => {
      if (window.inactivityTimer) {
        clearTimeout(window.inactivityTimer);
      }

      if (window.closeModalTimer) {
        clearTimeout(window.closeModalTimer);
      }
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [resetTimer]);

  //function

  const removeEventsFromWindow = () => {
    events.forEach((event) => window.removeEventListener(event, resetTimer));
  };
  const addEventsToWindow = () => {
    events.forEach((event) => window.addEventListener(event, resetTimer));
  };
  const randomModalPosition = () => {
    let positions;
    let isMobile = window.innerWidth < 768;
    if (isMobile) {
      positions = [
        {
          top: "50%",
        },
        {
          top: "0%",
        },
        {
          bottom: "0%",
        },
      ];
    } else {
      positions = [
        {
          left: "29%",
        },
        {
          right: "29%",
        },
        {
          top: "2%",
        },
        {
          bottom: "29%",
        },
      ];
    }

    let index = Math.floor(Math.random() * 3);

    setModalPosition(positions[index]);
  };
  const onClose = () => {
    setOpenViewersDrawer(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    askQuestionRef.current.resetModal();
  };

  const modalAutoClose = () => {
    setAttentionModal(false);
    setModalCloseScore(0);
    if (window.closeModalTimer) {
      clearTimeout(window.closeModalTimer);
    }
  };
  const handleAttentionCancel = () => {
    if (modalOpenTime && !slidesScored.includes(slideId)) {
      let modalScore = 0;
      const timeSpent = (Date.now() - modalOpenTime) / 1000; // Time taken before a response was sent
      if (timeSpent <= firstCalc) {
        modalScore = 100;
        setModalCloseScore(100);
      } else if (timeSpent <= secondCalc) {
        modalScore = 50;
        setModalCloseScore(50);
      } else if (timeSpent <= thirdCalc) {
        modalScore = 25;
        setModalCloseScore(25);
      }

      // Make the api Call here to basically record the attention
      handleSendActivityReport(modalScore);
      setAttentionModal(false);
      attentionModalRef.current = false;
    }
  };

  const closeQuestionsDrawer = () => {
    setOpenQuestionsDrawer(false);
  };

  const leavePresentation = (email) => {
    setIsLoading(true);
    RemoveGuest(connection, presentationId, getConnectionId(), false);
  };

  const shareLink = async () => {
    let routeOrigin = window.location.origin;
    let text = `${routeOrigin}/join-presentation/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(text);
      toast.success("Link copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy link");
    }
  };

  const items = [
    {
      key: "2",
      label: (
        <p onClick={() => setOpenViewersDrawer(true)} className="mb-2 text-sm">
          Viewers ({viewersCount})
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p
          onClick={() => setOpenQuestionsDrawer(true)}
          className="mb-2 text-sm"
        >
          Questions ({questionCount})
        </p>
      ),
    },

    {
      key: "4",
      label: (
        <div
          onClick={() => {
            setIsModalOpen(true);
          }}
          className="text-sm flex-items-center gap-x-2"
        >
          <p>Ask a question</p>
        </div>
      ),
    },

    {
      key: "1",
      label: (
        <p onClick={shareLink} className="mb-2 text-sm">
          Copy presentation link
        </p>
      ),
    },
    {
      key: "5",
      danger: true,
      label: (
        <div
          onClick={leavePresentation}
          className="text-xs flex-items-center gap-x-2"
        >
          {isLoading ? (
            <ClipLoader color="white" size={12} />
          ) : (
            <i className="pi pi-sign-out " style={{ fontSize: "0.7rem" }}></i>
          )}

          <p>Leave</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <>
        <section className="flex">
          {/* <div className="px-4 py-3 border-r lg:px-6">
            <img src={logo} alt="Presenta Logo" className="w-20 lg:w-24 " />
          </div> */}

          <FlexContainer extraClass="px-4 w-full items-center">
            {/* <section className="flex gap-x-4">
              <h2 className="hidden text-sm font-bold font-grotesk lg:text-base lg:block">
                {title}
              </h2>
              <Tooltip placement="bottom" title={title}>
                <h2 className="block text-sm font-bold font-grotesk lg:hidden">
                  {trimText(title, 20)}
                </h2>
              </Tooltip>
            </section> */}

            <section className="flex items-center gap-x-4 ">
              {/* <Timer /> */}

              <section className="lg:hidden">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <div
                    onClick={(e) => e.preventDefault()}
                    className="flex items-center justify-center w-8 h-8 border rounded-md cursor-pointer bg-gray20"
                  >
                    <img src={more} alt="settings" className="w-4" />
                  </div>
                </Dropdown>
              </section>

              {/* large screen  */}
              <section className="hidden lg:flex items-center gap-x-4 ">
                {/* more options */}
                <div className="flex gap-x-4">
                  <div className="relative">
                    
                    <button
                  className="relative flex items-center gap-2 flex-col text-xs rounded-lg cursor-pointer"
                  onClick={() => setOpenViewersDrawer(true)}
                >
                  <img src={people} alt="animate" className="w-5" />
                  <p className="absolute top-2 w-[0.875rem] h-[1rem] text-[0.75rem] font-bold text-white rounded-full right-2 bg-[#D72F2F] text-center flex items-center justify-center">
                    {viewersCount}
                  </p>
                  <p className="text-[#667084] text-xs"> Attendees</p>
                </button>
                  </div>
                </div>
                {/*  */}

                

                <button
                  className="relative flex flex-col items-center gap-2 px-4 py-2 text-xs cursor-pointer"
                  onClick={() => setOpenQuestionsDrawer(true)}
                >
                  <img src={messagenotif} alt="animate" className="w-5" />

                  <p className="absolute top-4 w-[0.875rem] h-[1rem] text-[0.75rem] font-bold text-white rounded-full left-11 bg-red-500 text-center flex items-center justify-center">
                    {questionCount}
                  </p>
                  <p className="text-[#667084] text-xs"> Questions</p>
                </button>

                <button
                      onClick={shareLink}
                      className="flex flex-col gap-2 items-center cursor-pointer"
                    >
                      <img src={link} alt="settings" className="w-4" />
                      <p className="text-[#667084] text-xs"> Share</p>

                    </button>

                      

                {/* {presentationDetails.data &&
                  presentationDetails.data.presentation.questionsAllowed && (
                    <FlexContainer
                      extraClass="bg-primary cursor-pointer rounded-lg  text-white text-xs px-4 py-2 gap-x-1"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <img src={msgFav} alt="play slide" className="w-3" />
                      <p>Ask a question</p>
                    </FlexContainer>
                  )} */}

                <button
                  extraClass="bg-red-500 cursor-pointer rounded-lg  text-white text-xs px-4 py-2 gap-x-1"
                  onClick={leavePresentation}
                >
                  {isLoading ? (
                    <ClipLoader color="white" size={12} />
                  ) : (
                    <img src={exit} alt="exit" />
                  )}

                </button>
              </section>
              {/*  */}
            </section>
          </FlexContainer>
        </section>
      </>

      <>
        {/* Modal */}

        <>
          <Modal
            style={{
              top: window.innerWidth > 900 ? "10%" : "5%",
            }}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <AskQuestion
              ref={askQuestionRef}
              isOpen={openQuestionsDrawer}
              connection={connection}
              slideId={slideId}
              slideNumber={slideNumber}
              activeDocument={activeDocument}
              presentationId={presentationId}
              activeImage={activeImage}
              closeModal={handleCancel}
            />
          </Modal>

          <Modal
            style={{ ...modalPosition, minWidth: getWidth() }}
            open={attentionModal}
            onCancel={handleAttentionCancel}
            footer={null}
            width={400}
            onOk={handleAttentionCancel}
            maskClosable={false}
          >
            <AttentionModal closeModal={handleAttentionCancel} />
          </Modal>

          {/* <Modal
            style={{ ...modalPosition, minWidth: "600px" }}
            open={attentionModal}
            onCancel={handleAttentionCancel}
            footer={null}
            width={400}
            onOk={handleAttentionCancel}
            maskClosable={false}
          >
            <AttentionModal closeModal={handleAttentionCancel} />
          </Modal> */}
        </>
        {/*  */}

        {/* Drawer */}
        <>
          <Drawer
            title={`Viewers (${viewersCount})`}
            onClose={onClose}
            open={openViewersDrawer}
          >
            <AllViewers
              connection={connection}
              presentationId={presentationId}
            />
          </Drawer>

          <Drawer
            width={window.innerWidth > 900 ? "30%" : "100%"}
            open={openQuestionsDrawer}
            onClose={closeQuestionsDrawer}
            title={`Questions  (${questionCount})`}
          >
            <AllQuestions
              connection={connection}
              presentationId={presentationId}
            />
          </Drawer>
        </>
        {/*  */}
      </>
    </>
  );
}
