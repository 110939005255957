import Axios from "lib/axiosInterceptor";

//get

export const _getProfilePic = () => {

  return Axios({
    url: `/Account/ProfilePic`,
    method: "GET",
    responseType: "blob", // Get the response as a blob
    headers: {
      Accept: "image/jpeg",
    },
  }).catch((err) => {
    console.log(err, "stream error");
  });

};

//post
export const _changePassword = (data) => {
  return Axios.post(`/Account/ChangePassword`, data);
};

export const _updateProfile = (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.post(`/Account/Update`, data, config);
};

export const _beginSubscriptionPayment = (planId) => {
  return Axios.post(`/Payments/Subscribe?planId=${planId}`);
};

export const _convertPointAmount = (data) => {
  const { value, to } = data ?? {};
  return Axios.get(`/Payments/PointConversion?value=${value}&to=${to}`, data);
};
