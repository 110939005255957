import { useState } from 'react';
import { setBriefcaseDocumentPermission } from 'store/Briefcase/briefcaseSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';

// Types
interface IsPrivateProps {
  isPrivate: boolean;
  spaceId: string;
  documentId: string;
}

export default function IsPrivate({
  isPrivate,
  spaceId,
  documentId,
}: IsPrivateProps) {
  const dispatch = useDispatch<AppDispatch>();
  // States
  const [private_, setPrivate_] = useState(isPrivate);

  //  Functions
  const handleIsPrivate = async () => {
    const data = {
      isPrivate: !private_,
      documentId: documentId,
    };
    setPrivate_(!private_);
    const { type } = await dispatch(
      setBriefcaseDocumentPermission({ spaceId, data })
    );
    if (!type.includes('fulfilled')) {
      setPrivate_(!private_);
      toast.error('Network error');
    }
  };

  return (
    <label className='inline-flex items-center cursor-pointer'>
      <input
        type='checkbox'
        value=''
        checked={private_}
        className='sr-only peer'
        onChange={handleIsPrivate}
      />
      <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
    </label>
  );
}
