import "./App.css";

function App() {
    console.log("Got triggered in the app context ")
    return (
        <>


        </>
        // <BrowserRouter>
        //   <Routes>
        //     <Route element={<Login />} path="/" errorElement={<ErrorPage />} />
        //   </Routes>
        // </BrowserRouter>
    );
}

export default App;
