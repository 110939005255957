import {MobilePresentation} from "../../../components/UI/mobile/mobilePresentation";

export const ExternalActivePresentation = () => {
    const getZoomCollaborate = async () => {
        const result = await zoomSdk.onCollaborateChange((event: any) => {
            console.log(event, "Event triggered on zoom collaborate mode change")
        });
    }
    window.addEventListener("load", async () => {
        getZoomCollaborate();
    });
    return <>
        <MobilePresentation />
    </>
}