import LoginCarousel from "components/Authentication/Login/Carousel";
import React, { useRef, useState } from "react";
import logo from "assets/svg/fullLogo.svg";
import { BackButton, CButton } from "components/UI/Button/Buttons";
import { Link } from "react-router-dom";
import AuthInput from "components/UI/Inputs/AuthInput";
import Label from "components/UI/Label";
import lineBg from "assets/svg/lineBg.svg";
import instance from "lib/axiosInterceptor";
import { toast } from "sonner";
import { ignore } from "antd/es/theme/useToken";

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const lineBgStyle = {
    background: `url(${lineBg}) no-repeat bottom`,
    // backgroundSize: "cover",
  };
  const [otpActive, setOtpActive] = useState(false);
  const [inputEmailActive, setInputEmailActive] = useState(true);
  const [email, setEmail] = useState("");
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));

  const handleForgotPassword = async () => {
    setLoading(true);
    if (!email) {
      toast.error("Email is required");
      setLoading(false);
      return;
    }
    try {
      const result = await instance.post(`/Account/ForgotPassword/${email}`);
      toast.info(`Password Reset link sent to ${email}`);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <section className="flex justify-between h-screen font-grotesk ">
        <section
          style={lineBgStyle}
          className="lg:w-[40%] w-full px-10 lg:py-4 py-10"
        >
          <Link to="/">
            <div>
              <img
                src={logo}
                alt="Presenta Logo"
                className="w-20 ml-5 lg:w-24"
              />
            </div>
          </Link>

          <section className="lg:mt-6 mt-[3%] w-[90%] mx-auto ">
            <div className="mb-12 mt-24">
              <BackButton />
            </div>
            <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  ">
              {inputEmailActive ? "Forgot Password" : "Enter Otp"}
            </h1>
            <div className="my-10 text-sm font-medium text-left lg:my-2 text-gray40 ">
              <h3>
                {inputEmailActive
                  ? "We have all been here before, let's reset it."
                  : `Enter Otp sent to ${email}`}
              </h3>
            </div>
            <section className="mt-12">
              <form onSubmit={handleForgotPassword} className="">
                <div className="mb-4">
                  <Label text="Email address" />
                  {/* @ts-ignore */}
                  <AuthInput
                    type="email"
                    name="email"
                    placeholder="example@presenta.com"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <CButton
                  fullWidth
                  variant="dark"
                  action={handleForgotPassword}
                  text="Reset password"
                  loading={loading}
                />
              </form>
            </section>
          </section>
        </section>
        <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
          <LoginCarousel />
        </section>
      </section>
    </>
  );
};
