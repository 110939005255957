import React, {useEffect, useRef, useState} from "react";
import {useGetQuestions} from "../../../../hooks/SignalR/Listeners/Presenter/useGetQuestions";
import {HubConnection} from "@microsoft/signalr";
import {ClipLoader} from "react-spinners";
import NowAnswering from "../../../Admin/ActivePresentation/QuestionsDrawer/NowAnswering";
import QuestionCard from "../../../Admin/ActivePresentation/QuestionsDrawer/QuestionCard";
import empty from "../../../../assets/svg/emptyTemplate.svg";
import {GetQuestions} from "../../../../hooks/SignalR/Invokers/Presenter/GetQuestions";

export const DesktopQuestion = ({connection, presentationId}: {
    connection?: HubConnection | null,
    presentationId?: string
}) => {
    const [tab, setTab] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
    const drawerRef = useRef<HTMLDivElement>(null);
    useGetQuestions(connection, presentationId, (questionList: any) => {
        const questions = questionList.sort((a: any, b: any) =>
            a?.askTime < b?.askTime ? 1 : -1
        );
        setQuestions(
            questions.filter((question: any) => {
                if (tab === 1) {
                    return !question?.isAnswered;
                }

                if (tab === 2) {
                    return question?.isAnswered;
                }

                return question;
            })
        );
        setIsLoading(false);
    });

    useEffect(() => {
        if (connection && connection.state == "Connected") {
            GetQuestions(connection, presentationId);
        }
    }, [presentationId, connection, tab]);

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return <section className="relative">
        <section className="z-[99999]  bg-white pb-5 lg:top-12  lg:mt-0 lg:w-[27%] w-[90%]">
            <div className="flex items-center text-xs font-medium gap-x-4 text-gray60">
                <p
                    className={` cursor-pointer ${
                        tab === 0 && " font-bold text-primary"
                    } `}
                    onClick={() => setTab(0)}
                >
                    ALL
                </p>
                <p
                    className={` cursor-pointer ${
                        tab === 1 && " font-bold text-primary"
                    } `}
                    onClick={() => setTab(1)}
                >
                    UNANSWERED
                </p>
                <p
                    className={` cursor-pointer ${
                        tab === 2 && " font-bold text-primary"
                    } `}
                    onClick={() => setTab(2)}
                >
                    {" "}
                    ANSWERED
                </p>
            </div>
        </section>

        <div  ref={drawerRef}>
            {isLoading && (
                <div className="flex items-center justify-center w-full mt-20">
                    <ClipLoader size={20} color="#FF6929"/>
                </div>
            )}

            {!isLoading && (
                <section>
                    {/* now answering  */}
                    {selectedQuestion && (
                        <section className="flex flex-col">
                            <NowAnswering
                                isViewer={false}
                                connection={connection}
                                question={selectedQuestion}
                                setSelectedQuestion={setSelectedQuestion}
                            />
                        </section>
                    )}

                    {/*  */}

                    {/* question queue  */}

                    <div>
                        <div className="mt-4">
                            <p className="text-xs font-bold gap-x-4 text-gray60">
                                QUESTION QUEUE
                            </p>
                        </div>
                        {questions?.length > 0 &&
                            questions?.map((question: any, index) => {
                                return (
                                    <section key={index}>
                                        {question?.id !== selectedQuestion?.id && (
                                            <QuestionCard
                                                isViewer={false}
                                                connection={connection}
                                                question={question}
                                                drawerRef={drawerRef}
                                                setSelectedQuestion={setSelectedQuestion}
                                            />
                                        )}
                                    </section>
                                );
                            })}

                        {questions.length < 1 && (
                            <div className="h-[60vh] flex flex-col items-center justify-center">
                                <img src={empty} alt="no viewer"/>
                                <p className="text-sm font-medium">No question on the list</p>
                            </div>
                        )}
                    </div>
                    {/*  */}
                </section>
            )}
        </div>
    </section>
}