import React from "react";

//assets
import edit from "assets/svg/edit.svg";
import paris from "assets/svg/paris.svg";
import duplicate from "assets/svg/copy.svg";
import timerImg from "assets/svg/timer1.svg";
import deletee from "assets/svg/whiteDelete.svg";
import pollHeader from "assets/svg/pollHeader.svg";

//components
import OptionList from "./OptionList";

//utils
import {
  deleteQuestionType,
  duplicateQuestionType,
  editQuestionType,
} from "utils/pollStateManagement";
import { timeTextFormatter } from "utils/formatter";
import { useDispatch } from "react-redux";

export default function QuestionTypeTemplate({ tab, details, title, order }) {
  const dispatch = useDispatch();

  const { question, options, questionType, id, questionImageUrl, duration } =
    details ?? {};

  const type = questionType?.toLowerCase();
  const isWordCloud = type === "word cloud";
  const isTrueFalse = type === "true or false";

  //functions
  const handleDuplication = () => {
    if (tab === 0) {
      duplicateQuestionType(id);
    } else {
   
    }
  };

  

  return (
    <section>
      {/* poll template */}
      <section className=" [ w-11/12 mx-auto ] rounded-2xl bg-white py-4 px-4 ">
        {/* header */}
        <section className="flex items-center justify-between ">
          <div className="flex items-center font-bold gap-x-2">
            <img src={pollHeader} alt="" />
            <h3 className="flex items-center">
              {order + 1}. {questionType}
            </h3>
            <div className="flex text-gray60 ">
              {" "}
              <img src={timerImg} alt="" />
              <p className="mt-[1.2px]">{timeTextFormatter(duration)}</p>
            </div>{" "}
          </div>

          <div className="flex items-center text-xs gap-x-4">
            <div
              onClick={handleDuplication}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              <img src={duplicate} alt="duplicate" />
              <p>Duplicate</p>
            </div>

            <div
              onClick={() => editQuestionType(id)}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              <img src={edit} alt="edit" />
              <p>Edit</p>
            </div>

            <div
              onClick={() => deleteQuestionType(id)}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              <img src={deletee} alt="delete" />
              <p>Delete</p>
            </div>
          </div>
        </section>
        {/*  */}

        {/* body */}

        <section
          className={`flex flex-col  py-4 my-4 rounded-lg gap-y-4 bg-gray550 ${
            isWordCloud || isTrueFalse
              ? "items-center justify-center"
              : "text-left pl-6 "
          }`}
        >
          {questionImageUrl && isTrueFalse && (
            <img src={paris} alt="" className="w-[9.8719rem] h-[6.6094rem]" />
          )}
          <h3 className="text-lg font-semibold text-black40">{question}</h3>
          {!isWordCloud && (
            <section
              className={`flex gap-x-4 ${
                !isTrueFalse && "flex-col w-9/12 gap-y-4"
              }  `}
            >
              {options?.map((option) => {
                return <OptionList key={option?.id} option={option} />;
              })}
            </section>
          )}
        </section>

        {/*  */}
      </section>
      {/*  */}
    </section>
  );
}
