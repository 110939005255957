import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//assets
import stripe from 'assets/svg/stripe.svg';
// import paypal from "assets/svg/paypal.svg";
import mark from 'assets/svg/orangeMark.svg';
import discount from 'assets/svg/discount.svg';

//components
import Loader from 'components/UI/Loader';
import StripePayment from '../Payment/Stripe';
import AdminNavbar from 'components/UI/AdminLayout/Navbar';

//slices
import {
  beginSubscriptionPayment,
  getPaymentState,
  getPlans,
} from 'store/Payment/paymentSlice';

//libraries
import { Dropdown, Modal } from 'antd';
import { ClipLoader } from 'react-spinners';

export default function TeamPlanCheckout() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const clientKey = useRef(null);

  //useselector
  const { plans } = useSelector(getPaymentState);
  const { agencyPlan, businessPlan, personalPlan } = plans ?? {};

  //usestates
  const [seats, setSeats] = useState(0);
  const [details, setDetails] = useState({});
  const [navProps, setNavProps] = useState({});
  const [duration, setDuration] = useState('year');
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [billOverview, setBillOverview] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [openStripePaymentModal, setOpenStripePaymentModal] = useState(false);

  //useeffects
  useEffect(() => {
    setNavProps({
      title: 'Checkout & payment',
      subTitle: `Complete subsciption process`,
    });

    dispatch(getPlans());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPlan) {
      const bill = selectedPlan?.plans?.find((plan) => {
        const interval = plan?.interval?.toLowerCase();
        return interval?.includes(duration);
      });

      const seatNum = selectedPlan?.planItems?.find((plan) => {
        const planItem = plan?.item?.toLowerCase();
        return planItem === 'number of users';
      })?.friendlyValue;

      setSeats(seatNum);

      setBillOverview(bill);
    }
  }, [duration, selectedPlan]);

  useEffect(() => {
    const planName = id?.toLowerCase();
    if (plans.data) {
      if (planName === 'agency') {
        setSelectedPlan(agencyPlan);
      }

      if (planName === 'personal') {
        setSelectedPlan(personalPlan);
      }

      if (planName === 'business') {
        setSelectedPlan(businessPlan);
      }
    }
  }, [plans, agencyPlan, personalPlan, businessPlan, id]);

  //variables
  const items = [
    {
      key: '1',
      label: (
        <Link
          to={`/admin/checkout/agency`}
          // to={`/admin/checkout/${agencyPlan?.paymentProductId}`}
        >
          Agency
        </Link>
      ),
    },
    {
      key: '2',
      label: <Link to={`/admin/checkout/business`}>Business</Link>,
    },
    {
      key: '3',
      label: <Link to={`/admin/checkout/personal`}>Personal</Link>,
    },
  ];

  //functions

  // Close Modal
  const closeTopupModal = () => {
    setOpenStripePaymentModal(false);
  };
  // Start Payment
  const startPayment = () => {
    setPaymentLoading(true);

    let planId = billOverview?.id;

    dispatch(beginSubscriptionPayment(planId)).then(({ type, payload }) => {
      setPaymentLoading(false);

      if (type?.includes('fulfilled')) {
        clientKey.current = payload?.clientSecret;
        setDetails({
          seats,
          tax: 0,
          amount: billOverview?.price,
        });
        setOpenStripePaymentModal(true);

        // closeModal();
      }
    });
  };

  return (
    <section className='relative '>
      <AdminNavbar navProps={navProps} />

      {plans.isLoading && <Loader />}

      {!plans.isLoading && (
        <section className='flex mt-20'>
          <section className='w-7/12 '>
            <section className='flex items-center justify-between py-3 text-sm text-white bg-black rounded-lg w-[60%] px-4'>
              <div className='flex items-center gap-x-2'>
                <img src={mark} alt='' className='w-[1.5rem]' />
                <p className='capitalize'>{id} plan</p>
              </div>

              <Dropdown
                menu={{
                  items,
                }}
                placement='bottomRight'
                arrow
              >
                <div className='cursor-pointer'>
                  <p className='underline'>Change</p>
                </div>
              </Dropdown>
            </section>

            <section className='mt-4'>
              <h4>Choose the number of seats</h4>

              <section className='flex items-center mt-4 gap-x-6'>
                {/* <button
                  disabled={!seats}
                  onClick={() => setSeats((seats) => seats && seats - 1)}
                  className={` ${
                    !seats && "cursor-not-allowed"
                  } w-[2.2rem]  h-[2.25rem] flex items-center justify-center rounded-lg bg-orange10`}
                >
                  <i className="pi pi-minus"></i>
                </button> */}

                <p className='pt-1 font-medium'>{seats}</p>

                {/* <button
                  onClick={() => setSeats((seats) => seats + 1)}
                  className="w-[2.2rem]  h-[2.25rem] flex items-center justify-center rounded-lg bg-orange10"
                >
                  <i className="text-sm pi pi-plus"></i>
                </button> */}
              </section>

              <section className='mt-4 text-sm'>
                This plan is suitable for only {seats} seats, additional seat
                will cost <br />
                extra{' '}
                <span className='font-semibold text-primary'>
                  ${selectedPlan?.plans?.[0]?.price}/month or $
                  {selectedPlan?.plans?.[1]?.price}/year
                </span>
              </section>
            </section>
          </section>

          <section className='w-5/12 h-[86vh] pl-4 self-start mt-[-20px] border border-l border-t-0 border-b-0 border-r-0'>
            <section>
              {/* header  */}
              <div>
                <h3 className='text-lg font-bold text-gray600'>
                  Billing cycle
                </h3>
                <p className='text-sm'>Choose a billing cycle</p>
              </div>
              {/*  */}

              {/* duration */}
              <form className='w-[250px] flex items-center justify-between mt-4 '>
                <div
                  className={`flex items-center gap-x-2 ${
                    duration === 'year' && 'text-primary font-semibold '
                  }`}
                >
                  <input
                    type='radio'
                    name='duration'
                    value='year'
                    checked={duration === 'year'}
                    onChange={() => setDuration('year')}
                    className='custom-radio'
                  />
                  <p>Yearly</p>
                </div>

                <div
                  className={`flex items-center gap-x-2 ${
                    duration === 'month' && 'text-primary font-semibold '
                  }`}
                >
                  <input
                    type='radio'
                    name='duration'
                    value='month'
                    checked={duration === 'month'}
                    onChange={() => setDuration('month')}
                    className='custom-radio'
                  />
                  <p>Monthly</p>
                </div>
              </form>
              {/*  */}

              {/* discount */}
              <div className='flex px-4 py-1 mt-2 text-sm font-semibold text-green500 bg-green100 rounded-full gap-x-2 w-[160px] text-center'>
                <img src={discount} alt='' />
                <p>Save up to 25%</p>
              </div>
              {/*  */}

              {/* bill overview */}
              <section className='w-10/12 mt-4'>
                <h3 className='text-lg font-bold text-gray600'>
                  Bill overview
                </h3>

                <section className='px-6 py-6 mt-4 font-semibold rounded-2xl text-gray600 bg-gray100'>
                  <section>
                    <div className='flex justify-between py-2'>
                      <p>No of users</p>
                      <p>x {seats}</p>
                    </div>

                    <div className='flex justify-between py-2'>
                      <p>Tax</p>
                      <p>$0</p>
                    </div>

                    <div className='flex justify-between py-2'>
                      <p>Subtotal</p>
                      <p>${billOverview?.price}</p>
                    </div>
                  </section>

                  <section className='text-center '>
                    <h4>Total</h4>
                    <h1 className='text-[40px] font-bold'>
                      ${billOverview?.price}
                    </h1>
                  </section>
                </section>

                {/* payment type  */}
                <section className='mt-4'>
                  {/* <h3 className="text-lg font-bold text-gray600">
                    Payment type
                  </h3> */}
                  <section className='flex mt-2 gap-x-4'>
                    <button
                      onClick={startPayment}
                      className='flex items-center justify-center w-full px-4 py-3 text-white bg-black rounded-lg gap-x-2'
                    >
                      {!paymentLoading ? (
                        <>
                          <img src={stripe} alt='' />
                          Pay with stripe
                        </>
                      ) : (
                        <ClipLoader size={12} color='white' />
                      )}
                    </button>

                    {/* <button className="flex items-center w-1/2 px-4 py-3 font-semibold text-black border border-black rounded-lg gap-x-2">
                      <img src={paypal} alt="" />
                      Pay with paypal
                    </button> */}
                  </section>
                </section>
                {/*  */}
              </section>
              {/*  */}
            </section>
          </section>
          {/* Modal  */}
          <>
            <Modal
              open={openStripePaymentModal}
              onCancel={closeTopupModal}
              footer={null}
            >
              <StripePayment
                clientKey={clientKey}
                details={details}
                closeModal={closeTopupModal}
              />
            </Modal>
          </>

          {/*  */}
        </section>
      )}
    </section>
  );
}
