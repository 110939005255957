import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//components
import Loader from 'components/UI/Loader';
import AdminNavbar from 'components/UI/AdminLayout/Navbar';
import Leads from '../Table/Leads';
import LeadDetails from '../Modal/LeadDetails';

// assets
import arrowRight from 'assets/svg/arrowRight.svg';

export default function Directory() {
  const dispatch = useDispatch();

  //usestates
  const [tab, setTab] = useState(0);
  const [loadingStatus] = useState(false);
  const [navProps, setNavProps] = useState({});

  const [leads] = useState([
    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat', 'LinkedIn'],
      role: 'Director',
      status: 'active',
    },
    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter'],
      role: 'Director',
      status: 'active',
    },

    {
      name: 'phoenix baker',
      company: 'Meta',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Skype'],
      role: 'owner',
      status: 'active',
    },
  ]);

  //useeffects
  useEffect(() => {
    setNavProps({
      title: 'Directory',
      breadcrumb: (
        <section className='flex gap-x-1 items-center'>
          <p className='lg:text-sm text-xs'>Directory</p>
          <img src={arrowRight} alt='arrow right icon' />
          <p className='lg:text-sm text-xs'>Leads</p>
          {tab === 1 && (
            <>
              <img src={arrowRight} alt='arrow right icon' />
              <p className='lg:text-sm text-xs'>Details</p>
            </>
          )}
        </section>
      ),
    });
  }, [dispatch]);

  return (
    <section className='relative'>
      <AdminNavbar navProps={navProps} />

      <section className='mt-20'>
        {/* loader */}
        {loadingStatus && <Loader />}
        {/*  */}

        {!loadingStatus && (
          <section>
            <section>{tab === 0 && <Leads leads={leads} />}</section>
            <section>{tab === 1 && <LeadDetails />}</section>
          </section>
        )}
      </section>
    </section>
  );
}
