import React, {useState} from "react";
import redIcon from "assets/svg/redCancel.svg";
import greenMark from "assets/svg/greenMark.svg";
import cancelGrey from "assets/svg/cancelGrey.svg";
import removeIcon from "assets/svg/newRemoveIcon.svg";
import {UserInterface} from "../../../../interfaces/interfaces-data";
import {HubConnection} from "@microsoft/signalr";
import fileEmpty from "../../../../assets/svg/emptyTemplate.svg";
import {AllowGuestIn} from "../../../../hooks/SignalR/Invokers/Presenter/AllowGuestIn";
import {RemoveGuest} from "../../../../hooks/SignalR/Invokers/Presenter/RemoveGuest";


export const DesktopViewers = ({users, requests, connection, presentationId}: {
    users: UserInterface[],
    requests: UserInterface[],
    connection?: HubConnection | null,
    presentationId?: string
}) => {

    const GrantAllAccess = () => {
        console.log(presentationId, "presentationId")
        connection?.invoke("GrantAccessToAll", presentationId, true).catch((err) => {
            console.log("Error Occured")
        })
    }

    const DeclineAll = () => {
        connection?.invoke("GrantAccessToAll", presentationId, false).catch((err) => {
            console.log("Error Occured")
        })
    }


    return (
        <div>
            {users.length > 0 && <div className="flex gap-2 items-center pb-4 border-b">
                <p className="text-[#101828] text-lg font-semibold">Viewers</p>
                <span className="bg-[#EEEEEE] text-[#545454] text-xs p-2 px-3 font-semibold rounded-lg">
                    {users.length}
                </span>
            </div>}
            <div>
                <div>
                    {requests.length ?
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-[#667084] text-sm font-semibold">Waiting Room</p>

                            </div>
                            <div className="flex justify-between items-center my-3">
                                <div className="flex items-center gap-2">
                                    <button onClick={() => GrantAllAccess()}
                                            className="flex items-center gap-1 p-1 px-3 rounded-full bg-[#DAF0E3]">
                                        <span>
                                            <img src={greenMark} alt=""/>
                                        </span>
                                        <p className="text-[#2B6B46] text-xs font-semibold">
                                            Allow all
                                        </p>
                                    </button>
                                    <button onClick={() => DeclineAll()}
                                            className="flex items-center gap-1 p-1 px-3 rounded-full bg-[#FADBD7]">
                                        <span>
                                            <img src={redIcon} alt=""/>
                                        </span>
                                        <p className="text-[#AE372A] text-xs font-semibold">
                                            Decline all
                                        </p>
                                    </button>
                                </div>

                            </div>
                        </div>
                        : ''}
                    {requests.length > 0 && requests.map(item => {
                        return <WaitingRoomWrapper presentationId={presentationId} connection={connection} user={item}
                                                   key={item.email}/>
                    })
                    }

                </div>
                {users.length ? <div className="mt-4">
                    <div className="flex justify-between items-center my-4">
                        <p className="text-[#667084] text-sm font-semibold">
                            IN THE MEETING
                        </p>
                    </div>

                    {users.map(item => {
                        return <InTheMeetingWrapper
                            key={item.email}
                            user={item}
                            connection={connection}
                            presentationId={presentationId}/>
                    })}
                </div> : <section className="flex flex-col items-center justify-center h-[70vh] text-sm ">
                    <img src={fileEmpty} alt=""/>
                    <div className="text-center">
                        <p className="font-semibold">
                            There are no active viewers in this presentation{" "}
                        </p>
                    </div>
                </section>}
            </div>
        </div>
    );
};

const WaitingRoomWrapper = ({user, connection, presentationId}: {
    user: UserInterface,
    connection?: HubConnection | null,
    presentationId?: string
}) => {
    const [actionLoading, setActionLoading] = useState(false);
    const handleUserRequest = (type: 'deny' | "allow") => {
        const status = type == "deny" ? false : true;
        console.log("I Got hereee")
        console.log(connection?.state, "connection state here")
        console.log(connection, presentationId, user.email, type)
        AllowGuestIn(
            connection,
            presentationId,
            user.email,
            setActionLoading,
            status
        )
    }


    return (
        <div className="flex  justify-between py-3 border-b">
            <div>
                <p className="text-[#111827] text-sm font-semibold">{user.name}</p>
                <p className="text-[#667084]  font-semibold text-xs">
                    {user.email}
                </p>
            </div>
            <div className="flex items-center gap-4">
                <button className="flex gap-1 items-center" onClick={() => {
                    handleUserRequest("allow")
                }}>
                    <img src={greenMark} alt=""/>
                    <p className="text-[#2B6B46] font-semibold text-xs">Allow</p>
                </button>
                <button onClick={() => handleUserRequest("deny")} className="flex gap-1 items-center">
                    <img src={cancelGrey} alt=""/>
                    <p className="text-[#757575] font-semibold text-xs">Decline</p>
                </button>
            </div>
        </div>
    );
};

const InTheMeetingWrapper = ({user, connection, presentationId}: {
    user: UserInterface,
    connection?: HubConnection | null,
    presentationId?: string
}) => {
    const removeViewer = () => {
        RemoveGuest(connection, presentationId, user.email, true)
    }
    return (
        <div className="flex justify-between py-3 border-b">
            <div>
                <p className="text-[#111827] text-sm font-semibold">{user.name}</p>
                <p className="text-[#667084]  font-semibold text-xs">
                    {user.email}
                </p>
            </div>
            <div className="flex items-center gap-4">
                <button onClick={removeViewer} className="flex gap-1 items-center">
                    <img src={removeIcon} alt=""/>
                    <p className="text-[#757575] font-semibold text-xs">Remove</p>
                </button>
            </div>
        </div>
    );
};
