export const resetPresentationServices = () => {
  localStorage.removeItem("startTime");
  localStorage.removeItem("isActive");

  localStorage.removeItem("polls");
  localStorage.removeItem("activePoll");
  localStorage.removeItem("pollStatus");
  localStorage.removeItem("pollStarted");
  localStorage.removeItem("viewerCount");
  localStorage.removeItem("finishedPolls");
  localStorage.removeItem("questionLength");
  localStorage.removeItem("wordCloudAnswer");
};

export const startPollService = () => {
  localStorage.setItem("pollStatus", JSON.stringify(true));

  window.dispatchEvent(new CustomEvent("pollStatus"));
};

export const endPollService = () => {
  localStorage.setItem("pollStatus", JSON.stringify(false));
  window.dispatchEvent(new CustomEvent("pollStatus"));
};

export const getPollStatus = () => {
  const status = localStorage.getItem("pollStatus");
  return status ? JSON.parse(status) : false;
};
export const getViewerCount = () => {
  const count = localStorage.getItem("viewerCount");
  return count ? JSON.parse(count) : 0;
};

export const storeViewerCount = (users) => {
  const count = users.filter((user) => {
    return user.status === 1;
  })?.length;

  localStorage.setItem("viewerCount", JSON.stringify(count));
};

export const getPolls = () => {
  const polls = localStorage.getItem("polls");

  return polls ? JSON.parse(polls) : [];
};

export const pollDetailService = (pollId) => {
  const polls = getPolls();

  const poll = polls?.find((poll) => {
    return poll?.rowKey === pollId;
  });

  return poll;
};

export const clearPolls = () => {
  localStorage.removeItem("polls");
};

export const storePolls = (newPoll) => {
  if (!newPoll) return;
  const polls = getPolls();
  const doesExist = polls?.find((poll) => poll?.rowKey === newPoll?.rowKey);
  if (doesExist) return;
  polls.push(newPoll);
  localStorage.setItem("polls", JSON.stringify(polls));
};

export const extractEachDuration = (pollId) => {
  const polls = getPolls();
  const durations = polls
    ?.find((poll) => {
      return poll?.rowKey === pollId;
    })
    ?.questions?.map((question) => {
      return question?.duration;
    });

  return durations;
};

export const isWordCloudCheck = (type) => {
  return type?.toLowerCase() === "word cloud";
};

export const isMultiAnswer = (type) => {
  return type?.toLowerCase() === "multi answer";
};

///HANDLING ANSWERS
export const getPresentationActivePoll = () => {
  const activePoll = localStorage.getItem("activePoll");

  return activePoll ? JSON.parse(activePoll) : null;
};

export const isQuestionInActivePoll = (questionId) => {
  const activePoll = getPresentationActivePoll()?.questions;
  if (!activePoll) return;
  const doesExist = activePoll?.find(
    (question) => question.questionId === questionId
  );
  return doesExist;
};

export const setPresentationActivePoll = (pollId) => {
  const pollDetails = pollDetailService(pollId);
  const questions = pollDetails?.questions?.map((question) => {
    return {
      questionId: question?.id,
      answers: [],
    };
  });
  const duration = extractEachDuration(pollId)?.reduce((acc, cur) => {
    return acc + cur;
  }, 0);

  const pollObj = { pollId, duration, questions };
  localStorage.setItem("activePoll", JSON.stringify(pollObj));
};

export const resetPresentationActivePoll = () => {
  localStorage.setItem("activePoll", null);
};

export const getFinishedPolls = () => {
  const finishedPolls = localStorage.getItem("finishedPolls");
  return finishedPolls ? JSON.parse(finishedPolls) : [];
};

export const fetchQuestionFromFinishedPolls = (questionId, pollId) => {
  const polls = getFinishedPolls()?.find((poll) => poll?.pollId === pollId);
  const answer = polls?.questions?.find(
    (question) => question?.questionId === questionId
  );

  //
  return answer;
};

//save poll in finishedPoll
export const moveToFinishedPoll = (pollId) => {
  const activePoll = getPresentationActivePoll();
  const finishedPolls = getFinishedPolls();
  if (activePoll) {
    const isExist = finishedPolls?.findIndex((poll) => {
      return poll?.pollId === pollId;
    });
    console.log(isExist, "finis");
    if (isExist === -1) {
      finishedPolls.push(activePoll);
    } else {
      finishedPolls[isExist] = activePoll;
    }

    localStorage.setItem("finishedPolls", JSON.stringify(finishedPolls));
  } else {
    localStorage.setItem("finishedPolls", JSON.stringify([]));
  }
};

export const getPollAnswer = () => {
  const answer = localStorage.getItem("pollAnswer");

  return answer ? JSON.parse(answer) : null;
};

export const addAnswer = (pollId, answer) => {
  let answers = getPollAnswer()?.answers;

  if (!answers) {
    answers = [];
  } else {
    answers.push(answer);
  }
  const pollAnswer = {
    pollId,
    answers,
  };
  localStorage.setItem("pollAnswer", JSON.stringify(pollAnswer));
};

//option based polls
export const storeOptionBasedAnswer = (answer, questionId) => {
  const activePoll = getPresentationActivePoll();

  if (!activePoll) return;

  //update active poll
  const getQuestionObjIndex = activePoll?.questions?.findIndex((question) => {
    return question?.questionId === questionId;
  });

  activePoll.questions[getQuestionObjIndex].answers.push(answer);
  localStorage.setItem("activePoll", JSON.stringify(activePoll));
};

// word cloud
export const getWordCloudAnswers = (questionId, pollId) => {
  // const finishedPoll = fetchQuestionFromFinishedPolls(
  //   questionId,
  //   pollId
  // )?.answers;

  // if (finishedPoll) {
  //   return finishedPoll;
  // }
  const answers = localStorage.getItem("wordCloudAnswer");
  return answers ? JSON.parse(answers) : [];
};

export const storeWorldCloudAnswer = (answer, questionId) => {
  const activePoll = getPresentationActivePoll();

  if (!activePoll) return;

  const answers = getWordCloudAnswers();

  let wordList;
  const doesExist = answers?.findIndex((word) => {
    return word?.text?.toLowerCase() === answer?.toLowerCase();
  });

  if (doesExist === -1) {
    const newWord = {
      text: answer?.toUpperCase(),
      value: 1,
    };
    wordList = [...answers, newWord];
  } else {
    wordList = answers;
    wordList[doesExist].value = wordList[doesExist]?.value + 1;
  }

  window.dispatchEvent(new CustomEvent("wordCloudUpdated", { wordList }));
  localStorage.setItem("wordCloudAnswer", JSON.stringify(wordList));

  //update active poll
  const getQuestionObjIndex = activePoll?.questions?.findIndex((question) => {
    return question?.questionId === questionId;
  });
  activePoll.questions[getQuestionObjIndex].answers = wordList;
  localStorage.setItem("activePoll", JSON.stringify(activePoll));

  return wordList;
};

// option based answers
export const getOptionAnswer = () => {
  const answers = localStorage.getItem("optionAnswer");
  return answers ? JSON.parse(answers) : [];
};

export const storeOptionAnswer = (answer) => {
  const answers = getOptionAnswer();

  let finalAnswer;
  const doesExist = answers?.findIndex((word) => {
    return word?.text?.toLowerCase() === answer?.toLowerCase();
  });

  if (doesExist === -1) {
    const newWord = {
      text: answer?.toUpperCase(),
      value: 1,
    };
    finalAnswer = [...answers, newWord];
  } else {
    finalAnswer = answers;
    finalAnswer[doesExist].value = finalAnswer[doesExist]?.value + 1;
  }

  window.dispatchEvent(new CustomEvent("optionAnswerUpdated", { finalAnswer }));
  localStorage.setItem("optionAnswer", JSON.stringify(finalAnswer));
  return finalAnswer;
};

///////////////
