import { toast } from "react-toastify";
import { getConnectionId } from "utils/localStorageServices";

export const UnvoteQuestion = (
  connection,
  presentationId,
  questionId,
) => {
  if (connection) {
    connection
      .invoke("UnVoteQuestion", presentationId, questionId, getConnectionId())
      .catch((err) => {
        console.log(err);
        toast.error(
          "An unexpected error occurred invoking 'UnVoteQuestion' on the server"
        );
      });
  }
};
