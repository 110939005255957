import React, { useState } from "react";

//libraries
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { useSelector } from "react-redux";
import { getPaymentState } from "store/Payment/paymentSlice";
import Loader from "components/UI/Loader";
import { formatNumber } from "utils/formatter";
import { formatDate } from "utils/formatDate";

export default function PointHistoryTable() {
  const { pointHistory } = useSelector(getPaymentState);

  const columns = [
    {
      header: "Date",
      field: "date",

      sortable: true,
      body: (rowData) => {
        return <p>{formatDate(rowData?.date, "lll")}</p>;
      },
    },
    {
      header: "Amount",
      field: "amount",
      sortable: true,
      body: (rowData) => {
        return <p>{formatNumber(rowData?.amount)}</p>;
      },
    },
    {
      header: "Point value",
      field: "pointValue",
      sortable: true,
      body: (rowData) => {
        return <p>{formatNumber(rowData?.pointValue)}</p>;
      },
    },
    {
      header: "Point balance",
      field: "pointBalance",
      sortable: true,
      body: (rowData) => {
        return <p>{formatNumber(rowData?.pointBalance)}</p>;
      },
    },

    {
      header: "Status",
      field: "transactionStatus",
      sortable: true,
      body: (rowData) => {
        const status = rowData?.transactionStatus?.toLowerCase();
        return (
          <>
            <p
              className={`capitalize text-sm font-semibold ${
                status === "credit"
                  ? "text-green-400"
                  : status === "pending"
                  ? "text-yellow-400"
                  : "text-red-400"
              }`}
            >
              {" "}
              {status}
            </p>
          </>
        );
      },
    },
  ];

  return (
    <>
      {pointHistory.isLoading && <Loader />}

      {!pointHistory.isLoading && (
        <section>
          <DataTable
            rows={8}
            paginator
            value={pointHistory.data}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "35rem", maxHeight: "300px" }}
            className="px-1 overflow-y-scroll text-xs border rounded-xl"
          >
            {columns.map((col, index) => {
              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  sortable={col?.sortable}
                />
              );
            })}
          </DataTable>
        </section>
      )}
    </>
  );
}
