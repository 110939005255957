import React, { useState } from 'react';

//assets
import addPlus from 'assets/svg/add-square.svg';
import sectionIcon from 'assets/svg/section-icon3.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { createFolder, getFolders } from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';

interface Props {
  closeModal: () => void;
  spaceId: string;
  parentId: string | null;
}

export default function AddSection(props: Props) {
  const { spaceId, parentId, closeModal } = props ?? {};

  const dispatch = useDispatch<AppDispatch>();

  //usestate
  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateFolder = () => {
    setIsLoading(true);

    dispatch(createFolder({ spaceId, title, parentId })).then((response) => {
      const { type } = response;
      if (type.includes('fulfilled')) {
        toast.success(`${title} added!`);
        dispatch(getFolders(spaceId));
      } else {
        toast.error('Something went wrong');
      }
      setIsLoading(false);
      closeModal();
      setTitle('');
    });
  };

  return (
    <section className='py-3'>
      <div className='mt-[-20px]'>
        <img src={sectionIcon} alt='' />
      </div>

      <section>
        <div className='mt-4'>
          <h1 className='text-2xl font-semibold'>Sections</h1>
          <p>
            Create a section in this briefcase, you can drag and drop items to a
            section
          </p>
        </div>

        <section>
          <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'></section>

          {/* form  */}
          <section className='mt-4'>
            <div className='flex flex-col gap-y-1'>
              <label
                htmlFor='space'
                className='font-normal text-gray700 text-base'
              >
                Section name
              </label>
              <input
                type='text'
                placeholder='Enter section name'
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              />
            </div>

            <section className='flex my-4 mt-6 gap-x-4'>
              <button
                disabled={isLoading}
                onClick={handleCreateFolder}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading ? 'opacity-40 cursor-not-allowed' : 'opacity-100'
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt='' />
                    Create section
                  </>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
