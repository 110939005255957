import { toast } from "react-toastify";
// import { GetQuestions } from "./GetQuestions";
// import store from "store/store";
// import { clearTranslatedAudio } from "store/Slides/slidesSlice";

export const AnswerQuestion = (
  connection,
  presentationId,
  questionId,
  answer,
 
) => {
  const state = connection?.state;
  if (state === "Connected") {
    // console.log(presentationId, questionId, answer, "Answer question");
    connection
      .invoke("AnswerQuestion", presentationId, questionId, answer).catch((err) => {
        toast.error(
          "An error occurred invoking 'AnswerQuestion' on the server"
        );
      });
  }
};
