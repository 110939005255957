import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//libraries
import { Dropdown, Menu } from "antd";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets
import move from "assets/svg/move.svg";
import share from "assets/svg/link.svg";
import trash from "assets/svg/delete.svg";
import rename from "assets/svg/rename.svg";
import access from "assets/svg/access.svg";
import arrowDown from "assets/svg/arrowDown.svg";
import folderImg from "assets/svg/folderImg.svg";
import presentNow from "assets/svg/presentNow.svg";

//slices
import {
  archiveFolder,
  deleteFolder,
  getAllWorkspace,
  getWorkspaceFolders,
} from "store/Workspace/workspaceSlice";

//component
import RenameFolderTemplate from "../RenameFolderTemplate";
import CreateNewFolderTemplate from "../CreateNewFolderTemplate";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import { getFileStructureState } from "store/FileStructure/fileStructureSlice";

export default function WorkspaceFolderTitlebar({
  openFolder,
  activeFolder,
  openDropdown,
  workspaceFolder,
}) {
  const dispatch = useDispatch();
  const { id, title } = workspaceFolder;
  const { mostActiveFolder } = useSelector(getFileStructureState);

  //usestate
  const [isHovered, setIsHovered] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [setIsFileUploadModalOpen] = useState(false);

  //functions
  //utils
  const showNewFolderTemplate = (e, type) => {
    e.stopPropagation();
    type ? setNewFolder(true) : setNewFolder(false);
  };

  //requests
  const deleteWorkspaceFolder = () => {
    setIsDeleteLoading(true);
    dispatch(deleteFolder(id)).then(({ type }) => {
      setIsDeleteLoading(false);

      if (type.includes("fulfilled")) {
        closeConfirmDelete();
        toast.success("Folder deleted successfully");
        window.location.reload()

        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;
            dispatch(getWorkspaceFolders(id));
          }
        });
      }
    });
  };

  const archiveWorkspaceFolder = () => {
    setIsArchiveLoading(true);
    dispatch(archiveFolder(id)).then(({ type }) => {
      setIsArchiveLoading(false);
      if (type.includes("fulfilled")) {
        closeConfirmDelete();
        toast.success("Folder archived successfully");
        window.location.reload()
        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;
            dispatch(getWorkspaceFolders(id));
          }
        });
      }
    });
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const closeConfirmArchive = () => {
    setConfirmArchive(false);
  };

  // variables
  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <div className="flex py-1 gap-x-2">
    //       <img src={presentNow} alt="create new folder" className="w-4" />
    //       <p className="text-sm">Present now</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: "2",
    //   label: (
    //     <div
    //       className="flex py-1 gap-x-2"
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={move} alt="create new folder" className="w-4" />
    //       <p className="text-sm">Move file </p>
    //     </div>
    //   ),
    // },

    {
      key: "3",
      label: (
        <div className="flex py-1 gap-x-2" onClick={() => setIsRenaming(true)}>
          <img src={rename} alt="create new folder" className="w-4" />
          <p className="text-sm">Rename</p>
        </div>
      ),
      OnClick: () => setIsRenaming(true),
    },

    // {
    //   key: "4",
    //   label: (
    //     <div
    //       className="flex py-1 gap-x-2"
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={access} alt="create new folder" className="w-4" />
    //       <p className="text-sm">Access control</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: "5",
    //   label: (
    //     <div
    //       className="flex py-1 gap-x-2"
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={share} alt="create new folder" className="w-4" />
    //       <p className="text-sm">Share</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: "6",
    //   label: (
    //     <div
    //       className="flex items-center py-1 gap-x-2"
    //       onClick={() => setConfirmArchive(true)}
    //     >
    //       <i className="pi pi-folder-open"></i>
    //       <p className="text-sm">Archive</p>
    //     </div>
    //   ),
    // },

    {
      key: "7",
      label: (
        <div
          className="flex py-1 gap-x-2"
          onClick={() => setConfirmDelete(true)}
        >
          <img src={trash} alt="create new folder" className="w-4" />
          <p className="text-sm">Delete</p>
        </div>
      ),
    },
  ];

  return (
    <section>
      {/* main folder title bar */}
      <div
        onClick={() => openFolder(workspaceFolder)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`flex items-center p-2 text-sm  rounded-md cursor-pointer justify-between font-grotesk [ hover:bg-gray200 hover:rounded ] ${
          (activeFolder === id &&
            mostActiveFolder?.id === id &&
            openDropdown) ||
          mostActiveFolder?.id === id
            ? "border bg-gray100 "
            : "bg-white"
        }`}
      >
        {/* folder name */}
        <div className="flex items-center gap-x-2">
          <img src={folderImg} alt="document" className="w-5" />
          {!isRenaming ? (
            <p className="font-semibold">{title}</p>
          ) : (
            <RenameFolderTemplate folder={workspaceFolder} />
          )}
        </div>
        {/*  */}

        {/* options */}
        <section className="flex gap-x-2">
          {isHovered && (
            <div className="flex items-center gap-x-2 ">
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  dropdownRender={(menu) => (
                    <Menu>
                      {items.map((item) => (
                        <Menu.Item key={item.key}>{item.label}</Menu.Item>
                      ))}
                    </Menu>
                  )}
                  trigger={["click"]}
                >
                  <div className="">
                    <i
                      className="cursor-pointer pi pi-ellipsis-h"
                      style={{ fontSize: "0.8rem" }}
                    ></i>
                  </div>
                </Dropdown>
              </div>

              <div onClick={(e) => showNewFolderTemplate(e, true)}>
                <i
                  className="cursor-pointer pi pi-plus"
                  style={{ fontSize: "0.8rem" }}
                ></i>
              </div>
            </div>
          )}

          <div className="flex items-center gap-x-2">
            {isLoading && (
              <div className="flex justify-center my-2">
                <ClipLoader color="gray" size={12} />
              </div>
            )}
            {isRenaming && (
              <i
                className="text-xs cursor-pointer pi pi-times"
                onClick={(e) => setIsRenaming(false)}
              ></i>
            )}

            <img
              src={arrowDown}
              alt="dropdown"
              className={`${openDropdown ? "rotate-180" : "rotate-0"}`}
            />
          </div>
        </section>
        {/*  */}
      </div>

      {/* create new folder */}
      {newFolder && (
        <CreateNewFolderTemplate setNewFolder={setNewFolder} folderId={id} />
      )}
      {/*  */}

      {/* Modal */}
      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={deleteWorkspaceFolder}
        text="Are you sure you want to delete this folder?"
      />

      <ConfirmationModal
        open={confirmArchive}
        isLoading={isArchiveLoading}
        onClose={closeConfirmArchive}
        func={archiveWorkspaceFolder}
        text="Are you sure you want to archive this folder?"
      />

      {/*  */}
    </section>
  );
}
