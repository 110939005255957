import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

//libraries
import moment from 'moment';
import { Column } from 'primereact/column';
import { ClipLoader } from 'react-spinners';
import { DataTable } from 'primereact/datatable';

//slices
import { getPaymentState } from 'store/Payment/paymentSlice';

//component
import Loader from 'components/UI/Loader';
import { formatDate } from 'utils/formatDate';

export default function InvoiceTable() {
  const { planHistory } = useSelector(getPaymentState);

  //usestate
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  // useEffect
  useEffect(() => {
    console.log(isDownloadLoading);
  }, [isDownloadLoading]);

  const handleDownload = (url) => {
    setIsDownloadLoading(true);
    // Create an anchor element and click it to start the download
    const link = document.createElement('a');
    link.href = url;
    link.download = true;
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    //Since we are using the anchor tag to dowload, everything happens fast and the loading state is not visible
    // Simulate a delay before resetting the loading state
    setTimeout(() => {
      setIsDownloadLoading(false);
    }, 3000); // 3-second delay
  };

  const columns = [
    {
      header: 'Invoice',
      field: 'invoiceId',
      sortable: true,
    },

    {
      header: 'Date',
      field: 'paymentDate',

      sortable: true,
      body: (rowData) => {
        return <p>{formatDate(rowData?.paymentDate, 'lll')}</p>;
      },
    },
    {
      header: 'Status',
      field: 'status',
      sortable: true,
      body: (rowData) => {
        return (
          <>
            {rowData?.status === 'paid' && (
              <div className='text-[#067647] bg-[#ECFDF3] flex gap-x-1  font-semibold text-xs w-[75px] justify-center border border-green-500 text-center py-1 rounded-full'>
                <i className='pi pi-check'></i>

                <span>Paid</span>
              </div>
            )}

            {rowData?.status === 'open' && (
              <div className='text-yellow-500 bg-yellow-100 flex gap-x-1  font-semibold text-xs w-[75px] justify-center border border-yellow-500 text-center py-1 rounded-full'>
                <i className='pi pi-clock'></i>

                <span>Open</span>
              </div>
            )}
          </>
        );
      },
    },

    {
      header: 'Plan',
      field: 'purchase',
      sortable: true,
      body: (rowData) => {
        return (
          <p className='font-semibold'>
            {rowData?.plan}{' '}
            <small className='font-semibold'>({rowData?.planType})</small>
          </p>
        );
      },
    },

    {
      header: '',
      body: (rowData) => {
        return (
          // <a
          //   href={rowData?.invoiceUrl}
          //   // target="_blank"
          //   rel="noopener noreferrer"
          //   download
          // >
          <button
            onClick={() => handleDownload(rowData?.invoiceUrl)}
            className='font-semibold text-primary'
          >
            {isDownloadLoading ? (
              <ClipLoader color='orange' size={12} />
            ) : (
              'Download'
            )}
          </button>
          // </a>
        );
      },
    },
  ];

  return (
    <>
      {planHistory.isLoading && <Loader />}

      {!planHistory.isLoading && (
        <section>
          <DataTable
            rows={8}
            paginator
            value={planHistory?.data}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
            className='px-1 overflow-y-scroll text-xs border rounded-xl'
          >
            {columns.map((col, index) => {
              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  sortable={col?.sortable}
                />
              );
            })}
          </DataTable>
        </section>
      )}
    </>
  );
}
