import moment from "moment-timezone";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!date) return;
  return moment.utc(date).tz(userTimeZone).format(format);
};

export const DateFromNow = (date) => {
  return moment.utc(date).tz(userTimeZone).fromNow();
};
