import React, { useState } from "react";
import { useDispatch } from "react-redux";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets
import folderImg from "assets/svg/folderImg.svg";

//slices
import {
  createFolder,
  getAllWorkspace,
  getWorkspaceFolders,
} from "store/Workspace/workspaceSlice";

export default function CreateNewFolderTemplate({ folderId, setNewFolder }) {
  const dispatch = useDispatch();

  //usestate
  const [isLoading, setIsLoading] = useState(false);

  //function
  const showNewFolderTemplate = (e, type) => {
    e.stopPropagation();
    type ? setNewFolder(true) : setNewFolder(false);
  };

  const createNewFolder = (e) => {
    if (e.key === "Enter") {
      // setNewFolder(false);
      setIsLoading(true);
      const title = e.target.value;

      if (!title) {
        toast.error("Enter folder's name");
        return;
      }

      if (!folderId) {
        toast.error("Folder's ID not present");
        return;
      }

      const data = {
        title,
        folderId,
      };

      console.log(data, "here");

      //monitor upload progress
      const config = {};

      //dispatch function
      dispatch(createFolder({ data, config })).then(({ payload }) => {
        setIsLoading(false);

        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;

            dispatch(getWorkspaceFolders(id));
          }
        });
      });
    }
  };

  return (
    <section className="flex items-center w-[90%] justify-between my-1 ">
      <div className="flex ml-4 gap-x-2">
        <div className="flex items-center gap-x-2">
          <img src={folderImg} alt="document" className="w-5" />
        </div>

        <div>
          <input
            type="text"
            placeholder="Folder name"
            className="outline-none text-xs [ placeholder:text-xs ] "
            onKeyDown={createNewFolder}
          />
        </div>
      </div>

      <div>
        {isLoading ? (
          <ClipLoader size={12} color="gray" />
        ) : (
          <i
            className="text-xs cursor-pointer pi pi-times"
            onClick={(e) => showNewFolderTemplate(e, false)}
          ></i>
        )}
      </div>
    </section>
  );
}
