import { toast } from "sonner";

export const RemoveGuest = (connection, presentationId, email, status) => {
  if (connection) {

    connection
      .invoke("RemoveUser", presentationId, email, status)
      .catch((err) => {
        toast.error(err);
        console.log(err); 
      });
  }
};
