import { useEffect } from "react";

export const useGenericListener = (connection, eventHandler) => {
  useEffect(() => {
    const handleEventResponse = (presentationId, eventName, object) => {
      // console.log(presentationId, eventName, object, "id");
      eventHandler(presentationId, eventName, object);
    };

    if (connection) {
      connection.on("Generic", handleEventResponse);
    }
    return () => {
      if (connection) {
        connection.off("Generic", handleEventResponse);
      }
    };
  }, [connection, eventHandler]);
};
