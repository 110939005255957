import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//assets
import team from "assets/svg/team.svg";
import timer from "assets/svg/timer.svg";
import person from "assets/svg/profile-add.svg";
import download from "assets/svg/document-download.svg";

//libraries
import { Chart } from "primereact/chart";

//slices
import {
  getAttentionGraphData,
  getDownloadGraphData,
  getPresentationMetrics,
  getPresentationState,
} from "store/Presentations/presentationSlice";

//components
import Loader from "components/UI/Loader";

//utils
import { formatNumber, trimText } from "utils/formatter";

export default function PerformanceMetric() {
  const dispatch = useDispatch();

  const { presentationId } = useParams();

  const { presentationMetrics, downloadGraphData, attentionGraphData } =
    useSelector(getPresentationState);
  const { invited, attendance, presentationDuration, downloads } =
    presentationMetrics.data ?? {};

  //usestate
  const [tab, setTab] = useState(1);
  const [mixedChartData, setMixedChartData] = useState({});
  const [mixedChartOptions, setMixedChartOptions] = useState({});
  const [doughnutchartData, setDoughnutChartData] = useState({});
  const [doughnutChartOptions, setDoughnutChartOptions] = useState({});

  // State to track the current view (original graph or slide details)
  const [currentView, setCurrentView] = useState("original");
  const [selectedSlideData, setSelectedSlideData] = useState(null);

  //useeffects
  useEffect(() => {
    dispatch(getPresentationMetrics(presentationId));
    dispatch(getDownloadGraphData(presentationId));
    dispatch(getAttentionGraphData(presentationId));
  }, [presentationId, dispatch]);

  useEffect(() => {
    setCurrentView("original");
  }, [tab]);

  useEffect(() => {
    const data = {
      datasets: [
        {
          data: [30, 100],
          backgroundColor: ["#ffffff", "#FF6929"],
          // hoverBackgroundColor: ["#ffffff80", "#FF692980"],
        },
      ],
    };
    const options = {
      cutout: "80%",
      animation: false,
    };

    setDoughnutChartData(data);
    setDoughnutChartOptions(options);
  }, []);

  useEffect(() => {
    if (downloadGraphData.isLoading || attentionGraphData.isLoading) return;
    const downloadData = downloadGraphData.data;
    const attentionData = attentionGraphData.data;
    const labelArray = downloadData ?? attentionData;

    const getLabels = labelArray?.map((label) => trimText(label.title, 20));
    const addUpAllDownloads = (data) => {
      return data.reduce((sum, download) => sum + download.value, 0);
    };
    const getDownloadData = downloadData?.map((download) =>
      addUpAllDownloads(download.data)
    );
    const getAttentionGraphData = attentionData?.map((attention) =>
      attention?.score?.toFixed(1)
    );

    const finalGraphData = tab === 0 ? getDownloadData : getAttentionGraphData;

    const data = {
      labels: getLabels,
      datasets: [
        {
          type: "bar",
          label: tab === 0 ? "Total slides downloaded" : "Attention",
          backgroundColor: tab === 0 ? "#73C496" : "#FFBA9D",

          borderWidth: 1,
          data: finalGraphData,
          borderRadius: {
            topLeft: 2,
            topRight: 2,
          },
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(0,0,0,0.1)",
          },
        },
        y: {
          ticks: {
            precision: 0,
          },
          grid: {
            display: true,
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
      barThickness: 35,
      onClick: (_, activeElements) => {
        if (activeElements.length > 0) {
          const chartElement = activeElements[0];
          const labelIndex = chartElement.index;

          if (tab === 0) {
            const selectedBar = downloadData?.[labelIndex];
            if (selectedBar?.data?.length > 0) {
              setCurrentView("slideDetails");
              setSelectedSlideData(selectedBar);
            }
          } else {
            const selectedBar = attentionGraphData.data?.[labelIndex];

            if (selectedBar?.data?.length > 0) {
              setCurrentView("slideDetails");
              setSelectedSlideData(selectedBar);
            }
          }
        }
      },
      onHover: (event, chartElement) => {
        if (chartElement.length > 0) {
          const index = chartElement[0].index;
          const hasData = downloadData[index]?.data?.length > 0;
          event.native.target.style.cursor = hasData
            ? "pointer"
            : "not-allowed";
        } else {
          event.native.target.style.cursor = "default";
        }
      },
    };

    setMixedChartData(data);
    setMixedChartOptions(options);
  }, [downloadGraphData, tab, attentionGraphData]);

  // const handleLabelClick = (labelIndex) => {
  //   // The logic will be the same as when you click the bar
  //   if (tab === 0) {
  //     const selectedBar = downloadData?.[labelIndex];
  //     if (selectedBar?.data?.length > 0) {
  //       setCurrentView("slideDetails");
  //       setSelectedSlideData(selectedBar);
  //     }
  //   } else {
  //     const selectedBar = attentionGraphData.data?.[labelIndex];
  //     if (selectedBar?.data?.length > 0) {
  //       setCurrentView("slideDetails");
  //       setSelectedSlideData(selectedBar);
  //     }
  //   }
  // };

  return (
    <section>
      {presentationMetrics?.isLoading && <Loader />}

      {!presentationMetrics?.isLoading && (
        <section className="py-4">
          {/* cards  */}
          <section className="flex items-center gap-x-4">
            {/* 1 */}
            <section className=" pt-3 pb-6 pl-4 border rounded-lg w-[25%]">
              <div>
                <img src={person} alt="" />
              </div>

              <p className="my-2 font-medium text-gray500">Total invited</p>

              <h2 className="text-[1.5rem] font-medium text-gray600">
                {invited}
              </h2>
            </section>
            {/*  */}

            {/* 2 */}
            <section className=" pt-3 pb-6 pl-4 border rounded-lg w-[25%]">
              <div>
                <img src={team} alt="" />
              </div>

              <p className="my-2 font-medium text-gray500">Total attendance</p>

              <h2 className="text-[1.5rem] font-medium text-gray600">
                {attendance}
              </h2>
            </section>
            {/*  */}

            {/* 3  */}
            <section className=" pt-3 pb-6 pl-4 border rounded-lg w-[25%]">
              <div>
                <img src={timer} alt="" />
              </div>

              <p className="my-2 font-medium text-gray500">
                Presentation Minute
              </p>

              <h2 className="text-[1.5rem] font-medium text-gray600">
                {formatNumber(presentationDuration)}
              </h2>
            </section>
            {/*  */}

            {/* 4  */}
            <section className=" pt-3 pb-6 pl-4 border rounded-lg w-[25%]">
              <div>
                <img src={download} alt="" />
              </div>

              <p className="my-2 font-medium text-gray500">
                Number of downloads
              </p>

              <h2 className="text-[1.5rem] font-medium text-gray600">
                {downloads}
              </h2>
            </section>
            {/*  */}

            {/* <section className=" pt-3 pb-6 px-2 border flex items-center rounded-lg w-[35%] bg-black gap-x-3 h-32 ">
              <section className="relative">
                {" "}
                <Chart
                  type="doughnut"
                  data={doughnutchartData}
                  options={doughnutChartOptions}
                  className=" z-10 max-w-[4rem]"
                />
                <p className="absolute top-[47%] right-[34%] z-50 text-[10px] font-semibold text-white">
                  80%
                </p>
              </section>
              <section className="py-1">
                <h5 className="font-semibold text-white">Attention metrics</h5>
                <p className="text-sm text-gray350">
                  Out of 50 attendees, 40 actively participated in the
                  interactive poll conducted{" "}
                </p>
              </section>
            </section> */}
          </section>
          {/*  */}

          {/* performance summary  */}

          <section className="my-4 ">
            <section className="py-3 border-b flex-between">
              <div className="">
                <h3 className="font-bold text-md text-gray600">
                  Performance summary
                </h3>
              </div>

              <section className="flex-items-center gap-x-2">
                <div
                  onClick={() => setTab(1)}
                  className={`px-4 py-1 rounded-lg cursor-pointer ${
                    tab === 1 && "bg-primary text-white "
                  }`}
                >
                  <button>Participation</button>
                </div>

                <div
                  onClick={() => setTab(0)}
                  className={`px-4 py-1 rounded-lg cursor-pointer ${
                    tab === 0 && "bg-primary text-white "
                  }`}
                >
                  <button>Downloads</button>
                </div>
              </section>
            </section>

            <section className="my-4">
              {/* <section className="flex w-full py-4">
                <section className="flex justify-end w-full font-semibold text-md gap-x-4 ">
                  <div className="flex items-center gap-x-2">
                    <div className="w-2 h-2 rounded-full bg-gray110"></div>
                    <p className="text-[#475467]  pt-[0.1rem]">Slide skips</p>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <div className="w-2 h-2 rounded-full bg-orange60"></div>
                    <p className="text-[#475467] pt-[0.1rem]">
                      Slide downloads
                    </p>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <div className="w-2 h-2 rounded-full bg-orange700"></div>
                    <p className="text-[#475467] pt-[0.1rem]">Attention</p>
                  </div>
                </section>
              </section> */}

              <section>
                {currentView === "original" && (
                  <Chart
                    type="bar"
                    height="400"
                    data={mixedChartData}
                    options={mixedChartOptions}
                  />
                )}
                <section> 
                  {/* Slide Details Graph */}
                  {currentView === "slideDetails" && (
                    <section>
                      <div
                        onClick={() => setCurrentView("original")}
                        className="flex items-center mb-4 cursor-pointer gap-x-2"
                      >
                        <i className="pi pi-arrow-circle-left"></i>
                        <h3 className="font-bold text-md text-gray600">
                          {selectedSlideData?.title}
                        </h3>
                      </div>

                      <section>
                        <Chart
                          type="bar"
                          height="400"
                          data={{
                            labels: selectedSlideData?.data?.map(
                              (slide) => slide.key
                            ),
                            datasets: [
                              {
                                type: "bar",
                                label: tab === 0 ? "Downloads" : "Attention",
                                backgroundColor:
                                  tab === 0 ? "#73C496" : "#FFBA9D",
                                data: selectedSlideData?.data?.map(
                                  (slide) => slide.value
                                ),
                                borderRadius: {
                                  topLeft: 2,
                                  topRight: 2,
                                },
                                stack: "stack1",
                              },
                            ],
                          }}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                              },
                              y: {
                                grid: {
                                  display: true,
                                },
                              },
                            },
                            maintainAspectRatio: false,
                            barThickness: 35,
                          }}
                        />
                      </section>
                    </section>
                  )}
                </section>
              </section>
            </section>
          </section>

          {/*  */}
        </section>
      )}
    </section>
  );
}
