import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  _getPresentation,
  _getPresentationDetails,
} from 'services/sharedPresentations/sharedPresentationsService';
import {
  InitialState,
  PresentationPayload,
  Document,
  DocCategory,
  SlidePayload,
  GetPresentationDetailsPayload,
} from 'interfaces/SharedPresentation';
import { _getSlides } from 'services/Briefcase/briefcaseServices';
import { getConnectionId } from 'utils/localStorageServices';

const initialState: InitialState = {
  presentation: {
    isLoading: false,
    data: null,
  },
  displayDocument: null,
  flattedDisplayDocuments: [],
  displayDocIndex: 0,
  slides: {
    isLoading: false,
    data: [],
    displayDocIndex: 0,
    displayDocument: null,
  },
};

export const getPresentation = createAsyncThunk(
  'sharedBriefcase/getPresentation',
  async (id: string) => {
    const response = await _getPresentation(id);
    return response.data;
  }
);

export const getPresentationDetails = createAsyncThunk(
  'sharedBriefcase/getPresentationDetails',
  async ({ presentationId, connectionId }: GetPresentationDetailsPayload) => {
    const response = await _getPresentationDetails(
      presentationId,
      connectionId
    );
    return response.data;
  }
);

export const getSlides = createAsyncThunk(
  'sharedBriefcase/getSlides',
  async (documentId: string) => {
    const response = await _getSlides(documentId);
    return response.data;
  }
);

export const sharedBriefcaseSlice = createSlice({
  name: 'sharedBriefcase',
  initialState,
  reducers: {
    setNextDisplayDoc(state) {
      if (state.displayDocIndex === state.flattedDisplayDocuments.length - 1) {
        state.displayDocIndex = 0;
      } else {
        state.displayDocIndex = state.displayDocIndex + 1;
      }
    },
    setPrevDisplayDoc(state) {
      // Function to handle Previous click
      if (state.displayDocIndex === 0) {
        state.displayDocIndex = state.flattedDisplayDocuments.length - 1;
      } else {
        state.displayDocIndex = state.displayDocIndex - 1;
      }
    },
    setDisplayDoc(state, action: PayloadAction<string>) {
      const doc = state.flattedDisplayDocuments.find(
        (doc, index) => doc.id === action.payload
      );
      if (doc) {
        state.displayDocument = doc;
        const docIndex = state.flattedDisplayDocuments.findIndex(
          (doc_) => doc.id === doc_.id
        );
        state.displayDocIndex = docIndex;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresentation.pending, (state) => {
        state.presentation.isLoading = true;
        state.presentation.data = null;
      })
      .addCase(
        getPresentation.fulfilled,
        (state, action: PayloadAction<PresentationPayload>) => {
          state.presentation.isLoading = false;

          if (action.payload.doc) {
            state.displayDocument = action.payload.doc[0].docs[0];
          }

          // moving groups without id to index 0
          const indexOfGroupWithNoId = action.payload.doc.findIndex(
            (item) => item.id === null
          );

          const [item] = action.payload.doc.splice(indexOfGroupWithNoId, 1);

          // Add the removed item to the front of the array
          action.payload.doc.unshift(item);

          // ====

          state.presentation.data = action.payload;

          //////////////////////////////
          // Helper function to recursively extract all documents, including sub-documents
          const extractAllDocuments = (
            categories: DocCategory[],
            parentFolderId: string | null = null
          ): Document[] => {
            const docs = categories.flatMap((category) => {
              // Combine the documents in the current category with those in its subcategories
              const currentDocsWithFolder = category.docs.map((doc) => ({
                ...doc,
                folderId: parentFolderId || category.id, // Add folderId reference to each document
              }));

              const allSubDocs =
                category.subs.length > 0
                  ? extractAllDocuments(category.subs, category.id)
                  : [];

              return [...currentDocsWithFolder, ...allSubDocs];
            });

            return docs;
          };

          state.flattedDisplayDocuments = extractAllDocuments(
            action.payload.doc
          );
        }
      )
      .addCase(getPresentation.rejected, (state) => {
        state.presentation.isLoading = false;
      });

    builder
      .addCase(getPresentationDetails.pending, (state) => {
        state.presentation.isLoading = true;
        state.presentation.data = null;
      })
      .addCase(
        getPresentationDetails.fulfilled,
        (state, action: PayloadAction<PresentationPayload>) => {
          state.presentation.isLoading = false;

          if (action.payload.doc) {
            state.displayDocument = action.payload.doc[0].docs[0];
          }

          // Clone the array to safely work on it
          const docArray = [...action.payload.doc];

          // Find the index of the item with id === null
          const indexOfGroupWithNoId = docArray.findIndex(
            (item) => item.id === null
          );

          if (indexOfGroupWithNoId !== -1) {
            const [item] = docArray.splice(indexOfGroupWithNoId, 1);
            // Add the item with no id to the front of the cloned array
            docArray.unshift(item);
          }

          // Assign the modified array back to the state
          // if (state.presentation) {
          state.presentation.data = { ...action.payload, doc: docArray };
          // }

          // if (action.payload.doc) {
          //   state.displayDocument = action.payload.doc[0].docs[0];
          // }

          // // moving groups without id to index 0
          // const indexOfGroupWithNoId = action.payload.doc.findIndex(
          //   (item) => item.id === null
          // );

          // // Check if an item with id === null exists
          // if (indexOfGroupWithNoId !== -1) {
          //   const [item] = action.payload.doc.splice(indexOfGroupWithNoId, 1);
          //   // Add the removed item to the front of the array
          //   action.payload.doc.unshift(item);
          // }

          // ====

          state.presentation.data = action.payload;

          //////////////////////////////
          // Helper function to recursively extract all documents, including sub-documents
          const extractAllDocuments = (
            categories: DocCategory[],
            parentFolderId: string | null = null
          ): Document[] => {
            const docs = categories.flatMap((category) => {
              // Combine the documents in the current category with those in its subcategories
              const currentDocsWithFolder = category.docs.map((doc) => ({
                ...doc,
                folderId: parentFolderId || category.id, // Add folderId reference to each document
              }));

              const allSubDocs =
                category?.subs?.length > 0
                  ? extractAllDocuments(category.subs, category.id)
                  : [];

              return [...currentDocsWithFolder, ...allSubDocs];
            });

            return docs;
          };

          state.flattedDisplayDocuments = extractAllDocuments(
            action.payload.doc
          );
        }
      )
      .addCase(getPresentationDetails.rejected, (state) => {
        state.presentation.isLoading = false;
      });

    // get preview slides
    builder
      .addCase(getSlides.pending, (state) => {
        state.slides.isLoading = true;
        state.slides.data = [];
      })
      .addCase(
        getSlides.fulfilled,
        (state, action: PayloadAction<SlidePayload>) => {
          state.slides.isLoading = false;

          const sortedSlides = action.payload.slides.sort(
            (slideA, slideB) => slideA.position - slideB.position
          );
          state.slides.data = sortedSlides;
        }
      )
      .addCase(getSlides.rejected, (state) => {
        state.slides.isLoading = false;
      });
  },
});

export default sharedBriefcaseSlice.reducer;
export const { setNextDisplayDoc, setPrevDisplayDoc, setDisplayDoc } =
  sharedBriefcaseSlice.actions;
