import { useEffect } from 'react';

export const useSignalREventListener = (connection, eventHandler) => {
  useEffect(() => {
    console.log('Connection is on!!! useSignalREventListener 1');
    if (connection) {
      connection.on('EventResponse', eventHandler);
    }
    return () => {
      if (connection) {
        connection.off('EventResponse', eventHandler);
      }
    };
  }, [connection, eventHandler]);
};
