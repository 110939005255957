import React from "react";
import { faq } from "./data";
import QuestionTab from "./QuestionTab";

export default function FAQ() {
  return (
    <section className="w-full my-10 bg-white">
      <section className=" mx-auto py-10 [ lg:w-[50%]  w-10/12 ]">
        <h1 className=" text-center font-semibold my-4 [ lg:text-[4rem] text-2xl ]">
          Frequently asked questions
        </h1>

        <section className="mt-10">
          {
            faq?.map((detail)=>{
              return <QuestionTab detail={detail}/>
            })
          }
        </section>
      </section>
    </section>
  );
}
