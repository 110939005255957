import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//assets

// Components
import AccessForm from 'components/User/AccessBriefcase/AccessForm';

//slices
import {
  connectSignalR,
  getSignalRConnection,
} from 'store/SignalR/signalRSlice';

//utils

//hooks
import { useSignalREventListener } from 'hooks/SignalR/Listeners/Global/useSignalRListener'; //for listening to generic Events -- I'm not very certain

import { toast } from 'sonner';

import { AppDispatch, RootState } from 'store/store';

// Fix moving this to it's own slice
import { getPresentationDetails } from 'store/Briefcase/briefcaseSlice';
import Loader from 'components/UI/Loader';

export default function JoinSharedPresentation() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { presentationId } = useParams();

  const presentationDetails = useSelector(
    (state: RootState) => state.briefcase.presentationDetails
  );

  // // usestate
  const [isLoading, setIsLoading] = useState(false);
  const [signalRMessage, setSignalRmessage] = useState<any>({});
  const [enterOTP, setEnterOTP] = useState(false);
  const [otpValues, setOtpValues] = useState<
    string | number | undefined | null
  >('');

  const [connection, setConnection] = useState(getSignalRConnection());

  // Getting presentation details to check if passcode is needed
  useEffect(() => {
    presentationId && dispatch(getPresentationDetails(presentationId));
  }, [dispatch, presentationId]);

  useEffect(() => {
    if (!connection) {
      dispatch(connectSignalR()).then(() => {
        setConnection(getSignalRConnection());
      });
    }
  }, [dispatch, connection]);

  console.log('connection =====>', connection);

  //SignalR event listener
  // Listens to generic events
  useSignalREventListener(
    connection,
    (
      id: string,
      eventName: string,
      status: string,
      message: any,
      presenterConnectionId: string
    ) => {
      console.log('id', id);
      console.log('eventName', eventName);
      console.log('status', status);
      console.log('message', message);

      /**There are two situations to handle,
       * 1. When they are accessing it for the first time, they require OTP
       * 2. When they are reaccessing ie they've verified their email using OTP alread - re-access
       */

      setIsLoading(false); //stops the button loader

      if (!status) {
        if (message) {
          if (typeof message === 'string') {
            toast.error(message ?? 'No data found');
          } else {
            if (message.message) {
              if (typeof message.message === 'string') {
                toast.error(message.message ?? 'No data found');
              }
            }
          }
        } else {
          toast.error('No data found');
        }
      }

      //
      const reference = message?.message?.toLowerCase();
      const allowedIn = 'meeting joined';
      localStorage.setItem('connectionId', presenterConnectionId ?? ''); // I am setting the presenterConnectionId generally if it is available

      // Handles when you enter a wrong OTP
      if (!status && eventName === 'ConfirmAccessOTP') {
        setOtpValues(''); //resets the input otp
        // setIsLoading(false); //stops the button loader
        toast.error(message);
      }

      if (status) {
        //attend event-response
        if (eventName === 'RequestSharedAccess') {
          if (reference === allowedIn) {
            // localStorage.setItem('connectionId', presenterConnectionId ?? ''); //this is where I am setting the connection ID
            navigate(`/shared-briefcase/${presentationId}`);
            return;
          } else {
            setSignalRmessage(message);
            // setIsLoading(false);
            setEnterOTP(true);
          }
        }

        // after entering OTP
        if (eventName === 'ConfirmAccessOTP') {
          if (message.toLowerCase() === allowedIn) {
            // localStorage.setItem('connectionId', presenterConnectionId ?? ''); //this is where I am setting the connection ID
            navigate(`/shared-briefcase/${presentationId}`);
          }
        }
      }
    }
  );

  //funtions
  const triggerLoading = () => {
    setIsLoading(true);
  };

  return (
    <section className='bg-[#F2F2F2] w-full h-[100vh] flex justify-center items-center p-5 md:p-0'>
      {presentationDetails.isLoading || !presentationDetails.data ? (
        <Loader />
      ) : (
        <AccessForm
          connection={connection}
          presentationId={presentationId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          triggerLoading={triggerLoading}
          signalRMessage={signalRMessage}
          enterOTP={enterOTP}
          setEnterOTP={setEnterOTP}
          otpValues={otpValues}
          setOtpValues={setOtpValues}
          presentationDetails={presentationDetails.data}
        />
      )}
    </section>
  );
}
