import React from "react";

//assets
import notif from "assets/svg/notif.svg";
import call from "assets/svg/calling.svg";
import connect from "assets/svg/connect.svg";

import twitter from "assets/svg/twitter.svg";
import youtube from "assets/svg/youtubeOrange.svg";
import linkedIn from "assets/svg/linkedInOrange.svg";
import facebook from "assets/svg/facebookOrange.svg";
import instagram from "assets/svg/instagramOrange.svg";

export default function ContactInfo() {
  return (
    <section>
      <section className="[ flex flex-col items-center justify-center ] rounded-lg [ lg:gap-y-6  gap-y-4 ] ">
        <section className=" [lg:w-[26.75rem] lg:h-[8.9rem] w-[90%] h-[6rem] ] [ flex flex-col items-center justify-center ] bg-white rounded-2xl gap-y-2 ">
          <div className="flex items-center text-lg font-semibold gap-x-2">
            {" "}
            <img src={call} alt="call use" />
            <p>Call us</p>
          </div>

          <p>+ 1 (239) 800 2387</p>
        </section>

        <section className=" [lg:w-[26.75rem] lg:h-[8.9rem] w-[90%] h-[6rem] ] [ flex flex-col items-center justify-center ] bg-white rounded-2xl gap-y-2 ">
          <div className="flex items-center text-lg font-semibold gap-x-2">
            {" "}
            <img src={connect} alt="Connect with us" />
            <p>Connect with us</p>
          </div>
          <div className="flex items-center gap-x-4">
            <img src={twitter} alt="twitter" />
            <img src={linkedIn} alt="linkedIn" />
            <img src={facebook} alt="facebook" />
            <img src={instagram} alt="instagram" />
            <img src={youtube} alt="youtube" />
          </div>
        </section>

        <section className=" [ lg:w-[26.75rem] h-[10rem] w-[19rem] ] [ flex flex-col items-center justify-center ] bg-white rounded-2xl gap-y-2  ">
          <div className="flex items-center text-lg font-semibold gap-x-2">
            {" "}
            <img src={notif} alt="Chat with us" />
            <p>Chat with us</p>
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <p>
              <span className="font-semibold">Support:</span>{" "}
              support@thepresenta.com
            </p>
            <p>
              <span className="font-semibold"> info:</span> info@thepresenta.com
            </p>
          </div>
        </section>
      </section>
    </section>
  );
}
