import React, { useEffect, useState } from 'react';

//utils
import { imageStream } from 'utils/imageStream';

//assets
// import pdfImg from 'assets/image/pdf.png';
import pdfImg from 'assets/image/pdf-main.png';
import pptImg from 'assets/image/ppt.png';
import excelImg from 'assets/image/excel.png';
import folderImg from 'assets/svg/folderImg.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';

interface DocumentData {
  documentId: string;
  downloads?: number;
  groupId?: string | null;
  id?: string;
  isPrivate?: boolean;
  spaceId?: string;
  title?: string;
  url?: string;
  views?: number;
  bannerId?: string;
}

interface ImageCache {
  [id: string]: string;
}

interface Props {
  file: DocumentData;
  width: number;
  height: number;
  imgTagheight: number;
  imgTagwidth: number;
  imageCache: ImageCache;
  // documentId: string;
}

export default function FileThumbnailCache({
  file,
  imageCache,
  width,
  height,
  imgTagheight,
  imgTagwidth,
}: // documentId,
Props) {
  const dispatch = useDispatch();

  //usestate
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useEffect(() => {
    let isCancelled = false;

    if (!file) {
      setThumbnail(folderImg);
      return;
    }

    const fileUrl = file?.url;
    let objectUrl: string | undefined = undefined;

    const fetchImageStream = async () => {
      setThumbnail(null);
      let url;
      const cachedImg = imageCache[file.documentId];

      if (cachedImg) {
        setThumbnail(cachedImg);
      } else {
        url = await imageStream(file.documentId, true, width, height);
        objectUrl = url;

        if (url) {
          imageCache[file.documentId] = url;
          setThumbnail(url);
        }
      }

      //   if (file.documentId) {
      //     url = await imageStream(file.documentId, true, width, height);
      //     objectUrl = url;
      //   }

      if (!isCancelled && url) {
        setThumbnail(url);
      }
    };

    // handles documents with both url and bannerid
    if (fileUrl) {
      if (fileUrl.endsWith('.ppt') || fileUrl.endsWith('.pptx')) {
        setThumbnail(pptImg);
      } else if (fileUrl.endsWith('.csv') || fileUrl.endsWith('.xlsx')) {
        setThumbnail(excelImg);
      } else if (fileUrl.endsWith('.pdf')) {
        setThumbnail(pdfImg);
      } else if (
        fileUrl.endsWith('.png') ||
        fileUrl.endsWith('.jpeg') ||
        fileUrl.endsWith('.jpg') ||
        fileUrl.endsWith('.svg')
      ) {
        fetchImageStream();
      }
    } else {
      setThumbnail(folderImg);
    }

    // return () => {
    //   isCancelled = true;
    //   if (objectUrl) {
    //     console.log('URL reference cleared!!!');
    //     URL.revokeObjectURL(objectUrl);
    //   }
    // };
  }, [file, dispatch, height, width, imageCache]);

  return (
    // <div className='flex items-center'>
    //   {thumbnail ? (
    //     <div
    //       className={`w-[${imgTagwidth}px] h-[${imgTagheight}px] overflow-hidden`}
    //     >
    //       <img
    //         src={thumbnail}
    //         alt={file?.title}
    //         className={`rounded-lg object-cover object-center max-h-[${imgTagwidth}px] max-w-[${imgTagwidth}px]`}
    //       />
    //     </div>
    //   ) : (
    //     <div
    //       className={`w-[${width ?? '36'}px] h-[${
    //         height ?? '36'
    //       }px] rounded-lg cursor-pointer flex justify-center items-center`}
    //     >
    //       <ClipLoader size={12} color='gray' />
    //     </div>
    //   )}
    // </div>
    <div className='flex items-center'>
      {thumbnail ? (
        <div
          // className={`w-[${imgTagwidth}px] h-[${imgTagheight}px] overflow-hidden`}
          style={{ width: imgTagwidth, height: imgTagheight }}
        >
          <img
            src={thumbnail}
            alt={file?.title}
            // className={`rounded-lg object-cover object-center max-h-[${imgTagwidth}px] max-w-[${imgTagwidth}px]`}
            className='rounded-lg object-cover object-center'
            style={{
              maxWidth: `${imgTagwidth}px`,
              maxHeight: `${imgTagheight}px`,
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
      ) : (
        <div
          className={`w-[${width ?? '36'}px] h-[${
            height ?? '36'
          }px] rounded-lg cursor-pointer flex justify-center items-center`}
        >
          <ClipLoader size={12} color='gray' />
        </div>
      )}
    </div>
  );
}
