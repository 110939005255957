import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SmoothScroll from "components/UI/SmoothScroll";
import { useLocation } from "react-router-dom";

export default function WebsiteLayout({ children }) {
  const location = useLocation();
  const [isLoading, setisLoading] = useState(true);
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const websiteRef = useRef();

  const circlesRef = useRef([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const circles = websiteRef.current.querySelectorAll(".circle");
    circlesRef.current = Array.from(circles);

    circlesRef.current.forEach((circle) => {
      circle.x = 0;
      circle.y = 0;
    });
  }, []);

  useEffect(() => {
    const moveMouseHandler = (e) => {
      const { clientX, clientY } = e;

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const notInsideWebpage =
        clientX <= 5 ||
        clientY <= 20 ||
        clientX >= viewportWidth - 10 ||
        clientY >= viewportHeight - 10;

      if (notInsideWebpage) {
        fadeOutCircles();
      } else {
        coord.x = clientX;
        coord.y = clientY + window.scrollY;
        setCoord({ ...coord }); // Trigger re-render by creating a new object

        requestAnimationFrame(animateCircle);

        const element = document.elementFromPoint(clientX, clientY);

        let targetElement = element;

        // Check if the element or its ancestors have the bg-black20 class
        while (
          targetElement &&
          !targetElement.classList.contains("bg-black20")
        ) {
          targetElement = targetElement.parentElement;
        }

        if (targetElement && targetElement.classList.contains("bg-black20")) {
          // Turn circles orange
          circlesRef.current.forEach((circle) => {
            circle.style.backgroundColor = "#FF6929";
          });
        } else {
          // Reset circles to black
          circlesRef.current.forEach((circle) => {
            circle.style.backgroundColor = "black";
          });
        }
      }
    };

    const animateCircle = () => {
      let x = coord.x;
      let y = coord.y;

      circlesRef.current.forEach((circle, index) => {
        circle.style.left = x - 12 + "px";
        circle.style.top = y - 12 + "px";
        circle.x = x;
        circle.y = y;
        circle.style.scale = index / 11;
        circle.style.opacity = 1;

        const nextCircle =
          circlesRef.current[(index + 1) % circlesRef.current.length];

        x += (nextCircle.x - x) * 0.02;
        y += (nextCircle.y - y) * 0.02;
      });
    };

    if (websiteRef.current && circlesRef.current.length > 0) {
      // window.addEventListener("mousemove", moveMouseHandler);
      setisLoading(false);
    }

    return () => {
      window.removeEventListener("mousemove", moveMouseHandler);
    };
  }, [coord.x, coord.y, coord]);

  const fadeOutCircles = () => {
    circlesRef.current.forEach((circle) => {
      circle.style.opacity = 0;
    });
  };

  return (
    <section
      className="relative min-h-screen pb-20 overflow-x-hidden bg-cream100 cabinFont"
      ref={websiteRef}
    >
      <section className="z-0">
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
        <div className="hidden circle lg:block"></div>
      </section>

      <section className="fixed z-50 w-full top-4">
        <section className=" [ lg:w-[80%] w-[90%]  mx-auto ] ">
          <Navbar />
        </section>
      </section>

      {/* <SmoothScroll> */}
        <section
          className="pt-24 transition-opacity duration-1000 ease-in-out lg:pt-32"
          style={{ opacity: isLoading ? 0 : 1 }}
        >
          {children}
          <Footer />
        </section>
      {/* </SmoothScroll> */}
    </section>
  );
}
