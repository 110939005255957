import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import avatar1 from "assets/image/avatarFB.png";
import avatar2 from "assets/image/avatarOl.png";
import { useNavigate } from "react-router-dom";
import ellipsis from "assets/svg/ellipsis-horizontal.svg";
import tableDownload from "assets/svg/tableDownload.svg";
import clockBroken from "assets/svg/brokenClock.svg";
import questionMark from "assets/svg/tableQuestion.svg";
import { PresentationAttendanceInterface } from "interfaces/interfaces-data";
import avatarPlain from "assets/svg/avatarPlain.svg";
import { RoundNumber } from "utils/numberRoundUp";
import { timeTextFormatter } from "utils/formatter";

export const VisitorTable = ({
  presentationVistor,
}: {
  presentationVistor: PresentationAttendanceInterface[];
}) => {
  const navigate = useNavigate();
  interface RowData {
    name: string;
    numberOfFiles: number;
    lastUpdated: string;
    tags: string[];
    access: string[];
    email?: string;
    phone: string;
  }
  const items = [
    {
      name: "Phoenix Baker",
      numberOfFiles: 120,
      lastUpdated: "2hr ago",
      tags: ["Design", "Marketing", "Finance", "SEO"],
      access: [avatar1, avatar2, avatar2],
      email: "alex@gmail.com",
      phone: "+859483849",
    },
    {
      name: "Phoenix Baker",
      numberOfFiles: 120,
      lastUpdated: "2hr ago",
      tags: ["Design", "Marketing", "Finance", "SEO"],
      access: [avatar1, avatar2, avatar2],
      email: "alex@gmail.com",
      phone: "+859483849",
    },
  ];

  const columns = [
    {
      header: "Names",
      body: (rowData: PresentationAttendanceInterface) => {
        return (
          <section>
            <section className="flex items-center gap-x-4">
              <div className=" text-white text-xs rounded-lg overflow-hidden w-[49px] h-[49px] flex items-center justify-center">
                <img
                  src={avatarPlain}
                  alt="profile"
                  className="w-full h-full"
                />
              </div>
              <div className="pt-1 font-medium capitalize">
                <p className="text-black text-sm">{rowData.name}</p>
              </div>
            </section>
          </section>
        );
      },
    },
    {
      header: "Email",
      body: (rowData: PresentationAttendanceInterface) => {
        return (
          <section>
            <section className="flex items-center gap-x-4">
              <div className="pt-1 font-medium capitalize">
                <p className="text-black text-sm">{rowData.email}</p>
              </div>
            </section>
          </section>
        );
      },
    },

    {
      header: "Metrics",
      field: "tags",
      sortable: true,
      body: (rowData: PresentationAttendanceInterface) => {
        return (
          <section className="w-full flex gap-x-2">
            {rowData.download && (
              <div className="flex items-center gap-1 bg-[#F6F3EF] rounded-full px-4 py-2">
                <span>
                  <img src={tableDownload} />
                </span>
              </div>
            )}

            <div className="flex gap-1 bg-[#F6F3EF] rounded-full items-center px-4 py-2">
              <span>
                <img src={clockBroken} />
              </span>
              <p className="text-xs">
                {rowData.timeSpent
                  ? timeTextFormatter(Math.ceil(rowData.timeSpent))
                  : 0}{" "}
              </p>
            </div>
            <div className="flex gap-1 bg-[#F6F3EF] rounded-full px-4 py-2">
              <span>
                <img src={questionMark} />
              </span>
              <p className="text-xs">{rowData.questions ?? 0}</p>
            </div>
          </section>
        );
      },
    },

    {
      header: "",
      sortable: true,
      body: () => {
        return (
          <div className="flex gap-x-3">
            <div className="cursor-pointer">
              {/* <img src={ellipsis} alt="" /> */}
              <p className="text-sm font-grotesk font-semibold">View</p>
            </div>
          </div>
        );
      },
    },
  ];

  const [filters, setFilters] = useState({
    // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });
  return (
    <section className="overflow-hidden rounded-xl w-full">
      <DataTable
        filters={filters}
        globalFilterFields={["name", "lastUpdated", "tags"]}
        value={presentationVistor}
        tableStyle={{ minWidth: "45rem", maxHeight: "300px" }}
      >
        {columns.map((col, index) => {
          return (
            <Column
              key={index}
              body={col?.body}
              field={col.field}
              header={col.header}
              headerClassName="bg-[#F5F5F5F5] text-blue300 font-normal text-sm"
              className="text-sm"
            />
          );
        })}
      </DataTable>
    </section>
  );
};
