import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {RouterProvider} from "react-router-dom";
import {router} from "routes";

import {PrimeReactProvider} from "primereact/api";

import "primeicons/primeicons.css";

import "primereact/resources/primereact.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

//toast
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";

//dayjs
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

//redux
import {Provider} from "react-redux";
import store from "store/store";
import {ToastProvider} from "components/UI/ToastProvider";
import {Toaster} from "sonner";

const root = ReactDOM.createRoot(document.getElementById("root"));
const value = {
    ripple: false,
};
// Extend dayjs with UTC plugin
dayjs.extend(utc);

root.render(
    // <React.StrictMode>

    <>
        <Toaster richColors position="bottom-right"/>
        <Provider store={store}>
            <PrimeReactProvider value={value}>
                <ToastProvider>
                    <RouterProvider router={router}>
                        <ToastContainer
                            // limit={3}
                            autoClose={2000}
                            position="top-center"
                            hideProgressBar
                        />
                        <App/>
                    </RouterProvider>
                </ToastProvider>
            </PrimeReactProvider>
        </Provider>
    </>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
