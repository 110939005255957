import React, { ReactNode } from "react";
import type { DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space } from "antd";

export const BottomDrawer = ({
  handleClose,
  modalActive,
  children,
  title,
  icon,
}: {
  handleClose: (val: boolean) => void;
  modalActive: boolean;
  children: ReactNode;
  title: string;
  icon: string;
}) => {
  return (
    <div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={() => handleClose(false)}
        open={modalActive}
        key="bottom"
      >
        <div className="mb-4">
          <span>
            <img src={icon} alt="Modal icon" />
          </span>
          <p className="text-[#333333] text-lg font-semibold">{title}</p>
        </div>

        {children}
      </Drawer>
    </div>
  );
};
