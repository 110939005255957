import { Avatar } from 'primereact/avatar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import {
  addMember,
  getBriefcase,
  removeMember,
} from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';
import { getInitials } from 'utils/formatter';
interface BriefcaseMember {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
}

interface TeamMember {
  teamId: string;
  team: string;
  userId: string | null;
  fullName: string | null;
  email: string;
  role: 'viewer' | 'owner' | 'editor'; // assuming these are possible roles
  dateAdded: string; // this would be an ISO date string
  active: boolean;
  id: string;
}

interface BriefcaseMember {
  fullName: string; // The full name of the team member
  id: string; // Unique identifier for the team member
  memberId: string; // ID specific to the member
  spaceId: string; // ID of the associated space
  spaceTitle: string; // Title of the associated space
}
export default function AddMembers({
  // isAddMemeberModalOpen,
  setIsAddMemberModalOpen,
  teamMembers,
  spaceId,
  teamId,
  briefcaseTeamMembers,
}: {
  // isAddMemeberModalOpen: boolean;
  setIsAddMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  teamMembers: TeamMember[];
  teamId: string | null;
  spaceId: string | undefined;
  briefcaseTeamMembers: BriefcaseMember[];
}) {
  const dispatch = useDispatch<AppDispatch>();
  // State
  const [selectedMembers, setSelectedMembers] = useState<TeamMember[]>([]);
  // const [disableBTN, setDisableBTN] = useState(true);

  const withoutOwner = teamMembers.filter(
    (member) => member.role.toLowerCase() !== 'owner'
  );

  // teamMember - userId
  // briefcaseMembers - memberId
  const noneMembers = withoutOwner.filter(
    (teamMember) =>
      !briefcaseTeamMembers.some(
        (briefcaseMember) => briefcaseMember.memberId === teamMember.userId
      )
  );

  const addedMembers = withoutOwner.filter((teamMember) =>
    briefcaseTeamMembers.some(
      (briefcaseMember) => briefcaseMember.memberId === teamMember.userId
    )
  );

  useEffect(() => {
    setSelectedMembers([...addedMembers]);
  }, []);

  const handleAddMember = async () => {
    if (!spaceId) return;

    // Checks for newly added members
    const newlyAdded = selectedMembers.filter((selectedMember) =>
      noneMembers.some((member) => selectedMember.userId === member.userId)
    );

    console.log('newlyAdded', newlyAdded);

    // checks for removed members
    const removedMembers = addedMembers.filter(
      (selectedMember) =>
        !selectedMembers.some(
          (member) => selectedMember.userId === member.userId
        )
    );

    console.log('removedMembers', removedMembers);

    // Adding new members
    const promiseListNewlyAdded = newlyAdded.map(async (member) => {
      return await dispatch(
        addMember({
          spaceId,
          memberId: member.userId ?? '', //remove this after Tomiwa's fix
        })
      ).then(({ type }) => {
        if (type.includes('fulfilled')) {
          toast.success(
            `${member.fullName ?? ''} has been added to your briefcase`
          );
        }
      });
    });

    await Promise.all(promiseListNewlyAdded);

    // Removing members
    const promiseListRemoveMembers = removedMembers.map(async (member) => {
      return await dispatch(
        removeMember({
          spaceId,
          memberId: member.userId ?? '', //remove this after Tomiwa's fix
        })
      ).then(({ type }) => {
        if (type.includes('fulfilled')) {
          toast.success(`${member.fullName ?? ''} has been remove`);
        }
      });
    });

    await Promise.all(promiseListRemoveMembers);

    spaceId && teamId && dispatch(getBriefcase({ spaceId, teamId }));
    setIsAddMemberModalOpen(false);
  };

  const columns = [
    {
      sortable: true,
      field: 'title',
      header: 'File name',
      body: (rowData: TeamMember) => (
        <div className='px-2 pt-2 '>
          <div className='flex items-center gap-x-2'>
            <Avatar
              label={getInitials(rowData.fullName ?? '')}
              shape='circle'
              size='normal'
              key={rowData.teamId}
            />
            <div>
              <h4 className='text-base text-[#333333]'>{rowData.fullName}</h4>
              <p className='text-xs text-[#757575]'>{rowData.email}</p>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <section className='z-[1000]'>
        {/* wrapper */}
        <section
          className='fixed top-0 left-0 w-full h-full bg-black opacity-20 z-[1001] cursor-default'
          onClick={() => {
            setIsAddMemberModalOpen(false);
          }}
        ></section>
        <section className=''>
          <div
            className={`w-[318px] bg-white p-3 rounded-md border absolute top-10 left-0 z-[1002] --- transition-all duration-500 ease-linear `}
          >
            <div className='w-full border rounded-md grid grid-cols-1 overflow-hidden'>
              {teamMembers && teamMembers?.length > 0 && (
                <DataTable
                  value={teamMembers.filter(
                    (member) => member.role.toLowerCase() !== 'owner'
                  )}
                  className='text-xs'
                  selectionMode='checkbox'
                  selection={selectedMembers}
                  size={'small'}
                  onSelectionChange={(e) => {
                    setSelectedMembers(e.value);
                    // e.value.length < 1
                    //   ? setDisableBTN(true)
                    //   : setDisableBTN(false);
                  }}
                >
                  <Column
                    selectionMode='multiple'
                    headerClassName={`bg-[#F5F5F5F5] text-blue300 font-normal text-sm hidden`}
                    className='w-10 ml-2'
                  ></Column>
                  {columns?.map((col, index) => (
                    <Column
                      key={index}
                      body={col?.body}
                      field={col.field}
                      header={col.header}
                      className='cursor-pointer'
                      headerClassName={`bg-[#F5F5F5F5] text-blue300 font-normal text-sm hidden`}
                    />
                  ))}
                </DataTable>
              )}
            </div>
            <button
              className={`h-12 w-full mt-3 rounded-md bg-black text-white px-5 py-2 font-semibold`}
              onClick={handleAddMember}
            >
              Save
            </button>
          </div>
        </section>
      </section>
    </>
  );
}
