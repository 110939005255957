import { HubConnection } from "@microsoft/signalr";
import { toast } from "react-toastify";
import { getConnectionState } from "store/SignalR/signalRSlice";

export const GetUsersListNew = (
  presentationId: string,
  connection?: HubConnection | null
) => {
  if (connection?.state == "Connected") {
    console.log("Got triggered heree");
    connection
      ?.invoke("GetUsers", presentationId)
      .then((res) => console.log(res, "response fro mthe invocation"))
      .catch((err) => {
        toast.error("An error occured while fetching the list of viewers");
        console.log(err);
      });
  }
};
