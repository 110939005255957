import { FormEvent, useEffect, useState } from 'react';

// Assets
import logo from 'assets/svg/logo-white-background.png';
import backgroundPattern from 'assets/svg/background-pattern.svg';

//libraries
import { toast } from 'sonner';
import { ClipLoader } from 'react-spinners';
import { InputOtp } from 'primereact/inputotp';

interface Props {
  triggerLoading: () => void;
  connection: any;
  presentationId: string | undefined;
  setIsLoading: any;
  isLoading: boolean;
  signalRMessage: any;
  enterOTP: boolean;
  setEnterOTP: any;
  otpValues: any;
  setOtpValues: any;
  presentationDetails: any;
}

export default function AccessForm({
  triggerLoading,
  connection,
  presentationId,
  setIsLoading,
  isLoading,
  signalRMessage,
  setEnterOTP,
  enterOTP,
  otpValues,
  setOtpValues,
  presentationDetails,
}: Props) {
  // States
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  // const [presentationDetails_ , setPresentationDe]

  const accessBriefcase = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name) {
      toast.error('Enter your fullname');
      return;
    }

    if (!email) {
      toast.error('Enter your email');
      return;
    }

    triggerLoading();

    if (connection) {
      connection
        .invoke(
          'RequestSharedAccess',
          presentationId,
          email,
          name,
          passcode,
          ''
        )
        .catch((err: Error) => {
          setIsLoading(false);
          console.log(err);
          toast.error(
            "An unexpected error occurred invoking 'RequestAccess' on the server"
          );
        });
    }
  };

  const submitOTP = (e: FormEvent) => {
    e.preventDefault();

    if (!otpValues) {
      toast.error('Enter OTP');
      return;
    }

    console.log('SignalR reference', signalRMessage?.message?.toLowerCase());
    console.log('OTP values', otpValues);
    // let otp = otpValues?.join('');

    triggerLoading();
    connection
      .invoke(
        'ConfirmAccessOTP',
        presentationId,
        otpValues,
        signalRMessage?.message?.toLowerCase() // reference
        // getConnectionId()
      )
      .catch((err: Error) => {
        console.log(err, 'error');
        toast.error(
          "An error occurred invoking 'ConfirmAccessOTP' on the server"
        );
      });
  };

  const customInput = ({ events, props }: any) => (
    <input {...events} {...props} type='text' className='custom-otp-input' />
  );

  const [timeLeft, setTimeLeft] = useState(180); // 180 seconds for 3 minutes
  const [isCountdownOver, setIsCountdownOver] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsCountdownOver(true);
    }
  }, [timeLeft]);

  const resendOTP = () => {
    // Reset timer and hide the button
    setTimeLeft(180);
    setIsCountdownOver(false);

    if (connection) {
      connection
        .invoke('RequestSharedAccess', presentationId, email, name, '', '')
        .catch((err: Error) => {
          setIsLoading(false);
          console.log(err);
          toast.error(
            "An unexpected error occurred invoking 'RequestAccess' on the server"
          );
        });
    }

    // Call the OTP resend API or function here
    toast.success('OTP resent!');
  };

  return (
    <section className='w-full md:w-[568px] bg-white rounded-xl pb-10'>
      <div className='relative'>
        <img
          src={backgroundPattern}
          alt='pattern'
          className='max-w-[398px] absolute top-0 left-[50%] -translate-x-[50%] pointer-events-none'
        />
        <img
          src={logo}
          alt='logo'
          className='absolute top-10 left-[50%] -translate-x-[50%] pointer-events-none'
        />
      </div>

      <div className='w-full flex justify-center mt-[170px]'>
        <h1 className='font-bold text-2xl md:text-[32px] '>
          {enterOTP ? 'OTP' : 'Enter your details to access the link'}
        </h1>
      </div>
      {enterOTP && (
        <p className='text-center text-[#344054]'>
          Please enter the OTP sent to your email address.
        </p>
      )}
      {!enterOTP ? (
        <form
          className='px-10 grid grid-cols-1 mt-5 gap-5'
          onSubmit={accessBriefcase}
        >
          <label htmlFor='fullname'>
            <p className='text-gray700 font-semibold'>
              Full name<span className='text-primary'>*</span>
            </p>
            <input
              type='text'
              id='fullname'
              placeholder='Enter your full name'
              className='bg-[#F6F3EF] px-3 py-3 w-full rounded-lg mt-2'
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label htmlFor='email'>
            <p className='text-gray700 font-semibold'>
              Email address<span className='text-primary'>*</span>
            </p>
            <input
              type='text'
              id='email'
              placeholder='Enter your email address'
              className='bg-[#F6F3EF] px-3 py-3 w-full rounded-lg mt-2'
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>

          {presentationDetails &&
            presentationDetails?.presentation?.requestPasscode && (
              <label htmlFor='fullname'>
                <p className='text-gray700 font-semibold'>
                  Passcode<span className='text-primary'>*</span>
                </p>
                <input
                  type='text'
                  id='fullname'
                  placeholder='Enter meeting passcode'
                  className='bg-[#e9e4dd] px-3 py-3 w-full rounded-lg mt-2'
                  value={passcode || ''}
                  onChange={(e) => setPasscode(e.target.value)}
                />
              </label>
            )}
          <button className='bg-black text-white px-3 py-3 w-full rounded-lg mt-5 font-semibold'>
            {isLoading ? <ClipLoader color='white' size={12} /> : ' view link'}
          </button>
        </form>
      ) : (
        <form
          className='px-10 grid grid-cols-1 mt-5 gap-5  overflow-hidden'
          onSubmit={submitOTP}
        >
          <div className='flex justify-center items-center w-full'>
            <style scoped>
              {`
                    .custom-otp-input {
                        width: 64px;
                        height: 64px;
                        font-size: 36px;
                        border: 1px solid #D0D5DD;
                        appearance: none;
                        text-align: center;
                        transition: all 0.2s;
                        background: #F6F3EF;
                        border-radius: 8px;
                        color: black;
                    }

                    .custom-otp-input:focus {
                        outline: 0 none;
                    }
                `}
            </style>
            <InputOtp
              length={6}
              value={otpValues}
              onChange={(e) => setOtpValues(e.value)}
              inputTemplate={customInput}
              placeholder='0'
              integerOnly
            />
          </div>
          <button className='bg-black text-white px-3 py-3 w-full rounded-lg mt-5 font-semibold'>
            {isLoading ? <ClipLoader color='white' size={12} /> : ' view link'}
          </button>

          {enterOTP && (
            <div className='w-full flex justify-center'>
              {isCountdownOver ? (
                <p
                  onClick={resendOTP}
                  className='text-[#FF6929] font-bold cursor-pointer'
                >
                  Resend OTP
                </p>
              ) : (
                <p>
                  Resend OTP in{' '}
                  <span className='text-[#FF6929] font-bold'>
                    {Math.floor(timeLeft / 60)}:
                    {String(timeLeft % 60).padStart(2, '0')}
                  </span>
                </p>
              )}
            </div>
          )}
        </form>
      )}
    </section>
  );
}
