import React, { useState } from 'react'
import logo from "../../../assets/svg/logo3.svg"
import pptx from '../../../assets/svg/pptxFile.svg'
import poll from '../../../assets/svg/pollType.svg'
import videoIcon from '../../../assets/svg/videoType.svg'
import pdfFileIcon from '../../../assets/svg/pdfFile.svg'

const Sidebar = () => {
  const [activeButton, setActiveButton] = useState('pptxFile');

  const Buttons = [
    {id : 'pptxFile', src : pptx, label:"PPTX File"},
    {id : 'poll', src : poll, label:"Poll"},
    {id : 'videoIcon', src : videoIcon, label:"Video"},
    {id : 'poll2', src : poll, label:"Pole"},
    {id : 'pdfFileIcon', src : pdfFileIcon, label:"PDF File"}
      ]


  return (
    <div className="border-[1px] ml-5 flex flex-col gap-6 rounded-xl px-5 py-6 self-start float-left">
      <button>
        <img className='max-w-52' src={logo} alt="presenta logo" />
      </button>
      {Buttons.map( Button => (
        <>
        <button key={Button.id} className={`flex flex-col ${activeButton === Button.id && `py-2 px-1 bg-gray-100 rounded-lg`} hover:scale-[1.05] items-center `} onClick={() => setActiveButton(Button.id)}>
          <img src={Button.src} alt={Button.label} />
          <p className=" text-orange-500 text-nowrap sm:text-sm leading-5 text-xs">{Button.label}</p>
        </button>
        </>
      ))}
      
      {/* <div className="flex flex-col gap-4 items-center">
        
        <button className="flex flex-col items-center hover:scale-[1.05]">
          <img src={poll} alt="poll" />
          <p className="text-[#667084] text-[12px] leading-5">Poll</p>
        </button>
        <button className="active:bg-gray-200 flex flex-col items-center hover:scale-[1.05]">
          <img src={videoIcon} alt="pptx file" />
          <p className="text-[#667084] text-[12px] leading-5">Video</p>
        </button>
        <button className="flex flex-col items-center hover:scale-[1.05]">
          <img src={poll} alt="pptx file" />
          <p className="text-[#667084] text-[12px] leading-5 hover:scale-[1.05]">Poll</p>
        </button>
        <button className="flex flex-col items-center hover:scale-[1.05]">
          <img src={pdfFileIcon} alt="pptx file" />
          <p className="text-[#667084] text-[12px] leading-5">PDF File</p>
        </button>
      </div> */}
    </div>
  );
}

export default Sidebar