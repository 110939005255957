import React from "react";

//assets
import map from "assets/svg/map.svg";

export default function BlackBackground() {
  const boxShadowStyle = {
    boxShadow: "0px 0px 0px 3px #333333",
  };

  const mapBg = {
    background: `url(${map}) no-repeat`,
    backgroundPosition: "top right",
  };

  return (
    <section className=" bg-black20   black-background my-10   [  py-10 ] [  lg:rounded-b-[5rem] rounded-b-[3rem] ]">
      <section
        className=" mx-auto [ lg:py-4 py-10 ] [  lg:w-[90%] w-[95%] ]"
        style={mapBg}
      >
        {/* first */}
        <section className=" mx-auto [ lg:w-[90%] w-full ]">
          <div className="text-white [ text-center lg:text-left ] ">
            <h1 className="  font-medium [ lg:text-[6rem] text-2xl ]  [ mb-4 lg:mb-8 ] [ lg:leading-[7rem] ] ">
              Join the multitude of <br className="[ hidden lg:block ]" />{" "}
              presenters <br className="[ lg:hidden block ]" /> worldwide
            </h1>

            <p className=" text-gray90 mt-4 [ lg:w-[30%] w-full ] [ lg:text-lg text-xs ]">
              Experience the transformative power of our platform and unlock
              your full potential with Presenta. Welcome to a new era of
              presentation excellence.
            </p>
          </div>
        </section>
        {/*  */}

        {/* second */}
        <section
          style={boxShadowStyle}
          className="mx-auto rounded-lg  text-center bg-gray800 [ lg:my-32 lg:py-12 my-10 py-8 ]  [  lg:w-[80%] w-11/12 ]"
        >
          <h1 className="text-orange50 [ lg:text-[4rem] text-2xl ]  [ mb-4 lg:mb-8 ] ">
            Get Started for <span className="text-primary">free</span>
          </h1>

          <p className=" mx-auto text-gray300 [ lg:w-[70%] w-11/12 ] [ lg:text-lg text-xs ] [ ]">
            Whether you're a seasoned professional or a first-time presenter,
            Presenta empowers you to deliver impactful presentations with ease.
            Be the first in your clique to use presenta to revolutionize your
            presentations .
          </p>

          <div className="flex justify-center mt-4">
            <button className="text-sm text-white bg-primary px-4 py-2 rounded-md [ flex items-center justify-center gap-x-2 ]">
              <span> Sign up now</span>
              <i className="font-bold cursor-pointer pi pi-chevron-circle-right"></i>
            </button>
          </div>
        </section>
        {/*  */}
      </section>
    </section>
  );
}
