import React, { useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//assets
import upload from "assets/svg/uploadDark.svg";

//slices
import { beginPresentationEdit } from "store/Presentations/presentationSlice";

//libraries
import dayjs from "dayjs";
import { DatePicker } from "antd";
import utc from "dayjs/plugin/utc";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import { forwardRef } from "react";
import moment from "moment";

// Extend dayjs with UTC plugin
dayjs.extend(utc);

function SetPresentationTime(props, ref) {
  const { closeModal, docId } = props ?? {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //useRef
  const presentationTitle = useRef();

  //usestate
  const [startTime, setStartTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  useImperativeHandle(ref, () => ({
    resetModal() {
      setStartTime(null);
      setIsLoading(null);
      setIsButtonActive(false);
      presentationTitle.current.value = "";
    },
  }));

  //functions
  const handleChange = (e) => {
    if (e?.target?.value) {
      setIsButtonActive(true);
    }
  };

  const onChange = (value, dateString) => {
    const selectedDate = dayjs.utc(dateString); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    setStartTime(formattedDate);
    setIsButtonActive(true);
    // console.log("Formatted Selected Time: ", dateString);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const beginPresentation = () => {
    const title = presentationTitle?.current?.value;

    // if (!startTime) {
    //   toast.error("Select a start time");
    //   return;
    // }

    if (!title) {
      toast.error("Enter presentation title");
      return;
    }

    if (title) {
      setIsLoading(true);
      setIsButtonActive(false);

      const data = {
        title,
        documentId: docId,
        type: 1,
      };

      console.log(data, "here");

      //dispatch function

      // dispatch(beginPresentationEdit(data))
      dispatch(beginPresentationEdit(data)).then(({ payload }) => {
        setIsLoading(false);
        setIsButtonActive(true);
        if (payload) {
          toast.success("Proceed to edit presentation");
          closeModal();

          navigate(`/presentation-details/${payload?.id}`);
          // presentationTitle = null;
          // dispatch(getFolderFiles(folderId));
        }
      });
    }
  };

  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-8 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">
            Before we proceed, let's give our presentation a name
          </h1>
          {/* <p className="text-sm">
            you can only upload a PowerPoint or pdf format
          </p> */}
        </div>

        <div className="my-4">
          <Label text="Presentation title" />
          <AuthInput
            type="text"
            name="text"
            customRef={presentationTitle}
            placeholder="Presentation title"
            onChange={(e) => handleChange(e)}
          />
        </div>

        {/* <div className="my-4">
          <Label text="Start time:" />
          <div className="block w-full mt-2">
            <DatePicker
              className="flex justify-between px-4 py-2 mt-1 rounded-md text-gray700 bg-orange10"
              showTime
              onChange={onChange}
              onOk={onOk}
              format="YYYY-MM-DD HH:mm:ss"
              minDate={dayjs()}
              defaultValue={moment()}
            />
          </div>
        </div> */}

        <button
          disabled={!isButtonActive}
          onClick={beginPresentation}
          className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading && <ClipLoader size={15} color="white" />}

          {!isLoading && (
            <>
              <p>Proceed</p>
            </>
          )}
        </button>
      </section>
    </section>
  );
}
export default forwardRef(SetPresentationTime);
