import React, { useEffect, useState } from "react";

//assets
import move from "assets/svg/move.svg";
import share from "assets/svg/link.svg";
import trash from "assets/svg/delete.svg";
import rename from "assets/svg/rename.svg";
import access from "assets/svg/access.svg";
import folderImg from "assets/svg/folderImg.svg";
import arrowDown from "assets/svg/arrowDown.svg";
import presentNow from "assets/svg/presentNow.svg";

//libraries
import { Dropdown, Menu } from "antd";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

//slices
import {
  archiveFolder,
  createFolder,
  deleteFolder,
  getAllWorkspace,
  getFolderFiles,
  getWorkspaceFolders,
} from "store/Workspace/workspaceSlice";

//component
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import RenameFolderTemplate from "../FolderStructure/RenameFolderTemplate";
import { getFileStructureState } from "store/FileStructure/fileStructureSlice";

export default function TitleBar({
  folder,
  showDropdown,
  activeFolder,
  openDropdown,
}) {
  const dispatch = useDispatch();
  const { Id, Title } = folder;
  const { mostActiveFolder } = useSelector(getFileStructureState);

  //useselector
  const { openedFolders } = useSelector(getFileStructureState);

  //usestate
  const [isHovered, setIsHovered] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [setIsFileUploadModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [lastOpenedFolder, setLastOpenedFolder] = useState(null);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  useEffect(() => {
    setLastOpenedFolder(openedFolders[openedFolders?.length - 1]);
  }, [openedFolders]);

  const items = [
    {
      key: "1",
      label: (
        <div className="flex py-1 gap-x-2">
          <img src={presentNow} alt="create new folder" className="w-4" />
          <p className="text-sm">Present now</p>
        </div>
      ),
      // onClick: (file) => {
      //   navigate(`/presentation-details/${file?.id}`);
      // },
    },

    {
      key: "2",
      label: (
        <div
          className="flex py-1 gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={move} alt="create new folder" className="w-4" />
          <p className="text-sm">Move file </p>
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <div className="flex py-1 gap-x-2" onClick={() => setIsRenaming(true)}>
          <img src={rename} alt="rename" className="w-4" />
          <p className="text-sm">Rename</p>
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div
          className="flex py-1 gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={access} alt="create new folder" className="w-4" />
          <p className="text-sm">Access control</p>
        </div>
      ),
    },

    {
      key: "5",
      label: (
        <div
          className="flex py-1 gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={share} alt="create new folder" className="w-4" />
          <p className="text-sm">Share</p>
        </div>
      ),
    },

    {
      key: "6",
      label: (
        <div
          className="flex items-center py-1 gap-x-2"
          onClick={() => setConfirmArchive(true)}
        >
          <i className="pi pi-folder-open"></i>
          <p className="text-sm">Archive</p>
        </div>
      ),
    },

    {
      key: "7",
      label: (
        <div
          className="flex py-1 gap-x-2"
          onClick={() => setConfirmDelete(true)}
        >
          <img src={trash} alt="create new folder" className="w-4" />
          <p className="text-sm">Delete</p>
        </div>
      ),
    },
  ];

  //functions

  const showNewFolderTemplate = (e, type) => {
    e.stopPropagation();
    type ? setNewFolder(true) : setNewFolder(false);
  };

  const createNewFolder = (e) => {
    if (e.key === "Enter") {
      // setNewFolder(false);
      setIsLoading(true);
      const title = e.target.value;
      const folderId = folder?.Id;

      if (!title) {
        toast.error("Enter folder's name");
        return;
      }

      if (!folderId) {
        toast.error("Folder's ID not present");
        return;
      }

      const data = {
        title,
        folderId,
      };

      //monitor upload progress
      const config = {};

      //dispatch function
      dispatch(createFolder({ data, config })).then(({ payload }) => {
        setIsLoading(false);
        
        window.location.reload();
        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;

            dispatch(getWorkspaceFolders(id));
          }
        });
      });
    }
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const closeConfirmArchive = () => {
    setConfirmArchive(false);
  };

  const deleteWorkspaceFolder = () => {
    setIsDeleteLoading(true);
    dispatch(deleteFolder(Id)).then(({ type }) => {
      setIsDeleteLoading(false);

      if (type.includes("fulfilled")) {
        closeConfirmDelete();
        toast.success("Folder deleted successfully");
        window.location.reload();

        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;
            dispatch(getWorkspaceFolders(id));
          }
        });
      }
    });
  };

  const archiveWorkspaceFolder = () => {
    setIsArchiveLoading(true);
    dispatch(archiveFolder(Id)).then(({ type }) => {
      setIsArchiveLoading(false);
      if (type.includes("fulfilled")) {
        closeConfirmDelete();
        toast.success("Folder archived successfully");

        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === "personal"
            )?.[0]?.workspaceId;
            dispatch(getWorkspaceFolders(id));
          }
        });
      }
    });
  };

  return (
    <section>
      <div
        onClick={() => showDropdown(folder)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`flex justify-between [ hover:bg-gray200 hover:rounded ] px-4 py-2  ${
          (mostActiveFolder?.id === Id && openDropdown) ||
          lastOpenedFolder?.id === Id
            ? "border bg-gray100 "
            : "bg-white"
        }`}
      >
        <div className="flex items-center text-sm gap-x-2">
          <img src={folderImg} alt="document" className="w-4" />

          {!isRenaming ? (
            <p className="font-semibold">{Title}</p>
          ) : (
            <RenameFolderTemplate folder={folder} />
          )}
        </div>

        {isHovered && (
          <div className="flex items-center gap-x-2 ">
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => (
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                    ))}
                  </Menu>
                )}
                trigger={["click"]}
              >
                <div className="">
                  <i
                    className="cursor-pointer pi pi-ellipsis-h"
                    style={{ fontSize: "0.8rem" }}
                  ></i>
                </div>
              </Dropdown>
            </div>

            <div onClick={(e) => showNewFolderTemplate(e, true)}>
              <i
                className="cursor-pointer pi pi-plus"
                style={{ fontSize: "0.8rem" }}
              ></i>
            </div>
            {isRenaming && (
              <i
                className="text-xs cursor-pointer pi pi-times"
                onClick={(e) => setIsRenaming(false)}
              ></i>
            )}

            {folder?.SubFolders?.length > 0 && (
              <img src={arrowDown} alt="dropdown" />
            )}
          </div>
        )}

        {!isHovered && folder?.SubFolders?.length > 0 && (
          <img src={arrowDown} alt="dropdown" />
        )}
      </div>

      {/* create new folder */}
      {newFolder && (
        <section className="flex items-center w-[90%] justify-between my-1 ">
          <div className="flex ml-4 gap-x-2">
            <div className="flex items-center gap-x-2">
              <img src={folderImg} alt="document" className="w-5" />
            </div>

            <div>
              <input
                type="text"
                placeholder="Folder name"
                className="outline-none text-xs [ placeholder:text-xs ] "
                onKeyDown={createNewFolder}
              />
            </div>
          </div>

          <div>
            {isLoading ? (
              <ClipLoader size={12} color="gray" />
            ) : (
              <i
                className="text-xs cursor-pointer pi pi-times"
                onClick={(e) => showNewFolderTemplate(e, false)}
              ></i>
            )}
          </div>
        </section>
      )}
      {/*  */}

      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={deleteWorkspaceFolder}
        text="Are you sure you want to delete this folder??"
      />

      <ConfirmationModal
        open={confirmArchive}
        isLoading={isArchiveLoading}
        onClose={closeConfirmArchive}
        func={archiveWorkspaceFolder}
        text="Are you sure you want to archive this folder?"
      />

      {/*  */}
    </section>
  );
}
