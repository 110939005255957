import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//components
import AdminNavbar from "components/UI/AdminLayout/Navbar";
import PollTab from "components/Admin/Presentation/PresentationMetrics/PollTab";
import VisitorsLeads from "components/Admin/Presentation/PresentationMetrics/VisitorsLeads";
import PerformanceMetric from "components/Admin/Presentation/PresentationMetrics/PerformanceMetric";
import { useDispatch, useSelector } from "react-redux";
import {
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";

export default function PresentationMetrics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { presentationDetails } = useSelector(getPresentationState);
  const { presentationId } = useParams();
  //usestates
  const [tab, setTab] = useState(0);
  const [navProps, setNavProps] = useState({});

  //useeffects
  useEffect(() => {
    presentationId && dispatch(getPresentationDetails(presentationId));
  }, [presentationId, dispatch]);

  useEffect(() => {
    if (presentationDetails.data) {
      const title = presentationDetails.data?.presentation?.title;
      setNavProps({
        title: "Presentation Metrics",
        subTitle: `Metrics on ${title}`,
        // modalTitle: "Upload new slide",
      });
    }
  }, [presentationDetails]);

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="px-2 mt-20 border rounded-lg">
        <section className="flex items-center my-6 ">
          <div
            onClick={() => navigate(-1)}
            className="flex items-center mb-2 cursor-pointer "
          >
            <i className="pi pi-arrow-circle-left text-primary"></i>
          </div>
          <div
            onClick={() => setTab(0)}
            className={`pb-2 px-4 cursor-pointer ${
              tab !== 0
                ? "border-b"
                : "border-b-2 border-primary text-primary font-semibold"
            }`}
          >
            <p>Performance Metrics</p>
          </div>

          {/* v2  */}

          {/* <div
            onClick={() => setTab(1)}
            className={`pb-2 px-8 cursor-pointer ${
              tab !== 1
                ? "border-b"
                : "border-b-2 border-primary text-primary font-semibold"
            }`}
          >
            <p>Poll & Quizzes</p>
          </div> */}

          {/*  */}

          <div
            onClick={() => setTab(2)}
            className={`pb-2 px-4 cursor-pointer ${
              tab !== 2
                ? "border-b"
                : "border-b-2 border-primary text-primary font-semibold"
            }`}
          >
            <p>Visitors & Leads</p>
          </div>
        </section>

        {tab === 0 && <PerformanceMetric />}

        {tab === 1 && <PollTab />}

        {tab === 2 && <VisitorsLeads />}
      </section>
    </section>
  );
}
