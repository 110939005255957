import AuthInput from "components/UI/Inputs/AuthInput";
import Label from "components/UI/Label";
import React from "react";

export default function ContactForm() {
  return (
    <section className="py-10 bg-white rounded-2xl">
      <div className="px-4 text-black ">
        <h3 className="text-lg font-semibold text-center">Send us a mail</h3>
        <form className="px-4">
          <div className="my-3">
            <Label text="Full name" />
            <AuthInput placeholder="Enter your full name" name="password" />
          </div>

          <div className="my-3">
            <Label text="Email address" />
            <AuthInput placeholder="example@presenta.com" name="email" />
          </div>

          <div className="my-3">
            <Label text="Phone number" />
            <AuthInput placeholder="Enter phone number" name="tel" />
          </div>

          <div className="my-3">
            <Label text="Description" />
            <textarea
              className="flex justify-between w-full px-4 mt-2 rounded-md text-gray700 bg-orange10"
              name=""
              id=""
              rows="3"
            ></textarea>
          </div>

          <div>
            <button className="w-full py-3 text-sm text-white bg-black rounded-lg">Submit</button>
          </div>
        </form>
      </div>
    </section>
  );
}
