import React from "react";

//assets
import fileEmpty from "assets/svg/fileEmpty.svg";
import presentation from "assets/svg/emptyPresentation.svg";

export default function EmptyState({ type }) {
  let img;
  if (type === "file") {
    img = fileEmpty;
  }
  if (type === "presentation") {
    img = presentation;
  }
  return (
    <div className="flex items-center w-full ">
      <div className="w-[45%] mx-auto">
        <img src={img} alt="empty " />
      </div>
    </div>
  );
}
