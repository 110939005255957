import LoginCarousel from "components/Authentication/Login/Carousel";
import React, { FormEventHandler, useEffect, useRef, useState } from "react";
import logo from "assets/svg/fullLogo.svg";
import { BackButton, CButton } from "components/UI/Button/Buttons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AuthInput from "components/UI/Inputs/AuthInput";
import Label from "components/UI/Label";
import lineBg from "assets/svg/lineBg.svg";
import instance from "lib/axiosInterceptor";
import { toast } from "sonner";
import { ignore } from "antd/es/theme/useToken";

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const lineBgStyle = {
    background: `url(${lineBg}) no-repeat bottom`,
    // backgroundSize: "cover",
  };
  const [otpActive, setOtpActive] = useState(false);
  const [inputEmailActive, setInputEmailActive] = useState(true);
  const [email, setEmail] = useState("");
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [id, setId] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getParams = new URLSearchParams(window.location.search);
    const code = getParams.get("code") ?? "";
    const id = getParams.get("q") ?? "";
    if (!code && !id) {
      navigate("/login");
      return;
    }
    setId(id);
    setCode(code);
  }, []);

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!newPassword && !confirmPassword) {
      toast.error("Both Fields are required");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Password Mismatch");
      setLoading(false);
      return;
    }

    try {
      const result = await instance.post(`/Account/ResetPassword`, {
        id,
        code,
        password: newPassword,
      });
      toast.success("Password reset successful");
      navigate("/login");
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <section className="flex justify-between h-screen font-grotesk ">
        <section
          style={lineBgStyle}
          className="lg:w-[40%] w-full px-10 lg:py-4 py-10"
        >
          <Link to="/">
            <div>
              <img
                src={logo}
                alt="Presenta Logo"
                className="w-20 ml-5 lg:w-24"
              />
            </div>
          </Link>

          <section className="lg:mt-6 mt-[3%] w-[90%] mx-auto ">
            <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  ">
              Reset Password
            </h1>
            <div className="my-10 text-sm font-medium text-left lg:my-2 text-gray40 ">
              <h3>Enter your new password</h3>
            </div>
            <section className="mt-12">
              <form onSubmit={handleResetPassword} className="">
                <div className="mb-4">
                  <Label text="New Password" />
                  {/* @ts-ignore */}
                  <AuthInput
                    type="password"
                    isPassword
                    name="newPassword"
                    placeholder="Enter new password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Label text="Confirm Password" />
                  {/* @ts-ignore */}
                  <AuthInput
                    type="password"
                    isPassword
                    name="confirmPassword"
                    placeholder="Confirm password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>

                <CButton
                  fullWidth
                  variant="dark"
                  text="Reset password"
                  loading={loading}
                  isSubmit
                />
              </form>
            </section>
          </section>
        </section>
        <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
          <LoginCarousel />
        </section>
      </section>
    </>
  );
};
