// Assets
import arrowDown from 'assets/svg/arrowDown.svg';
import arrowUp from 'assets/svg/arrowUp.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
import sectionIcon from 'assets/svg/section-icon2.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import addCircle from 'assets/svg/add-circle2.svg';
import dragNdropIcon from 'assets/svg/drag-drop-icon.svg';
import closeIcon from 'assets/svg/close-icon.svg';

import { Dropdown, Menu, Modal } from 'antd';
import { BriefcaseFolder } from 'interfaces/Briefcase';
import { Dispatch, SetStateAction, useState } from 'react';
import UpdateFolder from 'views/Admin/Briefcase/Modals/UpdateFolder';
import AddFile from '../Modals/AddFile';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { deleteFolder, getFolders } from 'store/Briefcase/briefcaseSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';

interface Props {
  folderIndex: number;
  folder: BriefcaseFolder;
  viewFolderDocuments: boolean;
  setViewFolderDocuments: Dispatch<SetStateAction<boolean>>;
  spaceId: string;
}
export default function GroupHeader({
  folderIndex,
  folder,
  viewFolderDocuments,
  setViewFolderDocuments,
  spaceId,
}: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [renameFolder, setRenameFolder] = useState(false);
  const [parentFolderId, setParentFolderId] = useState(null);
  const [confirmDeleteFolder, setConfirmDeleteFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);

  //   Variables
  const items_section = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div
          className='flex gap-x-2 pl-2 pr-5'
          onClick={(e) => {
            setRenameFolder(true);
            e.stopPropagation();
          }}
        >
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'addfile',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            setIsFileUploadModalOpen(true);
            e.stopPropagation();
          }}
        >
          <img src={addCircle} alt='metrics file' className='w-4' />
          <p>Upload files</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'remove',
      label: (
        <div
          className='flex gap-x-2 cursor-pointer pl-2 pr-5'
          onClick={(e) => {
            setConfirmDeleteFolder(true);
            e.stopPropagation();
          }}
        >
          <img src={trash} alt='delete file' className='w-5' />
          <p>Remove</p>
        </div>
      ),
    },
  ];

  const handleFileUploadCancel = () => {
    setIsFileUploadModalOpen(false);
  };

  const handleRenameFolder = async () => {
    setRenameFolder(false);
  };

  // delete folder
  const handleDeleteFolder = async () => {
    setIsDeletingFolder(true);
    const groupId = folder.id;
    const { type } = await dispatch(deleteFolder({ spaceId, groupId }));

    if (type.includes('fulfilled')) {
      toast.success(`${folder.title} deleted successfully`);
      dispatch(getFolders(spaceId));
    } else {
      toast.error(`Was not able to delete ${folder.title}`);
    }

    setConfirmDeleteFolder(false);
    setIsDeletingFolder(false);
  };

  const closeConfirmDeleteFolder = () => {
    setConfirmDeleteFolder(false);
  };

  return (
    <section className=''>
      {folderIndex === 0 ? (
        <section className='bg-[#F6F6F6] text-[#667085] rounded-lg w-full h-10 font font-medium'>
          <section className='h-full w-full flex text-sm'>
            {/* table data 1  */}
            <section
              className={` w-[5%] flex h-full items-center justify-center`}
            ></section>
            {/* table data 2  Name */}
            <section className={`w-[45%] flex items-center h-full `}>
              <p>Name</p>
            </section>
            {/* Table data 3 */}
            <section
              className={`flex items-center justify-center h-full w-[22%]`}
            >
              <p>Actions</p>
            </section>
            {/* Table data 4 */}
            <section
              className={`w-[14%] h-full flex items-center justify-center`}
            >
              <p>Permission</p>
            </section>
            {/* table data 5  */}
            <section className={`w-[14%]`}></section>
          </section>
        </section>
      ) : (
        <section
          className='bg-[#EEEEEE] text-[#333333] text-sm h-10 flex gap-x-3 items-center cursor-pointer rounded-md w-full '
          onClick={() => setViewFolderDocuments(!viewFolderDocuments)}
        >
          <div className='flex items-center gap-x-3 w-[86%] pl-4'>
            <div className='cursor-grab w-12 flex items-center bg-transparent'>
              <img
                src={dragNdropIcon}
                alt='drag'
                className='w-4 pointer-events-none'
              />
            </div>
            <p className='font-semibold'>{folder.title}</p>
            <img src={sectionIcon} alt='section' />
          </div>

          <div className='w-[14%]'>
            <div className='w-full flex items-center gap-x-3 justify-end pr-10'>
              <div>
                <img src={viewFolderDocuments ? arrowUp : arrowDown} alt='' />
              </div>
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {items_section.map((item) => {
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div
                    className='cursor-pointer py-4'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={ellipsis} alt='options' />
                  </div>
                </Dropdown>
              </section>
            </div>
          </div>
        </section>
      )}

      <Modal
        open={isFileUploadModalOpen}
        onCancel={handleFileUploadCancel}
        footer={null}
        width={452}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <AddFile
          closeAddFileModal={handleFileUploadCancel}
          spaceId={spaceId}
          groupId={folder.id}
        />
      </Modal>
      <Modal
        open={renameFolder}
        onCancel={handleRenameFolder}
        footer={null}
        closeIcon={<img src={closeIcon} alt='close' />}
        width={452}
      >
        <UpdateFolder
          closeModal={handleRenameFolder}
          spaceId={spaceId}
          parentId={parentFolderId}
          groupId={folder.id}
          folderTitle={folder.title}
          position={folderIndex}
        />
      </Modal>

      <ConfirmationModal
        open={confirmDeleteFolder}
        isLoading={isDeletingFolder}
        onClose={closeConfirmDeleteFolder}
        func={handleDeleteFolder}
        text='Remove section'
        subText='Are you sure you want to remove this section?'
      />
    </section>
  );
}
