import React from "react";

//components
import QuestionTypePreview from "./QuestionTypePreview";

export default function PollPreview({ activeDetails }) {
  //useselector

  return (
    <section
      className={` h-[84vh] rounded-lg cursor-pointer items-start self-start justify-start justify-self-start`}
    >
      <QuestionTypePreview details={activeDetails} />
    </section>
  );
}
