import React from 'react';
// Assets
import deleteImg from 'assets/svg/delete-orange.svg';

//libraries
import { Modal } from 'antd';
import { ClipLoader } from 'react-spinners';

export default function ConfirmationModal({
  open,
  text,
  func,
  onClose,
  isLoading,
  subText = '',
}) {
  return (
    <section>
      <Modal open={open} footer={null} closable={false} onCancel={onClose}>
        <div className='w-full flex items-center justify-center'>
          <img src={deleteImg} alt='delete' />
        </div>
        <section className='py-6'>
          <h1 className='text-2xl font-bold font-grotesk'>{text}</h1>
          <p className='text-sm'>{subText}</p>

          <section className='flex w-full mt-6 gap-x-4'>
            <button
              className=' w-full border py-4 px-2 text-black font-semibold bg-[#EEEEEE] font-grotesk rounded-lg'
              onClick={() => onClose()}
            >
              No, take me back
            </button>
            <button
              className=' w-full text-white font-semibold py-4 px-2 bg-[#000000] font-grotesk rounded-lg'
              onClick={() => func()}
            >
              {isLoading ? (
                <ClipLoader color='white' size={12} />
              ) : (
                'Yes, delete'
              )}
            </button>
          </section>
        </section>
      </Modal>
    </section>
  );
}
