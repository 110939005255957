import { Col, Row } from "antd";
import React, { ReactNode } from "react";

export const PresentationMobileWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Row justify={"center"} className="bg-[#F2F2F2] pt-4  min-h-screen h-screen  pb-4 ">
      <Col xs={22}>{children}</Col>
    </Row>
  );
};
