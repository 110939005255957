export default function extractMeetingId(input) {
  // Regular expression to match only digits and hyphens
  const regex = /(\d[-\d]*)$/;

  // Match the input against the regex
  const match = input.match(regex);

  if (match) {
    // Remove all hyphens and return the cleaned numeric ID
    return match[0].replace(/-/g, '');
  }

  return null; // Return null if no valid ID is found
}
