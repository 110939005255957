import React from "react";

//components
import Deck from "components/Website/About/Deck";
import Banner from "components/Website/About/Banner";
import BlackBackground from "components/Website/About/BlackBackground";

export default function AboutUs() {
  return (
    <section>
      <Banner />
      <Deck />
      <BlackBackground />
    </section>
  );
}
