
import bg from "assets/svg/joinNowBg.svg";

export const style = {
    background: `url(${bg}) no-repeat `,
    backgroundSize: "cover",
  };

  export const glassStyle = {
    boxShadow: "0px 0px 8px 0px #FFFFFF4D inset",
    border: " 1px solid rgba(255, 255, 255, .25)",
    backdropFilter: "blur(30px) saturate(50%)",
  };