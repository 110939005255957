import React from "react";
import { Link } from "react-router-dom";
import { card2, cards, style } from "views/Admin/Dashboard/data";

export default function ProfileCompletion({presentations}) {
  return (
    <section className="mt-8">
      {/* first time user */}
      {presentations?.data?.length < 1 && (
        <section className="hidden grid-cols-1 my-4 lg:grid gap-y-4 lg:grid-cols-3 gap-x-4">
          {cards.map((card, index) => {
            return (
              <div
                draggable={true}
                key={index}
                style={style}
                className="lg:h-[14rem] h-[12.5rem] relative border p-4 rounded-lg"
              >
                <div className="absolute bottom-4">
                  <h1 className="font-bold text-gray600 font-grotesk">
                    {card.title}
                  </h1>
                  <p className="text-xs">{card.desc}</p>

                  <Link to={card.link}>
                    <button
                      className={` flex items-center px-4 py-2 mt-2 text-xs border-[1.2px] rounded-lg  gap-x-2 font-medium  ${
                        card.buttonStatus
                          ? "bg-primary text-white"
                          : "bg-gray30 text-black"
                      } `}
                    >
                      {!card.buttonStatus && (
                        <img
                          src={card.icon}
                          alt="upload slide"
                          className="w-4"
                        />
                      )}
                      {card.buttonTitle}
                      {card.buttonStatus && (
                        <i
                          className="pi pi-check"
                          color="#FF6929"
                          style={{ fontSize: "0.7rem" }}
                        ></i>
                      )}
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </section>
      )}
      {/*  */}

      {/* second time user */}
      {presentations?.data?.length > 0 && (
        <section className="grid grid-cols-1 my-4  lg:gap-y-4 gap-y-6 lg:grid-cols-3 gap-x-4">
          {card2.map((card, index) => {
            return (
              <div key={index} className="px-4 py-4 border rounded-lg ">
                <div className="flex items-center gap-x-2 ">
                  <img src={card.icon} alt={card.name} />
                  <div>
                    <h1 className="text-sm font-bold ">{card.title}</h1>
                    <p className="text-xs"> {card.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      )}
      {/*  */}
    </section>
  );
}
