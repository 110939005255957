import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//assets
import sm from 'assets/svg/sm.svg';
import search from 'assets/svg/search.svg';
import avatar from 'assets/image/avatarFB.png';
import facebook from 'assets/svg/facebookOutline.svg';

//libraries
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { getInitials } from 'utils/formatter';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';

//slices
import {
  getPresentationState,
  getPresentationVisitors,
} from 'store/Presentations/presentationSlice';

//components
import Loader from 'components/UI/Loader';
import LeadDetailsDrawer from '../Drawer/LeadDetailsDrawer';

export default function Leads({ leads }) {
  const dispatch = useDispatch();

  const { presentationId } = useParams();
  const { presentationVisitors } = useSelector(getPresentationState);

  //usestate
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  //useeffects
  useEffect(() => {
    dispatch(getPresentationVisitors(presentationId));
  }, [presentationId, dispatch]);

  //functions
  const onClose = () => {
    setOpenDrawer(false);
    setSelectedRow(null);
  };

  const columns = [
    {
      header: 'Name',
      field: 'name',
      sortable: true,
      body: (rowData) => {
        return (
          <section>
            <section className='flex items-center gap-x-4'>
              <div className='bg-gray-800 text-white text-xs rounded-full overflow-hidden w-[40px] h-[40px] flex items-center justify-center'>
                {getInitials(rowData?.name)}
                {/* <img src={avatar} alt='profile' className='w-full h-full' /> */}
              </div>
              <div className='pt-1 text-sm font-medium capitalize'>
                <p>{rowData?.name}</p>
                <p className='my-1 text-xs'>{rowData?.role}</p>
              </div>
            </section>
          </section>
        );
      },
    },
    // {
    //   header: 'Company',
    //   field: 'company',
    //   sortable: true,
    //   body: (rowData) => {
    //     const { company } = rowData ?? {};
    //     return (
    //       <section>
    //         {company && (
    //           <section className='flex items-center gap-x-4'>
    //             <div className='text-xs text-white '>
    //               <img
    //                 src={facebook}
    //                 alt='profile'
    //                 className='w-[20px] h-[20px]'
    //               />
    //             </div>
    //             <div className='pt-1 text-sm font-medium capitalize'>
    //               <p>{rowData?.company}</p>
    //             </div>
    //           </section>
    //         )}

    //         {!company && <p>N/A</p>}
    //       </section>
    //     );
    //   },
    // },

    // {
    //   header: 'Company',
    //   field: 'company',
    //   sortable: true,
    // },

    {
      header: 'Email',
      field: 'email',
      sortable: true,
    },
    {
      header: 'Phone number',
      field: 'phone',
      sortable: true,
      body: (rowData) => {
        const { phone } = rowData ?? {};
        return <section>{phone ? <p>{phone}</p> : <p>N/A</p>}</section>;
      },
    },

    {
      header: 'Attention score',
      field: 'attention',
      sortable: true,
    },

    // {
    //   header: 'Time spent (minutes)',
    //   // field: 'timeSpent',
    //   sortable: true,
    //   body: (rowData) => {
    //     return <p>{parseFloat(rowData?.timeSpent.toFixed(2))}</p>;
    //   },
    // },

    // V2
    // {
    //   header: 'Socials',

    //   body: (rowData) => {
    //     return (
    //       <section className='flex flex-row flex-wrap items-center gap-x-2'>
    //         {rowData?.socials?.map((social, index) => {
    //           return (
    //             <div
    //               key={index}
    //               className='flex items-center justify-center px-4 py-2 text-xs border-2 rounded-full text-blue200 bg-blue100 gap-x-1 '
    //             >
    //               <img src={sm} alt='' />
    //               {social}
    //             </div>
    //           );
    //         })}
    //       </section>
    //     );
    //   },
    // },

    // V2
    // {
    //   header: '',
    //   body: (rowData) => {
    //     return (
    //       <section
    //         className='flex items-center gap-x-2'
    //         onClick={() => {
    //           setSelectedRow(rowData?.id);
    //           setOpenDrawer(true);
    //         }}
    //       >
    //         <button className='font-semibold outline-none'>View</button>

    //         <div className='cursor-pointer'>
    //           <i className='pi pi-ellipsis-v'></i>
    //         </div>
    //       </section>
    //     );
    //   },
    // },
  ];

  //functions
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  return (
    <section>
      {presentationVisitors.isLoading && <Loader />}

      {!presentationVisitors.isLoading && (
        <>
          {/* search & filter */}
          <section className='mb-4'>
            <section className={`w-full justify-end flex`}>
              <div className='flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30'>
                <img src={search} alt='sort' />
                <input
                  placeholder=' search'
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  className='bg-transparent w-[90%] py-1 outline-none'
                />
              </div>
            </section>
          </section>
          {/*  */}
          <section className='overflow-hidden border rounded-xl'>
            <DataTable
              paginator
              rows={10}
              filters={filters}
              selectionMode='checkbox'
              globalFilterFields={['name', 'email', 'phone']}
              value={presentationVisitors.data}
              // tableStyle={{ minWidth: '100rem' }}
              tableStyle={{ minWidth: '70rem' }}
              className='text-sm font-medium text-blue300'
            >
              {columns.map((col, index) => {
                return (
                  <Column
                    key={index}
                    body={col?.body}
                    field={col.field}
                    header={col.header}
                    sortable={col?.sortable}
                    headerClassName='bg-white text-blue300 font-medium border-b'
                  />
                );
              })}
            </DataTable>

            <Drawer
              // title="Basic Drawer"
              placement='right'
              closable={false}
              onClose={onClose}
              open={openDrawer}
              width='30%'
            >
              <LeadDetailsDrawer />
            </Drawer>
          </section>
        </>
      )}
    </section>
  );
}
