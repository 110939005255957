import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Assets
import chevronDown from 'assets/svg/chevron-down.svg';
import quizIconBlue from 'assets/svg/quiz-optionIcon-blue.svg';
import quizIconGreen from 'assets/svg/quiz-optionIcon-green.svg';
import markIcon from 'assets/svg/mark-icon.svg';
import imageIcon from 'assets/svg/image-icon.svg';

// Libraries

// Interface
import { PollOption, QuestionsList } from 'interfaces/PollSetup';
import QuestionOption from '../QuestionOption';

interface Props {
  setQuestion: Dispatch<SetStateAction<string>>;
  question: string;
  questionsList: QuestionsList[];
  setQuestionsList: Dispatch<SetStateAction<QuestionsList[]>>;
}

export default function MultiQuestion({
  setQuestion,
  question,
  questionsList,
  setQuestionsList,
}: Props) {
  //

  // States

  const [quizOptions, setQuizOptions] = useState<PollOption[]>([]);

  // UseEffect
  // useEffect(() => {
  //   // checks if the last input question is being typed and adds new item to the list
  // } , [])

  // Functions

  return (
    <section className='text-sm'>
      <div>
        <p className='mb-2'>QUESTION</p>
        <textarea
          name='question'
          id='question'
          className='h-[110px] w-full resize-none bg-[#F6F3EF] rounded-md p-3'
          placeholder='Type your questions'
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        ></textarea>
      </div>

      <div className='mt-3'>
        <p className='text-[#667084] mb-3'>CREATE YOUR OPTIONS?</p>
        {/* <button className='flex gap-x-3  bg-[#F6F3EF] h-11 items-center w-full px-3 rounded-lg'>
          <img src={quizIconBlue} alt='type another choice' />
          <p>Type another choice</p>
        </button> */}
        <section>
          {questionsList.map((question, index) => (
            <QuestionOption
              questionsList={questionsList}
              question={question}
              index={index}
              setQuestionsList={setQuestionsList}
            />
          ))}
        </section>
        {/* <label
          htmlFor=''
          className='flex gap-x-3 h-11 items-center justify- w-full px-3 rounded-lg border border-[#CBCBCB] mt-2 overflow-hidden'
        >
          <img src={quizIconGreen} alt='type another choice' />
          <input
            type='text'
            className='h-full w-full outline-none border-none'
            placeholder='Type another choice '
          />
          <img src={markIcon} alt='type another choice' />
          <img src={imageIcon} alt='type another choice' />
        </label> */}
      </div>
      <div className='flex gap-x-3 h-11 justify-between items-center w-full px-3 rounded-lg border border-[#EEEEEE] mt-2 overflow-hidden'>
        <p className='text-[#3D4045]'>Show result as percentage</p>

        <label className='inline-flex items-center cursor-pointer'>
          <input
            type='checkbox'
            value=''
            // checked={private_}
            className='sr-only peer'
            // onChange={handleIsPrivate}
          />
          <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
        </label>
      </div>

      <div className='mt-3'>
        <p className='mb-3 text-[#667084]'>DURATION</p>
        <div className='grid grid-cols-2 gap-x-2'>
          <input
            type='number'
            placeholder='Mins'
            className='flex gap-x-3 h-11 items-center w-full pl-3 rounded-lg border border-[#CBCBCB] overflow-hidden'
          />
          <input
            type='number'
            placeholder='Secs'
            className='flex gap-x-3 h-11 items-center w-full pl-3 rounded-lg border border-[#CBCBCB] overflow-hidden'
          />
        </div>
      </div>

      <button className='h-14 w-full bg-black text-white font-medium rounded-lg mt-3'>
        Save
      </button>
    </section>
  );
}
