import { Dispatch, SetStateAction } from 'react';

//assets
import logo from 'assets/svg/fullLogo.svg';
import Folder from './Folder';
import { PreviewPayload } from 'types/Admin/Briefcase/BriefcaseTypes';

// libraries
// import { PreviewPayload }

// interface
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}
interface Props {
  setDisplayDocumentIndex: Dispatch<SetStateAction<number>>;
  displayDocumentIndex: number;
  previewDocs: PreviewPayload[];
  flattedDisplayDocuments: PreviewDocument[];
}

export default function Sidebar({
  setDisplayDocumentIndex,
  displayDocumentIndex,
  previewDocs,
  flattedDisplayDocuments,
}: Props) {
  return (
    <section className='h-screen bg-white border-r border-r-[#E5E7EB] overflow-hidden   w-full'>
      <div className='flex flex-col justify-between h-screen px-1 py-4    w-full'>
        <section className='h-full  w-full'>
          {/* Logo */}
          <div className='px-3'>
            <img src={logo} alt='Presenta Logo' className='w-24' />
          </div>
          {/* Folders */}
          <section className='hide-scrollbar mb-2 mt-4  overflow-hidden overflow-y-auto max-h-[90%] px-3    w-full'>
            {previewDocs &&
              previewDocs.map((doc_) => (
                <Folder
                  doc={doc_}
                  setDisplayDocumentIndex={setDisplayDocumentIndex}
                  key={doc_.id}
                  displayDocumentIndex={displayDocumentIndex}
                  flattedDisplayDocuments={flattedDisplayDocuments}
                />
              ))}
          </section>
        </section>
      </div>
    </section>
  );
}
