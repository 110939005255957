import { PreviewMenu } from "components/UI/mobile/headers/preview-menu";
import { PreviewHeader } from "components/UI/mobile/headers/previewHeader";
import { PreviewMobileWrapper } from "components/UI/wrapper/preview-mobile-wrapper";
import React from "react";
import previeImagePlaceholder from "assets/image/preview-image-placeholder.png";
import ellipsis from "assets/svg/ellipsis-horizontal-gray.svg";
import status from "assets/svg/statusPlaceholder.svg";
import { CButton } from "components/UI/Button/Buttons";
import leftArrow from "assets/svg/leftArrow.svg";
import rightArrow from "assets/svg/rightArrow.svg";
import playBroken from "assets/svg/playBroken.svg";
import copyIcon from "assets/svg/copy.svg";

export const PreviewPresentationMobile = () => {
  return (
    <PreviewMobileWrapper>
      <PreviewHeader />
      <PreviewMenu />
      <ImageContainer />
      <StatusBar />
      <Controls />
      <div className="my-4">
        <textarea
          placeholder="Add a note"
          className="bg-[#F6F3EF] w-full border border-[#E0DDD9] text-[#6B6B6B] min-h-[100px] p-4 rounded-2xl"
        >
          {" "}
        </textarea>
      </div>
      <CButton
        action={() => {}}
        icon={playBroken}
        variant="orangeBg"
        text="Present Now"
        fullWidth
        iconPosition="right"
      />
      <div className="flex mt-2 justify-center cursor-pointer font-semibold gap-2 text-[#3D4045]">
        <p>ThePresenta/meet.com</p>
        <span>
          <img src={copyIcon} alt="" />
        </span>
      </div>
    </PreviewMobileWrapper>
  );
};

const ImageContainer = () => {
  return (
    <>
      <div className="border  my-3 border-[#E2E2E2] h-[200px]  min-h-[250px] rounded-2xl">
        <img src={previeImagePlaceholder} className="w-full h-full" />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-[#FF6929] font-grotesk font-semibold">Slide 1</p>
        <span className="cursor-pointer">
          <img src={ellipsis} alt="ellipsis" />
        </span>
      </div>
    </>
  );
};

const StatusBar = () => {
  return (
    <div className="my-4">
      <img src={status} className="w-full" alt="status" />
    </div>
  );
};

const Controls = () => {
  return (
    <div className="flex items-center justify-between gap-3">
      <CButton
        fullWidth
        action={() => {}}
        text="Previous"
        variant="grey"
        iconPosition="left"
        icon={leftArrow}
      />
      <div className="min-w-[100px]  ">
        <p className="text-[#333333] font-semibold text-center text-xs">
          1 of 8
        </p>
      </div>
      <CButton
        fullWidth
        action={() => {}}
        text="Next"
        variant="grey"
        iconPosition="right"
        icon={rightArrow}
      />
    </div>
  );
};
