import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

//assets
import upload from 'assets/svg/uploadDark.svg';
import uploadWhite from 'assets/svg/upload.svg';

//slices
import {
  addBriefcaseFile,
  getBriefcaseDocuments,
} from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';

//libraries
// import { v4 as uuidv4 } from 'uuid';
import { ClipLoader } from 'react-spinners';
import { toast } from 'sonner';
//components
import ImageUploadPreview from './ImageUploadPreview';

//slices

//utils
import { getFileType } from 'utils/getFileType';
import { isFileTypeAllowed } from 'utils/formatter';

interface FileDetails {
  file: File;
  id: string;
  name: string;
  imgUrl: string;
  size: string;
  mime: string;
}

interface Props {
  closeModal: () => void;
  spaceId: string;
  groupId: string | null;
}
export default function UploadDocuments(props: Props) {
  const { closeModal, spaceId, groupId } = props;

  const dispatch = useDispatch<AppDispatch>();

  //usestate
  const [pptFile, setPptFile] = useState<File | null>(null);
  const [multiFiles, setMultiFiles] = useState<FileDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  );

  //functions
  const deleteUpload = (id: string) => {
    const filteredUpload = multiFiles.filter((upload) => {
      return upload?.id !== id;
    });
    setMultiFiles(filteredUpload);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files_ = e.target.files ? e.target.files : null;
    if (!files_) return;
    const files = Array.from(files_);

    // Clear the file input value to allow selecting the same file again
    if (e.target?.value) {
      e.target.value = '';
    }

    const newUploads = files.reduce<FileDetails[]>((acc, file: File) => {
      const originalFileName = file?.name;
      const sizeInBytes = file?.size;

      // Check if the file type is allowed
      if (!isFileTypeAllowed(file)) {
        toast.error(`${originalFileName}: This file type is not allowed.`);

        return acc;
      }

      let displaySize;
      if (sizeInBytes >= 1024 * 1024) {
        const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
        displaySize = `${sizeInMB} MB`;
      } else {
        const sizeInKB = (sizeInBytes / 1024).toFixed(2);
        displaySize = `${sizeInKB} KB`;
      }

      const data = {
        file,
        id: '',
        name: originalFileName,
        imgUrl: URL.createObjectURL(file),
        size: displaySize?.toLocaleString(),
        mime: getFileType(originalFileName),
      };

      return [...acc, data];
    }, []);

    setMultiFiles([...multiFiles, ...newUploads]);
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setIsButtonActive(true);
    setPptFile(file);
  };

  const submitUpload = async () => {
    if (multiFiles.length < 1) {
      toast.error('Upload a document');
      return;
    }

    setIsLoading(true);
    setIsButtonActive(false);

    // Initialize an object to track progress for each file
    const initialProgressState = multiFiles.reduce<Record<string, number>>(
      (acc, file) => {
        acc[file.id] = 0; // Start each file's progress at 0%
        return acc;
      },
      {}
    );
    setUploadProgress(initialProgressState);

    // Upload each file in parallel
    const uploadPromises = multiFiles.map(async (fileData) => {
      const formData = new FormData();
      formData.append('Title', fileData.name);
      formData.append('File', fileData.file);
      formData.append('IsPrivate', JSON.stringify(false));

      // If groupId is provided, use it; otherwise, use generalFolder
      if (groupId) {
        formData.append('GroupId', groupId);
      }
      // Dispatch the file upload
      return dispatch(addBriefcaseFile({ spaceId, formData }))
        .then((response) => {
          const { type } = response;
          if (type.includes('fulfilled')) {
            toast.success(`${fileData.name} uploaded successfully`);
            dispatch(getBriefcaseDocuments(spaceId));
          } else {
            toast.error(`${fileData.name} failed to upload`);
          }
        })
        .catch((error) => {
          toast.error(`${fileData.name} failed to upload: ${error.message}`);
        });
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    // Reset the states and close the modal
    setIsLoading(false);
    setIsButtonActive(true);
    setUploadComplete(true);
    setPptFile(null);
    closeModal();
    setMultiFiles([]);
    // dispatch(getF)
  };

  return (
    <section className=''>
      <div className='mt-[-10px]'>
        <img src={upload} alt='upload documents' />
      </div>

      <section className='w-[93%] mx-auto my-4 font-grotesk'>
        <div>
          <h1 className='text-2xl font-bold'>Multi file upload</h1>
        </div>
        <label htmlFor='upload-documents'>
          <div
            draggable
            onDrop={(e) => drop(e)}
            onDragOver={(e) => dragOver(e)}
            className='my-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20'
            // onClick={openUploadModal}
          >
            <div className='flex flex-col items-center py-4 text-center'>
              <p>
                {' '}
                <span className='font-semibold text-primary'>
                  Click to upload
                </span>{' '}
                or drag and drop
              </p>
              <p>Powerpoint, JPG or PDF </p>
            </div>

            <input
              hidden
              multiple
              type='file'
              id='upload-documents'
              onChange={handleFileUpload}
            />
          </div>
        </label>

        {/* documents  */}
        {multiFiles?.length > 0 && (
          <section className='flex flex-col mb-4 gap-y-2'>
            {multiFiles?.map((upload) => {
              const { id } = upload ?? {};

              return (
                <ImageUploadPreview
                  key={id}
                  details={upload}
                  multiFiles={multiFiles}
                  deleteUpload={deleteUpload}
                  uploadProgress={uploadProgress[id]} // Pass progress here
                  setMultiFiles={setMultiFiles}
                />
              );
            })}
          </section>
        )}

        {/*  */}
        <button
          disabled={multiFiles.length < 1 || isLoading}
          onClick={submitUpload}
          className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            multiFiles.length > 0 || !isLoading
              ? 'opacity-100'
              : 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isLoading && <ClipLoader size={15} color='white' />}

          {!isLoading && (
            <>
              <img src={uploadWhite} alt='upload slide' className='w-4' />
              <p>Upload multifile</p>
            </>
          )}
        </button>
      </section>
    </section>
  );
}
