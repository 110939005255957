import React, { ReactNode, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { validateToken } from "utils/checkAuthorization";

import { getUserDetails } from "utils/localStorageServices";

export const RedirectFromLogin = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [urlParams, setUrlParams] = useState({});

  // Check if user is logged in , if yes , redirect to the page coming from

  useEffect(() => {
    // Check if user is logged in , if yes , redirect to the previous page

    if (validateToken()) {
      const urlParams = new URLSearchParams(window.location.search);
      const userDetails = getUserDetails();
      const getUrlParam = new URLSearchParams(window.location.search);
      if (urlParams.get("redirect")) {
        if (urlParams.get("app") === "zoom") {
          // window.location.href = `zoommtg://zoom.us/join?action=join&confno=${urlParams.get(
          //   'meetingId'
          // )}&pcmp=${getUserDetails()}`;
          const date = new Date();
          date.setTime(date.getTime() + 24 * 60 * 60 * 1000 );
          let expires = "; Expires=" + date.toUTCString();

          // document.cookie = `name + "=" + value || "" + expires + "; path=/"`;
          const cookieValue = `presenta-token=${userDetails.token}; Path=/; domain=.thePresenta.com; SameSite=None; Secure`;
          document.cookie = cookieValue;
          
        

          window.location.href = `${getUrlParam.get("redirect")}`;
        }
      }
      // navigate("/admin/mypresentations");
    }
  }, [navigate]);

  return children;
};
