import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    presentationData: {
        spaceId: null,
        friendlyId: "",
        title: "",
        thumbnail: "",
        userId: "",
        dateAdded: "",
        expiry: null,
        startTime: "",
        endTime: "",
        scheduledTime: "",
        visibility: false,
        currentSlide: 0,
        allowedDomains: 0,
        passcode: 0,
        status: 0,
        authMode: 0,
        type: 0,
        canDownload: 0,
        questionsAllowed: true,
        deleted: false,
        currentItem: 0,
        duration: 0,
        questions: 0,
        visitors: 0,
        views: 0,
        id: ""
    }
}

export const presentationDSlice = createSlice({
    name: 'presentationDSlice',
    initialState,
    reducers: {
        setPresentationDSlice(state, payload) {
            state.presentationData = payload.payload
        }
    }
})

export const { setPresentationDSlice } = presentationDSlice.actions

export default presentationDSlice.reducer