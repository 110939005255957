import React from "react";

export default function OptionList({ option }) {
  const { id, imgUrl, text, color } = option ?? {};
  return (
    <section>
      <div
        key={id}
        style={{
          color: color?.includes("#fff") || !color ? "#344054" : `white`,

          background: color?.includes("#fff") ? "transparent" : color,
        }}
        className={`  flex items-center w-full px-4 py-2 font-semibold border rounded-full border-gray350 gap-x-2 
                    `}
      >
        <input type="radio" name="id" />
        <p>{text}</p>
      </div>
    </section>
  );
}
