import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  _createFolder,
  _getFolderFiles,
  _getWorkspaceFolders,
  _getAllWorkspace,
  _getSubfolderFiles,
  _deleteFolder,
  _updateFolder,
  _archiveFolder,
  _updateDocument,
  _getDocument,
  _getDocumentVisits,
  _getDocumentAnalytics,
  _getDocumentUsage,
  // _downloadDocument,
} from 'services/Workspace/workspaceServices';

const initialState = {
  status: 'idle',
  allWorkspace: {
    isLoading: 'idle',
    data: null,
  },
  folderFiles: {
    data: {},
    // data: null,
    isLoading: false,
  },

  subfolderFiles: {
    isLoading: false,
    data: null,
  },
  workspaceFolders: {
    isLoading: 'idle',
    data: null,
    tableData: {},
  },
  selectedFiles: [],
  addToBriefcaseModalOpen: false,

  document: {
    isLoading: false,
    data: null,
    visits: [],
    analytics: null,
    usage: [],
  },
};

//GET

export const getAllWorkspace = createAsyncThunk(
  'workspace/getAllWorkspace',
  async () => {
    const response = await _getAllWorkspace();
    return response.data;
  }
);

export const getWorkspaceFolders = createAsyncThunk(
  'workspace/getWorkspaceFolders',
  async (workspaceId) => {
    const response = await _getWorkspaceFolders(workspaceId);
    //hide audio
    const folders = response.data?.filter(
      (doc) => !doc?.title?.includes('vrecord')
    );
    return folders;
  }
);

export const getFolderFiles = createAsyncThunk(
  'workspace/getFolderFiles',
  async (id) => {
    const response = await _getFolderFiles(id);

    return response.data;
  }
);

export const getSubfolderFiles = createAsyncThunk(
  'workspace/getSubfolderFiles',
  async (id) => {
    const response = await _getSubfolderFiles(id);
    return response.data;
  }
);

export const getDocument = createAsyncThunk(
  'workspace/getDocument',
  async (documentId) => {
    const response = await _getDocument(documentId);
    return response.data;
  }
);

export const getDocumentVisits = createAsyncThunk(
  'workspace/getDocumentVisits',
  async (spaceId) => {
    const response = await _getDocumentVisits(spaceId);
    return response.data;
  }
);
export const getDocumentAnalytics = createAsyncThunk(
  'workspace/getDocumentAnalytics',
  async (spaceId) => {
    const response = await _getDocumentAnalytics(spaceId);
    return response.data;
  }
);
export const getDocumentUsage = createAsyncThunk(
  'workspace/getDocumentUsage',
  async (spaceId) => {
    const response = await _getDocumentUsage(spaceId);
    return response.data;
  }
);

//POST

export const createFolder = createAsyncThunk(
  'workspace/createFolder',
  async (data) => {
    const response = await _createFolder(data);
    return response.data;
  }
);

//PUT

export const updateFolder = createAsyncThunk(
  'workspace/updateFolder',
  async ({ folderId, newTitle }) => {
    const response = await _updateFolder(folderId, newTitle);
    return response.data;
  }
);

export const updateDocument = createAsyncThunk(
  'workspace/updateDocument',
  async (data) => {
    const response = await _updateDocument(data);
    return response.data;
  }
);

// export const downloadDocument = createAsyncThunk(
//   'workspace/downloadDocument',
//   async (documentId) => {
//     const response = await _downloadDocument(documentId);
//     return response.data;
//   }
// );

//DELETE
export const archiveFolder = createAsyncThunk(
  'workspace/archiveFolder',
  async (folderId) => {
    const response = await _archiveFolder(folderId);
    return response.data;
  }
);

export const deleteFolder = createAsyncThunk(
  'workspace/deleteFolder',
  async (folderId) => {
    const response = await _deleteFolder(folderId);
    return response.data;
  }
);

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    clearFolderFiles(state, { payload }) {
      if (payload) {
        state.folderFiles.data.payload = null;
      } else {
        state.folderFiles = {};
      }
    },
    setSelectedFiles(state, { payload }) {
      state.selectedFiles = payload;
    },
    setAddToBriefcaseModalOpen(state, { payload }) {
      state.addToBriefcaseModalOpen = payload;
    },
  },
  extraReducers: (builder) => {
    // All workspaces
    builder
      .addCase(getAllWorkspace.pending, (state) => {
        state.allWorkspace.isLoading = true;
      })
      .addCase(getAllWorkspace.fulfilled, (state, { payload }) => {
        state.allWorkspace.data = payload;
        state.allWorkspace.isLoading = false;
      })
      .addCase(getAllWorkspace.rejected, (state, { payload }) => {
        state.allWorkspace.isLoading = false;
      });

    // workspace folders
    builder
      .addCase(getWorkspaceFolders.pending, (state, action) => {
        state.workspaceFolders.isLoading = true;
        const workspaceId = action.meta.arg;
        state.workspaceFolders.tableData[workspaceId] = {
          isLoading: true,
          files: null,
        };
      })
      .addCase(getWorkspaceFolders.fulfilled, (state, action) => {
        state.workspaceFolders.data = action.payload?.filter((folder) => {
          return !folder?.title?.includes('vrecords');
        });

        state.workspaceFolders.isLoading = false;

        const workspaceId = action.meta.arg;
        state.workspaceFolders.tableData[workspaceId] = {
          isLoading: false,
          files: action.payload,
        };
      })
      .addCase(getWorkspaceFolders.rejected, (state, { payload }) => {
        state.workspaceFolders.isLoading = false;
      });

    //All folder files
    builder
      .addCase(getFolderFiles.pending, (state, action) => {
        state.folderFiles.isLoading = true;

        const folderId = action.meta.arg;

        state.folderFiles.data[folderId] = { isLoading: true, files: null };
      })
      .addCase(getFolderFiles.fulfilled, (state, action) => {
        const sortedDocuments = action.payload?.documents?.sort((a, b) => {
          const unixTimeA = Date.parse(a.dateModified); // Convert to milliseconds since the UNIX epoch
          const unixTimeB = Date.parse(b.dateModified);

          return unixTimeB - unixTimeA; // Sort in ascending order
        });

        state.folderFiles.isLoading = false;
        // state.folderFiles.data = payload;

        const folderId = action.meta.arg;
        state.folderFiles.data[folderId] = {
          isLoading: false,
          files: { ...action.payload, documents: sortedDocuments },
        };
      })
      .addCase(getFolderFiles.rejected, (state, { payload }) => {
        state.folderFiles.isLoading = false;
      });

    //All subfolder files
    builder
      .addCase(getSubfolderFiles.pending, (state) => {
        state.subfolderFiles.isLoading = true;
      })
      .addCase(getSubfolderFiles.fulfilled, (state, { payload }) => {
        state.subfolderFiles.isLoading = false;
        state.subfolderFiles.data = payload;
      });

    // getDocument
    builder
      .addCase(getDocument.pending, (state) => {
        state.document.isLoading = true;
        state.document.data = null;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.document.isLoading = false;
        state.document.data = action.payload;
      })
      .addCase(getDocument.rejected, (state) => {
        state.document.isLoading = false;
      });

    // get document visits
    builder
      .addCase(getDocumentVisits.pending, (state) => {
        state.document.isLoading = true;
        state.document.visits = [];
      })
      .addCase(getDocumentVisits.fulfilled, (state, action) => {
        state.document.isLoading = false;
        state.document.visits = action.payload;
      })
      .addCase(getDocumentVisits.rejected, (state) => {
        state.document.isLoading = false;
      });

    // get document analytics
    builder
      .addCase(getDocumentAnalytics.pending, (state) => {
        state.document.isLoading = true;
        state.document.analytics = null;
      })
      .addCase(getDocumentAnalytics.fulfilled, (state, action) => {
        state.document.isLoading = false;
        state.document.analytics = action.payload;
      })
      .addCase(getDocumentAnalytics.rejected, (state) => {
        state.document.isLoading = false;
      });

    // get document usage
    builder
      .addCase(getDocumentUsage.pending, (state) => {
        state.document.isLoading = true;
        state.document.usage = [];
      })
      .addCase(getDocumentUsage.fulfilled, (state, action) => {
        state.document.isLoading = false;
        state.document.usage = action.payload;
      })
      .addCase(getDocumentUsage.rejected, (state) => {
        state.document.isLoading = false;
      });
  },
});

export const getWorkspaceState = (state) => state.workspace;
export const {
  clearFolderFiles,
  setSelectedFiles,
  setAddToBriefcaseModalOpen,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
