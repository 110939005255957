import Axios from 'lib/axiosInterceptor';

//get
export const _getAllWorkspace = () => {
  return Axios.get(`/Document/Workspaces`);
};

export const _getWorkspaceFolders = (workspaceId) => {
  return Axios.get(`/Document/Folder?workspaceId=${workspaceId}`);
};

export const _getFolderFiles = (id) => {
  return Axios.get(`/Document/Folder?id=${id}`);
};
export const _getSubfolderFiles = (id) => {
  return Axios.get(`/Document/Folder?id=${id}`);
};

export const _getDocumentFiles = (id) => {
  return Axios.get(`/Document/${id}`);
};

export const _getDocument = (documentId) => {
  return Axios.get(`/Document/${documentId}`);
};

export const _getDocumentVisits = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Visits/${documentId}`);
};

export const _getDocumentAnalytics = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Analytics/${documentId}`);
};
export const _getDocumentUsage = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Usage/${documentId}`);
};

// export const _downloadDocument = (documentId) => {
//   return Axios.get(`Document/Download/${documentId}`, {
//     responseType: 'blob',
//   });
// };
// export const _downloadDocument = (documentId) => {
//   return Axios.get(`Document/Download/${documentId}`, {
//     responseType: 'arraybuffer',
//   });
// };

//post
export const _createFolder = ({ data, config }) => {
  return Axios.post(`/Document/Folder`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...(config.headers || {}),
    },
  });
};

//put
export const _updateFolder = (folderId, newTitle) => {
  return Axios.post(`/Document/Folder/Rename/${folderId}?newTitle=${newTitle}`);
};

export const _updateDocument = (data) => {
  return Axios.post(`/Document/Update`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

//delete
export const _deleteFolder = (folderId) => {
  return Axios.post(`/Document/Folder/Delete/${folderId}`);
};

export const _archiveFolder = (folderId) => {
  return Axios.post(`/Document/Folder/Delete/${folderId}?archive=true`);
};
