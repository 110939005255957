import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import TeamList from "./Table/TeamList";
import Loader from "components/UI/Loader";
import AdminNavbar from "components/UI/AdminLayout/Navbar";
import UpgradeAccount from "components/Admin/Team/UpgradeAccount";
import {
  getMyPlans,
  getPaymentState,
  getPlans,
} from "store/Payment/paymentSlice";
import { getTeamMembers, getTeamsState } from "store/Teams/teamsSlice";
import { Modal } from "antd";
import InviteTeamMember from "./Modal/InviteTeamMember";

export default function Team() {
  const dispatch = useDispatch();
  const inviteTeamRef = useRef();

  //useselector
  const { teamMembers } = useSelector(getTeamsState);
  const { myPlans } = useSelector(getPaymentState);

  //usestates
  const [teams, setTeams] = useState([]);
  const [navProps, setNavProps] = useState({});
  const [isSubbed, setIsSubbed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  //useeffects
  useEffect(() => {
    dispatch(getMyPlans());
    dispatch(getPlans());
  }, [dispatch])
  
  useEffect(() => {
    const {plan} = myPlans.data ?? {}
    setNavProps({
      title: "Team",
      subTitle: `Upload and share resources with your team`,
      modalTitle: plan !== 'Basic' && "Add Team Member",
      func: () => window.dispatchEvent(new CustomEvent("openInviteTeamModal")),
    });
  
  }, [dispatch, myPlans]);

  useEffect(() => {
    const handleEditEvent = () => {
      openInviteTeamModal();
    };

    window.addEventListener("openInviteTeamModal", handleEditEvent);

    return () => {
      window.removeEventListener("openInviteTeamModal", handleEditEvent);
    };
  }, []);

  useEffect(() => {
    const plan = myPlans.data?.plan?.toLowerCase();

    if (plan === "basic") {
      setIsSubbed(false);
      setIsLoading(myPlans.isLoading);
    } else {
      setIsSubbed(true);
      dispatch(getTeamMembers());
    }
  }, [myPlans, dispatch]);

  useEffect(() => {
    setIsLoading(teamMembers.isLoading);
    const myTeam = teamMembers.data;
    if (myTeam) {
      setTeams(myTeam);
    }
  }, [teamMembers]);

  //functions
  const openInviteTeamModal = () => {
    setIsInviteModalOpen(true);
  };
  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    inviteTeamRef.current?.resetModal();
  };

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="mt-20">
        {/* loader */}
        {isLoading && <Loader />}
        {/*  */}

        {!isLoading && (
          <section>
            {!isSubbed && <UpgradeAccount />}
            {isSubbed && <TeamList teams={teams} />}
          </section>
        )}
      </section>

      {/* modal  */}
      <>
        <Modal
          open={isInviteModalOpen}
          onCancel={handleCloseInviteModal}
          footer={null}
        >
          <InviteTeamMember
            ref={inviteTeamRef}
            closeModal={handleCloseInviteModal}
          />
        </Modal>
      </>

      {/*  */}
    </section>
  );
}
