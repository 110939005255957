import Axios from 'lib/axiosInterceptor';

//get
export const _getTeamInfo = () => {
  return Axios.get(`/MyTeam`);
};

export const _getTeamMembers = () => {
  return Axios.get(`/Team/Members`);
};

//post

export const _inviteTeam = (data) => {
  return Axios.post('/Team/Invite', data);
};

export const _acceptTeamInvite = (data) => {
  return Axios.post('/AcceptInvite', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const _removeTeamMember = (data) => {
  const { id, teamId } = data ?? {};
  return Axios.post(`/Team/Members/Remove/${id}?teamId=${teamId}`, {});
};

export const _getSlideStream = (id) => {
  return Axios({
    url: `/Document/stream/${id}`,
    method: 'GET',
    responseType: 'blob', // Get the response as a blob
    headers: {
      Accept: 'image/jpeg',
    },
  }).catch((err) => {
    console.log(err, 'stream error');
  });
};
