import { toast } from "react-toastify";

export const GenericInvoker = (
  connection,
  presentationId,
  presentaContextId,
  eventName,
  object
) => {
  const state = connection?.state;
  if (state === "Connected") {
   
    connection
      .invoke("Generic", presentationId, presentaContextId, eventName, object)
      .catch((err) => {
        toast.error(`An error occurred invoking '${eventName}' on the server`);
      });
  }
};
