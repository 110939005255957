export const plans = [
  {
    price: "$120",
    duration: "month",
    title: "Agency",
    description: "Personal + Extended Dataroom +Team Scheduler",
  },

  {
    price: "$40",
    duration: "month",
    title: "Business",
    description: "Personal + Full Dataroom+ Full Scheduler",
  },

  {
    price: "$10",
    duration: "month",
    title: "Personal",
    description: "Dataroom + Individual Scheduler",
  },

  {
    price: "$0",
    title: "Basic",
    description: "File, Presentation",
  },
];

export const planList = [
  {
    title: "Additional user",
    agency: "$30/month or $250/year",
    business: "-",
    personal: "$30/month or $250/year",
    basic: "$30/month or $250/year",
  },

  {
    title: "Pool Storage",
    agency: "40GB/User",
    business: "30GB/User",
    personal: "10GB/User",
    basic: "200mb/User",
  },

  {
    title: "Individual file limit",
    agency: "2GB",
    business: "2GB",
    personal: "2GB",
    basic: "200mb",
  },
];

export const meetingPlanList = [
  {
    title: "Number of users",
    agency: "5",
    business: "3",
    personal: "1",
    basic: "1",
  },

  {
    title: "Attention Metrics",
    agency: "Summary, document and participant",
    business: "Summary, document and participant",
    personal: "Summary and file level",
    basic: "No",
  },

  {
    title: "Full Presentation Download Option",
    agency: "Same as pro",
    business: "Same as personal",
    personal: "Editable powerpoint, readonly powerpoint, image, pdf, svg",
    basic: "Exact presentation file",
  },

  {
    title: "Watermarked downloads",
    agency: "Readonly options(PDF, Images)",
    business: "Readonly options(PDF, Images)",
    personal: "Readonly options(PDF, Images)",
    basic: "No",
  },

  {
    title: " Guest List",
    agency: "500",
    business: "100",
    personal: "50",
    basic: "10",
  },

  {
    title: "Turn off advert",
    agency: "Unlimited",
    business: "First 100 guests",
    personal: "First 50 guests",
    basic: "First 25 guests",
  },
];
