import React, { ReactNode } from "react";
import camera from "assets/svg/cameraIcon.svg";
import lines from "assets/image/briefcaselayerlines.png";
import imagePlaceholder from "assets/image/pexels-souvenirpixels-417074.jpg";
import { useDispatch } from "react-redux";
import { setThumbnail } from "store/Slides/slidesSlice";
import { useToast } from "components/UI/ToastProvider";
import { AppDispatch } from "store/store";
import { toast } from "sonner";

export const BannerImageWrapper = ({
  children,
  bgImage,
  handleSetBannerImage,
  id,
}: {
  children: ReactNode;
  bgImage: string;
  handleSetBannerImage: (val: string) => void;
  id: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const updateThumbnail = (e: React.FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
    if (!file) {
      return;
    }

    // Store the current thumbnail before updating
    const previousThumbnailImg = bgImage;

    // Optimistic UI update
    const objectUrl = URL.createObjectURL(file);
    handleSetBannerImage(objectUrl);

    const data = {
      file,
      presentationId: id,
    };

    // Clear the file input value to allow selecting the same file again
    if (e.currentTarget?.value) {
      e.currentTarget.value = "";
    }

    dispatch(setThumbnail(data)).then(({ type }) => {
      if (type.includes("fulfilled")) {
        toast.success("Thumbnail updated successfully");
      } else {
        // Handle error by reverting to the previous image
        handleSetBannerImage(previousThumbnailImg);
      }
    });
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          objectFit: "contain",
        }}
        className="min-h-[120px] cursor-pointer relative p-4 pl-8 h-[120px]  max-h-[120px] border border-[#E2E2E2] rounded-xl"
      >
        <span>
          <input
            onChange={updateThumbnail}
            type="file"
            className="border absolute top-4 left-6 cursor-pointer opacity-0 w-[40px]"
          />
        </span>
        <img src={camera} />
      </div>
      <div className="mx-4 min-h-[100px] overflow-hidden relative -top-[50px] z-10 bg-[#FEFEFD] p-4 rounded-lg border border-[#F6F3EF] ">
        <img src={lines} className="absolute right-0 top-0" />
        <div>{children}</div>
      </div>
    </div>
  );
};
