import MainContent from 'components/Admin/Polls/MainContent';
import Navbar from 'components/Admin/Polls/Navbar';
import Sidebar from 'components/Admin/Polls/Sidebar';
import { useEffect, useState } from 'react';
import { Poll, QuestionsList } from 'interfaces/PollSetup';
import { localStorageSetter } from 'utils/localStorageServices';

export default function PollSetup() {
  const newPoll = {
    pollType: '',
    question: '',
    questionsList: [],
    duration: {
      mins: '',
      secs: '',
    },
    settings: {
      result_percentage: false,
    },
  };
  const [polls, setPolls] = useState<Poll[]>([newPoll]);
  const [currentDisplayedPoll, setCurrentDisplayedPoll] = useState<Poll>(
    polls[0]
  );
  const [currentDisplayedPollIndex, setCurrentDisplayedPollIndex] =
    useState<number>(0);

  useEffect(() => {
    localStorageSetter('polls', polls);
  }, [polls]);

  return (
    <section className='w-full relative bg-[#F2F2F2]'>
      {/* Navbar */}
      <Navbar />
      {/* Sidebar */}
      <Sidebar
        polls={polls}
        currentDisplayedPollIndex={currentDisplayedPollIndex}
        setCurrentDisplayedPollIndex={setCurrentDisplayedPollIndex}
        setPolls={setPolls}
      />
      {/* main content */}
      <MainContent
        polls={polls}
        currentDisplayedPollIndex={currentDisplayedPollIndex}
        setCurrentDisplayedPollIndex={setCurrentDisplayedPollIndex}
      />
    </section>
  );
}
