// Assets
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import eyeIcon from 'assets/svg/eye.svg';
import copyIcon from 'assets/svg/copy-1.svg';
import trash from 'assets/svg/trash.svg';
import engagement from 'assets/svg/engagement-icon.svg';
import edit from 'assets/svg/edit-2.svg';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { TableEmpty } from 'components/UI/States/table/TableEmpty';
import { toast } from 'sonner';
import { Drawer, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import EditBriefcase from '../Modals/EditBriefcaseLink';
import EditBriefcaseLink from '../Modals/EditBriefcaseLink';
import {
  getPresentationDetailsAuth,
  getShareHistory,
  updatePresentation,
} from 'store/Briefcase/briefcaseSlice';
import IsActive from 'components/Admin/BriefCase/isActive';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

interface ShareHistoryInterface {
  id: string;
  spaceId: string;
  title: string;
  friendlyId: string;
  dateCreated: string; // ISO date string
  views: number;
  published: boolean;
  isPrivate: boolean;
  isActive: boolean;
}

export default function ShareHistory({ spaceId }: { spaceId: string }) {
  const dispatch = useDispatch<AppDispatch>();

  //Types

  // States
  const shareHistory = useSelector(
    (state: RootState) => state.briefcase.briefcaseHistory.shareHistory
  );
  const [linkIndex, setLinkIndex] = useState<number>(0);
  const [openEditBriefcaseModal, setOpenEditBriefcaseModal] = useState(false);
  const [presentationId, setPresentationId] = useState<string | null>(null);

  const copyLink = async (friendlyId: string) => {
    let routeOrigin = window.location.origin;
    let text = `${routeOrigin}/access-shared-presentation/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(text);

      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const onCloseEditBriefcaseModal = () => {
    setOpenEditBriefcaseModal(false);
  };

  const handleOptionsClick = (index: number, action: string) => {
    if (action === 'edit') {
      console.log('Edit');
      setPresentationId(shareHistory[index].id);
      setOpenEditBriefcaseModal(true);
    }
  };

  // For handling active links
  const [presentationId_, setPresentationId_] = useState<string | null>(null);
  const [presentationActiveState, setPresentationActiveState] = useState<
    null | boolean
  >(null);
  const presentationDetails = useSelector(
    (state: RootState) => state.briefcase.presentationDetails.data?.presentation
  );

  useEffect(() => {
    if (presentationId_) {
      dispatch(getPresentationDetailsAuth(presentationId_)).then(() => {
        setPresentationId_(null);
      });
    }
  }, [dispatch, presentationId_]);

  useEffect(() => {
    if (presentationDetails) {
      console.log(presentationDetails, presentationActiveState);
      const shareLinkItems = {
        title: presentationDetails.title,
        spaceId: presentationDetails.spaceId,
        startTime: presentationDetails?.startTime ?? '',
        expiry: presentationDetails?.expiry ?? '',
        canDownload: presentationDetails.canDownload,
        type: presentationDetails.type,
        openLink: false,
        domains: presentationDetails.allowedDomains?.split(',') ?? [],
        passcode: presentationDetails.passcode,
        authMode: 1,
        watermark: '',
      };
      interface UpdatePresentation {
        expiry?: string;
        canDownload?: number;
        authMode?: number;
        title?: string;
        startTime?: string;
        passcode?: string;
        watermark?: string;
        allowQuestion?: boolean;
      }

      const data: UpdatePresentation = {
        title: shareLinkItems.title,
      };

      if (presentationActiveState) {
        data.expiry = dayjs
          .utc(`${new Date()}`)
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setPresentationActiveState(false);
      } else {
        setPresentationActiveState(true);
      }

      if (shareLinkItems.canDownload === 1) {
        data.canDownload = 1;
      } else if (shareLinkItems.canDownload === 0) {
        data.canDownload = 0;
      }

      if (shareLinkItems.authMode) {
        data.authMode = shareLinkItems.authMode;
      }
      if (shareLinkItems.startTime) {
        data.startTime = shareLinkItems.startTime;
      }
      if (shareLinkItems.passcode) {
        data.passcode = shareLinkItems.passcode;
      }
      if (shareLinkItems.watermark) {
        data.watermark = shareLinkItems.watermark;
      }

      presentationId_ &&
        dispatch(
          updatePresentation({
            presentationId: presentationId_,
            data,
          })
        ).then((response) => {
          const { type } = response;
          if (type.includes('fulfilled')) {
            toast.success('Updated successfully!');
            dispatch(getShareHistory(spaceId));
          } else {
            toast.error('Update failed!');
          }
        });
    }
  }, [presentationDetails]);

  //variables
  const items = [
    {
      key: '1',
      action: 'engagement',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={engagement} alt='rename file' className='w-5' />
          <p>Engagement metrics</p>
        </div>
      ),
    },

    {
      key: '2',
      action: 'edit',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={edit} alt='access control' className='w-5' />
          <p>Settings</p>
        </div>
      ),
    },

    {
      key: '3',
      action: 'delete',
      label: (
        <div className='flex gap-x-2 cursor-pointer pl-2 pr-5'>
          <img src={trash} alt='delete file' className='w-5' />
          <p>Delete link</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className=''>
            <section className='flex items-center gap-x-4'>
              <p className='text-black text-sm'>{rowData?.title}</p>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Links',
      field: 'links',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <div className='flex gap-x-2 items-center'>
            <p className='text-sm text-black italic'>
              thepresenta.com/access...
            </p>
            <img
              src={copyIcon}
              alt='copy'
              onClick={() => copyLink(rowData.friendlyId)}
              className='cursor-pointer'
            />
          </div>
        );
      },
    },
    {
      header: 'Visits',
      field: 'visits',
      sortable: true,
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className='w-full flex gap-x-2'>
            <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2'>
              <img src={eyeIcon} alt='profile' className='' />
              <div className='text-sm font-medium capitalize'>
                <p className='text-sm text-black'>{rowData.views}</p>
              </div>
            </div>
          </section>
        );
      },
    },

    {
      header: 'Date created',
      field: 'dateCreated',
      body: (rowData: ShareHistoryInterface) => {
        const relativeTime = moment.utc(rowData.dateCreated).fromNow();

        return (
          <div className='flex'>
            <p className='text-black text-sm'>{relativeTime}</p>
          </div>
        );
      },
    },

    {
      header: 'Published',
      field: 'published',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className='flex gap-x-5 items-center'>
            <p className='text-sm text-black'>Active</p>
            <IsActive
              isActive={rowData.isActive}
              spaceId={spaceId}
              presentationId={rowData.id}
            />
            {/* <label
              className='inline-flex items-center cursor-pointer'
              onClick={() => {
                console.log('Active btn clicked!');
                setPresentationId_(rowData.id);
                setPresentationActiveState(rowData.isActive);
              }}
            >
              <input
                type='checkbox'
                value=''
                checked={rowData.isActive}
                className='sr-only peer'
              />
              <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
            </label> */}
          </section>
        );
      },
    },

    {
      header: '',
      sortable: true,
      body: (rowData: ShareHistoryInterface, options: { rowIndex: number }) => {
        return (
          <div className='w-[100px] flex justify-end'>
            <div className='cursor-pointer flex items-center gap-x-5'>
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {items.map((item) => {
                        return (
                          <Menu.Item
                            key={item.key}
                            onClick={() => {
                              setLinkIndex(options.rowIndex);
                              handleOptionsClick(options.rowIndex, item.action);
                            }}
                          >
                            {item.label}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className='cursor-pointer py-1'>
                    <img src={ellipsis} alt='options' />
                  </div>
                </Dropdown>
              </section>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <section>
      {/* Table */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          value={shareHistory}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
          emptyMessage={TableEmpty}
          dataKey='id'
        >
          <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-+[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
      {/* )} */}

      {presentationId && (
        <Drawer
          placement='right'
          closable={false}
          onClose={onCloseEditBriefcaseModal}
          open={openEditBriefcaseModal}
          width={468}
          destroyOnClose
        >
          <EditBriefcaseLink
            presentationId={presentationId}
            spaceId={spaceId}
            onCloseEditBriefcaseModal={onCloseEditBriefcaseModal}
          />
        </Drawer>
      )}
    </section>
  );
}
