import { useEffect, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import getColorfulBgClass from 'utils/randomBgColour';
import { getInitials } from 'utils/formatter';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
// import { getUserImage } from 'store/Briefcase/briefcaseSlice';
import Axios from 'lib/axiosInterceptor';

interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
}

interface Props {
  member: Member;
  index: number;
}

export default function AvaterImage({ member, index }: Props) {
  const { backgroudColor } = getColorfulBgClass(index);
  const dispatch = useDispatch<AppDispatch>();
  const [profilePic, setProfilePic] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await Axios.get(
          `/Account/UserImage/${member.memberId}`,
          {
            responseType: 'blob',
          }
        );

        if (response) {
          const image =
            response.data?.size > 0
              ? URL.createObjectURL(new Blob([response.data]))
              : null;
          setProfilePic(image);
        } else {
          setProfilePic(null);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfileImage();
    // dispatch(getUserImage(member.memberId)).then(({ type, payload }) => {
    //   if (type.includes('fulfilled')) {
    //     const image =
    //       payload?.size > 0 ? URL.createObjectURL(new Blob([payload])) : null;
    //     setProfilePic(image);
    //   }
    // });
  }, []);

  if (profilePic) {
    return (
      <Avatar
        shape='circle'
        size='normal'
        key={index}
        image={profilePic}
        style={{
          backgroundColor: backgroudColor,
          color: 'white',
        }}
      />
    );
  } else {
    return (
      <Avatar
        label={getInitials(member.fullName ?? '')}
        shape='circle'
        size='normal'
        key={index}
        style={{
          backgroundColor: backgroudColor,
          color: 'white',
        }}
      />
    );
  }
}
