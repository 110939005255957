export const SummarizeText = (
  toast,
  connection,
  presentationId,
  questionId,
  content
) => {
  if (connection) {
    console.log(presentationId, questionId, content)
    connection
      .invoke("Summarize", presentationId, questionId, content)
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error occured while calling the 'Summarize' event",
        });
      });
  }
};
