import { toast } from "sonner";
import { getConnectionId } from "utils/localStorageServices";

export const RecordTimeline = (
  connection,
  leavingSlideId,
  source,
  timeSpent,
  previousSlideId
) => {
  if (connection) {
    const presentationId = localStorage.getItem("presentationId");

    console.log(
      
      leavingSlideId,
      source,
      timeSpent,
      previousSlideId
    );

    connection
      .invoke(
        "RecordTimeline",
        presentationId,
        getConnectionId(),
        leavingSlideId,
        source,
        timeSpent,
        previousSlideId
      )
      .catch((err) => {
        // toast.error("An error occured while recording timeline");
        console.log(err);
      });
  }
};
