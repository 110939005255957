import Axios from "lib/axiosInterceptor";

//get

export const _getPlans = () => {
  return Axios.get(`/Payments/Plans`);
};

export const _getPointBalance = () => {
  return Axios.get(`/Payments/PointBalance`);
};

export const _getMyPlans = () => {
  return Axios.get(`/Payments/MyPlan`);
};

export const _getPlanHistory = () => {
  return Axios.get(`/Payments/PlanHistory`);
};
export const _getPointHistory = () => {
  return Axios.get(`/Payments/PointTransactions`);
};




//post
export const _cancelSubscription = () => {
  return Axios.post(`/Payments/CancelSubscription`, {});
};
export const _beginPayment = (data) => {
  return Axios.post(`/Payments/PaymentId`, data);
};

export const _beginSubscriptionPayment = (planId) => {
  return Axios.post(`/Payments/Subscribe?planId=${planId}`);
};

export const _convertPointAmount = (data) => {
  const { value, to } = data ?? {};
  return Axios.get(`/Payments/PointConversion?value=${value}&to=${to}`, data);
};
