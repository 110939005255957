import { useEffect } from 'react';

export const useJoinPresentationEventResponse = (connection, eventHandler) => {
  useEffect(() => {
    const handleEventResponse = (
      id,
      eventName,
      status,
      message,
      presenterConnectionId
    ) => {
      console.log(
        id,
        eventName,
        status,
        message,
        presenterConnectionId,
        'join presentation response'
      );
      try {
        eventHandler(id, eventName, status, message, presenterConnectionId);
      } catch (error) {
        console.error('Error handling event response:', error);
      }
    };

    if (connection) {
      connection.on('EventResponse', handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off('EventResponse', handleEventResponse);
      }
    };
  }, [connection, eventHandler]);
};
