import {toast} from "sonner"
import {ReactNode} from "react";

export const CopyToClipBoard = ({children, text}: { children: ReactNode, text: string }) => {
    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Copied!")
        } catch (e) {
            toast.error("Unable to copy")
        }
    }
    return <>
        <div className={"cursor-pointer"} onClick={copyLink}>
            {children}
        </div>
    </>
}