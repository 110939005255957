//Assets
import arrowLeft from 'assets/svg/arrow-left-circle.svg';
import arrowLeftGray from 'assets/svg/arrow-left-circle-gray.svg';
import arrowRight from 'assets/svg/arrow-right-circle.svg';
import arrowRightGray from 'assets/svg/arrow-right-circle-gray.svg';

interface Props {
  screenWidth: number;
  isSlides: boolean;
  slides: any;
  slideDocumentIndex: number;
  handlePrevSlideDoc: () => void;
  handleNextSlideDoc: () => void;
  displayDocumentIndex: number;
  handlePrevDoc: () => void;
  flattedDisplayDocuments: any;
  handleNextDoc: () => void;
}

export default function Controller({
  screenWidth,
  isSlides,
  slides,
  slideDocumentIndex,
  handlePrevSlideDoc,
  displayDocumentIndex,
  flattedDisplayDocuments,
  handleNextSlideDoc,
  handlePrevDoc,
  handleNextDoc,
}: Props) {
  return (
    <section>
      {isSlides && slides.length > 0 ? (
        <section className='flex items-center gap-x-5'>
          <div
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg ${
              slideDocumentIndex === 0
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }`}
            onClick={handlePrevSlideDoc}
          >
            <img
              src={slideDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
              alt='previous slide'
            />
            <p className=''>Previous slide</p>
          </div>

          <div className='bg-[#FFD1BD] rounded-[20px] text-black px-2 h-[44px] flex items-center '>
            <p className='font-medium'>{`${slideDocumentIndex + 1} of ${
              slides.length
            }`}</p>
          </div>

          <div
            className='bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg cursor-pointer'
            onClick={handleNextSlideDoc}
          >
            <p>Next slide</p>
            <img
              src={
                slideDocumentIndex === slides.length - 1
                  ? arrowRightGray
                  : arrowRight
              }
              alt='next slide'
            />
          </div>
        </section>
      ) : (
        <section className='flex items-center gap-x-5'>
          <div
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg ${
              displayDocumentIndex === 0
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }`}
            onClick={handlePrevDoc}
          >
            <img
              src={displayDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
              alt='previous slide'
            />
            <p className='pointer-events-none'>Previous slide</p>
          </div>

          <div className='bg-[#FFD1BD] rounded-[20px] px-2 h-[44px] flex items-center'>
            <p className='font-medium'>{`1 of 1`}</p>
          </div>

          <div
            className={`bg-[#F5F5F5] flex gap-x-3 px-5 h-9 items-center rounded-lg cursor-pointer ${
              displayDocumentIndex === flattedDisplayDocuments.length - 1
                ? 'cursor-default text-[#E2E2E2]'
                : 'cursor-pointer text-black'
            }
                    `}
            onClick={handleNextDoc}
          >
            <p>Next slide</p>
            <img
              src={
                displayDocumentIndex === flattedDisplayDocuments.length - 1
                  ? arrowRightGray
                  : arrowRight
              }
              alt='next slide'
            />
          </div>
        </section>
      )}
    </section>
  );
}
