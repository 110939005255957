import { useEffect } from "react";

export const useViewerModeEventResponse = (connection, eventHandler) => {
  useEffect(() => {
    const handleEventResponse = (
      eventName,
      status,
      message,
      presenterConnectionId
    ) => {
      eventHandler(eventName, status, message, presenterConnectionId);
    };
    if (connection) {
      connection.on("EventResponse", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off("EventResponse", handleEventResponse);
      }
    };
  }, [connection, eventHandler]);
};
