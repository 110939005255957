import { useEffect, useState } from 'react';

// components

//assets
// import clock from 'assets/svg/clock-icon.svg';
// import ChevronDownBlack from 'assets/svg/chevron-down-black.svg';
// import lines from 'assets/image/briefcaselayerlines.png';
// import exportIconBlack from 'assets/svg/export-icon-black.svg';
import pptImg from 'assets/image/ppt.png';
import pdfImg from 'assets/image/pdf-main.png';

// Libraries
// import { AvatarGroup } from 'primereact/avatargroup';
// import { Avatar } from 'primereact/avatar';
// import FileVisits from './Tables/FileVisits';
// import FileAnalytics from './Tables/FileAnalytics';
// import FileUsage from './Tables/FileUsage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  getBriefcase,
  getBriefcaseDocument,
  getDocumentAnalytics,
  getDocumentPerformanceSummary,
  getDocumentUsage,
  getDocumentVisits,
} from 'store/Briefcase/briefcaseSlice';
import { imageStream } from 'utils/imageStream';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
// import moment from 'moment';
// import { getInitials } from 'utils/formatter';
// import getColorfulBgClass from 'utils/randomBgColour';
import DocumentDetails from 'components/Admin/Workspace/DocumentDetails';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

// types
interface TeamInfoData {
  id: string;
}

export default function BriefcaseFileDetails() {
  //
  // let [searchParams] = useSearchParams();
  const { spaceId, fileId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { teamInfo } = useSelector((state: RootState) => state.teams);
  const briefcaseDocument = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.data
  );

  const briefcase = useSelector(
    (state: RootState) => state.briefcase.briefcase.data
  );
  // const [filePreview, setFilePreview] = useState<string | null>(null);

  // Briefcase File details ===>
  const briefcaseDocumentUsage = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.usage
  );
  const briefcaseDocumentAnalytics = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.analytics
  );
  const briefcaseDocumentVisits = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.visits
  );
  const briefcaseDocumentPerformance = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.performance
  );

  // ====>

  useEffect(() => {
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.id;
      dispatch(getBriefcase({ spaceId, teamId }));
    }

    if (spaceId && fileId) {
      const documentId = fileId;
      dispatch(getBriefcaseDocument(documentId));
      dispatch(getDocumentAnalytics({ spaceId, documentId }));
      dispatch(getDocumentPerformanceSummary({ spaceId, documentId }));
      dispatch(getDocumentVisits({ spaceId, documentId }));
      dispatch(getDocumentUsage({ spaceId, documentId }));
    }
  }, []);

  // States
  // const [tab, setTab] = useState(0);
  // For getting document stream
  //  useEffect(() => {
  //   const getStream = async (id: string) => {
  //     const result = await imageStream(id, true, 115, 112);
  //     if (
  //       briefcaseDocument?.title.endsWith('ppt') ||
  //       briefcaseDocument?.title.endsWith('pptx')
  //     ) {
  //       setFilePreview(pptImg);
  //     } else if (briefcaseDocument?.title.endsWith('pdf')) {
  //       setFilePreview(pdfImg);
  //     } else {
  //       setFilePreview(result ?? null);
  //     }
  //   };

  //   spaceId && briefcaseDocument && getStream(spaceId);

  //   return () => {
  //     setFilePreview(null);
  //   };
  // }, [briefcaseDocument, spaceId]);

  return (
    <>
      {briefcaseDocument &&
        briefcase &&
        briefcase.members &&
        briefcaseDocumentAnalytics && (
          <DocumentDetails
            document={briefcaseDocument}
            // filePreview={filePreview}
            members={briefcase.members}
            documentVisits={briefcaseDocumentVisits}
            documentUsage={briefcaseDocumentUsage}
            documentAnalytics={briefcaseDocumentAnalytics}
            documentPerformance={briefcaseDocumentPerformance}
          />
        )}
    </>
  );
}

//   return (
//     <section>
//       {/* File Details Banner */}
//       {briefcaseDocument && (
//         <>
//           <section className='mt-20'>
//             <div className='bg-[#F6F3EF] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
//               <div className='w-full flex items-center gap-x-4'>
//                 <div className='w-[115px] h-[112px] rounded-md'>
//                   {filePreview && (
//                     <img
//                       src={filePreview}
//                       alt='file'
//                       className='w-[115px] h-[112px] rounded-md object-cover object-center'
//                     />
//                   )}
//                 </div>
//                 <div>
//                   <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
//                     {briefcaseDocument.title}
//                   </h1>
//                   <section className='flex gap-x-5 items-center text-sm'>
//                     <p>
//                       {/* Description goes here if theres any at all Description
//                       goes here if theres any at all */}
//                     </p>
//                     <div className='flex items-center gap-x-2'>
//                       <img src={clock} alt='clock' />
//                       {/* <p>Last updated 5 min ago</p> */}
//                       {briefcaseDocumentVisits && (
//                         <p>
//                           {moment
//                             .utc(briefcaseDocumentVisits.at(0)?.date)
//                             .fromNow()}
//                         </p>
//                       )}
//                     </div>
//                   </section>
//                   <div className='mt-3'>
//                     <AvatarGroup>
//                       {briefcase &&
//                         briefcase.members?.map((item, index) => {
//                           const { backgroudColor } = getColorfulBgClass(index);
//                           return (
//                             <Avatar
//                               label={getInitials(item.fullName ?? '')}
//                               shape='circle'
//                               size='normal'
//                               key={index}
//                               style={{
//                                 backgroundColor: backgroudColor,
//                                 color: 'white',
//                               }}
//                             />
//                           );
//                         })}
//                     </AvatarGroup>
//                   </div>
//                 </div>
//               </div>
//               <img
//                 src={lines}
//                 alt='briefcase'
//                 className=' absolute right-0 top-0'
//               />
//             </div>
//           </section>

//           {/* tabs */}
//           <section className='mt-5 mb-20'>
//             <section className='flex gap-x-4 items-center justify-between'>
//               <section className='flex gap-x-2 border-b'>
//                 <button
//                   onClick={() => setTab(0)}
//                   className={`px-4 pb-2 cursor-pointer text-sm  ${
//                     tab === 0 && 'border-b-2  text-primary border-b-primary'
//                   }`}
//                 >
//                   Visits
//                 </button>

//                 <button
//                   onClick={() => setTab(1)}
//                   className={`px-4 pb-2 cursor-pointer text-sm  ${
//                     tab === 1 && 'border-b-2  text-primary border-b-primary'
//                   }`}
//                 >
//                   Analytics
//                 </button>

//                 <button
//                   onClick={() => setTab(2)}
//                   className={`px-4 pb-2 cursor-pointer text-sm  ${
//                     tab === 2 && 'border-b-2  text-primary border-b-primary'
//                   }`}
//                 >
//                   Usage
//                 </button>
//               </section>
//               {/* Export */}
//               <section className='flex items-center gap-x-2'>
//                 <div className='bg-[#F6F6F6] text-[#333333] text-sm h-8 rounded-md flex gap-x-1 items-center cursor-pointer border border-[#E2E2E2] px-3'>
//                   <img src={exportIconBlack} alt='export icon' />
//                   <p>Export</p>
//                   <img src={ChevronDownBlack} alt='more' />
//                 </div>
//               </section>
//             </section>

//             <section className='mt-1 mb-8 pb-20'>
//               {tab === 0 && <FileVisits />}
//               {tab === 1 && <FileAnalytics />}
//               {tab === 2 && <FileUsage />}
//             </section>
//           </section>
//         </>
//       )}
//     </section>
//   );
// }
