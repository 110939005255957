import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getPresentationState,
  getPresentationVisitors,
} from "store/Presentations/presentationSlice";

//components
import Leads from "views/Admin/Directory/Table/Leads";

export default function VisitorsLeads() {
  const dispatch = useDispatch();
  const { presentationId } = useParams();

  //usestate
  const [leads] = useState([
    {
      name: "phoenix baker",
      company: "Meta",
      email: "@phoenix.baker",
      phone: "+23480127172",
      socials: ["Twitter", "Snapchat", "LinkedIn"],
      role: "Director",
      status: "active",
    },
    {
      name: "phoenix baker",
      company: "Chevolet",
      email: "@phoenix.baker",
      phone: "+23480127172",
      socials: ["Twitter"],
      role: "Director",
      status: "active",
    },

    {
      name: "phoenix baker",
      company: "Manchester United",
      email: "@phoenix.baker",
      phone: "+23480127172",
      socials: ["Twitter", "Skype"],
      role: "owner",
      status: "active",
    },
  ]);

  return <Leads leads={leads} />;
}
