import { useState } from 'react';
// import './DropArea.css';
type DraggedItemType = 'section' | 'document' | 'null';

interface Props {
  onDropDocument: () => void;
  dropAreaIndex: number;
  draggedItemType: DraggedItemType;
}

export default function DropArea({
  onDropDocument,
  dropAreaIndex,
  draggedItemType,
}: Props) {
  const [showDrop, setshowDrop] = useState(false);
  return (
    <section className='relative'>
      <section
        onDragEnter={() => {
          if (draggedItemType === 'document') {
            setshowDrop(true);
          }
        }}
        onDragLeave={() => setshowDrop(false)}
        className={`${
          showDrop
            ? 'w-full h-24 border border-dashed border-gray-300 rounded-lg p-4 mb-4 opacity-100 transition-all duration-200 ease-in-out'
            : 'opacity-0'
        }`}
        onDrop={() => {
          onDropDocument();
          setshowDrop(false);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        Drop here
      </section>
      <hr
        className={`w-full border absolute top-[50%] translate-y-[50%] ${
          showDrop ? 'hidden' : 'block'
        } ${dropAreaIndex === 0 ? 'hidden' : ''}`}
      />
    </section>
  );
}
