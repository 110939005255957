import { toast } from "react-toastify";
import { getConnectionId } from "utils/localStorageServices";

export const VoteQuestion = (
  connection,
  presentationId,
  questionId,
  status
) => {
  if (connection) {
    connection
      .invoke(
        "VoteQuestion",
        presentationId,
        getConnectionId(),
        questionId,
        status
      )
      .catch((err) => {
        console.log(err);
        toast.error(
          "An unexpected error occurred invoking 'VoteQuestion' on the server"
        );
      });
  }
};
