import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

//assets
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";

//libraries
import { ClipLoader } from "react-spinners";

//slices
import { getProfilePic, updateProfile } from "store/Settings/settingSlice";

//utils
import { getUserDetails } from "utils/localStorageServices";
import { toast } from "sonner";

export default function EditProfile({ avatar, closeModal }) {
  const dispatch = useDispatch();
  const { fullName, email, phone, expiresIn, roles, token } =
    getUserDetails() ?? {};

  //useref
  const emailRef = useRef(email);
  const phoneRef = useRef(phone);
  const fullNameRef = useRef(fullName);
  const uploadFileRef = useRef();

  //usestates
  const [isLoading, setIsLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);

  //function

  const openUploadModal = () => {
    uploadFileRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file?.type?.includes("image")) {
      toast.error( "Image files only");

      return;
    }
    setImageUpload(file);
  };

  const profileUpdate = (e) => {
    e.preventDefault();
    const name = fullNameRef.current.value ?? fullName;
    const phoneNumber = phoneRef.current.value ?? phone;

    if (!name || !phoneNumber) {
      toast.error( "Fill all details");
      return;
    }
    const data = {
      fullName: name,
      phone: phoneNumber,
      picture: imageUpload,
    };

    setIsLoading(true);

    dispatch(updateProfile(data)).then(({ type, payload }) => {
      setIsLoading(false);
      if (type?.includes("fulfilled")) {
        toast.success( "Profile updated successfully");
        const details = {
          fullName: payload?.fullName,
          email: payload?.email,
          phone: payload?.phoneNumber,
          expiresIn,
          roles,
          token,
        };
        dispatch(getProfilePic());
        phoneRef.current.value = "";
        setImageUpload(null);
        localStorage.setItem("user", JSON.stringify(details));
        closeModal();
      }
    });
  };

  return (
    <section>
      <div>
        <img
          src={avatar}
          alt="avatar"
          className="w-[48px] h-[48px] rounded-full"
        />
        <h4 className="pl-2 my-4 text-xl font-semibold">Edit Profile</h4>
      </div>

      <form className="flex flex-col px-3 my-4 gap-y-3">
        <div className="">
          <Label text="Email" />
          <AuthInput
            type="email"
            name="email"
            defaultValue={email}
            customRef={emailRef}
            disabled={true}
            className="cursor-not-allowed"
          />
        </div>

        <div className="">
          <Label text="Fullname" />
          <AuthInput
            type="text"
            name="text"
            defaultValue={fullName}
            customRef={fullNameRef}
          />
        </div>

        <div className="">
          <Label text="Phone number" />
          <AuthInput
            type="tel"
            name="phone"
            defaultValue={phone}
            customRef={phoneRef}
          />
        </div>

        <div className="">
          <Label text="Profile Picture" />
          <div
            draggable
            onClick={openUploadModal}
            //   onDrop={(e) => drop(e)}
            //   onDragOver={(e) => dragOver(e)}
            className="mt-2 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
          >
            <div className="flex flex-col items-center py-4 text-center">
              {imageUpload ? (
                <div>
                  <img
                    src={URL.createObjectURL(imageUpload)}
                    alt=""
                    className="w-12 mx-auto "
                  />
                  <p className="mt-1 text-center">{imageUpload?.name}</p>
                </div>
              ) : (
                <p>
                  {" "}
                  <span className="mr-2 font-semibold ">Click to upload</span>
                  or drag and drop
                </p>
              )}
            </div>

            <input
              hidden
              type="file"
              ref={uploadFileRef}
              onChange={handleFileUpload}
            />
          </div>
        </div>
        <button
          // disabled={!isButtonActive}
          onClick={profileUpdate}
          className={`flex mt-2 items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            !isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading && <ClipLoader size={15} color="white" />}

          {!isLoading && (
            <>
              <p>Update Profile</p>
            </>
          )}
        </button>
      </form>
    </section>
  );
}
