
import React, { useState } from "react";

//utils
import { getMIMEImg } from "utils/formatter";

//libraries
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export default function RecentPresentationVisits() {
  const [visits] = useState([
    {
      filename: "Tech requirements.ppt",
      type: "ppt",
      size: "200 KB",
      date: "Jan 4, 2022",
      lastUpdate: "Jan 4, 2022",
      viewedBy: [],
      timeSpent: "01:40",
    },

    {
        filename: "Dashboard screenshot.jpg",
        type: "jpg",
        size: "289 KB",
        date: "Jan 4, 2022",
        lastUpdate: "Jan 4, 2022",
        viewedBy: [],
        timeSpent: "01:40",
      },

      {
        filename: "Dashboard prototype recording.mp4",
        type: "mp4",
        size: "20 MB",
        date: "Jan 4, 2022",
        lastUpdate: "Jan 4, 2022",
        viewedBy: [],
        timeSpent: "01:40",
      },

      {
        filename: "Dashboard prototype FINAL.fig",
        type: "fig",
        size: "4.3 KB",
        date: "Jan 4, 2022",
        lastUpdate: "Jan 4, 2022",
        viewedBy: [],
        timeSpent: "01:40",
      },
  ]);

  const columns = [
    {
      header: "File name",
      field: "filename",
      sortable: true,
      body: (rowData) => {
        return (
          <section>
            <section className="flex items-center gap-x-4">
              <img src={getMIMEImg(rowData?.type)} alt="" />
              <div className="pt-1 text-sm font-medium ">
                <p className="text-blue400">{rowData?.filename}</p>
                <p className="my-1 font-light text-blue300">{rowData?.size}</p>
              </div>
            </section>
          </section>
        );
      },
    },

    {
      header: "Date uploaded",
      field: "date",
      sortable: true,
      body: (rowData) => {
        return <section>{rowData.date}</section>;
      },
    },

    {
      header: "Last updated",
      field: "lastUpdate",
      sortable: true,
      body: (rowData) => {
        return <section>{rowData.lastUpdate}</section>;
      },
    },

    {
      header: "View by",

      //   body: (rowData) => {
      //     return <section>
      //         {rowData.lastUpdate}
      //     </section>;
      //   },
    },

    {
      header: "Time spent",
      field: "timeSpent",
      sortable: true,
    },

    {
      header: "",
      body: (rowData) => {
        return (
          <section
            className="flex items-center gap-x-2"
            onClick={() => {
              //   setSelectedRow(rowData?.id);
              //   setOpenDrawer(true);
            }}
          >
            <div className="cursor-pointer">
              <i className="pi pi-ellipsis-v"></i>
            </div>
          </section>
        );
      },
    },
  ];

  return (
    <section className="mt-10 mb-6">
      <section className="py-6 border rounded-xl">
        <section className="flex flex-col items-center justify-between px-4 text-center lg:flex-row md:flex-row lg:text-left md:text-left gap-y-1">
          <div>
            <h3 className="text-lg font-semibold text-blue400 ">
              Recent presentation visits
            </h3>
            <p className="mt-1 text-sm text-blue300">
              Files and assets that have been attached to this project.
            </p>
          </div>

          <button className="px-3 py-2 text-sm font-semibold border rounded-lg">
            See all visit
          </button>
        </section>

        {/* table  */}
        <section className="my-4">
          <DataTable
            value={visits}
            className="text-sm"
            tableStyle={{ minWidth: "50rem" }}
          >
            {columns.map((col, index) => {
              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  sortable={col?.sortable}
                />
              );
            })}
            <Column />
          </DataTable>
        </section>
        {/*  */}
      </section>
    </section>
  );
}
