import React from "react";

//assets
import image1 from "assets/image/loginImg1.png";

export default function FirstSlide() {
  return (
    <div className=" w-full  h-[100%] ">
      <img
        src={image1}
        alt="login page"
        className="lh-fs w-[80%] mx-auto "
      />
    </div>
  );
}
