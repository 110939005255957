import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

//assets
import quiz from "assets/svg/quiz.svg";
import TFsvg from "assets/svg/TFsvg.svg";
import wordCloud from "assets/svg/wordCloud.svg";
import multiAnswer from "assets/svg/multiAnswers.svg";

//libraries
import { v4 as uuidv4 } from "uuid";
import { Modal, Select } from "antd";
import { toast } from "sonner";
import { ClipLoader } from "react-spinners";

//components
import AddImage from "../Modals/Poll/AddImage";
import PollOptionList from "components/Admin/Presentation/PollCreation/PollOptionList";

//slices
import {
  getPollsObject,
  getStoredPolls,
  pushPoll,
} from "utils/pollStateManagement";

//utils
import { capitalizeText } from "utils/formatter";

const { Option } = Select;
const PollForm = (props, ref) => {
  const { presentationId } = useParams();

  //useref
  const titleRef = useRef(null);
  const minuteRef = useRef(null);
  const secondsRef = useRef(null);
  const pollQuestionRef = useRef(null);

  //usestate
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplate, setIsTemplate] = useState(null);
  const [questionType, setQuestionType] = useState("");
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [pollQuestion, setPollQuestion] = useState("");
  const [editDetails, setEditDetails] = useState(null);
  const [isTitleEntered, setIsTitleEntered] = useState();
  // const [numOfTags, setNumOfTags] = useState([
  //   { id: uuidv4(), name: null, value: null, color: null, img: null },
  //   { id: uuidv4(), name: null, value: null, color: null, img: null },
  // ]);

  //useeffect
  useEffect(() => {
    const pollTitle = getPollsObject()?.title;
    setIsTitleEntered(pollTitle);
  }, []);

  useEffect(() => {
    const confirmEditOptions = editDetails?.options?.[0]?.text;
    const options = editDetails?.options;
    if (!confirmEditOptions) {
      setOptions([
        { id: uuidv4(), name: null, value: null, color: null, img: null },
        { id: uuidv4(), name: null, value: null, color: null, img: null },
      ]);
    } else {
      const reformatOptions = options?.map((option) => {
        const { id, rank, text, imageUrl, color, isSelected } = option ?? {};
        return { id, name: text, color, img: imageUrl };
      });

      setOptions(reformatOptions);
    }
  }, [editDetails]);

  useEffect(() => {
    const confirmEditOptions = editDetails?.options?.[0]?.text;

    if (questionType && !confirmEditOptions) {
      setOptions([
        { id: uuidv4(), name: null, value: null, color: null, img: null },
        { id: uuidv4(), name: null, value: null, color: null, img: null },
      ]);
    }
  }, [questionType, editDetails]);

  useEffect(() => {
    const handleEvent = () => {
      const savedTitle = getPollsObject()?.title;
      setIsTitleEntered(savedTitle);
    };
    window.addEventListener("storage", handleEvent);
    return () => {
      window.removeEventListener("storage", handleEvent);
    };
  }, []);

  useEffect(() => {
    const handleEditEvent = (event) => {
      const { questionType, duration, question } = event?.detail ?? {};
      setEditDetails(event?.detail);
      setQuestionType(questionType?.toLowerCase());
      setMinutes(parseInt(duration / 60));
      setSeconds(parseInt(duration % 60));
      setPollQuestion(question);
    };

    window.addEventListener("edit", handleEditEvent);

    return () => {
      window.removeEventListener("edit", handleEditEvent);
    };
  }, []);

  //vairables
  const questionTypeOptions = [
    { value: "", label: "Select question-type" },
    { value: "word cloud", label: "Word cloud", img: wordCloud },
    { value: "quiz", label: "Quiz", img: quiz },
    { value: "true or false", label: "True or false", img: TFsvg },
    { value: "multi answer", label: "Multi answer", img: multiAnswer },
  ];

  //functions

  const handleAddFileCancel = () => {
    setIsAddFileModalOpen(false);
  };

  const handleQuestionTypeChange = (e) => {
    setQuestionType(e);
  };

  const createPoll = () => {
    let rank = getStoredPolls()?.length;

    const title = titleRef.current?.value ?? getPollsObject()?.title;
    const isWordCloud = questionType === "word cloud";

    //question
    const question = pollQuestion;

    let questionImageUrl = null;

    if (questionType?.toLowerCase() === "word cloud") {
      questionImageUrl = null;
    }
    //duration
    let duration;
    if (!minutes && !seconds) {
      duration = 60;
    } else {
      duration = Number(minutes) * 60 + Number(seconds);
    }

    if (!options && !isWordCloud) {

      toast.error("Enter Options");
      return;
    }
    let mappedOptions = null;
    let canProceed = false;
    if (!isWordCloud) {
      mappedOptions = [...options]?.map((option, index) => {
        const { id, name = null, color = null, img = null } = option ?? {};
        if (!name) {
          toast.error(
            "A name must be entered in an option. Kindly enter an option or delete option"
          );

          return;
        } else {
          canProceed = true;
        }
        return {
          id,
          rank: index,
          text: name,
          imageUrl: img,
          color,
          // shape: "string",
          isSelected: false,
          // value: 0,
        };
      });
    }

    //title
    if (!title) {
      toast.error("Enter title");
      return;
    }
    //questionType

    if (!questionType) {
      toast.error("Select a question type");
      return;
    }

    if (!question) {
      toast.error("Type in a question");
      return;
    }
    if (!canProceed && !isWordCloud) {
      return;
    }

    // [
    //   {
    //     id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     rank: 0,
    //     text: "string",
    //     imageUrl: "string",
    //     shape: "string",
    //     color: "string",
    //     isSelected: true,
    //     value: 0,
    //   },
    // ],
    const questionObj = {
      id: editDetails?.id ?? uuidv4(),
      questionType: capitalizeText(questionType),
      rank: editDetails?.rank ?? rank,
      question,
      questionImageUrl, //come back for images
      // answer: "string",
      duration,
      options: mappedOptions,
    };
    pushPoll(title, questionObj);

    setMinutes(null);
    setSeconds(null);
    setPollQuestion("");
    setOptions(null);
    setQuestionType("");
    setEditDetails(null);
  };

  return (
    <section>
      {/* form  */}
      <section className="lg:w-10/12  w-full pr-4 mt-4 lg:h-[75vh]hide-scrollbar">
        {!isTitleEntered && (
          <div className="my-4">
            <h5 className="font-semibold text-md text-gray60">TITLE</h5>
            <input
              name="title"
              ref={titleRef}
              placeholder="Title"
              className="w-full px-4 py-3 mt-3 rounded-lg outline-none bg-orange10"
            />
          </div>
        )}

        <div className="my-4">
          <h5 className="font-semibold text-gray60">QUESTION?</h5>
          <textarea
            rows={2}
            name="pollQuestion"
            ref={pollQuestionRef}
            value={pollQuestion}
            onChange={(e) => setPollQuestion(e.target.value)}
            placeholder="Type your question"
            className="w-full px-4 py-3 pb-2 mt-3 outline-none rounded-xl bg-orange10"
          ></textarea>
        </div>

        <Select
          value={questionType}
          placeholder="Select question type"
          onChange={handleQuestionTypeChange}
          className="w-full h-10 border border-gray rounded-md [ placeholder:text-black ] "
        >
          {questionTypeOptions?.map((option) => {
            const { img, label, value } = option ?? {};
            return (
              <Option key={value}>
                <div className="flex items-center">
                  {img && (
                    <img
                      src={img}
                      alt={option.label}
                      className="w-5 h-5 mr-2"
                    />
                  )}

                  {label}
                </div>
              </Option>
            );
          })}
        </Select>

        {questionType && (
          <section>
            {/* {questionType?.toLowerCase() !== "word cloud" && (
              <section className="mt-4">
                <h5 className="font-semibold uppercase text-gray60">
                  Attach an image
                </h5>
                <div
                  onClick={() => setIsAddFileModalOpen(true)}
                  className="mt-3 mb-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
                >
                  <div className="flex flex-col items-center py-4 text-center">
                    <p>
                      {" "}
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p>Powerpoint, JPG or PDF </p>
                  </div>
                </div>
              </section>
            )} */}

            {questionType?.toLowerCase() !== "word cloud" && (
              <section>
                <h5 className="my-4 font-semibold text-gray60">
                  CREATE YOUR OPTIONS?
                </h5>
                <PollOptionList
                  setOptions={setOptions}
                  options={options}
                  questionType={questionType}
                  // setNumOfTags={setNumOfTags}
                  // numOfTags={numOfTags}
                />
              </section>
            )}

            <section className="mt-4">
              <h5 className="font-semibold uppercase text-gray60">Duration</h5>

              <div className="flex w-full mt-4 gap-x-2">
                <input
                  min="0"
                  max="59"
                  type="number"
                  value={minutes}
                  ref={minuteRef}
                  placeholder=" 1 Mins"
                  onChange={(e) => setMinutes(e.target?.value)}
                  className="w-1/2 px-4 py-3 border rounded-lg placeholder:text-gray350"
                />

                <input
                  min="0"
                  max="59"
                  type="number"
                  value={seconds}
                  ref={secondsRef}
                  placeholder="0 Secs"
                  onChange={(e) => setSeconds(e.target?.value)}
                  className="w-1/2 px-4 py-3 border rounded-lg placeholder:text-gray350"
                />
              </div>
            </section>

            {/* <section className="flex items-center w-full gap-x-4">
              <button
                onClick={() => {
                  createPoll(true);
                  setIsTemplate(true);
                }}
                className="w-1/2 px-4 py-2 my-4 text-sm font-semibold text-black border-2 border-black rounded-lg cursor-pointer gap-x-1"
              >
                {!isLoading && "Save as template"}
                {isLoading && isTemplate && (
                  <ClipLoader color="white" size={12} />
                )}
              </button>

              <button
                onClick={() => {
                  createPoll(false);
                  setIsTemplate(false);
                }}
                className="w-1/2 px-4 py-2 my-4 text-sm font-semibold text-white bg-black border-2 border-black rounded-lg cursor-pointer gap-x-1"
              >
                {!isLoading && "Add to presentation"}
                {isLoading && !isTemplate && (
                  <ClipLoader color="white" size={12} />
                )}
              </button>
            </section> */}

            <button
              onClick={createPoll}
              className="w-full py-4 my-4 font-semibold text-white bg-black rounded-lg"
            >
              {!isLoading ? (
                editDetails ? (
                  "Edit poll"
                ) : (
                  "Create poll"
                )
              ) : (
                <ClipLoader color="white" size={12} />
              )}
            </button>
          </section>
        )}
      </section>
      {/*  */}

      <>
        {/* modal  */}
        <Modal
          open={isAddFileModalOpen}
          onCancel={handleAddFileCancel}
          footer={null}
          // width={1200}
          centered
        >
          <AddImage
            closeModal={handleAddFileCancel}
            presentationId={presentationId}
          />
        </Modal>
        {/*  */}
      </>
    </section>
  );
};

export default PollForm;
