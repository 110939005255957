//assets
import ppt from "assets/svg/pptSvg.svg";
import jpg from "assets/svg/jpgSvg.svg";
import fig from "assets/svg/figSvg.svg";
import mp4 from "assets/svg/mp4Svg.svg";
import docSvg from "assets/svg/docxSvg.svg";

export const isFileTypeAllowed = (file) => {
  const allowedFileTypes = [
    "application/pdf", // PDF
    "application/msword", // .doc (older Word format)
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx (Word)
    "application/vnd.ms-excel", // .xls (older Excel format)
    "text/csv", // .csv
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx (Excel)
    "application/vnd.ms-powerpoint", // .ppt (older PowerPoint format)
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx (PowerPoint)
  ];
  return (
    allowedFileTypes.includes(file.type) ||
    file.type.startsWith("video/") ||
    file.type.startsWith("image/")
  );
};

export const timeTextFormatter = (time) => {
  let finalText;

  if (time >= 3600) {
    const hours = Math.floor(time / 3600);
    const remainingMinutes = Math.floor((time % 3600) / 60);
    finalText = `${hours}hr${hours > 1 ? "s" : ""}${
      remainingMinutes > 0
        ? ` ${remainingMinutes}min${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  } else if (time >= 60) {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    finalText = `${minutes}min${minutes > 1 ? "s" : ""}${
      remainingSeconds > 0
        ? ` ${remainingSeconds}sec${remainingSeconds > 1 ? "s" : ""}`
        : ""
    }`;
  } else {
    finalText = `${time > 0 ? time : 0}sec${time > 1 ? "s" : ""}`;
  }

  return finalText;
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  }`;
};

export const formatNumber = (value) => {
  const num = value ? Number(value) : 0;

  return num?.toLocaleString();
};
export function trimText(text, maxLength) {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export const capitalizeText = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

export const getInitials = (name) => {
  // Split the name into words
  let words = name.split(" ");

  // Initialize an empty string for the initials
  let initials = "";

  // Loop through each word and take the first letter
  for (let i = 0; i < words.length; i++) {
    initials += words[i].charAt(0);
  }

  // Return the initials in uppercase
  return initials.toUpperCase();
};

export const getMIMEImg = (type) => {
  if (type === "ppt") {
    return ppt;
  }
  if (type === "jpg") {
    return jpg;
  }

  if (type === "fig") {
    return fig;
  }

  if (type === "docx") {
    return docSvg;
  }

  if (type === "mp4") {
    return mp4;
  } 
};
