import React, { useState } from "react";

//library
import { Carousel } from "primereact/carousel";

//utils
import { items, responsiveOptions } from "views/Authentication/Login/data";



export default function LoginCarousel() {
  const [currentItem, setCurrentItem] = useState(0);

  const itemTemplate = (item) => {
    return (
      <section className="py-4">
        <div className="lh-loginTitle">
          <h1 className=" leading-[2.4rem] text-[2rem] w-[84%] font-semibold font-clashDisplay ">
            {item.title}
          </h1>

          <p className=" w-[58%] my-2 text-sm leading-[1.5rem]">
            {" "}
            {item.description}
          </p>
        </div>
      </section>
    );
  };

  const onPageChange = (event) => {
    setCurrentItem(event.page);
  };

  return (
    <>
      <Carousel
        circular
        value={items}
        autoplayInterval={2500}
        numVisible={1}
        numScroll={1}
        showNavigators={false}
        responsiveOptions={responsiveOptions}
        itemTemplate={itemTemplate}
        onPageChange={onPageChange}
        pt={{
          indicatorButton: {
            className: "border-round-lg bg-black10 rounded-md w-16",
          },
        }}
      />

      <div className="relative flex items-center justify-center mt-[1%]">
        {items?.[currentItem]?.img}
      </div>
    </>
  );
}
