import React, { useEffect, useState } from "react";

//assets
import avatar from "assets/svg/avatar.svg";
import fileEmpty from "assets/svg/emptyTemplate.svg";

//libraries
import { ClipLoader } from "react-spinners";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//slices
import { getConnectionState } from "store/SignalR/signalRSlice";
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { useGetViewersList } from "hooks/SignalR/Listeners/Presenter/useGetViewersList";

export default function AllViewers({ connection, presentationId }) {
  const connectionState = getConnectionState();

  //usestate
  const [viewers, setViewers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //hook functions
  const showViewers = (users) => {
    setViewers(
      users.filter((user) => {
        return user.status === 1;
      })
    );
    setIsLoading(false);
  };

  //hooks
  useGetViewersList(connection, presentationId, connectionState, showViewers);

  useEffect(() => {
    setIsLoading(true);
    GetUsersList(connection, presentationId);
  }, [connection, presentationId]);

  return (
    <section>
      {isLoading && (
        <div className="flex items-center justify-center w-full mt-20">
          <ClipLoader size={20} color="#FF6929" />
        </div>
      )}

      {!isLoading && (
        <div>
          {viewers?.map((viewer, index) => {
            return (
              <div key={index}>
                <FlexContainer extraClass="py-3 border-b px-2">
                  <div className="flex items-center gap-x-2">
                    <div>
                      <img
                        src={avatar}
                        alt={viewer?.name}
                        className="w-8 rounded-full"
                      />
                    </div>

                    <div>
                      <h3 className="font-semibold ">{viewer?.name}</h3>
                      <p className="text-xs text-gray60 ">{viewer?.email}</p>
                    </div>
                  </div>
                </FlexContainer>
              </div>
            );
          })}

          {viewers?.length < 1 && (
            <section className="flex flex-col items-center justify-center h-[70vh] text-sm ">
              <img src={fileEmpty} alt="" />
              <div className="text-center">
                <p className="font-semibold">
                  There are no active viewers in this presentation{" "}
                </p>
              </div>
            </section>
          )}
        </div>
      )}
    </section>
  );
}
