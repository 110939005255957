import React, {useEffect, useState} from "react";

//components
import LargeScreen from "./Views/largeScreen";
import {MobilePresentation} from "../../../components/UI/mobile/mobilePresentation";

export default function ActivePresentation() {
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    useEffect(() => {
        const updateScreenSize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };
        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);
    }, []);
    return (
        <section>
            {/*{isLargeScreen ? <LargeScreen /> : <MobilePresentation />}*/}
            <MobilePresentation />
            {/*<LargeScreen/>*/}
        </section>
    );
}
