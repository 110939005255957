import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { forwardRef } from "react";

//assets
import upload from "assets/svg/uploadDark.svg";

//slices
import {
  editPresentation,
  getAllPresentations,
  getPresentationDetails,
} from "store/Presentations/presentationSlice";

//libraries
import dayjs from "dayjs";
import { DatePicker } from "antd";
import utc from "dayjs/plugin/utc";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";

// Extend dayjs with UTC plugin
dayjs.extend(utc);

function EditPresentation(props, ref) {
  const { closeModal, presentation, fromPresentationDetailsPage } = props ?? {};
  const { id, title, scheduledTime, status } = presentation ?? {};

  const dispatch = useDispatch();

  //useRef
  const presentationTitle = useRef(title);

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [startTime, setStartTime] = useState(
    dayjs(scheduledTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  );

  useImperativeHandle(ref, () => ({
    resetModal() {
      setStartTime(null);
      setIsLoading(null);
      setIsButtonActive(false);
      presentationTitle.current.value = "";
    },
  }));

  //useeffect
  useEffect(() => {
    if (presentationTitle && startTime) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [startTime, presentationTitle]);

  //functions
  const handleChange = (e) => {
    if (e?.target?.value && startTime) {
      setIsButtonActive(true);
    }
  };

  const onChange = (value, dateString) => {
    const selectedDate = dayjs.utc(dateString); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    setStartTime(formattedDate);
    setIsButtonActive(true);
    // console.log("Formatted Selected Time: ", dateString);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const handleEdit = async () => {
    const title = presentationTitle?.current?.value;

    if (!startTime) {
      toast.error("Select a start time");
      return;
    }

    if (!title) {
      toast.error("Enter presentation title");
      return;
    }

    if (startTime && title) {
      setIsLoading(true);
      setIsButtonActive(false);

      const data = {
        title,
        startTime,
      };

      //dispatch function

      const presentationId = id;
      dispatch(editPresentation({ presentationId, data })).then(
        async ({ type }) => {
          setIsLoading(false);
          setIsButtonActive(true);

          if (type.includes("fulfilled")) {
            toast.success("Presentation edited successfully");
            if (fromPresentationDetailsPage) {
              await dispatch(getPresentationDetails(presentationId));
            } else {
              dispatch(getAllPresentations());
            }
            closeModal();
          }
        }
      );
    }
  };

  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-8 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">Edit Presentation</h1>
          {/* <p className="text-sm">
            you can only upload a PowerPoint or pdf format
          </p> */}
        </div>

        <div className="my-4">
          <Label text="Presentation title" />
          <AuthInput
            type="text"
            name="text"
            defaultValue={title}
            customRef={presentationTitle}
            placeholder="Presentation title"
            onChange={(e) => handleChange(e)}
          />
        </div>

        {status === 1 && (
          <div className="my-4">
            <Label text="Start time:" />
            <div className="block w-full mt-2">
              <DatePicker
                showTime
                onOk={onOk}
                minDate={dayjs()}
                onChange={onChange}
                format="YYYY-MM-DD HH:mm"
                defaultValue={dayjs(scheduledTime)}
                className="flex justify-between px-4 py-2 mt-1 rounded-md text-gray700 bg-orange10"
              />
            </div>
          </div>
        )}

        <button
          disabled={!isButtonActive}
          onClick={handleEdit}
          className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading && <ClipLoader size={15} color="white" />}

          {!isLoading && (
            <>
              <p>Proceed</p>
            </>
          )}
        </button>
      </section>
    </section>
  );
}
export default forwardRef(EditPresentation);
