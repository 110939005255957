import React, { useEffect, useState } from 'react';

//assets
import addPlus from 'assets/svg/add-square.svg';
import tagIcon from 'assets/svg/tag-icon.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { getBriefcases, updateBriefcase } from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';

interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
}
interface BriefcaseItems {
  bannerId: string;
  dateCreated: string;
  description: string;
  id: string;
  lastModified: string;
  members: Member[] | null;
  tags: string;
  teamId: string;
  title: string;
}
interface Props {
  closeModal: () => void;
  briefcaseDetails: BriefcaseItems;
}

export default function CreateTag(props: Props) {
  const dispatch = useDispatch<AppDispatch>();

  //usestate
  const [tagName, setTagName] = useState<string | null>(null);
  const [editedTags, setEditedTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  //   destructuring
  const { closeModal, briefcaseDetails } = props;
  const { tags, id, title, description, teamId } = briefcaseDetails;

  //useeffect
  useEffect(() => {
    if (tags) {
      setEditedTags(tags?.split(','));
    }
  }, [tags]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const tag = (e.target as HTMLInputElement).value.trim(); //trim removes white spaces
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (tag === ',') { //accounts for when one enters comma
        setTagName('');
        return;
      }
      if (tag) {
        setEditedTags((prev) => [...prev, tag]);
      }
      setTagName('');
    }
  };

  const removeTag = (index: number) => {
    const updatedTags = editedTags.filter((_, i) => i !== index);
    setEditedTags(updatedTags);
  };

  const updateTag = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('SpaceId', id);
    formData.append('Title', title);
    formData.append('Description', description);
    formData.append('Tags', editedTags.join(','));

    dispatch(updateBriefcase(formData)).then(() => {
      setIsLoading(false);
      closeModal();
      teamId && dispatch(getBriefcases(teamId));
    });
  };

  return (
    <section className='py-3'>
      <div className='mt-[-20px]'>
        <img src={tagIcon} alt='' />
      </div>

      <section>
        <div className='mt-4'>
          <h1 className='text-2xl font-semibold'>Tags</h1>

          <p className='mt-2 text-sm'>
            Tags help organize your Spaces. They're only visible to you and your
            <br /> team
          </p>
        </div>

        <section>
          <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
            {editedTags?.map((tag, index) => {
              return (
                <div
                  key={index}
                  className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                >
                  <p>{tag}</p>
                  <i
                    className='text-xs cursor-pointer pi pi-times mt-[2px]'
                    onClick={() => removeTag(index)}
                  ></i>
                </div>
              );
            })}
          </section>

          {/* form  */}
          <section className='mt-4'>
            <div className='flex flex-col gap-y-1'>
              <label htmlFor='space' className='font-normal text-gray700'>
                Enter tag name
              </label>
              <input
                type='text'
                placeholder='e.g design'
                onKeyDown={handleKeyDown}
                value={tagName || ''}
                onChange={(e) => setTagName(e.target.value)}
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              />
            </div>

            <section className='flex my-4 mt-6 gap-x-4'>
              <button
                disabled={isLoading}
                onClick={updateTag}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading ? 'opacity-40 cursor-not-allowed' : 'opacity-100'
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt='' />
                    Update tag
                  </>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
          {/*  */}
        </section>
      </section>
    </section>
  );
}
