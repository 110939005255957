import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

// Libraries
import { Modal } from "antd";
import { debounce } from "lodash";

// Assets
import stripe from "assets/svg/stripe.svg";
// import paypal from "assets/svg/paypal.svg";
import upload from "assets/svg/uploadDark.svg";
import caution from "assets/svg/whiteCaution.svg";
import convert from "assets/svg/convertArrow.svg";

// Components
import StripePayment from "views/Admin/Payment/Stripe";
import { useDispatch, useSelector } from "react-redux";
import {
  beginPayment,
  convertPointAmount,
  getPaymentState,
} from "store/Payment/paymentSlice";
import { formatNumber } from "utils/formatter";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const TopupModal = (props, ref) => {
  const { closeModal } = props ?? {};

  const dispatch = useDispatch();
  const clientKey = useRef(null);
  const amountRef = useRef(null);
  const stripeModalRef = useRef(null);

  // useSelector
  const { pointBalance } = useSelector(getPaymentState);

  // useStates
  const [lowPointWarn, setLowPointWarn] = useState(
    pointBalance?.data?.balance < 500
  );
  const [amount, setAmount] = useState("");
  const [details, setDetails] = useState({});
  const [convertedPoints, setConvertedPoints] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [isConversionLoading, setIsConversionLoading] = useState(false);

  const [openStripePaymentModal, setOpenStripePaymentModal] = useState(false);

  useImperativeHandle(ref, () => ({
    resetModal() {
      setAmount("");
      setConvertedPoints(0);
      setPaymentLoading(false);
      setIsConversionLoading(false);
      setOpenStripePaymentModal(false);
    },
  }));

  // Debounce function to handle API call
  const debouncedConvertAmount = useCallback(
    debounce((newValue) => {
      const data = {
        value: newValue,
        to: "points",
      };
      dispatch(convertPointAmount(data)).then(({ type, payload }) => {
        if (type?.includes("fulfilled")) {
          setConvertedPoints(payload);
          setIsConversionLoading(false);
        }
      });
    }, 500),
    []
  );

  // useEffect
  useEffect(() => {
    console.log(amountRef.current?.value);
    if (amountRef.current?.value) {
      amountRef.current.value = "";
    }
  }, []);

  useEffect(() => {
    if (!amount || amount <= 0) {
      setConvertedPoints(0);
      setIsConversionLoading(false);
      debouncedConvertAmount.cancel();
    }
  }, [amount, debouncedConvertAmount]);

  // Input handler
  const handleInput = (e) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, "");
    setAmount(newValue);
    if (newValue && newValue > 0) {
      setIsConversionLoading(true);
      debouncedConvertAmount(newValue);
    } else {
      setConvertedPoints(0);
    }
  };

  // Close Modal
  const closeStripModal = () => {
    setOpenStripePaymentModal(false);
  };

  // Start Payment
  const startPayment = () => {
    const data = {
      amount,
      paymentType: 0,
    };

    if (!amount) {
      toast.error("Enter an amount");
      return;
    }
    setPaymentLoading(true);
    dispatch(beginPayment(data)).then(({ type, payload }) => {
      setPaymentLoading(false);
      if (type?.includes("fulfilled")) {
        clientKey.current = payload?.clientSecret;
        setDetails({
          tax: 0,
          amount,
          points: convertedPoints,
        });
        setOpenStripePaymentModal(true);
        // stripeModalRef.current?.resetModal();
      }
    });
  };

  return (
    <section>
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">TOP UP</h1>
          <p className="text-sm">
            Buy more points to explore AI and other cool features
          </p>
        </div>

        {/* Low points warning */}
        {lowPointWarn && (
          <div className="flex items-center justify-between px-4 py-1 my-4 text-sm text-white rounded-lg bg-primary">
            <div className="flex items-center gap-x-3">
              <img src={caution} alt="" className="w-5" />
              <p className="pt-1">Your point is low, kindly top up</p>
            </div>
            <i
              className="text-sm cursor-pointer pi pi-times"
              onClick={() => setLowPointWarn(false)}
            ></i>
          </div>
        )}

        {/* Form */}
        <section className="mt-4">
          <div className="flex flex-col gap-y-1">
            <label htmlFor="amount" className="font-semibold text-gray700">
              Enter amount
            </label>
            <input
              type="text"
              ref={amountRef}
              value={amount}
              onInput={handleInput}
              placeholder="$20.00"
              className="py-3 pl-4 text-sm rounded-lg outline-none bg-orange10"
            />
          </div>

          <img src={convert} alt="" className="pl-2 my-2" />

          <div className="flex flex-col gap-y-1">
            <label htmlFor="amount" className="font-semibold text-gray700">
              Point value is
            </label>
            <div className="flex items-center w-full gap-x-2">
              <input
                disabled
                type="text"
                placeholder="1000.00"
                value={formatNumber(convertedPoints)}
                className={` ${
                  isConversionLoading ? "w-11/12" : "w-full"
                } py-3 pl-4 text-sm rounded-lg outline-none bg-orange10 transition-all`}
              />
              {isConversionLoading && (
                <ClipLoader size={12} color="gray" className="w-1/12" />
              )}
            </div>
          </div>

          <section className="flex my-4 mt-6 gap-x-4">
            <button
              disabled={!amount}
              onClick={startPayment}
              className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                !amount || amount < 0 || amount === "" || convertedPoints < 1
                  ? "opacity-40 cursor-not-allowed"
                  : "opacity-100"
              } bg-black rounded-lg gap-x-2`}
            >
              {!paymentLoading ? (
                <>
                  <img src={stripe} alt="" />
                  Pay with stripe
                </>
              ) : (
                <ClipLoader size={12} color="white" />
              )}
            </button>

            {/* <button className="flex items-center w-1/2 px-4 py-3 font-semibold text-black border border-black rounded-lg gap-x-2">
              <img src={paypal} alt="" />
              Pay with paypal
            </button> */}
          </section>
        </section>
      </section>

      <Modal
        open={openStripePaymentModal}
        onCancel={closeStripModal}
        footer={null}
      >
        <StripePayment
          details={details}
          ref={stripeModalRef}
          clientKey={clientKey}
          closeTopUpModal={closeModal}
          closeModal={closeStripModal}
        />
      </Modal>
    </section>
  );
};
export default forwardRef(TopupModal);
