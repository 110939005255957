import React from "react";
import { NavInterface } from "interfaces/interfaces-ui";
import arrowRight from "assets/svg/arrowRightShort.svg";
import bell from "assets/svg/bell.svg";
import { useNavigate } from "react-router-dom";

const Navbar_ = ({
  hasBreadCrumb,
  leftContent,
  subText,
  mainText,
}: NavInterface) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="text-[#1E1E1E] font-grotesk font-semibold text-lg">
          {mainText}
        </p>
        <p className="text-sm text-[#474747] font-semibold">
          {hasBreadCrumb ? (
            Array.isArray(subText) ? (
              <div className="flex items-center">
                {subText.map((item, index, root) => {
                  return (
                    <span
                      key={index}
                      onClick={() => navigate(item.link)}
                      className="flex items-center"
                    >
                      <p className="cursor-pointer">{item.text}</p>
                      {index !== root.length - 1 && (
                        <span className="px-1">
                          <img src={arrowRight} alt="right" />
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            ) : (
              subText
            )
          ) : (
            ""
          )}
        </p>
      </div>

      {/* side nave content  */}
      <div className="flex items-center gap-2">
        <div>{leftContent}</div>
        <div>
          <img
            className="border py-[6px]  rounded-lg px-3 border-[#EBEBEB] bg-[#FAFAFA] text-sm"
            src={bell}
            alt="bell-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar_;
