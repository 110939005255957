import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tab: 0,
};

const briefcaseTabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { setTab } = briefcaseTabSlice.actions;

export default briefcaseTabSlice.reducer;
