import React from "react";

//assets
import sort from "assets/svg/sort.svg";
import search from "assets/svg/search.svg";
import empty from "assets/svg/emptyPresentation.svg";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import PresentationCard from "../../../../components/Admin/Presentation/Cards/PresentationCard";

export default function ShortPresentationList({ presentations }) {
  return (
    <section className="w-full ">
      <section className="w-full my-10 ">
        <section>
          <FlexContainer isResponsive={true}>
            <div className="flex items-center px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
              <img src={sort} alt="sort" />
              All presentation
            </div>

            <div className="flex items-center px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
              <img src={search} alt="sort" />
              <input
                placeholder=" search presentation"
                className="bg-transparent w-[90%] py-1 outline-none"
              />
            </div>
          </FlexContainer>
        </section>

        {presentations?.data?.length < 1 && (
          <div className="flex items-center justify-center w-full">
            <img src={empty} alt="empty " className="" />
          </div>
        )}
        <section className="flex justify-center w-full pt-10">
          <section className="grid w-full grid-cols-1 lg:gap-x-8 lg:gap-y-2 gap-y-10 lg:grid-cols-3">
            {presentations?.data?.length > 0 &&
              presentations?.data?.slice(0, 3)?.map((presentation, index) => {
                return (
                  <PresentationCard presentation={presentation} key={index} />
                );
              })}
          </section>
        </section>
      </section>
    </section>
  );
}
