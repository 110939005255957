import pptImg from "assets/svg/pptType.svg";
import activePptImg from "assets/svg/activePptImg.svg";
import pngImg from "assets/svg/imageType.svg";
import activePngImg from "assets/svg/activePngImg.svg";
import pdfImg from "assets/image/pdf.png";
import excelImg from "assets/image/excel.png";
import pollImg from "assets/svg/pollType.svg";
import activePollImg from "assets/svg/activePollImg.svg";

import docSvg from "assets/svg/docxSvg.svg";

import mp4Img from "assets/svg/mp4.svg";

export const getFileType = (url) => {
  let fileType = url && url.split(/[#?]/)[0].split(".").pop().trim();

  return `.${fileType}`;
};

export const isPPTFile = (url) => {
  let fileType = url && url.split(/[#?]/)[0].split(".").pop().trim();

  if (fileType === "pptx") {
    return true;
  } else {
    return false;
  }

  // if (
  //   fileType === "png" ||
  //   fileType === "jpeg" ||
  //   fileType === "jpg" ||
  //   fileType === "pdf" ||
  //   fileType === "xlsx"
  // ) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const getFileTypeImg = (url, isActive) => {
  let fileType = url?.includes("//")
    ? url.split(/[#?]/)[0].split(".").pop().trim()
    : url?.toLowerCase();

  if (fileType === "ppt" || fileType === "pptx") {
    return isActive ? activePptImg : pptImg;
  } else if (fileType === "csv" || fileType === "xlsx") {
    return excelImg;
  } else if (
    fileType === "png" ||
    fileType === "jpeg" ||
    fileType === "jpg" ||
    fileType === "svg"
  ) {
    return isActive ? activePngImg : pngImg;
  } else if (fileType === "mp4" || fileType === "mp3" || fileType === "mkv") {
    return isActive ? mp4Img : mp4Img;
  } else if (fileType === "pdf") {
    return pdfImg;
  } else if (fileType === "poll") {
    return isActive ? activePollImg : pollImg;
  } else if (fileType === "docx") {
    return docSvg;
  } else {
    return pptImg;
  }
};
