import React from "react";

//assets
import clock from "assets/svg/clock.svg";
import robotIcon from "assets/svg/robotIcon.svg";
import dislikeIcon from "assets/svg/dislikeYellow.svg";
import likeDislike from "assets/svg/likeDislike.svg";
import loveIcon from "assets/svg/loveIcon.svg";

export default function AttentionModal({ closeModal }) {
  return (
    <section className="">
      <div className="flex items-center  ">
        <span className="hidden lg:inline">
          <img src={robotIcon} alt="" />
        </span>
        <div>
          <div>
            <h1 className="text-lg lg:text-2xl text-[#333333] font-semibold">
              {" "}
              Hey there!!!
            </h1>
            <p className="my-4 text-sm lg:text-base">
              What do you think of this presentation ?
            </p>
          </div>
          <div className="flex gap-1">
            <button
              onClick={closeModal}
              className="px-4  py-2 flex gap-1 items-center border border-[#FF6929] rounded-lg"
            >
              <span className="hidden lg:inline">
                <img src={loveIcon} alt="" />
              </span>
              <p className="lg:text-sm text-xs text-[#FF6929]">Fully engaged</p>
            </button>
            <button
              onClick={closeModal}
              className="px-4  py-2 flex gap-1 items-center border border-[#FF6929] rounded-lg"
            >
              <span className="hidden lg:inline">
                <img src={dislikeIcon} alt="" />
              </span>
              <p className="lg:text-sm text-xs text-[#FF6929]">
                Paying attention
              </p>
            </button>
            <button
              onClick={closeModal}
              className="px-4  py-2 flex gap-1 items-center border border-[#FF6929] rounded-lg"
            >
              <span className="hidden lg:inline">
                <img src={likeDislike} alt="" />
              </span>
              <p className="lg:text-sm text-xs text-[#FF6929]">Need a recap</p>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center">
        <img src={clock} alt="attention mode" className="w-40" />
      </div> */}

      {/* <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div className="text-center">
          <h1 className="text-2xl font-bold">Attention mode</h1>
          <p className="text-sm">
            To continue this presentation kindly click yes
          </p>
        </div>

        <div className="flex mt-4 gap-x-4">
          <button
            onClick={closeModal}
            className="w-full py-3 text-sm font-medium border rounded-lg cursor-pointer text-primary border-primary gap-x-2"
          >
            Continue
          </button>
          <button
            onClick={closeModal}
            className="w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2"
          >
            Yes
          </button>
        </div>
      </section> */}
    </section>
  );
}
