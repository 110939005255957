import React, { useEffect, useRef, useState } from "react";

//assets
import banner from "assets/svg/banner.svg";
import comments from "assets/svg/comments.svg";
import question from "assets/svg/questions.svg";
import moderator from "assets/svg/moderator.svg";
import projector from "assets/svg/projector.svg";
import watermark from "assets/svg/watermark.svg";
import downloads from "assets/svg/downloads.svg";
import { Switch } from "antd";
import padLock from "assets/svg/padLock.svg";
import instance from "../../../../lib/axiosInterceptor";

import {
  getDocumentFiles,
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";

//libraries
import { Modal } from "antd";

//components
import CommentModal from "views/Admin/Presentation/Modals/Settings/CommentModal";
import { useSelector } from "react-redux";

//slices
import { getSlidesState } from "store/Slides/slidesSlice";
import { toast } from "sonner";
import AuthInput from "../../../UI/Inputs/AuthInput";
import { CButton } from "../../../UI/Button/Buttons";

export default function Settings({
  // activeSlide,

  activePlaylist,
}) {
  const { presentationUI } = useSelector(getSlidesState);
  const { activeSlide } = presentationUI ?? {};
  const { note } = activeSlide ?? {};
  //useref
  const commentModalRef = useRef();
  //usestate
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const { documentFiles, presentationDetails } =
    useSelector(getPresentationState);
  const [waterMarkModalActive, setWaterMarkModalActive] = useState(false);

  const [payload, setPayload] = useState({});
  const [waterMarkLoading, setWaterMarkLoading] = useState(false);
  const [waterMarkValue, setWaterMarkValue] = useState("");

  //function
  const handleAddFileCancel = () => {
    setIsCommentModalOpen(false);
  };

  //use selector

  const handleDownloadTrigger = async (res) => {
    const payloadValue = {
      ...payload,
      canDownload: res ? 1 : 0,
    };
    setPayload({
      ...payload,
      canDownload: res ? 1 : 0,
    });
    try {
      const result = await instance.post(
        `/Presentation/Update/${presentationDetails.data.presentation.id}`,
        payloadValue
      );
      toast.success("Status Changed");
    } catch (e) {
      setPayload({
        ...payload,
        canDownload: res ? 0 : 1,
      });
      toast.error("Could not apply edit");
    }
  };

  const hadleAddWaterMark = async () => {
    setWaterMarkLoading(true);
    if (!waterMarkValue) {
      toast.error("Water mark is required");
      setWaterMarkLoading(false);
      return;
    }
    const payloadValue = {
      ...payload,
      watermark: waterMarkValue,
    };
    setPayload({
      ...payload,
      watermark: waterMarkValue,
    });
    try {
      const result = await instance.post(
        `/Presentation/Update/${presentationDetails.data.presentation.id}`,
        payloadValue
      );
      toast.success("Watermark updated");
      setWaterMarkModalActive(false);
    } catch (e) {
      setPayload({
        ...payload,
        watermark: waterMarkValue,
      });
      toast.error("Could not add watermark");
    } finally {
      setWaterMarkLoading(false);
    }
  };

  const handleQuestionTrigger = async (res) => {
    const payloadValue = {
      ...payload,
      allowQuestion: res,
    };
    setPayload({
      ...payload,
      allowQuestion: res,
    });
    try {
      const result = await instance.post(
        `/Presentation/Update/${presentationDetails.data.presentation.id}`,
        payloadValue
      );
      toast.success("Status Changed");
    } catch (e) {
      setPayload({
        ...payload,
        allowQuestion: !res,
      });
      toast.error("Could not apply edit");
    }
  };

  useEffect(() => {
    if (presentationDetails.data) {
      setPayload({
        expiry: presentationDetails.data.presentation.expiry,
        canDownload: presentationDetails.data.presentation.canDownload,
        title: presentationDetails.data.presentation.title,
        startTime: presentationDetails.data.presentation.startTime,
        passcode: presentationDetails.data.presentation.passcode,
        watermark: presentationDetails.data.presentation.watermark,
        allowQuestion: presentationDetails.data.presentation.questionsAllowed,
      });
    }
  }, presentationDetails);
  
  return (
    <section className="absolute hidden h-auto px-4 py-2 bg-white rounded-lg lg:self-start lg:block md:hidden right-10">
      <div>
        <h2 className="text-sm font-bold text-center uppercase border-b text-gray60">
          Settings
        </h2>
      </div>

      <section className="flex flex-col items-center py-4 text-xs font-medium gap-y-8 text-gray60">
        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={question} alt="" />
          <p>Questions</p>
          <span>
            <Switch
              checked={payload.allowQuestion}
              onChange={handleQuestionTrigger}
            />
          </span>
        </div>

        <div
          className="flex flex-col items-center cursor-pointer gap-y-1"
          onClick={() => setIsCommentModalOpen(true)}
        >
          <div className="relative">
            {note && (
              <span className="absolute top-[-4px] right-[-4px] w-2 h-2 bg-red-700 rounded-full"></span>
            )}
            <img src={comments} alt="" />
          </div>

          <p>Comments</p>
        </div>

        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={downloads} alt="" />
          <p>Downloads</p>
          <span>
            <Switch
              checked={payload.canDownload}
              onChange={handleDownloadTrigger}
            />
          </span>
        </div>

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={watermark} alt="" />
          <p>Watermark</p>
        </div> */}
        <div
          onClick={() => {
            setWaterMarkModalActive(true);
          }}
          className="flex flex-col items-center cursor-pointer gap-y-1"
        >
          <img src={padLock} alt="" />
          <p>Watermark</p>
          {/* <span>
            <Switch />
          </span> */}
        </div>

        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={banner} alt="" />
          <p>Banner</p>
          <span>
            <Switch />
          </span>
        </div>

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={projector} alt="" />
          <p>Projector</p>
        </div> */}

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={moderator} alt="" />
          <p>Moderator</p>
        </div> */}
      </section>

      <>
        {/* Modal */}

        <Modal
          open={isCommentModalOpen}
          onCancel={handleAddFileCancel}
          footer={null}
          title="Leave a note"
        >
          <CommentModal
            ref={commentModalRef}
            activeSlide={activeSlide}
            activePlaylist={activePlaylist}
            closeModal={handleAddFileCancel}
          />
        </Modal>

        <Modal
          onCancel={() => setWaterMarkModalActive(false)}
          footer={null}
          open={waterMarkModalActive}
          maskClosable={false}
          destroyOnClose={true}
        >
          <p className="text-[#333333] font-grotesk font-bold text-lg">
            Create or upload a watermark
          </p>
          <form className="mt-4">
            <p className="text-[#344054] "> Watermark name</p>
            <AuthInput
              name="waterMark"
              type="text"
              placeholder="e.g My Company name"
              defaultValue={payload.watermark}
              onChange={(e) => {
                setWaterMarkValue(e.target.value);
              }}
            />
            <div className="mt-4">
              <CButton
                loading={waterMarkLoading}
                text="Upload watermark"
                variant="dark"
                action={hadleAddWaterMark}
                fullWidth
              />
            </div>
          </form>
        </Modal>
        {/*  */}
      </>
    </section>
  );
}
