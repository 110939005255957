import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { addBriefcaseFile } from 'store/Briefcase/briefcaseSlice';
import {
  setAddToBriefcaseModalOpen,
  setSelectedFiles,
} from 'store/Workspace/workspaceSlice';

export default function useAddFilesToBriefcase() {
  const dispatch = useDispatch();

  const uploadSelectedFilesToBriefcases = async (
    selectedFiles,
    selectedBriefcases
  ) => {
    console.log(selectedFiles, selectedBriefcases);

    await selectedBriefcases.map(async (briefcase) => {
      const spaceId = briefcase.id;
      const uploadPromises = selectedFiles.map(async (document) => {
        const formData = new FormData();
        formData.append('DocumentId', document.id);

        return dispatch(addBriefcaseFile({ spaceId, formData }))
          .then((response) => {
            const { type } = response;
            if (type.includes('fulfilled')) {
              toast.success(`${document.name ?? ''} File added successfully`);
              // dispatch(getBriefcaseDocuments(spaceId));
            } else {
              toast.error(`File failed to add file to briefcase`);
            }
          })
          .catch((error) => {
            toast.error(`Failed to upload`);
          });
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
    });

    dispatch(setAddToBriefcaseModalOpen(false));
    dispatch(setSelectedFiles(false));
  };

  return {
    uploadSelectedFilesToBriefcases,
  };
}
