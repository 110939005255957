import React from "react";
import arrowLeft from "assets/svg/mobileBack.svg";
import mobileLock from "assets/svg/mobileLock.svg";
import ellipsisVertical from "assets/svg/ellipsis-vertical.svg";

export const PreviewHeader = () => {
  return (
    <div className="flex items-center my-4 justify-between">
      <div className="cursor-pointer">
        <img src={arrowLeft} alt="back arrow" />
      </div>
      <div className="flex items-center gap-2 font-semibold">
        <p>Marketing information</p>
        <span>
          <img src={mobileLock} alt="mobile lock" />
        </span>
      </div>

      <div className="cursor-pointer">
        <img src={ellipsisVertical} alt="" />
      </div>
    </div>
  );
};
