//assets
import zoom from "assets/svg/zoom.svg";
import gmail from "assets/svg/gmail.svg";
import linkedIn from "assets/svg/linkedIn.svg";
import facebook from "assets/svg/facebook.svg";
import microsoft from "assets/svg/microsoft.svg";
import FirstSlide from "components/Authentication/Login/FirstSlide";
import SecondSlide from "components/Authentication/Login/SecondSlide";
import ThirdSlide from "components/Authentication/Login/ThirdSlide";
import FourthSlide from "components/Authentication/Login/Fourth";

//variables

export const altLogins = [
  {
    img: gmail,
    name: "Google",
  },

  {
    img: linkedIn,
    name: "LinkedIn",
  },

  {
    img: microsoft,
    name: "Microsoft",
  },

  {
    img: zoom,
    name: "Zoom",
  },

  {
    img: facebook,
    name: "Facebook",
  },
];

export const items = [
  {
    id: "200",
    title: "Live presentation Anywhere, Anytime",
    description:
      "You can deliver presentations from anywhere, whether you're in a boardroom, a classroom, hosting a webinar or presenting on a big stage.",
    img: <FirstSlide />,
  },

  {
    id: "200",
    title: "Mobile Remote Control",
    description:
      "“Next slide please” …. not anymore. Seamlessly advance slides, pause, or go back with just a swipe, giving you complete control and freedom to engage with your audience at your own pace for your mobile phone",
    img: <SecondSlide />,
  },

  {
    id: "200",
    title: "Participant Interaction",
    description:
      "Your presentation participants can quickly navigate to any previous slide to clarify points they might have missed, ensuring everyone stays on the same page and maximizes understanding.",
    img: <ThirdSlide />,
  },

  {
    id: "200",
    title: "Moderated Q n A",
    description:
      "Maximize audience engagement with our live feed feature. Seamlessly integrate discussions into your presentation. Receive and respond to questions and comments from participants in real-time, empowering you to interact with your audience on your terms.",
    img: <FourthSlide />,
  },
];
export const responsiveOptions = [
  {
    breakpoint: "1400px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
];
