import React, {useEffect, useState} from "react";
import exportIcon from "assets/svg/exportIcon.svg";
import mobileLeave from "assets/svg/mobileLeaveIcon.svg";
import questionPlaceholder from "assets/svg/questionPlacholder.svg";
import viewers from "assets/svg/viewersPlaceholder.svg";
import * as signalR from "@microsoft/signalr";
import {Drawer, Modal} from "antd";
import AllViewersNew from "views/User/ViewerMode/Drawers/AllViewersNew";
import {UserInterface} from "interfaces/interfaces-data";
import {GetUsersListNew} from "hooks/SignalR/Invokers/Presenter/GetUserListNew";
import EndPresentations from "views/Admin/ActivePresentation/Modal";
import {useNavigate} from "react-router-dom";

export const PresentationHeader = ({
                                       meetingId,
                                       connection,
                                       presentationId,
                                   }: {
    meetingId?: string;
    connection?: signalR.HubConnection | null;
    presentationId: string;
}) => {
    const [openViewersDrawer, setViewersDrawer] = useState(false);
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [requests, setRequests] = useState<UserInterface[]>([]);
    const [endModalOpen, setEndModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const handleEventResponse = (id: string, users: UserInterface[]) => {
            setUsers((prev) => users.filter((item) => item.status == 1));
            setRequests((prev) => users.filter((item) => item.status == 0));
        };

        if (connection) {
            connection.on("UserList", handleEventResponse);
            // Fetch initial user list when connection is active
            GetUsersListNew(presentationId, connection);
        }

        // Cleanup event listener on component unmount or when connection changes
        return () => {
            connection?.off("UserList", handleEventResponse);
        };
    }, []);

    const endPresentation = () => {
        connection?.invoke("EndPresentation", presentationId).then(() => {
        });
        window.location.href = `/admin/presentation-details_?q=${presentationId}`;
    };
    return (
        <>
            <Drawer
                open={openViewersDrawer}
                onClose={() => setViewersDrawer(false)}
                width={490}
                title={`Viewers`}
                destroyOnClose={true}
            >
                <AllViewersNew
                    connection={connection}
                    requests={requests}
                    users={users}
                    presentationId={presentationId}
                />
            </Drawer>

            <Modal
                open={endModalOpen}
                onCancel={() => setEndModalOpen(false)}
                footer={null}
            >
                <EndPresentations
                    endPresentation={endPresentation}
                    closeModal={() => setEndModalOpen(false)}
                />
            </Modal>

            <div className="flex justify-between items-center">
        <span className="flex border border-white bg-[#EEEEEE] gap-3 p-3 rounded-lg">
          <p className="text-[#545454] font-grotesk font-semibold">
            {meetingId
                ? `${meetingId.slice(0, 3)}-${meetingId.slice(
                    3,
                    6
                )}-${meetingId.slice(6, 10)}`
                : "___-___-__"}
          </p>
          <span>
            <img src={exportIcon} alt=""/>
          </span>
        </span>
                <div className="flex gap-2 items-center">
          <span className="cursor-pointer">
            <img src={questionPlaceholder} alt=""/>
          </span>
                    <span
                        onClick={() => setViewersDrawer(true)}
                        className="cursor-pointer"
                    >
            <img src={viewers} alt=""/>
          </span>
                    <span
                        onClick={() => setEndModalOpen(true)}
                        className="cursor-pointer"
                    >
            <img src={mobileLeave} alt=""/>
          </span>
                </div>
            </div>
        </>
    );
};
