import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _deletePollQuestionType,
  _getPollDetails,
  _getPollTemplates,
  _getPolls,
  _pollCreation,
  _takePoll,
} from "services/Poll/pollServices";
import {
  _removeDocumentFromPlaylist,
  _updatePresentationEdit,
} from "services/Presentations/presentationServices";
import { getConnectionId } from "utils/localStorageServices";

const initialState = {
  polls: {
    isLoading: true,
    data: null,
  },
  presentations: {
    isLoading: true,
    data: null,
  },
  presentationDetails: {
    isLoading: true,
    data: null,
  },

  invitedGuests: {
    isLoading: false,
    data: null,
  },

  pollTemplates: {
    isLoading: false,
    data: null,
  },
  pollDetails: {
    isLoading: false,
    data: null,
  },
};

//GET

export const getPolls = createAsyncThunk(
  "poll/getPolls",
  async (presentationId) => {
    const response = await _getPolls(presentationId);
    return response.data;
  }
);

export const getPollDetails = createAsyncThunk(
  "poll/getPollDetails",
  async ({ presentationId, pollId }) => {
    const response = await _getPollDetails(presentationId, pollId);
    return response.data;
  }
);

export const getPollTemplates = createAsyncThunk(
  "poll/getPollTemplates",
  async () => {
    const response = await _getPollTemplates();
    return response.data;
  }
);

//POST

export const takePoll = createAsyncThunk("poll/takePoll", async (data) => {
  const response = await _takePoll(data);
  return response.data;
});

export const pollCreation = createAsyncThunk(
  "poll/pollCreation",
  async ({ data, presentationId }) => {
    const response = await _pollCreation(data, presentationId);
    return response.data;
  }
);

export const deletePollQuestionType = createAsyncThunk(
  "poll/deletePollQuestionType",
  async ({ presentationId, pollId }) => {
    const response = await _deletePollQuestionType(presentationId, pollId);
    return response.data;
  }
);

//PUT
export const updatePresentationEdit = createAsyncThunk(
  "presentations/updatePresentationEdit",
  async ({ presentationId, data }) => {
    const response = await _updatePresentationEdit(presentationId, data);
    return response.data;
  }
);

//DELETE
export const removeDocumentFromPlaylist = createAsyncThunk(
  "presentations/removeDocumentFromPlaylist",
  async ({ presentationId, removeDocId }) => {
    const response = await _removeDocumentFromPlaylist(
      presentationId,
      removeDocId
    );
    return response.data;
  }
);

export const pollSlice = createSlice({
  name: "poll",
  initialState,
  extraReducers: (builder) => {
    //get document files/slides
    builder
      .addCase(getPolls.pending, (state) => {
        state.polls.data = null;
        state.polls.isLoading = true;
      })
      .addCase(getPolls.fulfilled, (state, { payload }) => {
        state.polls.isLoading = false;
        state.polls.data = payload;
      })
      .addCase(getPolls.rejected, (state, { payload }) => {
        state.polls.isLoading = false;
      });

    //get poll details
    builder
      .addCase(getPollDetails.pending, (state) => {
        state.pollDetails.data = null;
        state.pollDetails.isLoading = true;
      })
      .addCase(getPollDetails.fulfilled, (state, { payload }) => {
        state.pollDetails.isLoading = false;
        state.pollDetails.data = payload;
      })
      .addCase(getPollDetails.rejected, (state, { payload }) => {
        state.pollDetails.isLoading = false;
      });

    //templates
    builder
      .addCase(getPollTemplates.pending, (state) => {
        state.pollTemplates.data = null;
        state.pollTemplates.isLoading = true;
      })
      .addCase(getPollTemplates.fulfilled, (state, { payload }) => {
        state.pollTemplates.isLoading = false;
        state.pollTemplates.data = payload;
      })
      .addCase(getPollTemplates.rejected, (state, { payload }) => {
        state.pollTemplates.isLoading = false;
      });
  },
});

export const getPollState = (state) => state.poll;

export default pollSlice.reducer;
