let toast;

export const setToast = (toastRef) => {
  toast = toastRef;
};

export const showToast = (severity, summary, detail) => {
  toast?.show({ severity, summary, detail });
};

export const errorToast = (detail) => {
  toast?.show({ severity: "error", summary: "Error", detail });
};

export const successToast = (detail) => {
  toast?.show({ severity: "success", summary: "Success", detail });
};


