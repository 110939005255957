import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isPollDrawerOpen: false,
  docId: null,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSidebar(state) {
      state.isOpen = true;
    },

    closeSidebar(state) {
      state.isOpen = false;
    },

    openPollDrawer(state, { payload }) {
      state.isPollDrawerOpen = true;
      state.docId = payload;
    },

    closePollDrawer(state) {
      state.isPollDrawerOpen = false;
    },
  },
});

export const getUIState = (state) => state.sidebarState;
export const { openSidebar, closeSidebar, openPollDrawer, closePollDrawer } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
