import React, { useState } from "react";
import { meetingPlanList, planList, plans } from "./data";

//assets
import zoom from "assets/svg/zoomBlack.svg";

export default function PricePlans() {
  //usestates
  const [tab, setTab] = useState(false);

  //variables

  return (
    <section className="my-8">
      {/* nav */}
      <section className=" bg-white rounded-lg [ px-6 py-3 ] [ w-[17.9375rem] mx-auto ]  ">
        <section className="[ flex justify-between items-center text-center ]">
          <div
            className={`${
              !tab &&
              "activeTab flex gap-x-2 items-center rounded-full  font-medium"
            } cursor-pointer [ px-6 py-2 ]`}
            onClick={() => setTab(false)}
          >
            {!tab && (
              <div className="w-[10px] h-[10px] bg-red-600 rounded-full"></div>
            )}
            <p>Monthly</p>
          </div>

          <div
            className={`${
              tab &&
              "activeTab flex gap-x-2 items-center rounded-full  font-medium"
            } cursor-pointer [ px-6 py-2 ]`}
            onClick={() => setTab(true)}
          >
            {tab && (
              <div className="w-[10px] h-[10px] bg-green-600 rounded-full"></div>
            )}
            <p>Yearly</p>
          </div>
        </section>
      </section>
      {/*  */}

      {/* plans & access  */}
      <section className="[ my-10  py-4 ] [ w-[90%] mx-auto ] bg-white rounded-lg">
        {/* plans */}
        <section className=" [ w-[90%] mx-auto ] pt-4 ">
          <section className="[ grid grid-cols-4 gap-x-10 ] ml-40 ">
            {plans?.map((plan, index) => {
              const isBusiness = plan?.title === "Business";
              const isBasic = plan?.title === "Basic";

              return (
                <section
                key={index}
                  className={` rounded-2xl  ${
                    isBusiness ? "bg-black text-white" : "bg-white"
                  } [ px-6 pt-6 pb-2 ] [ flex flex-col ] [ h-[267px] w-[250px] ] `}
                >
                  {isBusiness && (
                    <div className="flex self-end">
                      <div className="px-6 py-2 text-[11px] mt-[-10px] font-bold uppercase rounded-full bg-gray250">
                        most popular
                      </div>
                    </div>
                  )}
                  <div className="flex gap-x-2">
                    <h1 className="text-[2.25rem] font-bold">{plan?.price}</h1>
                    {!isBasic && (
                      <p className="self-end mb-2 text-gray40">
                        / {plan?.duration}
                      </p>
                    )}
                  </div>

                  <h3 className="my-2 text-2xl font-semibold">{plan?.title}</h3>

                  <p className="text-gray40">{plan?.description}</p>

                  <div>
                    <button
                      className={`w-full py-2 my-2 text-white  rounded-full
                       ${isBasic && "mt-12"}
                        ${isBusiness ? "bg-primary mt-3" : "bg-black"}
                        ${!isBusiness && !isBasic && "mt-6"}
                      
                      `}
                    >
                      Choose plan
                    </button>
                  </div>
                </section>
              );
            })}
          </section>
        </section>
        {/*  */}

        {/* access */}
        <section className="flex flex-col py-10 text-gray500">
          {planList.map((list, index) => {
            const isOdd = (index + 1) % 2 !== 0;
            return (
              <section
                className={`flex items-center  px-6 py-3 text-sm ${
                  isOdd && "bg-gray120 border border-gray400"
                } `}
              >
                <div className="w-[20%]">
                  <p>{list.title}</p>
                </div>

                <section className="[ grid grid-cols-4 gap-x-10 ] w-full text-center ">
                  <div>
                    <p>{list.agency}</p>
                  </div>
                  <div>
                    <p>{list.business}</p>
                  </div>
                  <div>
                    <p>{list.personal}</p>
                  </div>
                  <div>
                    <p>{list.basic}</p>
                  </div>
                </section>
              </section>
            );
          })}

          <section className="">
            <div className="flex items-center px-6 gap-x-1">
              <h3 className="py-4  font-semibold [ text-lg  text-black ]">
                Meetings and Presentation
              </h3>
              <img src={zoom} alt="" />
            </div>

            {meetingPlanList.map((list, index) => {
              const isOdd = (index + 1) % 2 !== 0;
              return (
                <section
                  className={`flex items-center  px-6 py-3 text-sm ${
                    isOdd && "bg-gray120 border border-gray400"
                  } `}
                >
                  <div className="w-[20%]">
                    <p>{list.title}</p>
                  </div>

                  <section className="[ grid grid-cols-4 gap-x-10 ] w-full text-center ">
                    <div>
                      <p>{list.agency}</p>
                    </div>
                    <div>
                      <p>{list.business}</p>
                    </div>
                    <div>
                      <p>{list.personal}</p>
                    </div>
                    <div>
                      <p>{list.basic}</p>
                    </div>
                  </section>
                </section>
              );
            })}

            <section
              className={`flex items-center  px-6 py-3 text-sm bg-gray120 border border-gray400 `}
            >
              <div className="w-[20%]">
                <p>Moderation</p>
              </div>

              <section className="[ grid grid-cols-4 gap-x-10 ] w-full text-center ">
                <div>
                  <p>Whitelist </p> <p> Guest list, </p> <p> Waiting room,</p>{" "}
                  <p> Verified email, </p> <p> authenticated users</p>
                </div>
                <div>
                  <p>Whitelist </p> <p> Guest list, </p> <p> Waiting room,</p>{" "}
                  <p> Verified email, </p> <p> authenticated users</p>
                </div>
                <div>
                  <p>Whitelist </p> <p> Guest list, </p> <p> Waiting room,</p>{" "}
                  <p> Verified email, </p>
                </div>
                <div>
                  <p>Ban users </p> <p> General access code, </p>
                </div>
              </section>
            </section>
          </section>
        </section>
        {/*  */}
      </section>
      {/*  */}
    </section>
  );
}
