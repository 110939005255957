import React, { useEffect, useState } from "react";

//assets
import pollHeader from "assets/svg/pollHeader.svg";
import profile from "assets/svg/profile-2user.svg";

//libraries
import { Chart } from "primereact/chart";
import { formatNumber } from "utils/formatter";
import { generateRandomColor } from "utils/colorGenerator";

export default function OptionMetricsTemplate({ details }) {
  const { question } = details ?? {};

  //usestate
  const [barChartData, setBarChartData] = useState({});
  const [barChartOptions, setBarChartOptions] = useState({});

  useEffect(() => {
    const generatedColors = [];
    const colorSet = new Set();

    const optionLabels = details?.options?.map((option) => {
      return option?.text;
    });

    optionLabels.forEach(() => {
      let color;
      do {
        color = `${generateRandomColor()}40`;
      } while (colorSet.has(color));
      colorSet.add(color);
      generatedColors.push(color);
    });

    const graphData = details?.options?.map((option) => {
      return option?.value;
    });

    const data = {
      labels: optionLabels,
      datasets: [
        {
          borderWidth: 1,

          data: graphData,
          backgroundColor: generatedColors,
          borderColor: generatedColors,
          borderRadius: 4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(0,0,0,0.1)",
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
      barThickness: 30,
    };

    setBarChartData(data);
    setBarChartOptions(options);
  }, [details]);
  return (
    <section className="px-6 bg-gray20 rounded-xl">
      <div className="flex items-center justify-center mt-5 mb-6 text-4xl font-bold text-gray600 gap-x-2">
        <img src={pollHeader} alt="" />
        <h3>{question}</h3>
      </div>

      <section className="py-4 w-[35%] mx-auto">
        {" "}
        <Chart
          type="bar"
          height="300"
          width="200"
          data={barChartData}
          options={barChartOptions}
        />
        <div className="flex items-center justify-center w-full py-4 text-sm font-semibold text-center text-gray40 gap-x-1">
          <img src={profile} alt="" />
          <p className="pt-1">{formatNumber(250)} votes</p>
        </div>
      </section>
    </section>
  );
}
