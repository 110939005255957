import React from 'react'

import hourGlass from "assets/image/hourGlass.png";

export default function JoinPresentationLoader() {
  return (
    <div className='flex items-center justify-center w-full py-10 hourGlassLoader'>
        <img src={hourGlass} alt="" className='w-10 ' />
    </div>
  )
}
