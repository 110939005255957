import Artisana from 'assets/svg/artisana.svg';
import WorkExpIcon from 'assets/svg/work-experience.svg';
import CloseIcon from 'assets/svg/closeIcon.svg';

export default function EduCert({ qualification, setTab }) {
  return (
    <section>
      <div
        className='hidden md:block fixed top-0 left-0 bg-black opacity-80 w-full h-[100vh] z-[100]'
        onClick={() => setTab(0)}
      ></div>

      <div className='md:py-5 w-full md:w-fit fixed top-0 left-[50%] -translate-x-[50%] z-[101] h-[100vh]'>
        <div className=' bg-white w-full md:w-[587px] p-5 md:p-10 overflow-y-auto md:rounded-xl h-full'>
          <div className='flex justify-between items-center'>
            <img src={WorkExpIcon} alt='work experience' />
            <img
              src={CloseIcon}
              alt='close tab'
              className='cursor-pointer'
              onClick={() => setTab(0)}
            />
          </div>
          <h2 className='text-xl font-bold mb-5 -ml-2 mt-3'>Work experience</h2>

          {qualification.map((item, index) => (
            <section key={index} className='pl-5 relative pb-5'>
              <div className='absolute top-0 left-0 -translate-x-[50%]'>
                <img src={Artisana} alt='' />
              </div>
              <div>
                <div>
                  <h3 className='text-sm font-medium text-[#333333]'>
                    {item.title}
                  </h3>
                  <p className='text-[#757575] text-sm italic my-1'>
                    {item.degree}
                  </p>
                  <p className='text-[#757575] text-xs'>{item.date}</p>
                  <p className='text-[#757575] text-xs'>{item.certID}</p>
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>
    </section>
  );
}
