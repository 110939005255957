import React from "react";
import { useDispatch, useSelector } from "react-redux";

//slices
import {
  clearFolderFiles,
  getFolderFiles,
  getWorkspaceFolders,
} from "store/Workspace/workspaceSlice";
import {
  getFileStructureState,
  setMostActiveFolder,
  updateOpenedFolder,
} from "store/FileStructure/fileStructureSlice";

//assets
import arrowRight from "assets/svg/arrowRight.svg";
import { Tooltip } from "antd";
import { trimText } from "utils/formatter";

export default function BreadCrumb() {
  const dispatch = useDispatch();

  //useselector
  const { personalWorkspaceId, mostActiveFolder, openedFolders } = useSelector(
    getFileStructureState
  );

  return (
    <div className="flex items-center gap-x-1 ">
      {/* <p>Workspace</p> */}

      {mostActiveFolder === "team" && <button>Team</button>}
      {personalWorkspaceId && mostActiveFolder !== "team" && (
        <>
          {/* <img src={arrowRight} alt="next page" /> */}
          <button
            onClick={() => {
              const { workspaceId, title } = personalWorkspaceId ?? {};
              dispatch(clearFolderFiles(mostActiveFolder?.id));
              dispatch(
                setMostActiveFolder({
                  id: workspaceId,
                  title: title,
                })
              );
              dispatch(getWorkspaceFolders(workspaceId));
              dispatch(updateOpenedFolder([]));
            }}
          >
            {personalWorkspaceId?.title}
          </button>

          {openedFolders?.length > 0 &&
            openedFolders?.map((folder, index) => {
              const { title, id } = folder ?? {};
              return (
                <button
                  key={index}
                  className="flex items-center gap-x-1"
                  onClick={() => {
                    dispatch(getFolderFiles(id));

                    dispatch(setMostActiveFolder(folder));
                    dispatch(
                      updateOpenedFolder(openedFolders.slice(0, index + 1))
                    );
                  }}
                >
                  <img src={arrowRight} alt="next page" />
                  <Tooltip placement="top" title={title}>
                    <p>{trimText(title, 15)}</p>
                  </Tooltip>
                </button>
              );
            })}
        </>
      )}
    </div>
  );
}
