import React from "react";
import { getFileTypeImg } from "utils/getFileType";

//libraries
import { Tooltip } from "antd";

//assets
import trash from "assets/svg/delete.svg";
import deletee from "assets/svg/whiteDelete.svg";

//utils
import { trimText } from "utils/formatter";

export default function DocuementList({
  doc,
  setRemoveDocId,
  activePlaylist,
  setConfirmDelete,
  setActivePlaylist,
}) {
  const { url, type } = doc ?? {};
  const img = getFileTypeImg(
    url !== "" ? url : type,
    activePlaylist?.id === doc?.id
  );

  return (
    <>
      <section key={doc.id} className="relative min-w-16 ">
        <Tooltip placement="top" title={doc?.title ?? type}>
          <div className="absolute right-0 top-1">
            <img
              src={activePlaylist?.id === doc?.id ? trash : deletee }
              alt="remove"
              className="w-3 cursor-pointer"
              onClick={() => {
                setRemoveDocId(doc.documentId);
                setConfirmDelete(true);
              }}
            />
          </div>
          <div
            onClick={() => setActivePlaylist(doc)}
            className={`flex flex-col items-center px-2 py-1 rounded cursor-pointer  ${
              activePlaylist?.id === doc?.id && " font-semibold text-primary"
            } `}
          >
            <img src={img} alt="" className="w-5 " />
            <p className="mt-1">{trimText(doc?.title ?? type , 10)}</p>
          </div>
        </Tooltip>
      </section>
    </>
  );
}
