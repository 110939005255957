import React from "react";
import { Navigate } from "react-router-dom";
import { getUserDetails } from "../utils/localStorageServices";
import moment from "moment";

export default function ProtectedRoute({ children }) {
  const tokenObject = getUserDetails();
  const tokenExpiry = tokenObject?.expiresIn;
  const token = tokenObject?.token;
  if (!tokenObject || !token || moment(tokenExpiry).isBefore(moment())) {
    const previousPath = localStorage.getItem("previousPath");
    return <Navigate to={"/login"} replace />;
  } else {
    return children;
  }
}
