export const AllowGuestIn = (
  connection,
  presentationId,
  email,
  setIsGrantAccessLoading,
  status
) => {
  if (connection) {
    connection.invoke("GrantAccess", presentationId, email, status).then(() => {
      setIsGrantAccessLoading(false);
    });
  }
};
