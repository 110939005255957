import React, { useEffect, useState } from "react";

//assets
import empty from "assets/svg/emptyTemplate.svg";

import { GetQuestions } from "hooks/SignalR/Invokers/Presenter/GetQuestions";
import { useGetQuestions } from "hooks/SignalR/Listeners/Presenter/useGetQuestions";
import { ClipLoader } from "react-spinners";
import QuestionCard from "components/Admin/ActivePresentation/QuestionsDrawer/QuestionCard";
import { getConnectionId } from "utils/localStorageServices";
import { CButton } from "../../../../components/UI/Button/Buttons";

export default function AllQuestions({ connection, presentationId }) {
  //usestate
  const [tab, setTab] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (connection && presentationId) {
      GetQuestions(connection, presentationId, getConnectionId());
    }
  }, [presentationId, connection, tab]);

  //hook function
  //hooks
  useGetQuestions(connection, presentationId, (questionList) => {
    const questions = questionList.sort((a, b) =>
      a?.askTime < b?.askTime ? 1 : -1
    );
    console.log(questions, 'questions')
    
    setQuestions(
      questions.filter((question) => {
        if (tab === 1) {
          return !question?.isAnswered;
        }

        if (tab === 2) {
          return question?.isAnswered;
        }

        return question;
      })
    );
    setIsLoading(false);
  });

  return (
    <section className="relative">
      <div className="mt-8">

      <CButton text="Ask a Question" />
      </div>

      <section className="fixed  py-5 bg-white top-12 w-[27%] ">
        <div className="flex items-center text-xs font-medium gap-x-4 text-gray60">
          <p
            className={` cursor-pointer ${
              tab === 0 && " font-bold text-primary"
            } `}
            onClick={() => setTab(0)}
          >
            ALL
          </p>
          <p
            className={` cursor-pointer ${
              tab === 1 && " font-bold text-primary"
            } `}
            onClick={() => setTab(1)}
          >
            UNANSWERED
          </p>
          <p
            className={` cursor-pointer ${
              tab === 2 && " font-bold text-primary"
            } `}
            onClick={() => setTab(2)}
          >
            {" "}
            ANSWERED
          </p>
        </div>
      </section>

      <section className="mt-4">
        {isLoading && (
          <div className="flex items-center justify-center w-full mt-20">
            <ClipLoader size={20} color="#FF6929" />
          </div>
        )}

        {!isLoading && (
          <div>
            {questions?.length > 0 &&
              questions?.map((question, index) => {
                return (
                  <QuestionCard
                    isViewer={true}
                    connection={connection}
                    question={question}
                    key={index}
                   
                  />
                );
              })}

            {questions.length < 1 && (
              <div className="h-[60vh] flex flex-col items-center justify-center">
                <img src={empty} alt="no viewer" />
                <p className="text-lg font-medium">No question on the list</p>
              </div>
            )}
          </div>
        )}
      </section>
    </section>
  );
}
