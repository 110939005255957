import React, { useEffect, useRef, useState } from "react";

//utils
import { trimText } from "utils/formatter";
import { getFileTypeImg } from "utils/getFileType";

//assets
import edit from "assets/svg/edit.svg";
import deletee from "assets/svg/whiteDelete.svg";
import { ProgressBar } from "primereact/progressbar";
import check from "assets/svg/Checkbox base.svg";
export default function ImageUploadPreview({
  details,
  multiFiles,
  deleteUpload,
  setMultiFiles,
  uploadProgress,
}) {
  const inputRef = useRef();
  const { id, size, mime, name, imgUrl } = details ?? {};

  //usestate
  const [finalImg, setFinalImg] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Use useEffect to focus the input when isEditing becomes true
  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    let img = mime.replace(".", "");

    if (img === "png" || img === "jpeg" || img === "jpg" || img === "svg") {
      setFinalImg(imgUrl);
    } else {
      setFinalImg(getFileTypeImg(img));
    }
  }, [imgUrl, mime]);

  //function
  const editDocument = (e) => {
    if (e.key?.toLowerCase() === "enter") {
      const filteredUpload = multiFiles.findIndex((upload) => {
        return upload?.id === id;
      });

      multiFiles[filteredUpload].name = inputRef.current.value;

      setMultiFiles([...multiFiles]);
      setIsEditing(false);
    }
  };

  const valueTemplate = () => {
    return <React.Fragment></React.Fragment>;
  };
  return (
    <section className="px-4 py-2 border rounded-lg">
      <section key={id} className="flex justify-between ">
        <div className="flex items-center gap-x-4 w-[83%] text-left">
          <img src={finalImg} alt="ppt file" className="w-[2rem]" />
          <div className="w-[90%]">
            <p
              className={`font-medium text-gray700 ${
                !isEditing ? "block" : "hidden"
              }`}
            >
              {trimText(name, 40)}
            </p>

            <input
              type="text"
              ref={inputRef}
              defaultValue={name}
              onKeyDown={editDocument}
              className={`w-full text-sm border-none outline-none ${
                isEditing ? "block" : "hidden"
              }`}
            />

            <p className="text-xs font-semibold blue300"> {size}</p>
          </div>
        </div>

        <div className="flex items-center gap-x-2 ">
          {uploadProgress === 100 && (
            <img
              src={check}
              alt="edit"
              className="cursor-pointer"
              onClick={() => setIsEditing(true)}
            />
          )}

          {!uploadProgress  && (
            <>
              <img
                src={edit}
                alt="edit"
                className="cursor-pointer"
                onClick={() => setIsEditing(true)}
              />
              <img
                src={deletee}
                alt="delete"
                className="cursor-pointer"
                onClick={() => deleteUpload(id)}
              />
            </>
          )}
        </div>
      </section>

      {uploadProgress < 100 && uploadProgress > 0 && (
        <div className="flex items-center w-full pb-2 text-sm gap-x-2">
          <ProgressBar
            color="#FF692990"
            value={uploadProgress}
            className="flex-grow h-2 mx-4"
            displayValueTemplate={valueTemplate}
          />
          <p className="text-xs">{uploadProgress} %</p>
        </div>
      )}
    </section>
  );
}
