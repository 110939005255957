import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Assets
import search from 'assets/svg/search.svg';
import sortIcon from 'assets/svg/sortIcon.svg';

import addGray from 'assets/svg/add-circle-gray.svg';
import addWhite from 'assets/svg/add-circle-white.svg';

// Libraries
import { FilterMatchMode } from 'primereact/api';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

import AllBriefcases from './Tables/AllBriefcases';
import { RootState } from 'store/store';
import { Dropdown, Menu } from 'antd';

// Hooks
import useAddFilesToBriefcase from 'hooks/Library/UseAddFilesToBriefcase';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//types
interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
  picUrl: string;
}

interface BriefcaseItems {
  bannerId: string;
  dateCreated: string;
  description: string;
  id: string;
  lastModified: string;
  members: Member[] | null;
  tags: string;
  teamId: string;
  title: string;
  documents: number;
}

interface Props {
  addFilesToBriefcase?: boolean;
}

export default function MyBriefCase({ addFilesToBriefcase }: Props) {
  const briefcases = useSelector(
    (state: RootState) => state.briefcase.briefcases.data
  );
  //

  const { uploadSelectedFilesToBriefcases } = useAddFilesToBriefcase();
  const selectedFiles = useSelector(
    (state: any) => state.workspace.selectedFiles
  );

  // States
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [briefcaseData, setBriefcaseData] = useState<BriefcaseItems[]>([
    ...briefcases,
  ]);
  const [sortBy, setSortBy] = useState('All spaces');
  const [disableBTN, setDisableBTN] = useState(true);
  const [selectedBriefcases, setSelectedBriefcases] = useState<
    BriefcaseItems[]
  >([]);

  // console.log(selectedBriefcases);
  //function

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  function sortAphabetically(items: BriefcaseItems[]): BriefcaseItems[] {
    return [...items].sort((a, b) => a.title.localeCompare(b.title));
  }

  // variables
  const filterItems = [
    {
      key: '1',
      action: 'All spaces',
      label: (
        <div
          className='flex gap-x-2 py2 pl-2 pr-5'
          onClick={() => {
            setBriefcaseData(briefcases);
            setSortBy('All spaces');
          }}
        >
          <p>All spaces</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'aphabetically',
      label: (
        <div
          className='flex gap-x-2 py2 pl-2 pr-5'
          onClick={() => {
            setBriefcaseData(sortAphabetically(briefcases));
            setSortBy('A -  Z');
          }}
        >
          <p>A - Z</p>
        </div>
      ),
    },
  ];

  return (
    <div>
      <section className={`mb-3 ${addFilesToBriefcase ? '' : 'hidden'}`}>
        <h1 className='text-2xl font-semibold'>Add files to briefcase</h1>
        <p className='text-[#667085] text-sm'>
          you can add to multiple briefcase
        </p>
      </section>
      {/* search & filter */}
      <section className='mb-4'>
        <section
          className={`w-full flex items-center ${
            addFilesToBriefcase ? ' justify-between' : ''
          }`}
        >
          <section
            className={`w-full flex items-center ${
              addFilesToBriefcase ? 'gap-x-3' : 'justify-between'
            }`}
          >
            <Dropdown
              trigger={['click']}
              dropdownRender={(menu) => (
                <Menu>
                  {filterItems.map((item) => {
                    return (
                      <Menu.Item
                        key={item.key}
                        // onClick={() => {
                        //   // setFileIndex(options.rowIndex);
                        //   // handleOptionsClick(options.rowIndex, item.action);
                        // }}
                      >
                        {item.label}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              )}
            >
              <div className='flex items-center w-28 h-8 rounded-md gap-x-2 bg-gray30 py-1 cursor-pointer px-3'>
                <img src={sortIcon} alt='sort' />
                <p className='text-[#545454] text-sm font-medium'>{sortBy}</p>
              </div>
            </Dropdown>

            <div className='flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30'>
              <img src={search} alt='sort' />
              <input
                placeholder=' search'
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                className='bg-transparent w-[90%] py-1 outline-none'
              />
            </div>
          </section>

          {/* Add selected files */}
          <section
            className={`w-full flex justify-end ${
              addFilesToBriefcase ? '' : 'hidden'
            }`}
          >
            <button
              className={`h-8 flex items-center gap-x-2 ${
                disableBTN
                  ? 'bg-[#E2E2E2] text-[#AFAFAF] cursor-not-allowed'
                  : 'bg-black text-white'
              } px-5 rounded-md  font-medium`}
              disabled={disableBTN}
              onClick={() => {
                uploadSelectedFilesToBriefcases(
                  selectedFiles,
                  selectedBriefcases
                );
              }}
            >
              <img src={disableBTN ? addGray : addWhite} alt='' />
              {`Add to selected briefcase${
                selectedBriefcases.length > 1 ? 's' : ''
              }`}
            </button>
          </section>
        </section>
      </section>
      {/* Table */}
      <AllBriefcases
        filters={filters}
        briefcaseData={briefcaseData}
        setDisableBTN={setDisableBTN}
        selectedBriefcases={selectedBriefcases}
        setSelectedBriefcases={setSelectedBriefcases}
      />
    </div>
  );
}
