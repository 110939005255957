import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

// Assets
import chevronDown from 'assets/svg/chevron-down-black.svg';
import sectionIcon from 'assets/svg/section-rectangle-group.svg';
import imageTypeIcon from 'assets/svg/imagetype-icon-jpeg-png.svg';

// Slice
import { Tooltip } from 'antd';
import FolderChildren from './FolderChildren';
import FolderHeader from './FolderHeader';

// Interface
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}

// Interface for each item in the main array
export interface PreviewPayload {
  id: string | null;
  title: string | null;
  docs: PreviewDocument[];
  subs: PreviewPayload[];
}

interface Props {
  doc: PreviewPayload;
  displayDocumentIndex: number;
  setDisplayDocumentIndex: Dispatch<SetStateAction<number>>;
  flattedDisplayDocuments: PreviewDocument[];
}

export default function Folder({
  doc,
  displayDocumentIndex,
  setDisplayDocumentIndex,
  flattedDisplayDocuments,
}: Props) {
  // State
  const [isOpen, setIsOpen] = useState(true);

  // const textRef = useRef<HTMLDivElement>(null);
  // const [isOverflow, setIsOverflow] = useState(false);

  // useEffect(() => {
  //   const checkOverflow = () => {
  //     if (textRef.current) {
  //       console.log('Scroll Width:', textRef.current.scrollWidth);
  //       console.log('Client Width:', textRef.current.clientWidth);
  //       setIsOverflow(
  //         textRef.current.scrollWidth > textRef.current.clientWidth
  //       );
  //     }
  //   };

  //   // Check overflow on component mount and on window resize
  //   checkOverflow();
  //   window.addEventListener('resize', checkOverflow);

  //   return () => {
  //     window.removeEventListener('resize', checkOverflow);
  //   };
  // }, []);

  return (
    <section className='border-b     w-full'>
      <section className=' relative h-fit     w-full'>
        {/* Folder Header */}
        <FolderHeader
          doc={doc}
          displayDocumentIndex={displayDocumentIndex}
          setDisplayDocumentIndex={setDisplayDocumentIndex}
          flattedDisplayDocuments={flattedDisplayDocuments}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {flattedDisplayDocuments[displayDocumentIndex].folderId === doc.id && (
          <div className='bg-primary absolute top-0 -right-[6px] translate-x-[50%] w-1 h-full rounded-full'></div>
        )}
      </section>
      {/* Folder documents */}
      <section
        className={`py-2 twoK:px-10 eightK:px-10 ${!doc.id ? '' : 'px-3'}`}
      >
        {isOpen &&
          doc.docs.map((doc_) => (
            <FolderChildren
              doc={doc_}
              displayDocumentIndex={displayDocumentIndex}
              setDisplayDocumentIndex={setDisplayDocumentIndex}
              flattedDisplayDocuments={flattedDisplayDocuments}
            />
            // <div
            //   className={`py-1 px-1 cursor-pointer flex gap-x-2 items-center my-2 ${
            //     flattedDisplayDocuments[displayDocumentIndex].id === doc_.id
            //       ? 'bg-[#F6F3EF] border border-[#D7D7D7] rounded-md'
            //       : ''
            //   }`}
            //   key={doc_.id}
            //   onClick={() => {
            //     const docIndex = flattedDisplayDocuments.findIndex(
            //       (document) => document.id === doc_.id
            //     );
            //     setDisplayDocumentIndex(docIndex);
            //   }}
            // >
            //   <img src={imageTypeIcon} alt='type' className='w-5' />
            //   <Tooltip
            //     title={<p className='text-black capitalize'>{doc_.title}</p>}
            //     color='white'
            //     placement='top'
            //   >
            //     {doc_.title && (
            //       <p className='text-sm'>{`${doc_.title.slice(0, 17)}${
            //         doc_.title.length > 17 ? '...' : ''
            //       }`}</p>
            //     )}
            //   </Tooltip>
            // </div>
          ))}
      </section>
    </section>
  );
}
