import { clearTranslatedAudio } from "store/Slides/slidesSlice";
import store from "store/store";
import { toast } from "sonner";

export const ViewerAskQuestion = (
  connection,
  presentationId,
  connectionId,
  slideId,
  question,
  audioQuestionId,
  setisLoading,
  closeModal
) => {
  if (connection) {
    console.log(
      presentationId,
      connectionId,
      slideId,
      question,
      audioQuestionId
    );
    connection
      .invoke(
        "AskQuestion",
        presentationId,
        connectionId,
        slideId,
        question,
        audioQuestionId
      )
      .then(() => {
        store.dispatch(clearTranslatedAudio());
        // setQuestion('');
        toast.success("Question sent");

        closeModal();
        setisLoading(false);
      });
  }
};
