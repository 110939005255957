// Assets
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import exportOrange from 'assets/svg/export-icon-orange.svg';
import profileAdd from 'assets/svg/profile-add.svg';
import documentDownload from 'assets/svg/document-download.svg';
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

export default function Analytics({briefcaseDocuments} : {briefcaseDocuments : any}) {
  const data = [
    {
      x: '1',
      y: '10',
      orange: 25,
      gray: 5,
      amt: 2400,
    },
    {
      x: '2',
      y: '20',
      orange: 29,
      gray: 6,
      amt: 2210,
    },
    {
      x: '3',
      y: '20',
      orange: 35,
      gray: 5,
      amt: 2290,
    },
    {
      x: '4',
      y: '20',
      orange: 23,
      gray: 45,
      amt: 2000,
    },
    {
      x: '5',
      y: '20',
      orange: 37,
      gray: 20,
      amt: 2181,
    },
    {
      x: '6',
      y: '30',
      orange: 38,
      gray: 30,
      amt: 2500,
    },
    {
      x: '7',
      y: '30',
      orange: 45,
      gray: 20,
      amt: 2100,
    },
    {
      x: '8',
      y: '40',
      orange: 25,
      gray: 5,
      amt: 2100,
    },
    {
      x: '9',
      y: '40',
      orange: 25,
      gray: 10,
      amt: 2100,
    },
    {
      x: '10',
      y: '50',
      orange: 20,
      gray: 20,
      amt: 2100,
    },
    {
      x: '11',
      y: '60',
      orange: 20,
      gray: 29,
      amt: 2100,
    },
  ];

  return (
    <section className='w-full pb-80'>
      <section className='grid grid-cols-1 md:grid-cols-2 gap-5'>
        {/* Presentation Analytics */}
        <section className='border rounded-xl'>
          {/* Heading */}
          <section className='flex justify-between p-3 border-b'>
            <div className='flex gap-x-2 items-center'>
              <img src={exportOrange} alt='export icon' />
              <h3 className='text-base font-medium'>Presentation Analytics</h3>
            </div>
            <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center bg-[#FCFCFC]'>
              <img src={ellipsisVertical} alt='ellipsis icon' />
            </div>
          </section>
          {/* Metrics */}
          <section className='p-3 flex gap-x-2'>
            {/* number of presentations */}
            <div className='border rounded-md bg-[#FCFCFC] flex flex-col gap-y-2 max-w-[201px] p-3'>
              <div className='flex gap-x-1 items-center'>
                <img src={profileAdd} alt='user' className='w-4' />
                <p className='text-sm'>Number of presentations</p>
              </div>
              <p className='text-xl font-medium'>20</p>
            </div>
            {/* document download */}
            <div className='border rounded-md bg-[#FCFCFC] flex flex-col gap-y-2 max-w-[201px] p-3'>
              <div className='flex gap-x-1 items-center'>
                <img src={documentDownload} alt='user' className='w-4' />
                <p className='text-sm'>Total downloads</p>
              </div>
              <p className='text-xl font-medium'>20</p>
            </div>
          </section>
          {/* Chart */}
          <section className='w-full h-[300px] pb-3'>
            <ResponsiveContainer height='100%' width='100%'>
              <AreaChart
                // height={500}
                // width={500}
                //no need for setting height and width because with the responsiveContainer
                //component we can set the height and weight to the same as the parent element
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
              >
                <defs>
                  <linearGradient id='colorOrange' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#f49871' stopOpacity={0.5} />
                    <stop offset='95%' stopColor='#fce6dd' stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id='colorGray' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#E1E3E8' stopOpacity={0.1} />
                    <stop offset='95%' stopColor='#E1E3E8' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey='x' //data for the x axis
                  axisLine={false} // removes the horizontal straight line
                  tickLine={false} //removes the x-axis tick line
                  tickMargin={20} //margin btw the tickline and the table
                />
                <YAxis
                  dataKey='y'
                  axisLine={false}
                  tickCount={5} // number of ticklines. it is 5 by default
                  tickLine={false}
                  tickMargin={20}
                />
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <Tooltip />
                <Area
                  type='monotone'
                  dataKey='orange'
                  stroke='#E86025'
                  fillOpacity={1}
                  fill='url(#colorOrange)'
                />
                <Area
                  type='monotone'
                  dataKey='gray'
                  stroke='#E1E3E8'
                  fillOpacity={1}
                  fill='url(#colorGray)'
                />
              </AreaChart>
            </ResponsiveContainer>
          </section>
        </section>
        {/*  */}
        {/*  */}
        {/*  */}
        {/* Share Analytics */}
        <section className='border rounded-xl'>
          {/* Heading */}
          <section className='flex justify-between p-3 border-b'>
            <div className='flex gap-x-2 items-center'>
              <img src={exportOrange} alt='export icon' />
              <h3 className='text-base font-medium'>Share Analytics</h3>
            </div>
            <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center bg-[#FCFCFC]'>
              <img src={ellipsisVertical} alt='ellipsis icon' />
            </div>
          </section>
          {/* Metrics */}
          <section className='p-3 flex gap-x-2'>
            {/* Total shared */}
            <div className='border rounded-md bg-[#FCFCFC] flex flex-col gap-y-2 max-w-[201px] p-3'>
              <div className='flex gap-x-1 items-center'>
                <img src={profileAdd} alt='user' className='w-4' />
                <p className='text-sm'>Total shared</p>
              </div>
              <p className='text-xl font-medium'>20</p>
            </div>
            {/* number of visitors */}
            <div className='border rounded-md bg-[#FCFCFC] flex flex-col gap-y-2 max-w-[201px] p-3'>
              <div className='flex gap-x-1 items-center'>
                <img src={documentDownload} alt='user' className='w-4' />
                <p className='text-sm'>Number of visitors</p>
              </div>
              <p className='text-xl font-medium'>20</p>
            </div>
            {/* number of downloads */}
            <div className='border rounded-md bg-[#FCFCFC] flex flex-col gap-y-2 max-w-[201px] p-3'>
              <div className='flex gap-x-1 items-center'>
                <img src={documentDownload} alt='user' className='w-4' />
                <p className='text-sm'>Number of downloads</p>
              </div>
              <p className='text-xl font-medium'>20</p>
            </div>
          </section>
          {/* Chart */}
          <section className='w-full h-[300px] pb-3'>
            <ResponsiveContainer height='100%' width='100%'>
              <AreaChart
                // height={500}
                // width={500}
                //no need for setting height and width because with the responsiveContainer
                //component we can set the height and weight to the same as the parent element
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
              >
                <defs>
                  <linearGradient id='colorOrange' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#f49871' stopOpacity={0.5} />
                    <stop offset='95%' stopColor='#fce6dd' stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id='colorGray' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#E1E3E8' stopOpacity={0.1} />
                    <stop offset='95%' stopColor='#E1E3E8' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey='x' //data for the x axis
                  axisLine={false} // removes the horizontal straight line
                  tickLine={false} //removes the x-axis tick line
                  tickMargin={20} //margin btw the tickline and the table
                />
                <YAxis
                  //   dataKey='y'
                  ticks={[10, 20, 30, 40, 50, 60]}
                  axisLine={false}
                  tickCount={5} // number of ticklines. it is 5 by default
                  tickLine={false}
                  tickMargin={20}
                />
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <Tooltip />
                <Area
                  type='monotone'
                  dataKey='orange'
                  stroke='#E86025'
                  fillOpacity={1}
                  fill='url(#colorOrange)'
                />
                <Area
                  type='monotone'
                  dataKey='gray'
                  stroke='#E1E3E8'
                  fillOpacity={1}
                  fill='url(#colorGray)'
                />
              </AreaChart>
            </ResponsiveContainer>
          </section>
        </section>
      </section>
    </section>
  );
}
