import Loader from "components/UI/Loader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPaymentState } from "store/Payment/paymentSlice";
import { formatNumber } from "utils/formatter";

export default function PlansModal() {
  //useselector
  const { plans, myPlans } = useSelector(getPaymentState);
  const { agencyPlan, businessPlan, personalPlan } = plans ?? {};

  //usestate
  const [tab, setTab] = useState(0);
  const [seats, setSeats] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [addUsers, setAddUsers] = useState([]);
  const [fileLimit, setFileLimit] = useState([]);
  const [activePlan, setActivePlan] = useState();
  const [durationFee, setDurationFee] = useState([]);
  const [poolStorage, setPoolStorage] = useState([]);
  const [mobileRemote, setMobileRemote] = useState([]);

  useEffect(() => {
    setActivePlan(myPlans?.data?.plan?.toLowerCase());
  }, [myPlans]);

  useEffect(() => {
    const planList = plans?.data;
    if (!planList) return;
    const extractFee = (planName) => {
      const duration = tab === 0 ? "month" : "year";
      const getPlan = planList?.find(
        (plan) => plan?.title?.toLowerCase() === planName
      )?.plans;

      const getFee = formatNumber(
        getPlan?.find((plan) => plan?.interval?.toLowerCase() === duration)
          ?.price
      );

      return getFee;
    };

    setDurationFee([
      extractFee("agency"),
      extractFee("business"),
      extractFee("personal"),
    ]);
  }, [plans, tab]);

  useEffect(() => {
    if (plans) {
      const extractPlanItem = (plan, itemName) => {
        return plan?.planItems?.find(
          (plan) => plan?.item?.toLowerCase() === itemName
        )?.friendlyValue;
      };

      setSeats([
        extractPlanItem(agencyPlan, "number of users"),
        extractPlanItem(businessPlan, "number of users"),
        extractPlanItem(personalPlan, "number of users"),
      ]);

      setAddUsers([
        extractPlanItem(agencyPlan, "additional user"),
        extractPlanItem(businessPlan, "additional user"),
        extractPlanItem(personalPlan, "additional user"),
      ]);

      setPoolStorage([
        extractPlanItem(agencyPlan, "pool storage"),
        extractPlanItem(businessPlan, "pool storage"),
        extractPlanItem(personalPlan, "pool storage"),
      ]);

      setFileLimit([
        extractPlanItem(agencyPlan, "individual file limit"),
        extractPlanItem(businessPlan, "individual file limit"),
        extractPlanItem(personalPlan, "individual file limit"),
      ]);

      setPlaylist([
        extractPlanItem(agencyPlan, "playlist"),
        extractPlanItem(businessPlan, "playlist"),
        extractPlanItem(personalPlan, "playlist"),
      ]);

      setMobileRemote([
        extractPlanItem(agencyPlan, "mobile remote"),
        extractPlanItem(businessPlan, "mobile remote"),
        extractPlanItem(personalPlan, "mobile remote"),
      ]);
    }
  }, [agencyPlan, businessPlan, personalPlan, plans]);

  return (
    <section>
      {(plans.isLoading || myPlans.isLoading) && <Loader />}

      {!plans.isLoading && !myPlans.isLoading && (
        <section className="font-grotesk">
          <h3 className="my-2 text-lg font-bold text-center text-gray40">
            Choose a plan{" "}
          </h3>
          {/* duration  */}
          <section className="[ flex items-center text-center justify-center ]">
            <div
              className={`${
                tab === 0 &&
                "activeTab flex gap-x-2 items-center rounded-full  font-medium"
              } cursor-pointer [ px-6 py-2 ]`}
              onClick={() => setTab(0)}
            >
              {tab === 0 && (
                <div className="w-[10px] h-[10px] bg-red-600 rounded-full"></div>
              )}
              <p>Monthly</p>
            </div>

            <div
              className={`${
                tab === 1 &&
                "activeTab flex gap-x-2 items-center rounded-full  font-medium"
              } cursor-pointer [ px-6 py-2 ]`}
              onClick={() => setTab(1)}
            >
              {tab === 1 && (
                <div className="w-[10px] h-[10px] bg-green-600 rounded-full"></div>
              )}
              <p>Yearly</p>
            </div>
          </section>
          {/*  */}
          <section className="w-[98%] h-full mx-auto bg-white rounded-2xl mt-4">
            <section>
              <section className="flex">
                <section className="w-[13%] right-border py-4 px-6"></section>

                {/* agency  */}
                <section className="w-[25%] right-border  py-4 px-4">
                  <section
                    className={` rounded-3xl  bg-black text-white
                 [ px-6 pt-6 pb-2 ] [ flex flex-col ] [ w-[95%] mx-auto] `}
                  >
                    <div className="flex self-end">
                      <div className="px-6 py-2 text-[11px] mt-[-10px] font-bold uppercase rounded-full bg-gray250">
                        most popular
                      </div>
                    </div>

                    <div className="flex gap-x-2">
                      <h1 className="text-[36px] font-bold">
                        ${durationFee[0]}
                        <span className="text-lg font-normal text-white">
                          /{tab === 0 ? "month" : "year"}
                        </span>
                      </h1>
                    </div>

                    <h3 className="my-2 text-2xl font-semibold">Agency</h3>

                    <p className="text-white text-md">
                      Personal + Full Dataroom+ Full Scheduler
                    </p>

                    {activePlan === "agency" ? (
                      <button className="w-full py-3 my-8 font-medium rounded-full cursor-not-allowed bg-gray200 text-gray500">
                        Current plan
                      </button>
                    ) : (
                      <Link
                        to={`/admin/checkout/agency`}
                        // to={`/admin/checkout/${agencyPlan?.paymentProductId}`}
                      >
                        <button
                          className={`w-full py-3 my-2 text-white  rounded-full
                 
                   bg-primary mt-6
                  
                  `}
                        >
                          Choose plan
                        </button>
                      </Link>
                    )}
                  </section>{" "}
                </section>
                {/*  */}

                <section className="w-[23%] right-border py-4 px-4">
                  <h1 className="font-bold text-gray600 text-[36px]">
                    ${durationFee[1]}
                    <span className="text-lg font-normal text-gray40">
                      /{tab === 0 ? "month" : "year"}
                    </span>
                  </h1>
                  <h3 className="text-black text-[1.25rem] font-semibold mt-4">
                    Business
                  </h3>
                  <p className="mt-2 text-gray650 text-md">
                    Personal + Extended Dataroom +Team Scheduler
                  </p>

                  {activePlan === "business" ? (
                    <button className="w-full py-3 my-8 font-medium rounded-full cursor-not-allowed bg-gray200 text-gray500">
                      Current plan
                    </button>
                  ) : (
                    <Link
                      to={`/admin/checkout/business`}
                      // to={`/admin/checkout/${businessPlan?.paymentProductId}`}
                    >
                      <button className="w-full py-3 my-5 font-medium text-white bg-black rounded-full">
                        Choose plan
                      </button>
                    </Link>
                  )}
                </section>

                <section className="w-[21%] right-border py-4 px-4">
                  <h1 className="font-bold text-gray600 text-[36px]">
                    ${durationFee[2]}
                    <span className="text-lg font-normal text-gray40">
                      /{tab === 0 ? "month" : "year"}
                    </span>
                  </h1>
                  <h3 className="text-black text-[1.25rem] font-semibold mt-4">
                    Personal
                  </h3>
                  <p className="mt-4 text-gray650 text-md">
                    Dataroom + Individual Scheduler
                  </p>

                  {activePlan === "personal" ? (
                    <button className="w-full py-3 my-8 font-medium rounded-full cursor-not-allowed bg-gray200 text-gray500">
                      Current plan
                    </button>
                  ) : (
                    <Link to={`/admin/checkout/personal`}>
                      <button className="w-full py-3 my-8 font-medium text-white bg-black rounded-full">
                        Choose plan
                      </button>
                    </Link>
                  )}
                </section>

                <section className="w-[18%]  py-4 px-6">
                  <h1 className="font-bold text-gray600 text-[36px]">$0</h1>
                  <h3 className="text-black text-[1.25rem] font-semibold mt-4">
                    Basic
                  </h3>
                  <p className="mt-4 text-gray650 text-md">
                    File, Presentation
                  </p>

                  {activePlan}

                  {activePlan === "basic" && (
                    <button className="w-full py-3 my-8 font-medium rounded-full cursor-not-allowed bg-gray200 text-gray500">
                      Current plan
                    </button>
                  )}
                </section>
              </section>

              <section className="flex border border-b-0 border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border py-2 pl-4">
                  <p>Number of users</p>
                </section>

                <section className="w-[25%]  right-border  py-2 pl-4">
                  <p>{seats?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{seats?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{seats?.[2]}</p>
                </section>

                <section className="w-[18%] py-2 pl-4">
                  <p>1</p>
                </section>
              </section>

              <section className="flex border border-b-0 border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border py-2 pl-4">
                  <p>Additional user</p>
                </section>

                <section className="w-[25%] right-border  py-2 pl-4">
                  <p>{addUsers?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{addUsers?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{addUsers?.[2]}</p>
                </section>

                <section className="w-[18%] py-2 pl-4">
                  <p>{addUsers?.[3]}</p>
                </section>
              </section>

              <section className="flex border border-b-0 border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border  py-2 pl-4">
                  <p>Pool Storage</p>
                </section>

                <section className="w-[25%]  right-border  py-2 pl-4">
                  <p>{poolStorage?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{poolStorage?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{poolStorage?.[2]}</p>
                </section>

                <section className="w-[18%] py-2 pl-4">
                  <p>200MB</p>
                </section>
              </section>

              <section className="flex border border-b-0 border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border py-2 pl-4">
                  <p>Individual file limit</p>
                </section>

                <section className="w-[25%] right-border py-2 pl-4">
                  <p>{fileLimit?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{fileLimit?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{fileLimit?.[2]}</p>
                </section>

                <section className="w-[18%]  py-2 pl-4">
                  <p>200MB</p>
                </section>
              </section>

              <section className="flex border border-b-0 border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border py-2 pl-4">
                  <p>Playlist</p>
                </section>
                <section className="w-[25%] right-border py-2 pl-4">
                  <p>{playlist?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{playlist?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{playlist?.[2]}</p>
                </section>
                <section className="w-[18%]  py-2 pl-4">
                  <p>No</p>
                </section>
              </section>

              <section className="flex border border-l-0 border-r-0 text-gray500">
                <section className="w-[13%] right-border py-2 pl-4">
                  <p>Mobile Remote</p>
                </section>

                <section className="w-[25%]  right-border py-2 pl-4">
                  <p>{mobileRemote?.[0]}</p>
                </section>

                <section className="w-[23%] right-border py-2 pl-4">
                  <p>{mobileRemote?.[1]}</p>
                </section>

                <section className="w-[21%] right-border py-2 pl-4">
                  <p>{mobileRemote?.[2]}</p>
                </section>

                <section className="w-[18%] py-2 pl-4 text-sm">
                  <p>Basic (Move forward and backward)</p>
                </section>
              </section>
            </section>
            <Link to="/pricing">
              <section className="flex items-center py-4 pl-6 font-medium text-primary gap-x-2">
                <p>See all features</p>{" "}
                <i className="pt-1 pi pi-arrow-right text-primary"></i>
              </section>
            </Link>
          </section>
        </section>
      )}
    </section>
  );
}
