import Loader from 'components/UI/Loader';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SlidesPreview from './SlidesPreview';

interface Slide {
  id: string;
  url: string;
  position: number;
  title: string;
  note: string | null;
}
interface ImageCache {
  [id: string]: string;
}
interface Props {
  screenWidth: number;
  minScreenWidth: number;
  isLoading: boolean;
  activeImage: string | null;
  activeImgRef: MutableRefObject<HTMLImageElement | null>;
  isPresentationDetailsLoading: boolean;
  slides: Slide[];
  slideDocumentIndex: number;
  imageCache: MutableRefObject<ImageCache>;
  setSlideDocumentIndex: Dispatch<SetStateAction<number>>;
  isGridDisplayActive: boolean;
  setIsGridDisplayActive: Dispatch<SetStateAction<boolean>>;
  isSlides: boolean;
}

export default function DocumentDisplay({
  screenWidth,
  minScreenWidth,
  isLoading,
  activeImage,
  activeImgRef,
  slides,
  slideDocumentIndex,
  imageCache,
  isPresentationDetailsLoading,
  setSlideDocumentIndex,
  isGridDisplayActive,
  setIsGridDisplayActive,
  isSlides,
}: Props) {
  return (
    <section
      className={`p-5 bg-gray100 w-full rounded-xl ${
        isGridDisplayActive ? '' : ' flex items-center justify-center'
      }  overflow-hidden [ dynamic-styling-height flex-grow flex-shrink basis-0 ]
    `}
    >
      {/* <section
      className={`p-5 bg-gray100 w-full rounded-xl flex items-center justify-center overflow-hidden 
    ${screenWidth > minScreenWidth ? 'h-[70%]' : 'h-[75%]'}
    `}
    > */}
      {isLoading || !activeImage || isPresentationDetailsLoading ? (
        <Loader />
      ) : (
        <>
          {!isGridDisplayActive ? (
            <img
              ref={activeImgRef}
              src={activeImage}
              alt='file'
              className='rounded-lg object-contain'
              style={{
                maxWidth: `100%`,
                maxHeight: `100%`,
                width: '100%',
                height: 'auto',
              }}
            />
          ) : (
            <>
              {isSlides && (
                <section className='hide-scrollbar grid grid-cols-5 w-full max-h-full gap-3 px-5 overflow-y-auto h-ful auto-rows-aut'>
                  {slides.map((item, index) => (
                    <section
                      key={item.id}
                      onClick={() => {
                        setSlideDocumentIndex(index);
                        setIsGridDisplayActive(false);
                      }} // Adjust index for sliced items
                      // ref={(el) => (itemRefs.current[index] = el)}
                      className={`h-fit w-full `}
                    >
                      <SlidesPreview
                        slide={item}
                        index={index}
                        imageCache={imageCache}
                        slideDocumentIndex={slideDocumentIndex}
                        showImage={true}
                        isGridDisplay={true}
                      />
                    </section>
                  ))}
                </section>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
}
