import { toast } from "react-toastify";

export const GetQuestions = (connection, presentationId, connectionId) => {
  const state = connection?.state;
  const presentaConnectionId = connectionId ?? "";

  if (state === "Connected") {
    connection
      .invoke("GetQuestions", presentationId, presentaConnectionId)
      .catch((err) => {
        toast.error("An error occurred invoking 'GetQuestions' on the server");
      });
  }
};
