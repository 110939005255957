import React, { ReactNode } from "react";
import { Drawer } from "antd";

export const SideDrawer = ({
  onClose,
  open,
  children,
  title,
}: {
  onClose: () => void;
  open: boolean;
  children: ReactNode;
  title: string;
}) => {
  return (
    <Drawer width={500} closeIcon={false} onClose={onClose} open={open}>
      <p className="text-lg font-semibold text-[#101828] font-grotesk">
        {title}
      </p>
      <div className="py-2">{children}</div>
    </Drawer>
  );
};
