import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _addDocumentToPresentation,
  _addPresentationGuest,
  _beginPresentationEdit,
  _deleteDocument,
  _deletePresentation,
  _editPresentation,
  _getAllPresentations,
  _getAttentionGraphData,
  _getDownloadGraphData,
  _getInvitedGuests,
  _getPollMetrics,
  _getPresentationDetails,
  _getPresentationMetrics,
  _getPresentationPerformanceSummary,
  _getPresentationPlaylist,
  _getPresentationSlideMetrics,
  _getPresentationVisitors,
  _removeDocumentFromPlaylist,
  _schedulePresentation,
  _updatePresentationEdit,
} from "services/Presentations/presentationServices";
import { _getDocumentFiles } from "services/Workspace/workspaceServices";
import { getConnectionId } from "utils/localStorageServices";

const initialState = {
  loadingStatus: "idle",
  presentations: {
    isLoading: true,
    data: null,
  },
  presentationDetails: {
    isLoading: true,
    data: null,
  },
  documentFiles: {
    isLoading: false,
    data: null,
  },
  invitedGuests: {
    isLoading: false,
    data: null,
  },

  presentationMetrics: {
    isLoading: false,
    data: null,
  },
  pollMetrics: {
    isLoading: false,
    data: null,
  },

  downloadGraphData: {
    isLoading: true,
    data: null,
  },
  attentionGraphData: {
    isLoading: true,
    data: null,
  },

  presentationVisitors: {
    isLoading: true,
    data: null,
  },

  presentationPlaylist: {
    isLoading: true,
    data: null,
  },

  presentationSlideMetrics: {
    isLoading: true,
    data: null,
  },
  presentationPerformanceSummary: {
    isLoading: true,
    data: null,
  },
  presentationDeleted: {
    isLoading: true,
    data: null,
  },
};

//GET

export const getPresentationPerformanceSummary = createAsyncThunk(
  "presentations/getPresentationPerformanceSummary",
  async (id) => {
    const response = await _getPresentationPerformanceSummary(id);
    return response.data;
  }
);

export const getPresentationSlideMetrics = createAsyncThunk(
  "presentation/getPresentationSlideMetrics",
  async (id) => {
    const response = await _getPresentationSlideMetrics(id);
    return response.data;
  }
);

export const getPresentationPlaylist = createAsyncThunk(
  "presentations/getPresentationPlaylist",
  async (id) => {
    const response = await _getPresentationPlaylist(id);
    return response.data;
  }
);
export const getPresentationVisitors = createAsyncThunk(
  "presentations/getPresentationVisitors",
  async (id) => {
    const response = await _getPresentationVisitors(id);
    return response.data;
  }
);
export const getInvitedGuests = createAsyncThunk(
  "presentations/getInvitedGuests",
  async (id) => {
    const response = await _getInvitedGuests(id);
    return response.data;
  }
);

export const getPresentationMetrics = createAsyncThunk(
  "presentations/getPresentationMetrics",
  async (presentationId) => {
    const response = await _getPresentationMetrics(presentationId);
    return response.data;
  }
);

export const getAttentionGraphData = createAsyncThunk(
  "presentations/getAttentionGraphData",
  async (presentationId) => {
    const response = await _getAttentionGraphData(presentationId);
    return response.data;
  }
);

export const getDownloadGraphData = createAsyncThunk(
  "presentations/getDownloadGraphData",
  async (presentationId) => {
    const response = await _getDownloadGraphData(presentationId);
    return response.data;
  }
);

export const getPollMetrics = createAsyncThunk(
  "presentations/getPollMetrics",
  async (presentationId) => {
    const response = await _getPollMetrics(presentationId);
    return response.data;
  }
);

export const getAllPresentations = createAsyncThunk(
  "presentations/getAllPresentations",
  async (data) => {
    const response = await _getAllPresentations(data);
    return response.data;
  }
);

export const getPresentationDetails = createAsyncThunk(
  "presentations/getPresentationDetails",
  async (presentationId) => {
    const response = await _getPresentationDetails(
      presentationId,
      getConnectionId()
    );
    return response.data;
  }
);

export const getDocumentFiles = createAsyncThunk(
  "presentations/getDocument",
  async (id) => {
    const response = await _getDocumentFiles(id);
    return response.data;
  }
);

//POST
export const addDocumentToPresentation = createAsyncThunk(
  "presentations/addDocumentToPresentation",
  async ({ data, config }) => {
    const response = await _addDocumentToPresentation(data, config);
    return response.data;
  }
);

export const addPresentationGuest = createAsyncThunk(
  "presentations/addPresentationGuest",
  async ({ presentationId, guestList }) => {
    const response = await _addPresentationGuest(presentationId, guestList);
    return response.data;
  }
);

export const schedulePresentation = createAsyncThunk(
  "presentations/schedulePresentation",
  async ({ presentationId, data }) => {
    const response = await _schedulePresentation(presentationId, data);
    return response.data;
  }
);

export const beginPresentationEdit = createAsyncThunk(
  "presentations/beginPresentationEdit",
  async (data) => {
    const response = await _beginPresentationEdit(data);
    return response.data;
  }
);

export const editPresentation = createAsyncThunk(
  "presentations/editPresentation",
  async ({ presentationId, data }) => {
    const response = await _editPresentation(presentationId, data);
    return response.data;
  }
);

//PUT
export const updatePresentationEdit = createAsyncThunk(
  "presentations/updatePresentationEdit",
  async ({ presentationId, data }) => {
    const response = await _updatePresentationEdit(presentationId, data);
    return response.data;
  }
);

//DELETE
export const removeDocumentFromPlaylist = createAsyncThunk(
  "presentations/removeDocumentFromPlaylist",
  async ({ presentationId, removeDocId }) => {
    const response = await _removeDocumentFromPlaylist(
      presentationId,
      removeDocId
    );
    return response.data;
  }
);

export const deletePresentation = createAsyncThunk(
  "presentations/deletePresentation",
  async (presentationId) => {
    const response = await _deletePresentation(presentationId);
    return response.data;
  }
);

export const deleteDocument = createAsyncThunk(
  "presentations/deleteDocument",
  async ({ selectedDocument, isArchive }) => {
    const response = await _deleteDocument(selectedDocument, isArchive);
    return response.data;
  }
);

export const presentationSlice = createSlice({
  name: "presentations",
  initialState,
  extraReducers: (builder) => {
    //get presentation vistors
    builder
      .addCase(getPresentationVisitors.pending, (state) => {
        state.presentationVisitors.data = null;
        state.presentationVisitors.isLoading = true;
      })
      .addCase(getPresentationVisitors.fulfilled, (state, { payload }) => {
        state.presentationVisitors.isLoading = false;
        state.presentationVisitors.data = payload;
      })
      .addCase(getPresentationVisitors.rejected, (state, { payload }) => {
        state.presentationVisitors.isLoading = false;
      });

    //get attention graph metrics
    builder
      .addCase(getAttentionGraphData.pending, (state) => {
        state.attentionGraphData.data = null;
        state.attentionGraphData.isLoading = true;
      })
      .addCase(getAttentionGraphData.fulfilled, (state, { payload }) => {
        state.attentionGraphData.isLoading = false;
        state.attentionGraphData.data = payload;
      })
      .addCase(getAttentionGraphData.rejected, (state, { payload }) => {
        state.attentionGraphData.isLoading = false;
      });

    //get download graph metrics
    builder
      .addCase(getDownloadGraphData.pending, (state) => {
        state.downloadGraphData.data = null;
        state.downloadGraphData.isLoading = true;
      })
      .addCase(getDownloadGraphData.fulfilled, (state, { payload }) => {
        state.downloadGraphData.isLoading = false;
        state.downloadGraphData.data = payload;
      })
      .addCase(getDownloadGraphData.rejected, (state, { payload }) => {
        state.downloadGraphData.isLoading = false;
      });

    //get presentation metrics
    builder
      .addCase(getPresentationMetrics.pending, (state) => {
        state.presentationMetrics.data = null;
        state.presentationMetrics.isLoading = true;
      })
      .addCase(getPresentationMetrics.fulfilled, (state, { payload }) => {
        state.presentationMetrics.isLoading = false;
        state.presentationMetrics.data = payload;
      })
      .addCase(getPresentationMetrics.rejected, (state, { payload }) => {
        state.presentationMetrics.isLoading = false;
      });

    //get poll metrics
    builder
      .addCase(getPollMetrics.pending, (state) => {
        state.pollMetrics.data = null;
        state.pollMetrics.isLoading = true;
      })
      .addCase(getPollMetrics.fulfilled, (state, { payload }) => {
        state.pollMetrics.isLoading = false;
        state.pollMetrics.data = payload;
      })
      .addCase(getPollMetrics.rejected, (state, { payload }) => {
        state.pollMetrics.isLoading = false;
      });

    //get document files/slides
    builder
      .addCase(getDocumentFiles.pending, (state) => {
        state.documentFiles.data = null;
        state.documentFiles.isLoading = true;
      })
      .addCase(getDocumentFiles.fulfilled, (state, { payload }) => {
        state.documentFiles.isLoading = false;
        state.documentFiles.data = payload;
      })
      .addCase(getDocumentFiles.rejected, (state, { payload }) => {
        state.documentFiles.isLoading = false;
      });

    //invited guests
    builder
      .addCase(getInvitedGuests.pending, (state) => {
        state.invitedGuests.isLoading = true;
      })
      .addCase(getInvitedGuests.fulfilled, (state, { payload }) => {
        state.invitedGuests.isLoading = false;
        state.invitedGuests.data = payload;
      })
      .addCase(getInvitedGuests.rejected, (state, { payload }) => {
        state.invitedGuests.isLoading = false;
      });

    builder
      .addCase(getAllPresentations.pending, (state) => {
        state.presentations.isLoading = true;
      })
      .addCase(getAllPresentations.fulfilled, (state, { payload }) => {
        state.presentations.isLoading = false;
        state.presentations.data = payload;
      });

    builder
      .addCase(getPresentationDetails.pending, (state) => {
        state.presentationDetails.data = null;
        state.presentationDetails.isLoading = true;
      })
      .addCase(getPresentationDetails.fulfilled, (state, { payload }) => {
        state.presentationDetails.isLoading = false;
        state.presentationDetails.data = payload;
      });

    //get presentation playlist
    builder
      .addCase(getPresentationPlaylist.pending, (state) => {
        state.presentationPlaylist.data = null;
        state.presentationPlaylist.isLoading = true;
      })
      .addCase(getPresentationPlaylist.fulfilled, (state, { payload }) => {
        state.presentationPlaylist.data = payload;
        state.presentationPlaylist.isLoading = false;
      })
      .addCase(getPresentationPlaylist.rejected, (state, { payload }) => {
        state.presentationPlaylist.isLoading = false;
      });

    //Get presentation slide metrics or graph data
    builder
      .addCase(getPresentationSlideMetrics.pending, (state) => {
        state.presentationSlideMetrics.data = null;
        state.presentationSlideMetrics.isLoading = true;
      })
      .addCase(getPresentationSlideMetrics.fulfilled, (state, { payload }) => {
        state.presentationSlideMetrics.data = payload;
        state.presentationSlideMetrics.isLoading = false;
      })
      .addCase(getPresentationSlideMetrics.rejected, (state, { payload }) => {
        state.presentationSlideMetrics.data = null;
        state.presentationSlideMetrics.isLoading = false;
      });

    // Get presentation performance summary

    builder
      .addCase(getPresentationPerformanceSummary.pending, (state) => {
        state.presentationPerformanceSummary.data = null;
        state.presentationPerformanceSummary.isLoading = true;
      })
      .addCase(
        getPresentationPerformanceSummary.fulfilled,
        (state, { payload }) => {
          state.presentationPerformanceSummary.isLoading = false;
          state.presentationPerformanceSummary.data = payload;
        }
      )
      .addCase(
        getPresentationPerformanceSummary.rejected,
        (state, { payload }) => {
          state.presentationPerformanceSummary.isLoading = false;
          state.presentationPerformanceSummary.data = null;
        }
      );
  },
});

export const getPresentationState = (state) => state.presentations;

export default presentationSlice.reducer;
