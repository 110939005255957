import React, { useState } from 'react';

//assets
import demo from 'assets/video/demo.mp4';

//libraries
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';

//utils
import extractMeetingId from 'utils/extractMeetingId';
import { toast } from 'sonner';

export default function Banner() {
  const [meetingId, setMeetingId] = useState('');
  const navigate = useNavigate();

  const handleJoinPresentation = () => {
    const meetingId_ = extractMeetingId(meetingId);
    if (meetingId_) {
      navigate(`/join-presentation/${meetingId_}`);
    } else {
      toast.error('Not a valide meeting link');
    }
  };

  return (
    <section>
      <section className='flex items-center justify-center w-full mb-10 text-sm px-5'>
        <form
          onSubmit={handleJoinPresentation}
          className='rounded-2xl bg-white border border-[#E2E4E9] min-[]: gap-x-5 items-center px-5 md:px-8 py-2 w-full sm:w-[90%] md:w-[90%] lg:w-[70%] xl:w-[50%] grid grid-cols-8'
        >
          <div className='py-2 col-span-2'>
            <p className='text[#474747] font-semibold'>Join a meeting</p>
          </div>
          <label htmlFor='meeting-code' className='col-span-4'>
            <input
              type='text'
              className='outline-none h-[40px] w-full bg-[#EAECF0] rounded-lg px-3 text-sm text-[#6B6B6B]'
              placeholder='000-000-000-000'
              value={meetingId || ''}
              onChange={(e) => setMeetingId(e.target.value)}
            />
          </label>
          <button
            className='bg-black text-white px-5 w-full h-[40px] rounded-lg font-semibold col-span-2'
            onClick={handleJoinPresentation}
          >
            Join now
          </button>
        </form>
      </section>

      <section className=' mx-auto [lg:w-[80%] w-[99%] ]'>
        <div className='text-center'>
          <h1 className='  [ lg:text-[4rem]  text-2xl lg:leading-tight font-semibold ] '>
            <span className='text-primary'>
              <TypeAnimation
                sequence={[
                  ' Transforming',
                  1000,
                  'Innovating',
                  2000,
                  'Evolutionalizing',
                ]}
                speed={20}
                wrapper='span'
                cursor={true}
                repeat={Infinity}
              />
            </span>{' '}
            Presentations, <br /> Anywhere, Anytime
          </h1>

          <p className=' mx-auto text-black30  [ lg:w-[50%] w-[90%] ] [ lg:text-[1.25rem] text-sm ] [ lg:mt-6 mt-4 ]  [ lg:leading-[2rem] leading-[1rem] ] '>
            Elevate your presentations to new heights with Presenta, the
            ultimate solution for modern presenters. Seamlessly create, deliver,
            and engage with your audience like never before, whether you're in a
            boardroom, a classroom, or hosting a webinar.
          </p>
        </div>
      </section>
      <section className=' my-4  lg:h-[42rem] video-section flex items-center'>
        <div className='  z-0   [  mt-10 mb-4 min-xl:my-20 lg:my-0  ] [ flex justify-center items-center ] [ lg:w-[65%] w-10/12  mx-auto  ]  shadow-lg rounded-lg'>
          <video autoPlay muted loop={true} className=''>
            <source src={demo} type='video/mp4' />
          </video>
        </div>
      </section>
    </section>
  );
}
