import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//assets
import profile from 'assets/svg/profile2.svg';
import arrowDown from 'assets/svg/arrowDown.svg';
import arrowUp from 'assets/svg/arrowUp.svg';
import folderImg from 'assets/svg/folderImg.svg';

//libraries
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

//slices
import {
  createFolder,
  getAllWorkspace,
  getWorkspaceFolders,
  getWorkspaceState,
} from 'store/Workspace/workspaceSlice';

//components
import MainFolder from '../FolderStructure/MainFolder';
import FlexContainer from 'components/UI/StyleComponents/FlexContainer';
import {
  getFileStructureState,
  setFileToggled,
  setMostActiveFolder,
  setPersonalWorkspaceId,
} from 'store/FileStructure/fileStructureSlice';

export default function PersonalWorkspace({ workSpaces, setActiveWorkSpace }) {
  const dispatch = useDispatch();

  //useselector
  const { workspaceFolders, allWorkspace } = useSelector(getWorkspaceState);
  const { mostActiveFolder } = useSelector(getFileStructureState);

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [togglePersonalDD, setTogglePersonalDD] = useState(true);

  //useeffect
  useEffect(() => {
    mostActiveFolder === 'team'
      ? setTogglePersonalDD(false)
      : setTogglePersonalDD(true);
  }, [mostActiveFolder]);

  //functions
  const togglePersonalFolder = () => {
    dispatch(setFileToggled(true));
    // dispatch(setMostActiveFolder("personal"));
    setActiveWorkSpace('personal');
    dispatch(
      setPersonalWorkspaceId(
        workSpaces.find((item) => item.title.toLowerCase() == 'personal')
      )
    );
  };

  const createNewFolder = (e) => {
    if (e.key === 'Enter') {
      setIsLoading(true);
      const title = e.target.value;
      const workspaceId = allWorkspace?.data.filter(
        (el) => el?.title?.toLowerCase() === 'personal'
      )?.[0]?.workspaceId;

      if (!title) {
        toast.error("Enter folder's name");
        return;
      }

      if (!workspaceId) {
        toast.error("Folder's ID not present");
        return;
      }

      const data = {
        title,
        workspaceId,
      };

      //monitor upload progress
      const config = {};

      //dispatch function
      dispatch(createFolder({ data, config })).then(({ payload }) => {
        setIsLoading(false);
        setNewFolder(false);

        // to be looked at
        dispatch(getAllWorkspace()).then(({ payload }) => {
          if (payload) {
            const id = payload.filter(
              (el) => el?.title?.toLowerCase() === 'personal'
            )?.[0]?.workspaceId;

            dispatch(getWorkspaceFolders(id));
          }
        });
      });
    }
  };

  const showNewFolderTemplate = (e, type) => {
    e.stopPropagation();
    type ? setNewFolder(true) : setNewFolder(false);
  };

  return (
    <section>
      {/* personalnav */}
      <FlexContainer extraClass='cursor-pointer text-sm'>
        <div onClick={togglePersonalFolder} className='flex  justify-between'>
          <div className='flex items-center gap-x-1'>
            <img src={profile} alt='personal workspace' className='w-5' />
            <p>Personal workspace</p>
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-x-2'>
            {workspaceFolders?.isLoading && (
              <div className='flex justify-center my-2'>
                <ClipLoader color='gray' size={12} />
              </div>
            )}

            {!workspaceFolders?.isLoading && (
              <div onClick={(e) => showNewFolderTemplate(e, true)}>
                <i
                  className='cursor-pointer pi pi-plus'
                  style={{ fontSize: '0.8rem' }}
                ></i>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setTogglePersonalDD(!togglePersonalDD);
            }}
          >
            <img src={togglePersonalDD ? arrowDown : arrowUp} alt='dropdown' />
          </div>
        </div>
      </FlexContainer>
      {/*  */}

      {/* create new folder */}
      {newFolder && (
        <section className='flex items-center w-[95%] justify-between mt-2  '>
          <div className='flex ml-2 gap-x-2'>
            <div className='flex items-center gap-x-2'>
              <img src={folderImg} alt='document' className='w-5' />
            </div>

            <div>
              <input
                type='text'
                placeholder='Folder name'
                className='outline-none text-sm font-semibold [ placeholder:text-sm ] '
                onKeyDown={createNewFolder}
              />
            </div>
          </div>

          <div>
            {isLoading ? (
              <ClipLoader size={12} color='gray' />
            ) : (
              <i
                className='text-xs cursor-pointer pi pi-times'
                onClick={(e) => showNewFolderTemplate(e, false)}
              ></i>
            )}
          </div>
        </section>
      )}
      {/*  */}

      {/* dropdown */}

      <section
        className={`flex flex-col my-2 gap-y-2 w-full ${
          togglePersonalDD ? 'block' : 'hidden'
        }`}
      >
        {!workspaceFolders?.isLoading &&
          workspaceFolders?.data?.length > 0 &&
          workspaceFolders?.data?.map((el, index) => {
            return (
              <MainFolder
                key={index}
                folder={el}
                // activeFolder={activeFolder}
                // setActiveFolder={setActiveFolder}
              />
            );
          })}
      </section>
      {/*  */}
    </section>
  );
}
