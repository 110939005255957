import React, { useState } from 'react';

//assets
import sm from 'assets/svg/sm.svg';
import dots from 'assets/svg/dots.svg';
import pause from 'assets/svg/pause.svg';
import trash from 'assets/svg/trash.svg';
import access from 'assets/svg/access.svg';
import details from 'assets/svg/details2.svg';

//libraries
import { Dropdown, Menu } from 'antd';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getInitials } from 'utils/formatter';

export default function Visitor({ visits }) {
  //usestate
  const [setIsFileUploadModalOpen] = useState(false);

  // dropdown items
  const items = [
    {
      key: '1',
      label: (
        <div className='flex gap-x-2'>
          <img src={details} alt='create new folder' className='w-4' />
          <p>Details</p>
        </div>
      ),
      // onClick: (file) => {
      //   setPresentationDoc(file?.id);
      //   setIsModalOpen(true);
      // },
    },
    {
      key: '4',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={access} alt='access control' className='w-4' />
          <p>Access control</p>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          // onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={pause} alt='move file' className='w-4' />
          <p>Suspend</p>
        </div>
      ),
    },

    {
      key: '6',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={trash} alt='delete file' className='w-4' />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  // Model field
  const columns = [
    {
      header: 'Name',
      field: 'name',
      sortable: true,
      body: (rowData) => {
        return (
          <section>
            <section className='flex items-center gap-x-2'>
              <div className='bg-[#1877F2] text-white text-xs rounded-full px-2 py-2'>
                {getInitials(rowData?.name)}
              </div>
              <p>{rowData?.name}</p>
            </section>
          </section>
        );
      },
    },

    {
      header: 'Email',
      field: 'email',
      sortable: true,
    },

    {
      header: 'Socials',

      body: (rowData) => {
        return (
          <section className='flex flex-row items-center gap-x-2'>
            {rowData?.socials?.map((social, index) => {
              return (
                <div
                  key={index}
                  className='flex items-center justify-center px-4 py-1 text-xs border-2 rounded-full text-blue200 bg-blue100 gap-x-1 blu '
                >
                  <img src={sm} alt='' />
                  {social}
                </div>
              );
            })}
          </section>
        );
      },
    },

    {
      header: '',
      body: (file) => {
        return (
          <section className='flex items-center gap-x-4'>
            <button className='font-semibold'>convert to lead</button>

            <Dropdown
              trigger={['click']}
              dropdownRender={(menu) => (
                <Menu>
                  {items.map((item) => (
                    <Menu.Item
                      key={item.key}
                      onClick={() => item?.onClick(file)}
                    >
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            >
              <div className='cursor-pointer'>
                <i className='pi pi-ellipsis-v'></i>
              </div>
            </Dropdown>
          </section>
        );
      },
    },
  ];

  return (
    <section className='border rounded-xl overflow-hidden'>
      <DataTable
        // stripedRows
        value={visits}
        className='text-xs font-medium' //component style
        selectionMode='checkbox'
        // selection={selectedStaff}
        tableStyle={{ minWidth: '50rem' }}
        // onSelectionChange={(e) => setSelectedStaff(e.value)}
      >
        {columns.map((col, index) => {
          return (
            <Column
              key={index}
              body={col?.body}
              field={col.field}
              header={col.header}
              // sortable={col?.sortable}
              headerClassName='bg-white text-blue300 font-medium border-b'
            />
          );
        })}
      </DataTable>
    </section>
  );
}
