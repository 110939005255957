import right from 'assets/image/right-404.png';
import left from 'assets/image/Left-404.png';
import bot from 'assets/image/bot-img.png';
import notFound from 'assets/image/404-notfound.png';
import logo from 'assets/svg/fullLogo.svg';
import { useNavigate } from 'react-router-dom';

// Libraries and utils
// import useScreenWidth from 'hooks/UseScreenWidth';
// import { useEffect, useState } from 'react';

export default function NotFound() {
  const navigate = useNavigate();
  //   const screenWidth = useScreenWidth();
  //   const [width, setWidth] = useState('');

  //   useEffect(() => {
  //     if (screenWidth > 2000) {
  //       setWidth('2000');
  //     }
  //   }, []);

  return (
    <section className='bg-[#F2F2F2] w-full min-h-screen overflow-hidde flex justify-center'>
      <img src={logo} alt='Presenta Logo' className='w-24 fixed top-4 left-4' />

      <div className='relative mt-20'>
        <img src={bot} alt='' className='pointer-events-none' />
        <div className=' absolute top-[200px]'>
          <div className='relative w-full flex flex-col items-center '>
            <img
              src={notFound}
              alt=''
              className='w-[288px] pointer-events-none'
            />
            <button
              className='absolute text-white bg-primary py-2 w-[85%] bottom-5 rounded-lg text-sm font-semibold'
              onClick={() => {
                navigate('/');
              }}
            >
              Back to homepage
            </button>
          </div>
        </div>
      </div>

      <div className='fixed bottom-0 w-full hidden md:flex justify-between pointer-events-none'>
        <div className={`w-[400px]`}>
          <img src={left} alt='' className={`w-full  `} />
        </div>
        <img src={right} alt='' className='fixed right-0 bottom-0 w-[400px]' />
      </div>
    </section>
  );
}
