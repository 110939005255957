import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// Assets
import search from 'assets/svg/search.svg';
import PollTemplates from './Tables/PollTemplates';
import MyPolls from './Tables/MyPolls';

export default function Polls() {
  //
  let [searchParams] = useSearchParams();

  // States
  const [tab, setTab] = useState(0);

  //useeffects
  useEffect(() => {
    const tabParam = searchParams.get('tab')?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === 'my-polls' && setTab(0);
    tabParam === 'templates' && setTab(1);
  }, [searchParams]);

  return (
    <section>
      {/* Tabs */}
      <section className='flex gap-x-4 items-center justify-between'>
        <section className='flex gap-x-2 border-b'>
          <Link to={`/admin/polls?tab=my-polls`}>
            <button
              onClick={() => setTab(0)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 0 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              My Polls
            </button>
          </Link>

          <Link to={`/admin/polls?tab=templates`}>
            <button
              onClick={() => setTab(1)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 1 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              Templates
            </button>
          </Link>
        </section>
        <div className='flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30'>
          <img src={search} alt='sort' />
          <input
            placeholder=' search'
            // value={globalFilterValue}
            // onChange={onGlobalFilterChange}
            className='bg-transparent w-[90%] py-1 outline-none'
          />
        </div>
      </section>

      {/* Main content */}
      <section className='my-8 pb-20'>
        {tab === 0 && <MyPolls />}
        {tab === 1 && <PollTemplates />}
      </section>
    </section>
  );
}
