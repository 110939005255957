// Assets
import JonnyCImg from 'assets/image/leadDetailsImagePlaceholder.png';
import ColdIcon from 'assets/svg/coldIcon.svg';
import ShellLogo from 'assets/svg/shell-logo.svg';
import SmsTracking from 'assets/svg/sms-tracking.svg';
import Sms from 'assets/svg/sms.svg';
import XLogo from 'assets/svg/x-logo.svg';
import InstagramLogo from 'assets/svg/instagramLogo.svg';
import LinklnLogo from 'assets/svg/linkedIn.svg';
import FacebookLogo from 'assets/svg/facebookLogo.svg';
import Location from 'assets/svg/location.svg';
import Phone from 'assets/svg/phone.svg';
import School from 'assets/svg/school.svg';
import ArrowRight from 'assets/svg/arrow-right.svg';
import ArrowRightOrange from 'assets/svg/arrow-right-orange.svg';
import Artisana from 'assets/svg/artisana.svg';
import DetailsImg1 from 'assets/image/best-creative-business.png';
import DetailsImg2 from 'assets/image/detailsImg2.png';
import pdf from 'assets/svg/pdf.svg';

//Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import WorkExperience from '../Leads/WorkExperience';
import EduCert from '../Leads/EduCert';

export default function LeadDetails() {
  const [tab, setTab] = useState(0);
  const [history] = useState([
    {
      date: '30-4-2024',
      filename: 'Tech requirements.pdf',
      action: 'viewed',
      timeSpent: '01:40',
    },
    {
      date: '30-4-2024',
      filename: 'Tech requirements.pdf',
      action: 'viewed',
      timeSpent: '01:40',
    },
    {
      date: '30-4-2024',
      filename: 'Tech requirements.pdf',
      action: 'viewed',
      timeSpent: '01:40',
    },
    {
      date: '30-4-2024',
      filename: 'Tech requirements.pdf',
      action: 'viewed',
      timeSpent: '01:40',
    },
  ]);

  const columns = [
    {
      header: 'Date',
      field: 'date',
    },
    {
      header: 'Name',
      field: 'name',
      body: (rowData) => {
        return (
          <section>
            <section className='flex items-center gap-x-4'>
              <div className='flex items-center'>
                <img
                  src={pdf}
                  alt='profile'
                  className='w-[40px] h-[40px] mr-2'
                />
                <p>{rowData?.filename}</p>
              </div>
            </section>
          </section>
        );
      },
    },

    {
      header: 'Action',
      field: 'action',
    },

    {
      header: 'Time spent',
      field: 'timeSpent',
    },
  ];

  const stat = [
    {
      name: 'Presentations attended',
      metric: 20,
      view: 'See presentations',
    },
    {
      name: 'Poll participation',
      metric: 3,
      view: 'See polls',
    },
    {
      name: 'Downloads',
      metric: 2,
      view: 'See downloads',
    },
    {
      name: 'Questions asked',
      metric: 4,
      view: 'See questions',
    },
  ];

  const awardsData = [
    {
      title: 'Prime Africa',
      description: 'Seasoned product designer with over 5 years of experience',
      image: DetailsImg1,
    },
    {
      title: 'Prime Africa',
      description: 'Seasoned product designer with over 5 years of experience',
      image: DetailsImg2,
    },
    {
      title: 'Prime Africa',
      description: 'Seasoned product designer with over 5 years of experience',
      image: DetailsImg2,
    },
    {
      title: 'Prime Africa',
      description: 'Seasoned product designer with over 5 years of experience',
      image: DetailsImg2,
    },
    {
      title: 'Prime Africa',
      description: 'Seasoned product designer with over 5 years of experience',
      image: DetailsImg2,
    },
  ];

  const steeperData1 = [
    {
      title: 'Prime Africa',
      date: '2012 - 2024',
      description:
        'Researched and designed a fintech solution that solves payment issues like bill payment, money transfer, airtime, and fueling. The option of the scan to pay helps users from buying fuel from a designated gas station with ease.',
    },
    {
      title: 'Prime Africa',
      date: '2012 - 2024',
      description:
        'Researched and designed a fintech solution that solves payment issues like bill payment, money transfer, airtime, and fueling. The option of the scan to pay helps users from buying fuel from a designated gas station with ease.',
    },
    {
      title: 'Prime Africa',
      date: '2012 - 2024',
      description:
        'Researched and designed a fintech solution that solves payment issues like bill payment, money transfer, airtime, and fueling. The option of the scan to pay helps users from buying fuel from a designated gas station with ease.',
    },
    {
      title: 'Prime Africa',
      date: '2012 - 2024',
      description:
        'Researched and designed a fintech solution that solves payment issues like bill payment, money transfer, airtime, and fueling. The option of the scan to pay helps users from buying fuel from a designated gas station with ease.',
    },
    {
      title: 'Prime Africa',
      date: '2012 - 2024',
      description:
        'Researched and designed a fintech solution that solves payment issues like bill payment, money transfer, airtime, and fueling. The option of the scan to pay helps users from buying fuel from a designated gas station with ease.',
    },
  ];

  const steeperData2 = [
    {
      title: 'Havard business school',
      degree: 'Bsc computer science',
      date: '2012 - 2024',
      image: Artisana,
    },
    {
      title: 'Humber college',
      degree: 'Post graduate certificate',
      date: '2012 - 2024',
      image: Artisana,
    },
    {
      title: 'Jira',
      degree: 'Atlassian',
      date: null,
      image: Artisana,
      certID: 'id: 90075567',
    },
  ];

  return (
    <section className='w-full overflow-hidden pb-[100px]'>
      <section className='w-full border border-[#E2E8F0] rounded-lg p-5'>
        <div className='flex flex-col md:flex-row justify-between'>
          <div className='flex'>
            <div className='overflow-hidden mr-3 w-[76px] h-[76px]'>
              <img src={JonnyCImg} alt='profile' />
            </div>
            <div className=''>
              <div className='flex'>
                <h2 className='text-2xl font-semibold mr-5'>Jonny Cage</h2>
                <div className='flex items-center'>
                  <div className='w-[16px] h-[16px]  rounded-full'>
                    <img
                      src={ColdIcon}
                      alt='cold icon'
                      className='w-full h-full'
                    />
                  </div>
                  <p className='text-base text-[#AFAFAF]'>Cold</p>
                </div>
              </div>
              <span className='flex text-base items-center mt-2 text-[#AFAFAF]'>
                <p className='text-base mr-1'>CTO at</p>
                <img src={ShellLogo} alt='company logo' />
                <p className='text-base ml-1'>SYNEOUT INC</p>
              </span>
            </div>
          </div>
          <div>
            <div className='flex mt-3 md:mt-0'>
              <div className='col-span-3 bg-[#EEEEEE] h-[30px] md:h-[40px] px-5 flex items-center mr-3 rounded-lg overflow-hidden'>
                <img src={SmsTracking} alt='sms logo' className='mr-2' />
                <p className='text-sm hidden md:inline-block'>Send an email</p>
                <p className='text-sm md:hidden'>Email</p>
              </div>
              <div className='col-span-1 h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-full bg-[#EEEEEE] overflow-hidden flex items-center justify-center mr-2'>
                <img src={XLogo} alt='x logo' />
              </div>
              <div className='col-span-1 h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-full bg-[#EEEEEE] overflow-hidden flex items-center justify-center mr-2'>
                <img src={LinklnLogo} alt='x logo' />
              </div>
              <div className='col-span-1 h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-full bg-[#EEEEEE] overflow-hidden flex items-center justify-center mr-2'>
                <img src={InstagramLogo} alt='x logo' />
              </div>
              <div className='col-span-1 h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-full bg-[#EEEEEE] overflow-hidden flex items-center justify-center'>
                <img src={FacebookLogo} alt='x logo' />
              </div>
            </div>
            <div className='w-full flex md:justify-end mt-3'>
              <img src={Location} alt='location' className='mr-2' />
              <p className='text-base'>Lagos, Nigeria</p>
            </div>
          </div>
        </div>
        <ul className='flex flex-col md:flex-row mt-3'>
          <li className='md:mr-5 flex items-center'>
            <img src={Sms} alt='email' className='mr-2' />
            <p>jonny.cage@thepresenta.com</p>
          </li>
          <li className='mt-2 md:mt-0 md:mr-5 flex items-center'>
            <img src={Phone} alt='email' className='mr-2' />
            <p>447-800-9890</p>
          </li>
          <li className='mt-2 md:mt-0 flex items-center'>
            <img src={School} alt='email' className='mr-2' />
            <p>Havard university</p>
          </li>
        </ul>
      </section>

      <section className='mt-5'>
        <h3 className='text-xl font-bold'>Summary</h3>
        <div className='bg-[#F6F8FA] p-5 rounded-lg mt-5'>
          <p className='text-[#333333]'>
            Seasoned product designer with over 5 years of experience crafting
            digital solutions across diverse industries including Fintech,
            e-commerce, luxury, and oil & gas downstream, with notable work for
            esteemed clients like Shell. Demonstrated expertise in user
            research, wireframing, prototyping, and UI design, consistently
            delivering innovative solutions that drive tangible results for
            stakeholders and users. Currently, I lead a dynamic 3-person design
            team at Fuelmetrics Limited.
          </p>
        </div>
      </section>

      <section className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
        <section className='p-5 pl-10 border rounded-xl relative pb-[60px]'>
          <h2 className='text-xl font-bold mb-5 -ml-2'>Work experience</h2>
          {steeperData1.slice(0, 2).map((item, index) => (
            <section
              key={index}
              className='border-l border-[#E2E2E2] pl-5 relative pb-5'
            >
              <div className='absolute top-0 left-0 -translate-x-[50%]'>
                <img src={Artisana} alt='' />
              </div>
              <div>
                <div>
                  <h3 className='text-sm font-medium text-[#333333]'>
                    {item.title}
                  </h3>
                  <p className='text-[#757575] text-xs'>{item.date}</p>
                </div>
                <div className='p-5 bg-[#EBF1FF] text-sm rounded-xl mt-3'>
                  <p>{item.description}</p>
                  <button className='text-[#FF6929] font-medium'>
                    Read more
                  </button>
                </div>
              </div>
            </section>
          ))}
          <button
            className='flex items-center mt-5 absolute bottom-5 left-10'
            onClick={() => setTab(1)}
          >
            <p className='text-sm font-medium mr-2'>View all work experience</p>
            <img src={ArrowRight} alt='arrow right' />
          </button>
        </section>
        <section className='p-5 pl-10 border rounded-xl relative pb-[60px]'>
          <h2 className='text-xl font-bold mb-5 -ml-2'>
            Education & Certificate
          </h2>

          {steeperData2.map((item, index) => (
            <section className='pl-5 relative pb-5'>
              <div className='absolute top-0 left-0 -translate-x-[50%]'>
                <img src={Artisana} alt='' />
              </div>
              <div>
                <div>
                  <h3 className='text-sm font-medium text-[#333333]'>
                    {item.title}
                  </h3>
                  <p className='text-[#757575] text-sm italic my-1'>
                    {item.degree}
                  </p>
                  <p className='text-[#757575] text-xs'>{item.date}</p>
                  <p className='text-[#757575] text-xs'>{item.certID}</p>
                </div>
              </div>
            </section>
          ))}

          <button
            className='flex items-center mt-5 absolute bottom-5 left-10'
            onClick={() => setTab(2)}
          >
            <p className='text-sm font-medium mr-2'>
              View all education & certicate
            </p>
            <img src={ArrowRight} alt='arrow right' />
          </button>
        </section>
      </section>

      <section>
        <h3 className='text-xl font-bold my-5'>Awards</h3>
        <div className='w-full  flex gap-5 overflow-y-auto hide-scrollbar'>
          {awardsData.map((item, index) => (
            <div
              key={index}
              className='min-w-[348px] border rounded-xl border-[#EAECF0] p-3 py-5'
            >
              <h4 className='font-medium text-[#333333]'>{item.title}</h4>
              <p className='text-sm text-[#757575]'>{item.description}</p>
              <div className='w-full h-[157px] mt-3'>
                <img src={item.image} alt='' />
              </div>
              <button className='flex items-center text-[#FF6929] font-medium mt-3'>
                <p className='text-sm font-medium mr-2'>Read more</p>
                <img src={ArrowRightOrange} alt='arrow right' />
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className='p-5 border mt-5 rounded-xl'>
        <div className='w-[482px] grid grid-cols-1 md:grid-cols-2 gap-y-5'>
          {stat.map((item) => (
            <ul>
              <li className='text-[#757575]'>{item.name}</li>
              <li className='text-[#3D4045] font-medium'>{item.metric}</li>
              <li className='flex cursor-pointer'>
                <p className='text-sm mr-2 text-[#545454] font-medium'>
                  {item.view}
                </p>
                <img src={ArrowRight} alt='' />
              </li>
            </ul>
          ))}
        </div>
      </section>

      <section>
        <h3 className='text-xl font-bold my-5'>History</h3>

        <div className='border rounded-xl overflow-hidden mt-5'>
          <DataTable
            value={history}
            className='text-sm font-medium text-blue300'
            selectionMode='checkbox'
            tableStyle={{ minWidth: '50rem' }}
          >
            {columns.map((col, index) => {
              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  // sortable={col?.sortable}
                  headerClassName='bg-white text-blue300 font-medium border-b'
                />
              );
            })}
          </DataTable>
        </div>
      </section>

      {tab === 1 && (
        <WorkExperience experience={steeperData1} setTab={setTab} />
      )}

      {tab === 2 && <EduCert qualification={steeperData2} setTab={setTab} />}
    </section>
  );
}
