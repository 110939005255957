// Assets
// Assets
import image1 from 'assets/image/bg-marketing-goals.png';
import image2 from 'assets/image/bg-marketing-goals.png';
import ellipsis from 'assets/svg/ellipsis-horizontal-gray.svg';
import clock from 'assets/svg/timer-topDash-gray.svg';

export default function MyPolls() {
  return (
    <section>
      <section className='grid grid-cols-3 gap-5'>
        <div className=''>
          <div className='rounded-md overflow-hidden border cursor-pointer h-[185px]'>
            <img
              src={image1}
              alt='background'
              className='w-full object-cover'
            />
          </div>
          <div className='mt-2'>
            <div className='flex justify-between items-center'>
              <p className='font-semibold'>Women health poll</p>
              <img src={ellipsis} alt='options' className='cursor-pointer' />
            </div>
            <div className='flex gap-x-1 items-center'>
              <img src={clock} alt='date created' className='w-4' />
              <p className='text-xs leading-none'>20 mins ago</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
