import React, { Dispatch, MutableRefObject, Ref, SetStateAction } from 'react';

// Assets

import addComment from 'assets/svg/add-comment.svg';
import download from 'assets/svg/download-icon-white.svg';
import print from 'assets/svg/print-icon.svg';
import share from 'assets/svg/share.svg';
import expand from 'assets/svg/expand.svg';
import grid from 'assets/svg/grid-icon.svg';

//
import Controller from './Controller';
import SlidesPreview from './SlidesPreview';

interface Slide {
  id: string;
  url: string;
  position: number;
  title: string;
  note: string | null;
}

interface ImageCache {
  [id: string]: string;
}

interface Props {
  screenWidth: number;
  isSlides: boolean;
  slides: Slide[];
  slideDocumentIndex: number;
  handlePrevSlideDoc: () => void;
  handleNextSlideDoc: () => void;
  displayDocumentIndex: number;
  handlePrevDoc: () => void;
  flattedDisplayDocuments: any;
  handleNextDoc: () => void;
  minScreenWidth: number;
  setSlideDocumentIndex: Dispatch<SetStateAction<number>>;
  itemRefs: MutableRefObject<(HTMLElement | null)[]>;
  imageCache: MutableRefObject<ImageCache>;
  // enterFullscreen: () => void;
  // exitFullscreen: () => void;
  handleFullscreen: () => void;
  handleGridDisplay: () => void;
  isGridDisplayActive: boolean;
}

export default function ControllerParent({
  screenWidth,
  isSlides,
  slides,
  slideDocumentIndex,
  handlePrevSlideDoc,
  displayDocumentIndex,
  flattedDisplayDocuments,
  handleNextSlideDoc,
  handlePrevDoc,
  handleNextDoc,
  minScreenWidth,
  setSlideDocumentIndex,
  imageCache,
  itemRefs,
  // enterFullscreen,
  // exitFullscreen,
  handleFullscreen,
  handleGridDisplay,
  isGridDisplayActive,
}: Props) {
  return (
    <section className={`w-full h-fit`}>
      {!isGridDisplayActive && (
        <div
          className={`relative ${
            isSlides
              ? screenWidth > minScreenWidth
                ? 'h-[180px] h-fi aspect-[8.5/11'
                : 'h-[40px]'
              : 'h-[10px]'
          }`}
        >
          {isSlides && (
            <section
              className={`hide-scrollbar w-full overflow-hidden overflow-x-auto py-5 flex gap-x-3 content-end ${
                screenWidth < minScreenWidth
                  ? 'absolute top-10 left-0 -translate-y-[100%] group'
                  : 'my-[10px] h-[10rem]'
              } `}
            >
              {slides.map((item, index) => (
                <section
                  key={item.id}
                  onClick={() => setSlideDocumentIndex(index)} // Adjust index for sliced items
                  ref={(el) => (itemRefs.current[index] = el)}
                  className={`  ${
                    screenWidth < minScreenWidth
                      ? 'h-[100px] min-w-[10rem] max-w-[10rem]'
                      : 'h-[10rem] min-w-[12rem] max-w-[12rem] aspect-[8.5/11 aspect-[16/9 h-[100px'
                  }`}
                >
                  <SlidesPreview
                    slide={item}
                    index={index}
                    imageCache={imageCache}
                    slideDocumentIndex={slideDocumentIndex}
                    showImage={screenWidth < minScreenWidth ? false : true}
                    isGridDisplay={false}
                  />
                </section>
              ))}
            </section>
          )}
        </div>
      )}

      <section
        className={`h-11 w-full flex justify-between mb-4 ${
          isGridDisplayActive && 'mt-4'
        }`}
      >
        <Controller
          screenWidth={screenWidth}
          isSlides={isSlides}
          slides={slides}
          slideDocumentIndex={slideDocumentIndex}
          handlePrevSlideDoc={handlePrevSlideDoc}
          displayDocumentIndex={displayDocumentIndex}
          flattedDisplayDocuments={flattedDisplayDocuments}
          handleNextSlideDoc={handleNextSlideDoc}
          handlePrevDoc={handlePrevDoc}
          handleNextDoc={handleNextDoc}
        />

        {/*  */}
        <section className='flex gap-x-4'>
          {isSlides && (
            <div
              className='flex items-center px-2 h-[44px] w-[44px] justify-center rounded-lg gap-x-4 bg-gray30 cursor-pointer'
              onClick={handleGridDisplay}
            >
              <img
                src={grid}
                alt='move right'
                className={` w-5 object-cover`}
              />
            </div>
          )}
          <div
            className='flex items-center px-2 h-[44px] w-[44px] justify-center rounded-lg gap-x-4 bg-gray30 cursor-pointer'
            onClick={handleFullscreen}
          >
            <img
              src={expand}
              alt='move right'
              className={` w-5 object-cover`}
            />
          </div>

          <section className='bg-[#363636] px-5 gap-x-5 flex items-center h-[44px] rounded-lg'>
            <div className='cursor-pointer'>
              <img src={addComment} alt='add comment' />
            </div>
            <div className='cursor-pointer'>
              <img src={print} alt='print' />
            </div>
            <div className='cursor-pointer'>
              <img src={download} alt='download' />
            </div>
            <div className='cursor-pointer'>
              <img src={share} alt='share' />
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}
