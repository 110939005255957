import { useEffect } from "react";

export const useGetSummarizedText = (
  connection,
  presentationId,
  eventHandler
) => {
  useEffect(() => {
    const handleEventResponse = (presentationId, summary) => {
      eventHandler(summary);
    };
    if (connection) {
      connection.on("Summarize", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off("Summarize", handleEventResponse);
      }
    };
  }, [connection, presentationId, eventHandler]);
};
