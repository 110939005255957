import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import avatar1 from "assets/image/avatarFB.png";
import avatar2 from "assets/image/avatarOl.png";
import { useNavigate } from "react-router-dom";
import ellipsis from "assets/svg/ellipsis-horizontal.svg";
import tableDownload from "assets/svg/tableDownload.svg";
import tableEye from "assets/svg/tableEye.svg";
import clockBroken from "assets/svg/brokenClock.svg";
import questionMark from "assets/svg/tableQuestion.svg";
import designPlaceHolder from "assets/svg/designDeck.svg";
import { PresentationPlaylistInterface } from "interfaces/interfaces-data";
import pdfIcon from "assets/svg/pdf.svg";
import { resolveFileType } from "utils/resolveFileTypes";
import { ClipLoader } from "react-spinners";
import { ResolveTableImage } from "components/UI/Image/ResolveImage";
import trash from "assets/svg/trash.svg";
import { Col, Dropdown, MenuProps, Row, Switch } from "antd";
import editIcon from "assets/svg/editIcon.svg";
import documentText from "assets/svg/document-text.svg";
import documentNormal from "assets/svg/document-normal.svg";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import { useDispatch } from "react-redux";
import {
  getPresentationDetails,
  getPresentationPlaylist,
} from "store/Presentations/presentationSlice";
import { toast } from "sonner";
import { removeDocumentFromPlaylist } from "store/Presentations/presentationSlice";

export const PresentationTable = ({
  playList,
}: {
  playList: PresentationPlaylistInterface[];
}) => {
  const navigate = useNavigate();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [presentationId, setPresentationId] = useState("");
  const [docId, setDocId] = useState("");
  const getFileType = (text: string) => {
    const fileExtension = text.split(".")[text.split(".").length - 1];
    if (fileExtension) {
      return fileExtension.toLowerCase();
    }
    return "";
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          <span>
            <img src={documentNormal} />
          </span>
          <p className="font-grotesk text-sm">Details</p>
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setDeleteModalActive(true);
          }}
          className="flex items-center gap-2"
        >
          <span>
            <img src={trash} />
          </span>
          <p className="font-grotesk text-sm text-[#D44333]">Delete</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: "Names",
      field: "title",
      body: (rowData: PresentationPlaylistInterface) => {
        return (
          <section>
            <section className="flex items-center gap-x-4">
              <div className=" text-white text-xs rounded-lg overflow-hidden w-[49px] h-[49px] flex items-center justify-center">
                {getFileType(rowData.url) == "jpeg" ||
                getFileType(rowData.url) == "jpg" ||
                getFileType(rowData.url) == "png" ? (
                  <>
                    <ResolveTableImage id={rowData.documentId} />
                  </>
                ) : (
                  <img
                    src={resolveFileType(rowData.url)}
                    alt="profile"
                    className="w-full h-full"
                  />
                )}
              </div>
              <div className="pt-1 font-medium capitalize">
                <p className="text-black text-sm">{rowData.title}</p>
              </div>
            </section>
          </section>
        );
      },
    },

    {
      header: "Actions",
      sortable: true,
      body: (rowData: PresentationPlaylistInterface) => {
        return (
          <section className="w-full flex gap-x-2">
            <div className="flex items-center gap-1 bg-[#F6F3EF] rounded-full px-4 py-2">
              <span>
                <img src={tableEye} />
              </span>
              <p className="text-xs">{rowData.views}</p>
            </div>
            <div className="flex items-center gap-1 bg-[#F6F3EF] rounded-full px-4 py-2">
              <span>
                <img src={tableDownload} />
              </span>
              <p className="text-xs">{rowData.downloads}</p>
            </div>
            <div className="flex gap-1 bg-[#F6F3EF] rounded-full items-center px-4 py-2">
              <span>
                <img src={clockBroken} />
              </span>
              <p className="text-xs">{rowData.timeSpent} sec</p>
            </div>
            <div className="flex gap-1 bg-[#F6F3EF] rounded-full px-4 py-2">
              <span>
                <img src={questionMark} />
              </span>
              <p className="text-xs">{rowData.questions}</p>
            </div>
          </section>
        );
      },
    },

    {
      header: "",
      sortable: true,
      body: (rowData: PresentationPlaylistInterface) => {
        return (
          <div className="flex gap-x-3">
            <div className="cursor-pointer">
              <Dropdown
                onOpenChange={() => {
                  console.log(
                    rowData.documentId,
                    "The value of the stuff here"
                  );
                  setDocId(rowData.documentId);
                }}
                menu={{ items }}
              >
                <img src={ellipsis} alt="" />
              </Dropdown>
            </div>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const handleDeleteDocument = () => {
    setDeleteLoading(true);
    const removeDocId = docId;
    console.log({ presentationId, removeDocId }, "value for the table heree");
    // @ts-ignore
    dispatch(removeDocumentFromPlaylist({ presentationId, removeDocId })).then(
      //@ts-ignore
      ({ type }) => {
        setDeleteLoading(true);
        //@ts-ignore
        dispatch(getPresentationPlaylist(presentationId));
        if (type.includes("fulfilled")) {
          setDeleteModalActive(false);
          toast.success("Document removed successfully");
        }
      }
    );
  };
  const [filters, setFilters] = useState({
    // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    const getPresentationId = new URLSearchParams(window.location.search);
    setPresentationId(getPresentationId.get("q") ?? "");
  }, []);
  return (
    <section className="overflow-hidden rounded-xl w-full">
      <ConfirmationModal
        text={
          "Are you sure you want to remove the document from the playlist ? "
        }
        isLoading={deleteLoading}
        open={deleteModalActive}
        onClose={() => setDeleteModalActive(false)}
        func={handleDeleteDocument}
      />
      <DataTable
        filters={filters}
        globalFilterFields={["name", "lastUpdated", "tags"]}
        value={playList}
        tableStyle={{ minWidth: "45rem", maxHeight: "300px" }}
      >
        {columns.map((col, index) => {
          return (
            <Column
              key={index}
              body={col?.body}
              field={col.field}
              header={col.header}
              headerClassName="bg-[#F5F5F5F5] text-blue300 font-normal text-sm"
              className="text-sm"
            />
          );
        })}
      </DataTable>
    </section>
  );
};
