import Axios from 'lib/axiosInterceptor';

//get

export const _getPresentationPerformanceSummary = (presentationId) => {
  return Axios.get(`/Report/PerformanceSummary/${presentationId}`);
};

export const _getPresentationSlideMetrics = (presentationId) => {
  return Axios.get(`/Report/AverageTimeSpent/${presentationId}`);
};

export const _getPresentationVisitors = (id) => {
  return Axios.get(`/Report/Attendance/Presentation/${id}`);
};
export const _getInvitedGuests = (id) => {
  return Axios.get(`/Presentation/Guests/${id}`);
};

export const _getAllPresentations = () => {
  return Axios.get('/presentation/all');
};
export const _getPresentationMetrics = (presentationId) => {
  return Axios.get(`/report/PresentationMetrics/${presentationId}`);
};
export const _getAttentionGraphData = (presentationId) => {
  return Axios.get(`/Report/UserAttention/${presentationId}`);
};
export const _getDownloadGraphData = (presentationId) => {
  return Axios.get(`/Report/Downloads/${presentationId}`);
};
export const _getPollMetrics = (presentationId) => {
  return Axios.get(`/report/PollMetrics/${presentationId}`);
};
export const _getPresentationDetails = (presentationId, connectionId) => {
  return Axios.get(
    `/presentation/${presentationId}?presentaConnectionId=${
      connectionId ?? null
    }`
  );
};

export const _getPresentationPlaylist = (presentationId) => {
  return Axios.get(`/Report/Presentation/Playlist/${presentationId}`);
};

//post
export const _addDocumentToPresentation = (data, config) => {
  return Axios.post('/Presentation/AddDocument', data, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...(config.headers || {}),
    },
  });
};
export const _beginPresentationEdit = (data) => {
  return Axios.post('/presentation/new', data);
};

export const _editPresentation = (presentationId, data) => {
  return Axios.post(`/Presentation/Update/${presentationId}`, data);
};

export const _schedulePresentation = (id, data) => {
  return Axios.post(`/Presentation/Schedule/${id}`, data);
};

export const _addPresentationGuest = (id, data) => {
  return Axios.post(`/Presentation/AddGuests/${id}`, data);
};

//put
export const _updatePresentationEdit = (presentationId, data) => {
  return Axios.post(`/Presentation/UpdatePosition/${presentationId}`, data);
};

//delete

export const _removeDocumentFromPlaylist = (presentationId, documentId) => {
  return Axios.post(
    `/Presentation/RemoveDocument/${presentationId}/${documentId}`
  );
};

export const _deletePresentation = (presentationId) => {
  return Axios.post(`/presentation/delete/${presentationId}`);
};

export const _deleteDocument = (documentId, archive) => {
  return Axios.post(`/Document/Delete/${documentId}?archive=${archive}`);
};
