import React from "react";

//assets
import zap from "assets/svg/zap.svg";
import bgImg from "assets/svg/bgImg.svg";
import cards from "assets/svg/cards.svg";
import play from "assets/svg/playVideo.svg";
import present from "assets/svg/Present.svg";
import presentBg from "assets/svg/presentBg.svg";
import placeholder from "assets/svg/videoPlaceholder.svg";

export default function Features() {
  const boxShadowStyle = {
    boxShadow: "0px 0px 0px 3px #EAECF0",
  };

  const presentStyle = {
    backgroundPosition: "90% 70%",
    boxShadow: "0px 0px 0px 3px #EAECF0",
    backgroundImage: `url(${presentBg})`,
  };

  const videoPH = {
    background: `url(${placeholder}) no-repeat`,
  };
  return (
    <section className="[ lg:mt-10  ] [ lg:w-[80%] w-[90%] mx-auto ]">
      <h1 className=" [ lg:text-left text-center ]  [ lg:text-[3rem] text-3xl lg:leading-tight font-semibold ] ">
        Features
      </h1>

      <section className="  lg:gap-x-10  gap-y-4 my-8 [ flex lg:flex-row flex-col items-center ]">
        <section
          className="py-4 bg-white rounded-3xl [ lg:w-[32%] w-full ]"
          style={presentStyle}
        >
          <div className="px-6 ">
            <img src={zap} alt="" />
            <div className="my-4">
              <h4 className="[ lg:text-lg font-medium ] ">
                Mobile Remote Control
              </h4>
              <p className="mt-2 [ lg:text-sm text-xs ] text-gray600">
                “Next slide please” …. not anymore. Seamlessly advance slides,
                pause, or go back with just a swipe, giving you complete control
                and freedom to engage with your audience at your own pace for
                your mobile phone
              </p>
            </div>
            <div className="flex justify-center w-full ">
              <img src={present} alt="present preview" />
            </div>
          </div>
        </section>

        <section className=" [ lg:w-[68%] w-full ] flex gap-y-8 flex-col">
          <section className="[ flex  lg:flex-row flex-col  ] [ lg:gap-x-6 gap-y-4 ]">
            {/* first section */}
            <section
              style={boxShadowStyle}
              className=" [ flex items-center ] gap-x-2 py-4 px-4 bg-white rounded-3xl [ lg:w-[50%] w-full ]"
            >
              <section>
                <div>
                  <img src={zap} alt="" />
                </div>
                <div className="my-4">
                  <h4 className="[ lg:text-lg font-medium ]">
                    Participant Interaction
                  </h4>
                  <p className="mt-2 [ lg:text-sm text-xs ] text-gray600">
                    Your presentation participants can quickly navigate to any
                    previous slide to clarify points they might have missed,
                    ensuring everyone stays on the same page and maximizes
                    understanding.
                  </p>
                </div>
                <div className="flex justify-center w-full ">
                  <img src={cards} alt="present preview" />
                </div>
              </section>
            </section>
            {/*  */}

            {/* second section */}
            <section
              style={videoPH}
              className=" [ flex items-center  ] gap-x-2 py-4 px-4 bg-white rounded-3xl [ lg:w-[50%] w-full ] lg:h-120"
            >
              <section>
                <div className="flex justify-center">
                  <img src={play} alt="play video" />
                </div>
                <div className="my-4 text-white [ lg:text-left text-center ]">
                  <h4 className="[ lg:text-lg font-medium ]">
                    Presentation Playlist
                  </h4>
                  <p className="mt-2 [ lg:text-sm text-xs ] ">
                    Elevate your presentation flexibility with Presenta's
                    innovative Playlist feature. Seamlessly switch between
                    multiple files and file types within a single presentation,
                    empowering you to deliver dynamic and engaging content
                    tailored to your audience's needs.
                  </p>
                </div>
              </section>
            </section>
            {/*  */}
          </section>

          <section className="[ flex items-center lg:flex-row flex-col  ]  lg:gap-x-4 gap-y-4">
            {/* first section */}
            <section
              style={boxShadowStyle}
              className=" [ flex items-center justify-between ] gap-x-2 py-4 px-4 bg-white rounded-2xl [ lg:w-[60%] w-full ]"
            >
              <div>
                <h4 className="font-semibold">
                  Cloud-Based Presentation Delivery
                </h4>
                <p className="text-grey600 text-xs w-[80%]">
                  {" "}
                  Access your presentations anytime, anywhere, from our secure
                  cloud platform.
                </p>
              </div>
              <div>
                <img src={bgImg} alt="present preview" />
              </div>
            </section>
            {/*  */}

            {/* second section */}
            <section
              style={boxShadowStyle}
              className="py-4 px-8 bg-white [ lg:rounded-3xl rounded-lg ] [ lg:w-[40%] w-full ] h-full"
            >
              <h4 className="font-semibold">Moderated Q n A</h4>
              <div className="flex items-center gap-x-4">
                <h3 className="text-4xl font-semibold ">78%</h3>
                <p className="[ lg:text-sm text-xs ] text-gray80">
                  Of people don't talk to therapists
                </p>
              </div>
            </section>
            {/*  */}
          </section>
        </section>
      </section>
    </section>
  );
}
