import React, { useRef, useState } from 'react';
import emptyState from 'assets/svg/emptyBriefcase.svg';
import addPlus from 'assets/svg/add-circle.svg';
import { Modal } from 'antd';
import CreateBriefcase from 'views/Admin/Briefcase/Modals/CreateBriefcase';

export default function EmptyBriefcase() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  interface CreateSpaceModalRef {
    resetModal: () => void;
  }
  //useRef
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  return (
    <section>
      <section className='flex flex-col  items-center justify-center lg:h-[60vh]'>
        <div className='mb-4 center-content'>
          <img src={emptyState} alt='No briefcase' className='center-content' />
        </div>

        <div className='text-center'>
          <h1 className='text-xl font-semibold lg:text-2xl'>
            A briefcase for all your playlists
          </h1>
          <p className='text-sm'>
            Create and manage your presentation playlists here.
          </p>
          <div className='mt-2 center-content'>
            <button
              onClick={openCreateSpaceModal}
              className='flex items-center px-4 py-2 text-sm text-white bg-black rounded-lg gap-x-2 '
            >
              <img src={addPlus} alt='' className='w-5 h-5' />
              <span>Create a briefcase</span>
            </button>
          </div>
        </div>
      </section>
      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateBriefcase closeModal={handleCancel} ref={createSpaceModal} />
      </Modal>
    </section>
  );
}
