import React, { useEffect, useState } from "react";

//assets
import avatar from "assets/svg/avatar.svg";
import fileEmpty from "assets/svg/emptyTemplate.svg";

//libraries
import { ClipLoader } from "react-spinners";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//slices
import { getConnectionState } from "store/SignalR/signalRSlice";
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { useGetViewersList } from "hooks/SignalR/Listeners/Presenter/useGetViewersList";
import { UserInterface } from "interfaces/interfaces-data";
import allow from "assets/svg/greenAllow.svg";
import decline from "assets/svg/redDecline.svg";
import { AllowGuestIn } from "hooks/SignalR/Invokers/Presenter/AllowGuestIn";
import { HubConnection } from "@microsoft/signalr";
import remove from "assets/svg/remove.svg";
import { RemoveGuest } from "hooks/SignalR/Invokers/Presenter/RemoveGuest";

export default function AllViewers({
  users,
  requests,
  connection,
  presentationId,
}: {
  users: UserInterface[];
  requests: UserInterface[];
  connection?: HubConnection | null;
  presentationId: string;
}) {
  //usestate
  const [viewers, setViewers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGrantAllLoading, setIsAllLoading] = useState(false);
  const [grantAccessLoading, setGrantAccessLoading] = useState(false);

  const grantAllAccess = () => {};

  const removeViewer = (email: string) => {
    RemoveGuest(connection , presentationId , email , true)
  };

  return (
    <section>
      <div>
        {requests.length > 0 && (
          <section>
            <section className="flex items-center justify-between mb-2">
              <h3 className="text-xs font-bold text-gray60">WAITING TO JOIN</h3>

              <section className="flex text-xs gap-x-2">
                <div
                  onClick={grantAllAccess}
                  className="flex items-center px-2 py-1 font-bold rounded-full cursor-pointer gap-x-1 bg-green100 text-green600"
                >
                  {isGrantAllLoading ? (
                    <ClipLoader color="green" size={12} />
                  ) : (
                    <img src={allow} alt="" />
                  )}
                  <p>Allow all</p>
                </div>

                {/* <div className="flex items-center px-2 py-1 font-bold text-red-600 bg-red-100 rounded-full cursor-pointer gap-x-1">
                      <img src={decline} alt="" />
                      <p>Decline all</p>
                    </div> */}
              </section>
            </section>
            <div>
              {requests.map((item) => {
                return (
                  <RequestWrapper
                    connection={connection}
                    presentationId={presentationId}
                    user={item}
                  />
                );
              })}

              {/* <RequestList
              requests={requests}
              connection={connection}
              presentationId={presentationId}
              isPresentationActive={isPresentationActive}
            /> */}
            </div>
          </section>
        )}
        {users.length ? (
          <>
            <section className="flex items-center justify-between px-3 mb-2">
              <h3 className="text-xs mt-3 font-bold text-gray60">
                IN THE MEETING ({users.length})
              </h3>
            </section>
            {users.map((viewer, index) => {
              return (
                <div key={index}>
                  <FlexContainer
                    onClick={""}
                    isResponsive={""}
                    extraClass="py-3 border-b px-2"
                  >
                    <div className="flex items-center gap-x-2">
                      <div>
                        <img
                          src={avatar}
                          alt={viewer?.name}
                          className="w-8 rounded-full"
                        />
                      </div>

                      <div>
                        <h3 className="font-semibold ">{viewer?.name}</h3>
                        <p className="text-xs text-gray60 ">{viewer?.email}</p>
                      </div>
                    </div>

                    <div
                      onClick={() => removeViewer(viewer.email)}
                      className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40"
                    >
                      {isLoading ? (
                        <ClipLoader color="gray" size={12} />
                      ) : (
                        <img src={remove} alt="remove viewer" />
                      )}
                      <p>Remove</p>
                    </div>
                  </FlexContainer>
                </div>
              );
            })}
          </>
        ) : (
          <section className="flex flex-col items-center justify-center h-[70vh] text-sm ">
            <img src={fileEmpty} alt="" />
            <div className="text-center">
              <p className="font-semibold">
                There are no active viewers in this presentation{" "}
              </p>
            </div>
          </section>
        )}
      </div>
    </section>
  );
}

const RequestWrapper = ({
  user,
  connection,
  presentationId,
}: {
  user: UserInterface;
  connection?: HubConnection | null;
  presentationId: string;
}) => {
  const [grantAccessLoading, setGrantAccessLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  const handleUserRequest = (type: "deny" | "allow") => {
    const status = type == "deny" ? false : true;
    AllowGuestIn(
      connection,
      presentationId,
      user.email,
      !status ? setDenyLoading : setGrantAccessLoading,
      status
    );
  };

  return (
    <div className="flex items-center justify-between w-full border-b  py-2">
      <div className="flex  items-center gap-2 ">
        <div>
          <img
            src={avatar}
            // src={viewer?.img}
            alt={user.name}
            className="w-8 rounded-full"
          />
        </div>

        <div>
          <p className="font-semibold text-base ">{user.name}</p>
          <p className="text-xs text-gray60 ">{user.email}</p>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div
          onClick={() => handleUserRequest("allow")}
          className="flex items-center cursor-pointer gap-x-1 text-green600"
        >
          {grantAccessLoading ? (
            <ClipLoader color="green" size={12} />
          ) : (
            <img src={allow} alt="" />
          )}

          <p>Allow </p>
        </div>
        <div
          onClick={() => handleUserRequest("deny")}
          className="flex items-center text-red-600 cursor-pointer gap-x-1"
        >
          {denyLoading ? (
            <ClipLoader color="red" size={12} />
          ) : (
            <img src={decline} alt="" />
          )}
          <p>Decline </p>
        </div>
      </div>
    </div>
  );
};
