import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//components
import Visitor from '../Table/Visitors';
import Loader from 'components/UI/Loader';
import AdminNavbar from 'components/UI/AdminLayout/Navbar';

// assets
import arrowRight from 'assets/svg/arrowRight.svg';

export default function Directory() {
  const dispatch = useDispatch();

  //usestates
  const [loadingStatus] = useState(false);
  const [navProps, setNavProps] = useState({});

  const [visits] = useState([
    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },
    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },

    {
      name: 'phoenix baker',
      email: '@phoenix.baker',
      phone: '+23480127172',
      socials: ['Twitter', 'Snapchat'],
      role: 'owner',
      status: 'active',
    },
  ]);

  //useeffects
  useEffect(() => {
    setNavProps({
      title: 'Directory',
      breadcrumb: (
        <section className='flex gap-x-1 items-center'>
          <p className='lg:text-sm text-xs'>Directory</p>
          <img src={arrowRight} alt='arrow right icon' />
          <p className='lg:text-sm text-xs'>Visitors</p>
        </section>
      ),
    });
  }, [dispatch]);

  return (
    <section className='relative'>
      <AdminNavbar navProps={navProps} />

      <section className='mt-20'>
        {/* loader */}
        {loadingStatus && <Loader />}
        {/*  */}

        {!loadingStatus && (
          <section>
            <Visitor visits={visits} />
          </section>
        )}
      </section>
    </section>
  );
}
