import { QuestionsList } from 'interfaces/PollSetup';
import React from 'react';
import { style } from 'views/Admin/Dashboard/data';

interface Props {
  questionOption: QuestionsList;
}

export default function QuestionsOptionsDisplay({ questionOption }: Props) {
  return (
    <div className='flex flex-col'>
      <div className='h-[200px] rounded-md overflow-hidden'>
        <div className='bg-[#EAECF0] w-full h-[95%]'></div>
        <div
          className={`h-[5%] bg-[${questionOption.tagColor}] bg-[#a73333 w-full`}
          style={{ backgroundColor: questionOption.tagColor }}
        ></div>
      </div>
      <div className='flex items-center justify-center w-full'>
        <p className='text-sm font-medium text-[#344054]'>
          {questionOption.value}
        </p>
      </div>
    </div>
  );
}
