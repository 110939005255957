import React from "react";
import airPlane from "assets/svg/airPlane.svg";
import {CButton} from "components/UI/Button/Buttons";
import linkIcon from "assets/svg/buttonLinkIcon.svg";
import {CopyToClipBoard} from "./CopyToClipBoard";

export const CopyActionWrapper = ({text}: { text ?: string }) => {
    return (
        <div className="border my-2 border-[#EEEEEE] p-4 rounded-lg">
      <span>
        <img src={airPlane}/>
      </span>
            <p className="text-sm font-grotesk  my-2 font-semibold">Copy link to your clipboard</p>
            <CopyToClipBoard text={text ?? ""}>
                <CButton
                    action={() => {
                    }}
                    icon={linkIcon}
                    iconPosition="left"
                    variant="plain"
                    text="Copy link"
                    loading={false}
                />
            </CopyToClipBoard>
        </div>
    );
};
