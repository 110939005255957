import { useEffect } from "react";

export const useGetQuestions = (connection, presentationId, eventHandler) => {
  useEffect(() => {
    const handleEventResponse = (id, questions) => {
      eventHandler(questions);
    };
    if (connection) {
      connection.on("Questions", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off("Questions", handleEventResponse);
      }
    };
  }, [connection, presentationId, eventHandler]);
};
