import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _beginPayment,
  _beginSubscriptionPayment,
  _cancelSubscription,
  _convertPointAmount,
  _getMyPlans,
  _getPlanHistory,
  _getPlans,
  _getPointBalance,
  _getPointHistory,
} from "services/Payment/paymentServices";

const initialState = {
  pointBalance: {
    isLoading: true,
    data: null,
  },
  plans: {
    isLoading: true,
    data: null,
    agencyPlan: null,
    personalPlan: null,
    businessPlan: null,
  },
  myPlans: {
    isLoading: true,
    data: null,
  },
  planHistory: {
    isLoading: true,
    data: null,
  },
  pointHistory: {
    isLoading: true,
    data: null,
  },
};

//GET

export const getPlans = createAsyncThunk("payment/getPlans", async () => {
  const response = await _getPlans();
  return response.data;
});

export const getPointBalance = createAsyncThunk(
  "payment/getPointBalance",
  async () => {
    const response = await _getPointBalance();
    return response.data;
  }
);

export const getMyPlans = createAsyncThunk("payment/getMyPlans", async () => {
  const response = await _getMyPlans();
  return response.data;
});

export const getPlanHistory = createAsyncThunk(
  "payment/getPlanHistory",
  async () => {
    const response = await _getPlanHistory();
    return response.data;
  }
);

export const getPointHistory = createAsyncThunk(
  "payment/getPointHistory",
  async () => {
    const response = await _getPointHistory();
    return response.data;
  }
);

//POST
export const cancelSubscription = createAsyncThunk(
  "payment/cancelSubscription",
  async () => {
    const response = await _cancelSubscription();
    return response.data;
  }
);
export const beginPayment = createAsyncThunk(
  "payment/beginPayment ",
  async (data) => {
    const response = await _beginPayment(data);
    return response.data;
  }
);

export const beginSubscriptionPayment = createAsyncThunk(
  "payment/beginSubscriptionPayment",
  async (data) => {
    const response = await _beginSubscriptionPayment(data);
    return response.data;
  }
);

export const convertPointAmount = createAsyncThunk(
  "payment/convertPointAmount ",
  async (data) => {
    const response = await _convertPointAmount(data);
    return response.data;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: (builder) => {
    //get my plans
    builder
      .addCase(getMyPlans.pending, (state) => {
        state.myPlans.isLoading = true;
      })
      .addCase(getMyPlans.fulfilled, (state, { payload }) => {
        state.myPlans.isLoading = false;
        state.myPlans.data = payload;
      })
      .addCase(getMyPlans.rejected, (state, { payload }) => {
        state.myPlans.isLoading = false;
      });

    //get my plan history
    builder
      .addCase(getPlanHistory.pending, (state) => {
        state.planHistory.isLoading = true;
      })
      .addCase(getPlanHistory.fulfilled, (state, { payload }) => {
        state.planHistory.isLoading = false;
        state.planHistory.data = payload;
      })
      .addCase(getPlanHistory.rejected, (state, { payload }) => {
        state.planHistory.isLoading = false;
      });

    //get point history
    builder
      .addCase(getPointHistory.pending, (state) => {
        state.pointHistory.isLoading = true;
      })
      .addCase(getPointHistory.fulfilled, (state, { payload }) => {
        state.pointHistory.isLoading = false;
        state.pointHistory.data = payload;
      })
      .addCase(getPointHistory.rejected, (state, { payload }) => {
        state.pointHistory.isLoading = false;
      });

    //get point balance
    builder
      .addCase(getPointBalance.pending, (state) => {
        state.pointBalance.isLoading = true;
      })
      .addCase(getPointBalance.fulfilled, (state, { payload }) => {
        state.pointBalance.isLoading = false;
        state.pointBalance.data = payload;
      })
      .addCase(getPointBalance.rejected, (state, { payload }) => {
        state.pointBalance.isLoading = false;
      });

    //get plans details
    builder
      .addCase(getPlans.pending, (state) => {
        state.plans.data = null;
        state.plans.isLoading = true;
      })
      .addCase(getPlans.fulfilled, (state, { payload }) => {
        state.plans.isLoading = false;
        state.plans.data = payload;
        if (payload) {
          state.plans.agencyPlan = payload?.filter((plan) => {
            return plan?.title?.toLowerCase() === "agency";
          })?.[0];

          state.plans.personalPlan = payload?.filter((plan) => {
            return plan?.title?.toLowerCase() === "personal";
          })?.[0];

          state.plans.businessPlan = payload?.filter((plan) => {
            return plan?.title?.toLowerCase() === "business";
          })?.[0];
        }
      })
      .addCase(getPlans.rejected, (state, { payload }) => {
        state.plans.isLoading = false;
      });
  },
});

export const getPaymentState = (state) => state.paymentState;

export default paymentSlice.reducer;
