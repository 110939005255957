  //assets
import zoom from "assets/svg/zoom.svg";
import gmail from "assets/svg/gmail.svg";
import linkedIn from "assets/svg/linkedIn.svg";
import facebook from "assets/svg/facebook.svg";
import microsoft from "assets/svg/microsoft.svg";
  
  //variables

  export const altLogins = [
    {
    img: gmail,
    name: "Gmail"
  },

  {
    img: linkedIn,
    name: "LinkedIn"
  },

  {
    img: microsoft,
    name: "Microsoft"
  },

  {
    img: zoom,
    name: "Zoom"
  },

  {
    img: facebook,
    name: "Facebook"
  },
]

  export const items = [
    {
      id: "200",
      title: "Your on - the - go presenting app",
      description:
        "Gather valuable insights with audience feedback and Q&A sessions. Keep your audience engaged and make your presentations more memorable.",
    },

    {
      id: "200",
      title: "Your on - the - go presenting app",
      description:
        "Gather valuable insights with audience feedback and Q&A sessions. Keep your audience engaged and make your presentations more memorable.",
    },
  ];
  export const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];