import React, { useEffect, useRef, useState } from "react";

//assets
import edit from "assets/svg/edit.svg";
import deletee from "assets/svg/whiteDelete.svg";
import colorPicker from "assets/svg/colorPicker.svg";

//libraries
import { toast } from "react-toastify";
import { SketchPicker } from "react-color";

export default function PollOptionField({ data, updateTitle, deleteOption }) {
  const { id, name , color} = data ?? {};
 

  //useRef
  const fileRef = useRef();
  const colorPickerRef = useRef();

  //usestate
  const [imgFile, setImgFile] = useState(null);
  const [inputValue, setInputValue] = useState(name ?? '');
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedColor, setSelectedColor] = useState(color ?? "#fff");
  const [showColorPicker, setShowColorPicker] = useState(false);

  //useeffect
  useEffect(() => {
    const handleClosePlaylist = (e) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(e.target)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClosePlaylist);

    return () => {
      document.removeEventListener("mousedown", handleClosePlaylist);
    };
  }, []);

  useEffect(() => {
    setInputValue(name);
  }, [name, data]);

  //function
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    updateTitle(id, inputValue);
    if (inputValue && selectedColor) {
      setIsDisabled(true);
    }
  };

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("Selected file is not an image.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Selected image exceeds the 5MB size limit.");
        return;
      }
      setSelectedImg(URL.createObjectURL(file));
      setImgFile(file);
      setIsDisabled(true);
      data.img = file;
    }
  };

  const handleColorPickerClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (color) => {
    data.color = color.hex;
    setSelectedColor(color.hex);
    name && setIsDisabled(true);
  };

  const resetOption = () => {
    setImgFile(null);
    setSelectedImg(null);
    setIsDisabled(false);
  };
  
  return (
    <section>
      <section
        style={{
          backgroundColor: selectedColor,
          color: !selectedColor?.includes("#fff") ? "white" : "#AFAFAF",
        }}
        className={`flex items-center justify-between py-2 border border-gray350 rounded-lg px-4 cursor-pointer`}
      >
        {
          <input
            onBlur={handleBlur}
            disabled={isDisabled}
            defaultValue={inputValue}
            onChange={handleInputChange}
            placeholder="Type another choice"
            className={`bg-transparent 
          
            outline-none w-11/12`}
          />
        }

        {!isDisabled && (
          <div className="flex items-center gap-x-2">
            <div className="relative" ref={colorPickerRef}>
              {showColorPicker && (
                <div className="absolute bottom-10">
                  <SketchPicker
                    color={selectedColor}
                    onChange={handleColorChange}
                  />
                </div>
              )}
              <img
                src={colorPicker}
                alt="color picker"
                className="cursor-pointer"
                onClick={handleColorPickerClick}
              />
            </div>
            {/* <img
              src={imgSvg}
              alt="img svg"
              className="cursor-pointer"
              onClick={() => fileRef.current.click()}
            /> */}

            <input
              ref={fileRef}
              type="file"
              className="hidden"
              onChange={fileChange}
            />
          </div>
        )}

        {isDisabled && (
          <div className="flex items-center ">
            <img
              src={edit}
              alt="edit"
              className="cursor-pointer"
              onClick={resetOption}
            />
          </div>
        )}

        <img
          src={deletee}
          alt="delete"
          className="ml-1 cursor-pointer"
          onClick={() => deleteOption(id)}
        />
      </section>
    </section>
  );
}
