import React, { useEffect, useState } from "react";

//assets
import bgDots from "assets/svg/teamBg.svg";
import rocket from "assets/svg/rocket.svg";
import littleRocket from "assets/svg/littleRock.svg";
import { Modal } from "antd";
import PlansModal from "./PlansModal";

export default function UpgradeAccount() {
  //usestate
  const [openPlanModal, setOpenPlanModal] = useState(false);

 

  const closeModal = () => {
    setOpenPlanModal(false);
  };

  const bgStyle = {
    backgroundImage: `url(${bgDots})`,
  };

  return (
    <section>
      <section className="w-[60%]  mx-auto  bg-gray70 rounded-3xl  flex items-center  py-4 my-16">
        <section
          style={bgStyle}
          className="w-[90%] mx-auto flex flex-col justify-center py-4"
        >
          <div className="flex justify-center">
            <img src={rocket} alt="upgrade your account" />
          </div>

          <div className="flex flex-col items-center justify-center mt-2 text-center font-grotesk gap-y-2">
            <p>You need to upgrade your plan to access this feature</p>

            <button
              onClick={() => setOpenPlanModal(true)}
              className="flex items-center justify-center px-2 py-2 text-xs text-white bg-black rounded-lg gap-x-1"
            >
              <img
                src={littleRocket}
                alt="upgrade your account"
                className="w-4"
              />
              <span>Upgrade my account</span>
            </button>
          </div>
        </section>
      </section>

      {/* Modal  */}
      <Modal
        open={openPlanModal}
        footer={false}
        onCancel={closeModal}
        width="92%"
        className="plan-modal"
      >
        <PlansModal />
      </Modal>

      {/*  */}
    </section>
  );
}
