import React from "react";
import { useSelector } from "react-redux";
import { getPresentationState } from "store/Presentations/presentationSlice";
import { isPPTFile } from "utils/getFileType";

export default function ImagePreview({ activeImage, disableRightClick }) {
  const { documentFiles } = useSelector(getPresentationState);


  const fileType = documentFiles?.data?.url?.replace(".", "");
  return (
    <section className="ml-36">
      <img
        onContextMenu={disableRightClick}
        src={activeImage}
        alt="presentation slide"
        id="mainImg"
        className={`   ${
          !isPPTFile(fileType) ? "object-contain h-[85vh]" : "w-[85%]  mx-auto"
        }`}
      />

      {/* <img
        onContextMenu={disableRightClick}
        src={activeImage}
        alt="presentation slide"
        id="mainImg"
        className={` [ min-xxl:w-[70.1875rem] min-vl:w-[80.1875rem] ] min-lg:w-[65.1875rem] rounded-lg cursor-pointer object-contain self-start  mx-auto `}
      /> */}
    </section>
  );
}
