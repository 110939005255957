import React, { useEffect, useState } from "react";

//assets
import iconn from "assets/svg/paymentSuccessIcon.svg";
// import { Link } from "react-router-dom";
import { formatNumber } from "utils/formatter";
import { getPointBalance } from "store/Payment/paymentSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default function PaymentSuccess({
  details,
  closeModal,
  closeTopUpModal,
}) {
  const { points, seats, amount } = details ?? {};
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //usestate
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    dispatch(getPointBalance());
  }, [dispatch]);

  useEffect(() => {
    const timeoutInterval = setTimeout(() => setCanContinue(true), 5000);
    return () => {
      clearTimeout(timeoutInterval);
    };
  }, []);

  const leavePage = () => {
    dispatch(getPointBalance());
    closeModal();
    closeTopUpModal && closeTopUpModal();

    if (pathname?.includes("/admin/setting")) {
      window.location.reload();
    } else {
      navigate("/admin/settings?tab=billing");
    }
  };

  return (
    <section className="w-11/12 mx-auto font-grotesk">
      <div>
        <img src={iconn} alt="" />
      </div>

      <section>
        <div>
          <h2 className="text-2xl font-semibold">Payment successful</h2>
        </div>

        <div className="mt-4">
          <p>Thank you for your payment!</p>
        </div>

        <section className="bg-[#FDFAF6]  text-sm font-semibold px-6 pb-6  pt-4 mt-4 rounded-2xl text-[#474747]">
          {points && (
            <div className="flex justify-between py-2">
              <p>No of points</p>
              <p>{points}</p>
            </div>
          )}

          {seats && (
            <div className="flex justify-between py-2">
              <p>No of users</p>
              <p>x 3</p>
            </div>
          )}

          <div className="flex justify-between py-2">
            <p>Tax</p>
            <p>$0</p>
          </div>

          <div className="flex justify-between py-2">
            <p>Subtotal</p>
            <p>${formatNumber(amount)}</p>
          </div>
          <section className="text-center ">
            <h4>Total</h4>
            <h1 className="text-[40px]   font-bold my-4">
              ${formatNumber(amount)}
            </h1>
          </section>
        </section>

        <div className="flex flex-col py-8 gap-y-6">
          <p className="font-medium">
            An email confirmation has been sent to you. If you have any
            questions, please contact our support team.
          </p>

          <div className="flex justify-center">
            <button
              onClick={leavePage}
              className="flex items-center justify-center px-4 py-2 text-sm text-white bg-black rounded-lg gap-x-2"
            >
              {canContinue ? (
                <>
                  <i className="pi pi-check" color="#FF6929"></i>
                  <span>Continue</span>
                </>
              ) : (
                <ClipLoader size={12} color="white" />
              )}
            </button>
          </div>
        </div>
      </section>
    </section>
  );
}
