import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CheckoutForm from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function StripePayment({
  clientKey,
  closeModal,
  details,
  closeTopUpModal,
}) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientKey.current,
  };
  

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        details={details}
        closeModal={closeModal}
        closeTopUpModal={closeTopUpModal}
      />
    </Elements>
  );
}
