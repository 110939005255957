export const viewerModeUseCase = (eventName, status, presenterConnectionId) => {
  eventName = eventName?.toLowerCase();
  if (eventName === "confirmaccessotp" && status) {
    console.log(presenterConnectionId, "presenter connectionID");
    localStorage.setItem("connectionId", presenterConnectionId ?? "");
  }

  if (eventName === "attend" && status) {
    if (presenterConnectionId !== "" || presenterConnectionId === null) {
      localStorage.setItem("connectionId", presenterConnectionId ?? "");
    }
  }
};
