import { CButton } from "components/UI/Button/Buttons";
import React from "react";

export const TextInput = ({
  name,
  placeholder,
  change,
  label,
}: {
  name: string;
  placeholder: string;
  change: (val: string) => void;
  label: string;
}) => {
  return (
    <>
      <p className="text-[#474747] text-xs lg:text-sm">
        Enter your meeting code
      </p>
      <div className="mt-2 rounded-md text-gray700 bg-orange10 relative overflow-hidden">
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          className={` pr-5  w-full outline-none bg-inherit border-none placeholder:text-sm py-3 pl-5 text-sm`}
          onChange={(e) => change(e.target.value)}
        />
      </div>
    </>
  );
};
