import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

//libraries
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";
import { errorToast, successToast } from "utils/toastUtils";

//assets
import expand from "assets/svg/fullSceen.svg";
import leftArrow from "assets/svg/leftArrow.svg";
import catchUp from "../../../assets/svg/catchUpWithPresenter.svg";
import exit from "../../../assets/svg/exit.svg";
import share from "../../../assets/svg/share1.svg";
import people from "../../../assets/svg/peopleGroup.svg";
import questionNotif from "../../../assets/svg/questionNotif.svg";

//components
import Loader from "components/UI/Loader";
import Navbar from "components/User/ViewerMode/Navbar";

//utils

import {
  getConnectionId,
  setUnlockedSlides,
  stopViewerJourney,
  getUnlockedSlides,
  beginViewerJourney,
  resetUnlockedSlides,
  hasViewerJourneyBegan,
  resetTimer,
  getPresentationId,
  localStorageSetter,
} from "utils/localStorageServices";
import { baseUrl } from "utils/baseUrl";
import { imageStream } from "utils/imageStream";

//slices
import {
  connectSignalR,
  disconnectSignalR,
  getConnectionState,
  getSignalRConnection,
  getSignalRState,
} from "store/SignalR/signalRSlice";
import {
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { viewerModeUseCase } from "hooks/SignalR/EventResponse/UseCases/ViewerModeUseCase";

//hook
import { useViewerModeEventResponse } from "hooks/SignalR/EventResponse/useViewerModeResponse";
import { useSignalRConnectionStatus } from "hooks/SignalR/Listeners/Global/useSignalConnectionStatus";
import { RecordTimeline } from "hooks/SignalR/Invokers/Viewer/RecordTimeline";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";
import { useHasViewerBeenRemoved } from "hooks/SignalR/Listeners/Viewers/useHasViewerBeenRemoved";
import { getPollDetails } from "store/Poll/pollSlice";
import PollQuestionTemplate from "components/User/ViewerMode/PollQuestionTemplate";
import { downloadSlide } from "store/Slides/slidesSlice";
import { ClipLoader } from "react-spinners";
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";
import {
  pollDetailService,
  startPollService,
  storePolls,
} from "utils/activePresentationUtils";
import { formatDate } from "utils/formatDate";
import NetworkError from "components/UI/NetworkError/NetworkError";
import instance from "../../../lib/axiosInterceptor";
import Sidebar from "components/User/ViewerMode/Sidebar";
import Nav from "components/User/ViewerMode/BottomBar";
import BottomBar from "components/User/ViewerMode/BottomBar";

let connection = null;

export default function ViewerMode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  connection = getSignalRConnection();
  const connectionState = getConnectionState();

  const { friendlyId } = useParams();

  const { presentationDetails } = useSelector(getPresentationState);
  const { connectionId } = useSelector(getSignalRState);

  // usememo
  const documentSlideCache = useMemo(() => ({}), []);

  //hook

  useViewerModeEventResponse(
    connection,
    (eventName, status, message, presenterConnectionId) => {
      viewerModeUseCase(eventName, status, presenterConnectionId);
    }
  );
  // handled in signalR slice
  // useSignalRConnectionStatus(connection);

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();

      // console.log(event, message, "event name");
    }
  );

  //usestate
  const [count, setCount] = useState(0);
  const [modalPosition, setModalPosition] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [orientation, setOrientation] = useState(
    window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape"
  );

  //new usestates
  const [timer, setTimer] = useState(null);
  const [pollID, setPollID] = useState(null);
  const [timeSpent, setTimeSpent] = useState(0);
  const [canNavBack, setCanNavBack] = useState(false);
  const [canNavForward, setCanNavForward] = useState(false);

  const [presentationId, setPresentationId] = useState(null);
  const [title, setTitle] = useState("Starting presentation...");
  const [activeStreamImage, setActiveStreamImage] = useState(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  //experiment usestate
  const [activeCount, setActiveCount] = useState(0);
  const [documentObj, setDocumentObj] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [playlistNumber, setPlaylistNumber] = useState(null);
  const [activePlaylist, setActivePlaylist] = useState(null);
  const [unlockedSlideId, setUnlockedSlideId] = useState(null);
  const [loadingFirstDocument, setLoadingFirstDocument] = useState(true);
  const [presenterActiveCount, setPresenterActiveCount] = useState(null);
  const [presenterActivePlaylist, setPresenterActivePlaylist] = useState(null);

  const [refreshPage, setRefreshPage] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [docDetailsFetched, setDocDetailsFetched] = useState(false);
  const [overallData, setOverallData] = useState([]);

  // useref
  const activeImgRef = useRef(null);
  const timeSpentRef = useRef(timeSpent);

  //hook
  useHasViewerBeenRemoved(connection, presentationId);

  //experiment useeffect
  const getSlideId = (slideNumber, docId) => {
    const activeDocumentSlides = documentSlideCache?.[docId];
    if (!activeDocumentSlides || !activeDocumentSlides.length) {
      // console.log(documentSlideCache?.[docId], "static");
      const particularDoc = overallData.find((item) => item.id == docId);
      if (particularDoc.slides && particularDoc.slides.length) {
        return particularDoc.slides[slideNumber].id;
      }
    }
    return activeDocumentSlides?.[slideNumber]?.slideId;
  };
  //get presentation details
  useEffect(() => {
    if (connectionState === "Connected") {
      if (getConnectionId()) {
        connection
          .invoke("Attend", friendlyId, 1, getConnectionId())
          .then(() => {
            if (presentationDetails.data) {
              const presentationValue = presentationDetails.data.presentation;
              const presentationId = presentationValue?.id;

              const timestamp = formatDate(presentationValue.startTime);
              const dateObject = new Date(timestamp);
              const startTime = dateObject.getTime();
              // const startTime = date.getTime() + 3600000; //subtract an hour
              localStorage.setItem("presentationId", presentationId);
              localStorage.setItem("startTime", JSON.stringify(startTime));
              localStorage.setItem("isActive", JSON.stringify("true"));
              setPresentationId(presentationId);
              window.dispatchEvent(new Event("timer"));
            } else {
              dispatch(getPresentationDetails(friendlyId)).then(
                ({ type, payload }) => {
                  if (type?.includes("fulfilled")) {
                    const presentationId = payload?.presentation?.id;

                    const timestamp = formatDate(
                      payload?.presentation?.startTime
                    );
                    const dateObject = new Date(timestamp);
                    const startTime = dateObject.getTime();
                    // const startTime = date.getTime() + 3600000; //subtract an hour
                    localStorage.setItem("presentationId", presentationId);
                    localStorage.setItem(
                      "startTime",
                      JSON.stringify(startTime)
                    );
                    localStorage.setItem("isActive", JSON.stringify("true"));
                    setPresentationId(presentationId);
                    window.dispatchEvent(new Event("timer"));
                  }
                }
              );
            }
          })
          .catch((err) => {
            toast.error(
              "An unexpected error occurred invoking 'Attend' on the server"
            );
          });
      } else {
        let routeOrigin = window.location.origin;
        window.location.href = `${routeOrigin}/join-presentation/${friendlyId}`;
      }
    }
  }, [connectionState, friendlyId, presentationId, dispatch]);

  useEffect(() => {
    if (!presentationDetails.isLoading) {
      const presentationDocuments = presentationDetails.data.doc;

      if (presentationDocuments.length) {
        // Create an array of promises for each API call
        const documentDetailsPromises = presentationDocuments.map((data) =>
          getDocumentDetails(data.documentId)
        );

        // Use Promise.all to wait for all promises to resolve
        Promise.all(documentDetailsPromises)
          .then((responses) => {
            // You can now use the array of responses (e.g., store them in a state variable)
            const overallData = responses.map((res) => res.data); // or however you want to process it
            setOverallData(overallData);
          })
          .catch((error) => {
            console.error("Error fetching document details", error);
          });
      }
    }

    async function getDocumentDetails(docId) {
      try {
        const result = await instance.get(`/Document/${docId}`);
        return result;
      } catch (e) {
        console.log("Error occurred", e);
        // Re-throw to handle error in Promise.all
      }
    }
  }, [presentationDetails]);

  //handles presentation details (stores document list, saves active document & caches document ids/keys)
  useEffect(() => {
    if (presentationDetails.data && overallData.length) {
      const { data } = presentationDetails ?? {};
      if (data) {
        const { doc } = data ?? {};

        if (doc) {
          let docs = [...doc];
          const sortedDocs = docs.sort((a, b) => {
            const first = Number(a?.position ?? 0);
            const second = Number(b?.position ?? 0);
            if (first < second) {
              return -1;
            } else if (first > second) {
              return 1;
            } else if (first === second) {
              return 0;
            }
            return 0;
          });
          const currentSlide =
            data?.presentation?.currentSlide === -1
              ? 0
              : data?.presentation?.currentSlide;
          const currentDoc =
            data?.presentation?.currentItem ?? sortedDocs?.[0]?.documentId;

          setDocumentList(sortedDocs);

          //set presentation title
          setTitle(data?.presentation?.title);

          //save unlocked slides

          setUnlockedSlides(
            getSlideId(currentSlide, currentDoc),
            currentDoc,
            currentSlide
          );

          if (docs?.length > 0) {
            setActivePlaylist(currentDoc);
            setActiveCount(currentSlide);

            setPresenterActiveCount(currentSlide);
            setPresenterActivePlaylist(currentDoc);
          }

          sortedDocs.forEach((document) => {
            documentSlideCache[document.documentId] = [];
          });

          setDocumentObj(documentSlideCache);
        }
      }
    }
  }, [presentationDetails, documentSlideCache, overallData]);

  //THE ENGINE
  useEffect(() => {
    //convert object into an array of keys
    const documentKeys = Object.keys(documentObj);
    const storePoll = (pollId) => {
      if (pollId && documentSlideCache[pollId]?.length < 1) {
        dispatch(getPollDetails({ presentationId, pollId })).then(
          ({ type, payload }) => {
            if (type?.includes("fulfilled")) {
              storePolls(payload);
              const questionList = payload?.questions;

              documentSlideCache[pollId] = questionList;

              if (activePlaylist === pollId) {
                setLoadingFirstDocument(false);
                // console.log(documentSlideCache, "first load poll");
                // Mark first document slides as loaded
              }
            }
          }
        );
      }
    };

    const slideObj = async (docSlides, docId, slide, retries = 3) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        const id = slide?.id ?? slide;
        try {
          const slideExist = documentSlideCache[docId].some(
            (slide) => slide.slideId === id
          );
          if (!slideExist) {
            const url = await imageStream(id);
            // Not the issue

            if (url) {
              const slideObj = {
                slideId: id,
                imgUrl: url,
                title: slide?.title ?? "1",
                fileType: slide?.url,
              };
              //No issues here

              //No issues with slideObject
              documentSlideCache[docId].push(slideObj);

              documentSlideCache[docId].sort((a, b) => {
                const first = Number(a?.title.split("_")?.[1]);
                const second = Number(b?.title.split("_")?.[1]);
                if (first < second) {
                  return -1;
                } else if (first > second) {
                  return 1;
                }
                return 0;
              });

              // Check if this is the first document and if all slides are loaded

              if (
                activePlaylist === docId &&
                documentSlideCache[docId].length === (docSlides.length ?? 1)
              ) {
                setLoadingFirstDocument(false); // Mark first document slides as loaded
              }
            }
            break; //Exit when successful
          } else {
            break; //exit iteration and move to the next
          }
        } catch (err) {
          if (attempt === retries) {
            console.error(
              `Error fetching image stream for slide ID: ${id} after ${retries} attempts`,
              err
            );
          } else {
            setRefreshPage(true);
            setLoadingFirstDocument(false);
            console.warn(
              `Retrying image stream for slide ID: ${id}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const processSlidesInBatches = async (docSlides, docId, batchSize = 4) => {
      for (let i = 0; i < docSlides.length; i += batchSize) {
        const batch = docSlides.slice(i, i + batchSize);
        // Wait for the current batch to complete before moving to the next
        await Promise.all(
          batch.map((slide) => slideObj(docSlides, docId, slide))
        );
      }
    };

    const getDocumentDetails = async (docId, retries = 3) => {
      //this retries a broken request 3 times
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const documentType = documentList
            ?.find((doc) => doc?.documentId === docId)
            ?.type?.toLowerCase();

          if (documentType === "poll") {
            storePoll(docId);
            // break;
            // Exit the loop if successful
          } else {
            //fetch document details
            const documentDetails = await axios.get(
              `${baseUrl}/Document/${docId}`
            );

            // assign slides
            const docSlides = documentDetails?.data?.slides;

            if (docSlides?.length > 0) {
              setDocDetailsFetched(true);
              // Process the slides for this document in batches
              await processSlidesInBatches(docSlides, docId);

              // //function to get stream of each slide
              // await Promise.all(
              //   docSlides.map(async (slide) => {
              //     await slideObj(docSlides, docId, slide);
              //   })
              // );
            } else {
              // usecase for when it's a different MIME type (image, video)
              await slideObj(1, docId, documentDetails?.data?.id);
            }

            break; // Exit the loop if successful
          }
        } catch (err) {
          // setRefreshPage(true);
          if (attempt === retries) {
            toast.error("Error occured while fetching slides");

            console.error(
              `Error fetching document details for document ID: ${docId} after ${retries} attempts`,
              err
            );
          } else {
            console.warn(
              `Retrying document details for document ID: ${docId}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const fetchDocumentList = async () => {
      try {
        const docId = activePlaylist?.documentId ?? activePlaylist;
        //run first object request first or savedDoc
        await getDocumentDetails(docId);

        let remainingKeys = documentKeys.filter((key) => key !== docId);

        // Process the remaining documents one-by-one
        for (const key of remainingKeys) {
          if (documentSlideCache[key]?.length < 1) {
            await getDocumentDetails(key);
          }
        }

        //process other document keys next and in parallel except 1

        // await Promise.all(
        //   remainingKeys.map(
        //     (key) =>
        //       documentSlideCache[key]?.length < 1 && getDocumentDetails(key)
        //   )
        // );
      } catch (err) {
        console.error("Error in fetching document list", err);
      }
    };

    if (documentKeys?.length > 0) {
      fetchDocumentList();
    }
  }, [documentObj, documentSlideCache, dispatch]);

  //renders active image and next image

  // useEffect(() => {
  //   // This is technically not needed
  //   timeSpentRef.current = timeSpent;
  //   console.log("i keep getting triggered for every re-rendering");
  // }, [timeSpent]);

  // Timer to update timeSpent
  useEffect(() => {
    // This technically does not get re-rendered but because the setInterval timer is active, it would basically continue running
    const slideTime = setInterval(() => {
      timeSpentRef.current += 1;
    }, 1000);

    return () => clearInterval(slideTime);
  }, []);

  // useEffect(() => {
  //   const slideTime = setInterval(() => {
  //     setTimeSpent(timeSpentRef.current + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(slideTime);
  //   };
  // }, []);

  useEffect(() => {
    if (!loadingFirstDocument) {
      const id = activeCount === -1 ? 0 : activeCount;

      const slide = documentSlideCache?.[activePlaylist]?.[id];

      setActiveStreamImage(slide);

      //navigation
      let unlockedSlides = getUnlockedSlides();
      const reversedArr = unlockedSlides?.reverse();
      let foundReverseIndex;

      reversedArr.some((el, index) => {
        if (activePlaylist === el.docId && activeCount === el.slideNumber) {
          setUnlockedSlideId(el.id);    
          foundReverseIndex = index;
          return true;
        }
        return false;
      });
      // checking if viewer can move backwards
      if (reversedArr[foundReverseIndex + 1]) {
        setCanNavBack(true);
      } else {
        setCanNavBack(false);
      }

      //checking if viewer can move forward
      if (reversedArr[foundReverseIndex - 1]) {
        setCanNavForward(true);
      } else {
        setCanNavForward(false);
      }
    }
  }, [activeCount, loadingFirstDocument, activePlaylist, documentSlideCache]);

  ///

  // useeffect

  // UI handles fullscreen

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");

    const handleOrientationChange = () => {
      setOrientation(mediaQuery.matches ? "portrait" : "landscape");
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  // attention modal positions
  useEffect(() => {
    let positions = [
      {
        left: "29%",
      },
      {
        right: "29%",
      },
      {
        top: "2%",
      },
      {
        bottom: "29%",
      },
    ];

    let index = Math.floor(Math.random() * 3);

    setModalPosition(positions[index]);
  }, []);
  //
  //

  // Signal R connection

  useEffect(() => {
    if (!connection) {
      dispatch(connectSignalR());
    }
  }, [dispatch]);

  useEffect(() => {
    if (connection) {
      //close connection
      // connection.onclose((e) => {
      //   if (e) {
      //     toast.current.show({
      //       severity: "error",
      //       summary: "Error",
      //       detail: `Connection closed with error: "${e || ""}"`,
      //     });
      //   }
      // });

      //events
      connection.on("MoveSlide", (id, int, docId) => {
        int = int === -1 ? 0 : int;

        setPresenterActiveCount(int);
        setPresenterActivePlaylist(docId);
        const timeSpent = timeSpentRef.current;

        let unlockedSlides = getUnlockedSlides();
        let unlockedSlideLength = unlockedSlides?.length;
        let leavingSlide = unlockedSlides?.[unlockedSlideLength - 1]?.id;
        let previousSlide = unlockedSlides?.[unlockedSlideLength - 2]?.id;

        //record timeline
        RecordTimeline(
          connection,
          leavingSlide,
          "presenter",
          timeSpentRef.current,
          previousSlide
        );

        timeSpentRef.current = 0;
        //save unlocked slides
        setUnlockedSlides(getSlideId(int, docId), docId, int);
        if (!hasViewerJourneyBegan()) {
          //reset
          setActiveCount(int);
          setActivePlaylist(docId);
          //
        }
      });

      connection.on("Attend");

      connection.on("EndPresentation", async (message, something) => {
        navigate("/presentation-ended");
        resetTimer();
        resetUnlockedSlides();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, connection]);

  // UI useffects
  useEffect(() => {
    if (activePlaylist) {
      const documentNumber = Object.keys(documentSlideCache).findIndex((el) => {
        return el === activePlaylist;
      });

      setPlaylistNumber(documentNumber + 1);
    }
  }, [activePlaylist, documentSlideCache]);

  useEffect(() => {
    const previousSlide = () => {
      beginViewerJourney();

      let unlockedSlides = getUnlockedSlides();

      const currentSlidePosition = unlockedSlides.findIndex((el) => {
        return el.id === unlockedSlideId;
      });
      const slide = unlockedSlides[currentSlidePosition - 1];
      const { docId, slideNumber } = slide ?? {};
      if (slide) {
        setActivePlaylist(docId);
        setActiveCount(slideNumber);

        let unlockedSlides = getUnlockedSlides();
        let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
        let previousSlide = slide?.id;

        //record timeline
        RecordTimeline(
          connection,
          leavingSlide,
          "presenter",
          timeSpentRef.current,
          previousSlide
        );

        timeSpentRef.current = 0;
      }
    };

    const nextSlide = () => {
      beginViewerJourney();
      let unlockedSlides = getUnlockedSlides();
      const currentSlidePosition = unlockedSlides.findIndex((el) => {
        return el.id === unlockedSlideId;
      });
      // whawt if the currentSlidePosition isn't existing , this works because by default the button is usually disabled, if it wasn't disabled , this would have caused a much bigger issue
      const slide = unlockedSlides[currentSlidePosition + 1];

      // Wrong order of check for the code
      const { docId, slideNumber } = slide ?? {};

      if (slide) {
        setActivePlaylist(docId);
        setActiveCount(slideNumber);
      }

      let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
      let previousSlide = unlockedSlides?.[currentSlidePosition - 1]?.id;

      //record timeline
      RecordTimeline(
        connection,
        leavingSlide,
        "presenter",
        timeSpentRef.current,
        previousSlide
      );

      timeSpentRef.current = 0;
    };
    const handleEventListener = (e) => {
      if (connection) {
        if (e.key === "ArrowRight") {
          nextSlide();
        }

        if (e.key === "ArrowLeft") {
          previousSlide();
        }
      }
    };

    window.addEventListener("keydown", handleEventListener);

    return () => {
      window.removeEventListener("keydown", handleEventListener);
    };
  }, [unlockedSlideId]);

  //

  //function
  const shareLink = async () => {
    let routeOrigin = window.location.origin;
    let text = `${routeOrigin}/join-presentation/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(text);
      toast.success("Link copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy link");
    }
  };

  //fullscreen function

  const lockScreenOrientation = () => {
    const screen = window.screen;
    if (screen.orientation && screen.orientation.lock) {
      screen.orientation.lock("landscape").catch((error) => {
        console.error("Orientation lock failed: ", error);
      });
    }
  };

  const enterFullscreen = () => {
    const elem = activeImgRef.current;
    if (!elem) return;

    setIsFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen().then(() => {
        lockScreenOrientation();
      });
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen().then(() => {
        lockScreenOrientation();
      });
    }
  };

  const exitFullscreen = () => {
    setIsFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  const previousSlide = () => {
    beginViewerJourney();
    let unlockedSlides = getUnlockedSlides();

    const currentSlidePosition = unlockedSlides.findIndex((el) => {
      return el.id === unlockedSlideId;
    });
    const slide = unlockedSlides[currentSlidePosition - 1];
    const { docId, slideNumber } = slide ?? {};

    setActivePlaylist(docId);
    setActiveCount(slideNumber);

    let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
    let previousSlide = slide?.id ?? null;

    //record timeline
    RecordTimeline(
      connection,
      leavingSlide,
      "presenter",
      timeSpentRef.current,
      previousSlide
    );

    timeSpentRef.current = 0;
  };

  const nextSlide = () => {
    beginViewerJourney();
    let unlockedSlides = getUnlockedSlides();

    const currentSlidePosition = unlockedSlides.findIndex((el) => {
      return el.id === unlockedSlideId;
    });
    const slide = unlockedSlides[currentSlidePosition + 1];
    const { docId, slideNumber } = slide ?? {};
    if (slideNumber === presenterActiveCount) {
      catchPresenter();
      toast.success("Now in Sync with presenter");
      return;
    }

    setActivePlaylist(docId);
    setActiveCount(slideNumber);

    let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
    let previousSlide = unlockedSlides?.[currentSlidePosition - 1]?.id;

    //record timeline
    RecordTimeline(
      connection,
      leavingSlide,
      "presenter",
      timeSpentRef.current,
      previousSlide
    );

    timeSpentRef.current = 0;
  };
  const catchPresenter = () => {
    stopViewerJourney();
    setActiveCount(presenterActiveCount);
    setActivePlaylist(presenterActivePlaylist);
    toast.success("Now in Sync with presenter");
  };

  const isLandscape = () => {
    return orientation === "landscape" && 1000 > screenWidth;
  };

  // const download = () => {
  //   setIsDownloadLoading(true);
  //   const slideId = activeStreamImage?.slideId;
  //   const presentationId = getPresentationId();
  //   const fileName = activeStreamImage?.title;
  //   const presentaConnectionId = getConnectionId();
  //   dispatch(
  //     downloadSlide({ slideId, presentationId, presentaConnectionId })
  //   ).then(({ type, payload }) => {
  //     setIsDownloadLoading(false);
  //     if (type?.includes("fulfilled")) {
  //       let filename = fileName ?? "downloaded-file";

  //       const url = URL.createObjectURL(new Blob([payload]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `${filename}.jpg`);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     }
  //   });
  // };

  ////// HANDLING POLLS

  //hook

  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();
    const { pollId } = obj ?? {};

    setPollID(pollId);

    if (event === "startpoll") {
      //get poll
      const pollDetails = pollDetailService(pollId);
      setActiveStreamImage(pollDetails);
      startPollService();
      setCount(0);
      setTimer(pollDetails?.questions?.[0]?.duration);

      //

      //reset answer
      localStorage.removeItem("pollAnswer");

      const pollAnswer = {
        pollId,
        answers: [],
      };
      localStorage.setItem("pollAnswer", JSON.stringify(pollAnswer));
    }
  });

  //////
  return (
    <section
      className={`relative overflow-x-hidden w-full z-50 min-h-screen `}
    >
      {/* nav */}

      {/* {!isLandscape() && (
        <Navbar
          title={title}
          connection={connection}
          activeImage={activeStreamImage?.imgUrl}
          slideId={activeStreamImage?.slideId}
          activeDocument={activePlaylist}
          slideNumber={activeCount}
          presentationId={presentationId}
          friendlyId={friendlyId}
        />
      )} */}

      {/* {!isLandscape() && (
        <Sidebar />
      )} */}

      {/*  */}

      {/* main */}

      {refreshPage && (
        <section>
          <NetworkError />
        </section>
      )}

      {!refreshPage && (
        <section className="  mt-[2rem] w-full absolute  ">
          {/* loader */}
          {/* {(documentFiles?.isLoading || !activeImage) && <Loader />} */}

          <section>
            {(presentationDetails?.isLoading ||
              !connection ||
              loadingFirstDocument ||
              !presentationId) && (
              <div className="">
                {!isLandscape() && <Sidebar />}
                <Loader />
              </div>
            )}
            {/* {(presentationDetails?.isLoading ||
              !connection ||
              loadingFirstDocument ||
              !presentationId) && !isLandscape() &&
              <>
              <Sidebar />
              <Loader />
              </> } */}

            {/*  */}

            {!presentationDetails?.isLoading &&
              connection &&
              !loadingFirstDocument &&
              presentationId && (
                <div className=" flex flex-row items-center">
                  {!isLandscape() && <Sidebar />}
                  <section className={`lg:h-[90vh] h-[80vh] flex flex-col`}>
                    <section
                      className={` w-[95%] flex flex-col items-center justify-start  h-full mx-auto ${
                        isLandscape() && "w-[90%]"
                      } `}
                    >
                      {/* slides */}
                      <section
                        className={`flex items-center  ${
                          orientation === "landscape" && "mt-1"
                        } z-50 gap-x-2`}
                      >
                        {/* left control */}
                        {isLandscape() && (
                          <button
                            className={` ${
                              !canNavBack && "opacity-40"
                            }    cursor-pointer  `}
                            onClick={previousSlide}
                            disabled={!canNavBack}
                          >
                            <img
                              src={leftArrow}
                              alt="move left"
                              className={`cursor-pointer`}
                            />
                          </button>
                        )}
                        {/*  */}

                        {/* image stream   */}
                        <section>
                          <section>
                            {!activeStreamImage?.questions && (
                              <section
                                className={`${hasViewerJourneyBegan() ? "border-2 border-[#FF6929]" : ""} z-10 flex w-full py-4 px-4 border-1 rounded-xl items-center justify-center bg-[#F6F6F6] `}
                              >
                                <section
                                  ref={activeImgRef}
                                  className="flex items-center border-[1.5px] rounded-lg"
                                >
                                  {isFullScreen && canNavBack && (
                                    <button
                                      className={`    cursor-pointer `}
                                      onClick={previousSlide}
                                      disabled={!canNavBack}
                                    >
                                      {/* <img
                        src={leftArrow}
                        alt="move left"
                        className={`cursor-pointer`}
                      /> */}
                                    <div className="bg-white rounded-full w-7 h-7 center-content ">
                                      <i className="text-lg text-black pi pi-angle-left "></i>
                                    </div>
                                  </button>
                                )}

                                {!activeStreamImage?.question && (
                                  <img
                                    onClick={handleFullscreen}
                                    alt="presentation slide"
                                    src={activeStreamImage?.imgUrl}
                                    className={`  object-contain rounded-lg cursor-pointer lg:w-[1900px] [ lg:max-h-[550px]  vl:max-h-[620px] vvl:h-[800px]  ] w-[90%] py-1 px-4  mx-auto`}
                                  />
                                )}

                                {isFullScreen && canNavForward && (
                                  <button
                                    className={` cursor-pointer   `}
                                    onClick={nextSlide}
                                    disabled={!canNavForward}
                                  >
                                    {/* <img
                        src={leftArrow}
                        alt="move left"
                        className={`cursor-pointer rotate-180`}
                      /> */}
                                      <div className="bg-white rounded-full w-7 h-7 center-content ">
                                        <i className="text-lg text-black pi pi-angle-right "></i>
                                      </div>
                                    </button>
                                  )}
                                </section>
                              </section>
                            )}

                            {activeStreamImage?.questions && (
                              <section className=" bg-gray100 w-full  px-4 pt-4 pb-6 overflow-y-scroll  [  lg:max-h-[35rem]  vl:max-h-[37rem] vvl:max-h-[43rem] ] ">
                                <PollQuestionTemplate
                                  toast={toast}
                                  documentSlideCache={documentSlideCache}
                                  connection={connection}
                                  pollID={pollID}
                                  pollDetails={activeStreamImage}
                                  timer={timer}
                                  setTimer={setTimer}
                                  count={count}
                                  setCount={setCount}
                                />
                              </section>
                            )}
                          </section>
                        </section>
                        {/*  */}

                        {/* right control */}
                        {isLandscape() && (
                          <button
                            className={` ${
                              !canNavForward && "opacity-40"
                            }  cursor-pointer `}
                            onClick={nextSlide}
                            disabled={!canNavForward}
                          >
                            <img
                              src={leftArrow}
                              alt="move left"
                              className={`cursor-pointer rotate-180`}
                            />
                          </button>
                        )}
                        {/*  */}
                      </section>

                      {/*  */}

                      {/* controls */}
                      {!activeStreamImage?.questions && (
                        <section className="flex flex-col bg-[#f6f6f6] items-center justify-between py-3 w-full px-5 my-4 lg:flex-row md:rounded-xl">
                          {!isLandscape() && (
                            <section className="flex gap-x-4">
                              <button
                                className={` ${
                                  !canNavBack && "opacity-40"
                                }  flex items-center px-5 py-2 lg:text-sm text-xs border cursor-pointer rounded-full bg-gray50 gap-x-2 text-gray500`}
                                onClick={previousSlide}
                                disabled={!canNavBack}
                              >
                                <img
                                  src={leftArrow}
                                  alt="move left"
                                  className={`cursor-pointer`}
                                />
                                <p>Previous slide</p>
                              </button>

                              {/* slide number  */}
                              <div className="mt-2 text-sm text-center ">
                                {" "}
                                <span className="px-2 py-1 font-medium text-black rounded-full bg-[#AFAFAF]">
                                  {activeCount + 1}
                                </span>
                              </div>

                              {/*  */}

                              <button
                                className={` ${
                                  !canNavForward && "opacity-40"
                                } flex items-center px-5 py-2 lg:text-sm text-xs border cursor-pointer rounded-full bg-gray200 gap-x-2 text-[#AFAFAF]`}
                                onClick={nextSlide}
                                disabled={!canNavForward}
                              >
                                <p>Next slide</p>
                                <img
                                  src={leftArrow}
                                  alt="move left"
                                  className={`cursor-pointer rotate-180`}
                                />
                              </button>
                            </section>
                          )}

                          <section className="flex items-center gap-x-4">
                            {/* {isDownloadLoading ? (
                            <ClipLoader color="gray" size={14} />
                          ) : (
                            <i
                              onClick={download}
                              className="mr-2 text-gray-500 pi pi-download"
                              style={{ fontSize: "1.4rem" }}
                            ></i>
                          )} */}
                            <div className="flex flex-col gap-2 items-center">
                              <button className="cursor-pointer">
                                <img
                                  src={catchUp}
                                  alt="catch up Presenter"
                                  onClick={catchPresenter}
                                />
                              </button>
                              <p className="text-xs text-[#667084]">Catch Up</p>
                            </div>

                            <div className="flex flex-col gap-2 items-center">
                              <button className="cursor-pointer">
                                <img
                                  src={expand}
                                  alt="move right"
                                  onClick={handleFullscreen}
                                  className={`cursor-pointer w-6  h-full`}
                                />
                              </button>
                              <p className="text-xs text-[#667084]">
                                Full Screen
                              </p>
                            </div>
                            <BottomBar
                              connection={connection}
                              activeImage={activeStreamImage?.imgUrl}
                              slideId={activeStreamImage?.slideId}
                              activeDocument={activePlaylist}
                              slideNumber={activeCount}
                              presentationId={presentationId}
                              friendlyId={friendlyId}
                            />
                            {/* 
                            <div className="flex flex-col items-center">
                              <button className="cursor-pointer">
                                <img src={people} alt="people in group" />
                              </button>
                              <p className="text-sm text-[#667084]">
                                Attendees
                              </p>
                            </div>

                            <div className="flex flex-col items-center">
                              <button className="cursor-pointer">
                                <img
                                  src={questionNotif}
                                  alt="question notification icon"
                                />
                              </button>
                              <p className="text-sm text-[#667084]">
                                Questions
                              </p>
                            </div>

                            <div>
                              <button className="cursor-pointer " onClick ={shareLink}>
                                <img src={share} alt="Share" />
                              </button>
                              <p className="text-sm text-[#667084]">
                                Share
                              </p>
                            </div>

                            <button
                              className={`  flex items-center cursor-pointer gap-x-2`}
                            >
                              <img src={exit} alt="exit" />
                            </button> */}
                          </section>
                        </section>)}
                      </section>
                    
                  </section>
                </div>
              )}
          </section>
        </section>
      )}

      {/*  */}
    </section>
  );
}
