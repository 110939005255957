import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//assets
// import edit from "assets/svg/edit.svg";
import paris from "assets/svg/paris.svg";
// import duplicate from "assets/svg/copy.svg";
import timerImg from "assets/svg/timer2.svg";
// import deletee from "assets/svg/whiteDelete.svg";
import pollHeader from "assets/svg/pollHeader.svg";

//libraries
import { ClipLoader } from "react-spinners";

//slices
import { deletePollQuestionType, takePoll } from "store/Poll/pollSlice";

//hooks
import { GenericInvoker } from "hooks/SignalR/Invokers/Global/GenericInvoker";
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";

//utils
import {
  addAnswer,
  getPollAnswer,
  getPollStatus,
  endPollService,
  startPollService,
  pollDetailService,
} from "utils/activePresentationUtils";
import { getConnectionId, getPresentationId } from "utils/localStorageServices";

//components
import OptionList from "./Polls/OptionList";

export default function PollQuestionTemplate({
  timer,
  toast,
  count,
  pollID,
  setCount,
  setTimer,
  connection,
  pollDetails,
}) {
  const dispatch = useDispatch();
  const { presentationId } = useParams();

  //usestate
  const [answer, setAnswer] = useState("");
  const [details, setDetails] = useState();
  const [pollId, setPollId] = useState(pollID);
  const [isLoading, setIsLoading] = useState(false);
  const [activePoll, setActivePoll] = useState(null);
  const [pollStarted, setPollStarted] = useState(false);
  const [pollComplete, setPollComplete] = useState(false);
  const [pollFullDetails, setPollFullDetails] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    ids: [],
    texts: [],
  });

  const { id, question, options, questionType, questionImageUrl, duration } =
    details ?? {};

  const type = questionType?.toLowerCase();
  const isWordCloud = type === "word cloud";
  const isTrueFalse = type === "true or false";

  //useeffect
  useEffect(() => {
    setPollComplete(false);
    setSelectedOptions({ ids: [], texts: [] });

    const poll = pollId ? pollDetailService(pollId) : pollDetails;
    setActivePoll(poll);
    setPollFullDetails(poll);
    setDetails(poll?.questions?.[count]);
  }, [count, pollId, pollDetails]);

  useEffect(() => {
    let interval;
    if (getPollStatus()) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 1;
          clearInterval();
          moveQuestion();
        });
      }, 1000);
    }

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  ////// HANDLING POLLS

  //hook

  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();
    const { pollId } = obj ?? {};
    console.log(event, "event");

    if (event === "startpoll") {
      console.log("Start");
      setPollId(pollId);
      startPollService();
      localStorage.removeItem("pollAnswer");

      const pollAnswer = {
        pollId,
        answers: [],
      };

      localStorage.setItem("pollAnswer", JSON.stringify(pollAnswer));
    }

    if (event === "endpoll") {
      handlePollCompletion();
    }
  });

  //function
  const moveQuestion = () => {
    const questionLength = pollFullDetails?.questions?.length - 1;
    if (count < questionLength) {
      const newCount = count + 1;
      setCount(newCount);
      const poll = pollId ? pollDetailService(pollId) : pollDetails;
      console.log(newCount, "count");
      console.log(poll?.questions?.[newCount]?.duration, "poll");
      console.log(getPollStatus());
      setTimer(poll?.questions?.[newCount]?.duration);
    } else {
      handlePollCompletion();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitWordCloudAnswer();
    }
  };

  const submitWordCloudAnswer = () => {
    if (!answer) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Enter a response",
      });
    }
    GenericInvoker(
      connection,
      getPresentationId(),
      getConnectionId(),
      "ViewerPollAnswer",
      {
        pollId,
        answer,
        questionId: details?.id,
        questionType: details?.questionType,
      }
    );

    const answerObj = {
      question,
      questionId: details?.id,
      optionId: [""],
      option: [answer],
    };

    addAnswer(pollId, answerObj);

    const questionLength = pollDetailService(pollId)?.questions?.length - 1;

    if (count < questionLength) {
      setCount(count + 1);
    } else {
      setPollStarted(false);
      setIsSubmitLoading(true);
      dispatch(takePoll(getPollAnswer())).then(({ type, payload }) => {
        setIsSubmitLoading(false);
        if (type?.includes("fulfilled")) {
          handlePollCompletion();
        }
      });
    }
  };

  const sumbitOtherAnswer = () => {
    if (!selectedOptions.texts.length) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select an option",
      });
      return;
    }
    const answerObj = {
      question,
      questionId: details?.id,
      optionId: selectedOptions.ids,
      option: selectedOptions.texts,
    };
    addAnswer(pollId, answerObj);

    GenericInvoker(
      connection,
      getPresentationId(),
      getConnectionId(),
      "ViewerPollAnswer",
      {
        pollId,
        answer: selectedOptions,
        questionId: details?.id,
        questionType: details?.questionType,
      }
    );

    const questionLength = pollDetailService(pollId)?.questions?.length - 1;

    if (count < questionLength) {
      setCount(count + 1);
    } else {
      setPollStarted(false);
      setIsSubmitLoading(true);
      dispatch(takePoll(getPollAnswer())).then(({ type, payload }) => {
        setIsSubmitLoading(false);
        if (type?.includes("fulfilled")) {
          handlePollCompletion();
        }
      });
    }
  };

  const handleOptionChange = (id, text) => {
    setSelectedOptions((prev) => {
      const { ids, texts } = prev;
      const isSelected = ids.includes(id);
      return {
        ids: isSelected ? ids.filter((i) => i !== id) : [...ids, id],
        texts: isSelected ? texts.filter((t) => t !== text) : [...texts, text],
      };
    });
  };

  const handlePollCompletion = () => {
    endPollService();
    setPollComplete(true);
  };

  const deleteQT = () => {
    const pollId = id;
    setIsLoading(true);
    dispatch(deletePollQuestionType({ presentationId, pollId })).then(
      ({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          toast.current.show({
            severity: "success",
            summary: "Success!",
            detail: "Poll question type deleted successfully",
          });
          window.location.reload();
        }
      }
    );
  };

  ///////////

  return (
    <section className=" md:w-[40rem] rounded-2xl  min-h-[65vh] mx-auto text-sm">
      {/* poll template */}
      {pollComplete && (
        <section className="flex items-center justify-center w-full  h-[65vh]">
          <p className="text-2xl font-semibold text-center">Poll Ended</p>
        </section>
      )}

      {!pollComplete && (
        <section className="w-full px-4 py-4 bg-white rounded-2xl ">
          {/* header */}
          <section className="flex items-center justify-between ">
            <div className="flex items-center font-bold gap-x-2">
              <img src={pollHeader} alt="" />
              <h3>{questionType} </h3>
            </div>
          </section>
          {/*  */}

          {/* body */}

          <section
            className={`flex flex-col  py-4 my-4 rounded-lg gap-y-4 bg-gray550 min-h-[350px] ${
              isWordCloud || isTrueFalse
                ? "items-center justify-center"
                : "text-left pl-6 "
            }`}
          >
            {questionImageUrl && isTrueFalse && (
              <img src={paris} alt="" className="w-[9.8719rem] h-[6.6094rem]" />
            )}
            <h3 className="text-lg font-semibold text-black40 ">{question}</h3>

            {isWordCloud && (
              <section>
                <div>
                  <input
                    type="text"
                    placeholder="Enter answer"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setAnswer(e.target.value)}
                    className="w-full py-2 text-lg text-center bg-transparent border-b-2 border-black outline-none placeholder:text-center"
                  />
                </div>

                <button
                  onClick={submitWordCloudAnswer}
                  className="w-[80%] mx-auto flex justify-center py-3 my-4 font-semibold text-white bg-black rounded-lg"
                >
                  {isSubmitLoading ? (
                    <ClipLoader size={12} color="white" />
                  ) : (
                    "Sumbit"
                  )}
                </button>
              </section>
            )}

            {!isWordCloud && (
              <>
                <section
                  className={`flex gap-x-4  ${
                    !isTrueFalse && "flex-col w-6/12 gap-y-4"
                  }  `}
                >
                  {options?.map((option) => {
                    return (
                      <OptionList
                        key={option?.id}
                        option={option}
                        questionType={questionType}
                        onOptionChange={handleOptionChange}
                      />
                    );
                  })}
                </section>
                <button
                  onClick={sumbitOtherAnswer}
                  className="w-1/2 py-3 my-2 font-semibold text-white bg-black rounded-lg "
                >
                  {isSubmitLoading ? (
                    <ClipLoader size={12} color="white" />
                  ) : (
                    "Sumbit"
                  )}
                </button>
              </>
            )}

            <section className="flex my-4 font-bold gap-x-1 text-gray40">
              <img src={timerImg} alt="" />
              <p className="mt-[2px]">{timer ?? duration} Secs</p>
            </section>
          </section>

          {/*  */}
        </section>
      )}

      {/*  */}
    </section>
  );
}
