import React, { useState } from "react";

//assets
import edit from "assets/svg/edit.svg";
import paris from "assets/svg/paris.svg";
import duplicate from "assets/svg/copy.svg";
import timer from "assets/svg/timer2.svg";
import deletee from "assets/svg/whiteDelete.svg";
import pollHeader from "assets/svg/pollHeader.svg";

// import { editQuestionType } from "utils/pollStateManagement";
import OptionList from "../../PollCreation/PollTemplates/OptionList";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { deletePollQuestionType } from "store/Poll/pollSlice";

export default function QuestionTypePreview({ details }) {
  const dispatch = useDispatch();

  const { presentationId } = useParams();
  const { id, question, options, questionType, questionImageUrl, duration } =
    details ?? {};

  const type = questionType?.toLowerCase();
  const isWordCloud = type === "word cloud";
  const isTrueFalse = type === "true or false";
  //usestate
  const [isLoading, setIsLoading] = useState(false);

  const deleteQT = () => {
    const pollId = id;
    setIsLoading(true);
    dispatch(deletePollQuestionType({ presentationId, pollId })).then(
      ({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          toast.success("Poll question type deleted successfully");
          window.location.reload();
        }
      }
    );
  };

  return (
    <section className="lg:w-[65rem] md:w-[40rem] mx-auto lg:ml-20">
      {/* poll template */}
      <section className="w-full px-4 py-4 bg-white rounded-2xl ">
        {/* header */}
        <section className="flex items-center justify-between ">
          <div className="flex items-center font-bold gap-x-2">
            <img src={pollHeader} alt="" />
            <h3>{questionType} </h3>
          </div>

          <div className="flex items-center text-xs gap-x-4">
            {/* <div
              onClick={() => duplicateQuestionType(id)}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              <img src={duplicate} alt="duplicate" />
              <p>Duplicate</p>
            </div> */}

            {/* <div
              onClick={() => editQuestionType(id)}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              <img src={edit} alt="edit" />
              <p>Edit</p>
            </div> */}

            <div
              onClick={deleteQT}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              {isLoading ? (
                <ClipLoader size={10} color="gray" />
              ) : (
                <img src={deletee} alt="delete" />
              )}

              <p>Delete</p>
            </div>
          </div>
        </section>
        {/*  */}

        {/* body */}

        <section
          className={`flex flex-col   py-4 my-4 rounded-lg gap-y-4 bg-gray550  ${
            isWordCloud || isTrueFalse
              ? "items-center justify-center"
              : "text-left pl-6 "
          }`}
        >
          {questionImageUrl && isTrueFalse && (
            <img src={paris} alt="" className="w-[9.8719rem] h-[6.6094rem]" />
          )}
          <h3 className="text-lg font-semibold text-black40 ">{question}</h3>
          {!isWordCloud && (
            <section
              className={`flex gap-x-4  ${
                !isTrueFalse && "flex-col w-6/12 gap-y-4"
              }  `}
            >
              {options?.map((option) => {
                return <OptionList key={option?.id} option={option} />;
              })}
            </section>
          )}

          <section className="flex my-10 mt-2 font-semibold gap-x-1 text-gray40">
            <img src={timer} alt="" />
            <p className="mt-[2px]">{duration} Secs</p>
          </section>
        </section>

        {/*  */}
      </section>
      {/*  */}
    </section>
  );
}
