import store from "store/store";

//assets
import fav from "assets/svg/fav.svg";
import voice from "assets/svg/voice.svg";
import slide from "assets/svg/bigSlide2.svg";
import cardSVG from "assets/svg/cardSVG.svg";
import slide3 from "assets/svg/bigSlide3.svg";
import slide2 from "assets/svg/bigSlide2.svg";
import document from "assets/svg/document.svg";
import orangeDoc from "assets/svg/orangeDoc.svg";
import scheduled from "assets/svg/scheduled.svg";

//states
const presentations = store.getState()?.presentations?.presentations;
const workspaceFolders = store.getState()?.workspace?.workspaceFolders?.data;

export const style = {
  background: `url('${cardSVG}') no-repeat`,
};

export const testData = [
  {
    img: slide,
    title: "Presenta template",
    date: "20 January 2023",
  },

  {
    img: slide2,
    title: "Synout canva",
    date: "20 January 2023",
  },
  {
    img: slide3,
    title: "Synout canva",
    date: "20 January 2023",
  },
];

export const cards = [
  {
    title: "Upload your first file",
    desc: "Create a folder to add your first file",
    icon: document,
    buttonTitle: "Upload folder",
    buttonStatus: workspaceFolders?.length > 0,
    link: "/admin/workspace",
  },

  {
    title: "Schedule your first presentation",
    desc: "Import at least a slide to get started",
    icon: voice,
    buttonTitle: "Set up a presentation",
    buttonStatus: presentations?.length > 0,
    link: "/admin/presentations",
  },

  {
    title: "Complete your profile",
    desc: "Import at least a slide to get started",
    icon: fav,
    buttonTitle: "Update your profile",
    buttonStatus: presentations?.length > 0,
    link: "/admin/workspace",
  },
];

export const card2 = [
  {
    title: "Scheduled presentations",
    desc: "Import at least a slide to get started",
    icon: scheduled,
  },

  {
    title: "My Drafts",
    desc: "Import at least a slide to get started",
    icon: orangeDoc,
  },

  {
    title: "Past presentations",
    desc: "Import at least a slide to get started",
    icon: scheduled,
  },
];
