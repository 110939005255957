import React, { useEffect, useState } from "react";

//assets
import trophy from "assets/svg/trophy.svg";
// import detail from "assets/svg/details3.svg";
import timer from "assets/svg/green-timer.svg";
// import profile from "assets/svg/profile-2user.svg";

//libraries
// import { Chart } from "primereact/chart";
import { useDispatch, useSelector } from "react-redux";
import {
  getPollMetrics,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { useParams } from "react-router-dom";
import Loader from "components/UI/Loader";
// import WordCloudTemplate from "./PollMetricsTemplate/WordCloudTemplate";
import WordCloudMetricsTemplate from "./PollMetricsTemplate/WordCloudTemplate";
import OptionMetricsTemplate from "./PollMetricsTemplate/OptionMetricsTemplate";
import { isWordCloudCheck } from "utils/activePresentationUtils";
import { formatNumber } from "utils/formatter";

export default function PollTab() {
  const dispatch = useDispatch();
  const { presentationId } = useParams();

  const { pollMetrics } = useSelector(getPresentationState);

  //useeffects
  useEffect(() => {
    dispatch(getPollMetrics(presentationId));
  }, [presentationId, dispatch]);

  const convertTime = (time) => {
    let finalTime;
    let duration;
    if (time >= 3600) {
      finalTime = (time / 3600)?.toFixed(1);
      duration = "hrs";
    } else if (time >= 60) {
      finalTime = (time / 60)?.toFixed(1);
      duration = "mins";
    } else {
      finalTime = time;
      duration = "secs";
    }

    return `${formatNumber(finalTime)} ${duration}`;
  };

  return (
    <section className="h-[72vh] overflow-y-scroll flex flex-col gap-y-5 pb-8">
      {pollMetrics?.isLoading && <Loader />}

      {!pollMetrics?.isLoading && (
        <>
          {pollMetrics.data?.length < 1 && (
            <section className="flex items-center justify-center w-11/12 h-full mx-auto text-4xl font-bold text-gray600 ">
              <h3>Presentation has no Poll</h3>
            </section>
          )}
          {pollMetrics.data?.length > 0 && (
            <>
              {pollMetrics.data?.map((poll) => {
                const { participation, averageResponseTime, questions, title } =
                  poll ?? {};
                return (
                  <section key={poll?.id}>
                    <section className="px-6 bg-gray20 rounded-xl">
                      <section className="flex items-center justify-between py-2 border-b">
                        <section className="font-semibold text-black40">
                          <p>{title}</p>
                        </section>

                        <section className="flex gap-x-6">
                          <div className="flex items-center gap-x-2">
                            <img src={trophy} alt="" className="w-[3.75rem]" />

                            <div>
                              <p className="text-sm font-semibold text-gray500">
                                Participation
                              </p>
                              <h5 className="mt-1 font-bold text-black text-md">
                                {participation}
                                {/* 100% Responded */}
                              </h5>
                            </div>
                          </div>

                          <div className="flex items-center gap-x-2">
                            <img src={timer} alt="" className="w-[3.75rem]" />

                            <div>
                              <p className="text-sm font-semibold text-gray500">
                                Average response time
                              </p>
                              <h5 className="mt-1 font-bold text-black text-md">
                                {convertTime(averageResponseTime)} 
                              </h5>
                            </div>
                          </div>
                        </section>
                      </section>

                      {/* question type template  */}
                      <section>
                        {questions?.map((question) => {
                          return (
                            <section key={question?.id} className="border-b">
                              {isWordCloudCheck(question?.questionType) ? (
                                <WordCloudMetricsTemplate details={question} />
                              ) : (
                                <OptionMetricsTemplate details={question} />
                              )}
                            </section>
                          );
                        })}
                      </section>
                      {/*  */}
                    </section>
                  </section>
                );
              })}
            </>
          )}
        </>
      )}
    </section>
  );
}
