import React, { useEffect } from "react";

//libraries
import { v4 as uuidv4 } from "uuid";

//assets
import addMore from "assets/svg/addMore.svg";

//components
import PollOptionField from "./PollOptionField";

export default function PollOptionList({ setOptions, options, questionType }) {

  //useeffects
  useEffect(() => {
    const handleEditEvent = (event) => {
      const { options } = event?.detail ?? {};

      if (options) {
        const reformatOptions = options?.map((option) => {
          const { id, text, imageUrl, color } = option ?? {};
          return { id, name: text, color, img: imageUrl };
        });
        setOptions(reformatOptions);
      }
    };

    window.addEventListener("edit", handleEditEvent);

    return () => {
      window.removeEventListener("edit", handleEditEvent);
    };
  }, [setOptions]);


  //functions
  const updateTitle = (id, newName) => {
    const updatedTags = options?.map((tag, index) => {
      if (tag.id === id) {
        return { ...tag, name: newName };
      }
      return tag;
    });
    setOptions(updatedTags);
  };

  const deleteOption = (id) => {
    const filteredResult = options.filter((option) => option.id !== id);
    setOptions(filteredResult);

    setOptions(filteredResult);
  };

  const addOption = () => {
    setOptions([...options, { id: uuidv4(), name: "", value: null }]);
  };

  return (
    <section className="flex flex-col gap-y-2">
      {options?.map((option, index) => {
        return (
          <PollOptionField
            key={option.id}
            data={option}
            updateTitle={updateTitle}
            deleteOption={deleteOption}
          />
        );
      })}

      {(questionType?.toLowerCase() !== "true or false" ||
        options?.length < 2) && (
        <div
          onClick={addOption}
          className="flex w-24 mt-1 font-semibold cursor-pointer text-primary gap-x-1"
        >
          <img src={addMore} alt="add more" />
          <p>Add more</p>
        </div>
      )}
    </section>
  );
}
