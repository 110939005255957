interface BackgroudColor {
  backgroudColor: string;
}

const getColorfulBgClass = (index: number): BackgroudColor => {
  const bgList = [
    { backgroudColor: '#EF75BE' },
    { backgroudColor: '#E375EF' },
    { backgroudColor: '#C575EF' },
  ];

  if (index < bgList.length) {
    return bgList[index];
  } else {
    return bgList[(index % index) - 1];
  }
};

export default getColorfulBgClass;
