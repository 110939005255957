import React, { useEffect, useState } from 'react';
//assets
import clock from 'assets/svg/clock-icon.svg';
import ChevronDownBlack from 'assets/svg/chevron-down-black.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import exportIconBlack from 'assets/svg/export-icon-black.svg';
import pptImg from 'assets/image/ppt.png';
import pdfImg from 'assets/image/pdf-main.png';

// Libraries and utils
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import moment from 'moment';
import { getInitials } from 'utils/formatter';
import getColorfulBgClass from 'utils/randomBgColour';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import { imageStream } from 'utils/imageStream';

// Components
import FileVisits from './Tables/FileVisits';
import FileAnalytics from './Tables/FileAnalytics';
import FileUsage from './Tables/FileUsage';
import {
  Document,
  DocumentAnalytics,
  DocumentUsage,
  DocumentVisits,
  Member,
  DocumentPerformance,
} from 'types/Admin/Workspace/WorkspaceTypes';
import AvaterImage from 'components/UI/Avatar/AvaterImage';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

interface Props {
  document: Document;
  //   filePreview: string;
  documentVisits: DocumentVisits[];
  members?: Member[];
  documentUsage: DocumentUsage[];
  documentAnalytics: DocumentAnalytics;
  documentPerformance: DocumentPerformance[];
}

export default function DocumentDetails({
  document,
  //   filePreview,
  members,
  documentVisits,
  documentUsage,
  documentAnalytics,
  documentPerformance,
}: Props) {
  // States
  const [tab, setTab] = useState(0);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  // For getting document stream
  useEffect(() => {
    const getStream = async (id: string) => {
      const result = await imageStream(id, true, 115, 112);
      if (document.url === '.ppt' || document.url === '.pptx') {
        setFilePreview(pptImg);
      } else if (document.url === '.pdf') {
        setFilePreview(pdfImg);
      } else {
        setFilePreview(result ?? null);
      }
      //   setFilePreview(result ?? null);
    };

    document && getStream(document.id);

    return () => {
      setFilePreview(null);
    };
  }, []);

  return (
    <section>
      {/* File Details Banner */}
      {document && (
        <>
          <section className='mt-20'>
            <div className='bg-[#F6F3EF] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
              <div className='w-full flex items-center gap-x-4'>
                <div className='w-[115px] h-[112px] rounded-md'>
                  {/* should be a component */}
                  {filePreview && (
                    <img
                      src={filePreview}
                      alt='file'
                      className='w-[115px] h-[112px] rounded-md object-cover object-center'
                    />
                  )}
                </div>
                <div>
                  <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                    {document.title}
                  </h1>
                  <section className='flex gap-x-5 items-center text-sm'>
                    <p>
                      {/* Description goes here if theres any at all Description
                    goes here if theres any at all */}
                    </p>
                    <div className='flex items-center gap-x-2'>
                      <img src={clock} alt='clock' />
                      {/* <p>Last updated 5 min ago</p> */}
                      {documentVisits && (
                        <p>
                          {moment.utc(documentVisits.at(0)?.date).fromNow()}
                        </p>
                      )}
                    </div>
                  </section>
                  <div className='mt-3'>
                    <AvatarGroup>
                      {members &&
                        members?.map((item, index) => (
                          <AvaterImage member={item} index={index} />
                        ))}
                    </AvatarGroup>
                  </div>
                </div>
              </div>
              <img
                src={lines}
                alt='briefcase'
                className=' absolute right-0 top-0'
              />
            </div>
          </section>

          {/* tabs */}
          <section className='mt-5 mb-20'>
            <section className='flex gap-x-4 items-center justify-between'>
              <section className='flex gap-x-2 border-b'>
                <button
                  onClick={() => setTab(0)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 0 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Visits
                </button>

                <button
                  onClick={() => setTab(1)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 1 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Analytics
                </button>

                <button
                  onClick={() => setTab(2)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 2 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Usage
                </button>
              </section>
              {/* Export */}
              <section className='flex items-center gap-x-2'>
                <div className='bg-[#F6F6F6] text-[#333333] text-sm h-8 rounded-md flex gap-x-1 items-center cursor-pointer border border-[#E2E2E2] px-3'>
                  <img src={exportIconBlack} alt='export icon' />
                  <p>Export</p>
                  <img src={ChevronDownBlack} alt='more' />
                </div>
              </section>
            </section>

            <section className='mt-1 mb-8 pb-20'>
              {tab === 0 && <FileVisits documentVisits={documentVisits} />}
              {tab === 1 && (
                <FileAnalytics
                  documentAnalytics={documentAnalytics}
                  documentPerformance={documentPerformance}
                />
              )}
              {tab === 2 && <FileUsage documentUsage={documentUsage} />}
            </section>
          </section>
        </>
      )}
    </section>
  );
}
