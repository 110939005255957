import { useState } from "react";

export default function UsePasswordToggle() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return {
    showPassword,
    togglePassword,
  };
}
