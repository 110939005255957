import React from "react";

//assets
// import logo from "assets/svg/logo2.svg";
import ended from "assets/svg/ended.svg";
import bg from "assets/svg/joinNowBg.svg";

export default function RemovedUser() {
  const style = {
    background: `url(${bg}) no-repeat `,
    backgroundPosition: "40% 90%",
    backgroundSize: "cover",
  };

  const glassStyle = {
    // backgroundColor:
    //   "linear-gradient( 120deg, rgba(255, 255, 255, .60),  rgba(255, 255, 255, .40), 70%, rgba(255, 255, 255, .30))",
    border: " 1px solid rgba(255, 255, 255, .25)",
    // boxShadow: " 0 0 10px 1px rgba(0, 0, 0, .25)",
    backdropFilter: "blur(80px) saturate(80%)",
  };
  return (
    <section
      className="h-screen w-full [ flex justify-center items-center ]"
      style={style}
    >
      <section
        style={glassStyle}
        className=" rounded-lg
        [ lg:w-[80%] w-[95%]  ]  
          [ shadow-black/70 shadow-2xl ] [ mx-auto lg:p-10 px-2 py-4  ] [ flex justify-between items-center ] "
      >
        {/* {!connection && <JoinPresentationLoader />} */}

        <section className=" [ flex justify-between items-center  flex-col-reverse gap-y-4 ] w-full">
          <section className=" w-[40%] flex items-center justify-center">
            <img
              src={ended}
              alt="waiting to be let in"
              className="w-20 lg:w-40"
            />
          </section>

          <section className="flex flex-col items-center justify-center text-white gap-y-4">
            <div>
              <h3 className="text-2xl font-semibold text-center text-white lg:text-3xl">
                You've been removed by the Presenter
              </h3>
              <p className="mt-3 font-semibold text-center">
                Thanks for participating
              </p>
            </div>

            {/* <img src={people} alt="" className="hidden lg:block" /> */}
          </section>
        </section>
      </section>
    </section>
  );
}
