import {
  _getSlideStream,
  _getSlideStreamThumbNail,
} from "services/Slides/slidesServices";

export const imageStream = async (
  id,
  isThumbNail = false,
  width = 100,
  height = 100
) => {
  try {
    const response = isThumbNail
      ? await _getSlideStreamThumbNail(id, width, height)
      : await _getSlideStream(id);

    if (response && response.data) {
      // const blob = isAudio
      //   ? new Blob([response.data], { type: "audio/*" })
      //   : response.data;

      //const blob = new Blob([response.data], { type: "audio/*" });
      const url = URL.createObjectURL(response.data);
      return url;
    } else {
      console.error("Response does not contain data");
    }
  } catch (error) {
    console.error("Failed to fetch image:", error);
    // if (
    //   currentUrl?.includes("viewer-mode/") ||
    //   currentUrl.includes("/active-presentation")
    // ) {
    //   throw error;
    // }
  }
};
