import React, { ReactNode } from 'react';
interface PreviewDocument {
  sessionId: string;
  sessionType: number;
  documentId: string;
  title: string | null;
  note: string | null;
  position: number;
  url: string;
  type: string | null;
  deleted: boolean;
  id: string;
  folderId: string | null;
}

export default function SidebarWrapper({
  children,
  isOpen,
  flattedDisplayDocuments,
}: {
  children: ReactNode;
  isOpen: boolean;
  flattedDisplayDocuments: PreviewDocument[];
}) {
  return (
    <section
      className={`transition-all duration-500  ease-in-out fixed lg:translate-x-0 ${
        flattedDisplayDocuments.length === 1 ? 'hidden' : 'w-[18%]'
      } z-50 ${isOpen ? ' translate-x-0' : ''} `}
    >
      {children}
    </section>
  );
}
