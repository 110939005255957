import React from "react";

const CTab = ({
  active,
  setActive,
  tabList,
}: {
  active: number;
  setActive: (val: number) => void;
  tabList: string[];
}) => {
  return (
    <div className="flex items-center">
      {tabList.map((item, index) => {
        return (
          <p
            key={item}
            onClick={() => setActive(index)}
            className={`${
              index === active
                ? " text-[#FF6929]  border-[#FF6929] "
                : "border-[#F3F0EB]"
            } py-2 px-4 border-b-2 font-semibold cursor-pointer text-sm  `}
          >
            {item}
          </p>
        );
      })}
    </div>
  );
};

export default CTab;
