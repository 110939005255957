import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

// assets
import edit from "assets/svg/edit.svg";
import pdfImg from "assets/image/pdf.png";
import pptImg from "assets/image/ppt.png";
import excelImg from "assets/image/excel.png";
import upload from "assets/svg/uploadDark.svg";
import uploadWhite from "assets/svg/upload.svg";

// slices
import { uploadPPT } from "store/Slides/slidesSlice";
import { getFolderFiles } from "store/Workspace/workspaceSlice";

// libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { ProgressBar } from "primereact/progressbar";

// components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import {
  addDocumentToPresentation,
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { getFileTypeImg } from "utils/getFileType";

const UploadFromComputer = (props, ref) => {
  const { closeModal, presentationId } = props ?? {};
  const dispatch = useDispatch();

  // refs
  const uploadFileRef = useRef();

  // selectors
  const { presentationDetails } = useSelector(getPresentationState);

  // state
  const [pptFile, setPptFile] = useState(null);
  const [finalImg, setFinalImg] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  useImperativeHandle(ref, () => ({
    resetModal() {
      setFinalImg(null);
      setFolderName("");
      setPptFile(null);
      setIsButtonActive(false);
      setIsLoading(false);
    },
  }));

  useEffect(() => {
    if (isEditing) {
      const input = document.getElementById("folderNameInput");
      input && input.focus();
    }
  }, [isEditing]);

  const editDocument = (e) => {
    if (e.key?.toLowerCase() === "enter") {
      setFolderName(e.target.value);
      setIsEditing(false);
    }
  };

  const openUploadModal = () => {
    uploadFileRef.current.click();
  };

  const storeUploadedFile = (e) => {
    const file = e.target.files[0];
    const originalFileName = file?.name;

    if (!file) {
      return;
    }

    const nameSplit = originalFileName?.split(".");
    nameSplit.pop();
    const final = nameSplit.join(".");

    // update state
    setFinalImg(getFileTypeImg(file.name.split(".").pop()));
    setFolderName(final);
    setPptFile(file);
    setIsButtonActive(true);
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const drop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    storeUploadedFile({ target: { files: [file] } });
  };

  const submitUpload = () => {
    const title = folderName;
    if (pptFile) {
      setIsLoading(true);
      setIsButtonActive(false);

      // monitor upload progress
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );

          setUploadProgress(progress);
          if (progress === 100) {
            setUploadComplete(true);
          } else {
            setUploadComplete(false);
          }
        },
      };

      const data = {
        documentTitle: title,
        presentationId,
        file: pptFile,
        position: presentationDetails.data?.doc?.length,
      };

      // dispatch function
      dispatch(addDocumentToPresentation({ data, config })).then(({ meta }) => {
        const { requestStatus = "" } = meta ?? {};
        setIsLoading(false);
        setIsButtonActive(true);
        if (requestStatus?.toLowerCase() === "fulfilled") {
          toast.success("File added successfully");
          dispatch(getPresentationDetails(presentationId));
          setPptFile(null);
          closeModal();
        }
      });
    }
  };

  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">Upload document</h1>
        </div>

        <div
          draggable
          onDrop={drop}
          onDragOver={dragOver}
          className="my-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
          onClick={openUploadModal}
        >
          {!pptFile && (
            <div className="flex flex-col items-center py-4 text-center">
              <p>
                {" "}
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p>Powerpoint, JPG or PDF </p>
            </div>
          )}

          {pptFile && (
            <div className="py-4">
              <img src={finalImg} alt="ppt file" className="w-10 mx-auto" />

              <section
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center mt-2 text-left gap-x-4"
              >
                <section>
                  <p
                    className={`font-medium text-gray700 ${
                      !isEditing ? "block" : "hidden"
                    }`}
                  >
                    {folderName}
                  </p>

                  <input
                    type="text"
                    name="text"
                    id="folderNameInput"
                    defaultValue={folderName}
                    onKeyDown={editDocument}
                    className={`w-full text-sm border-none outline-none ${
                      isEditing ? "block" : "hidden"
                    }`}
                  />
                </section>

                <img
                  src={edit}
                  alt="edit"
                  className="cursor-pointer"
                  onClick={() => setIsEditing(true)}
                />
              </section>
            </div>
          )}

          <input
            hidden
            type="file"
            ref={uploadFileRef}
            onChange={storeUploadedFile}
          />
        </div>

        {(uploadProgress < 1 || uploadComplete) && (
          <button
            disabled={!isButtonActive}
            onClick={submitUpload}
            className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
              isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
            }`}
          >
            {isLoading && !uploadComplete && (
              <ClipLoader size={15} color="white" />
            )}

            {isLoading && uploadProgress === 100 && (
              <div className="flex items-center gap-x-1">
                <p>Processing</p>
                <ClipLoader size={15} color="white" />
              </div>
            )}
            {!isLoading && (
              <>
                <img src={uploadWhite} alt="upload slide" className="w-4" />
                <p>Upload file</p>
              </>
            )}
          </button>
        )}

        {uploadProgress > 0 && !uploadComplete && (
          <ProgressBar
            color="#FF692990"
            label="Uploading"
            value={uploadProgress}
            className="h-8 rounded-lg"
          ></ProgressBar>
        )}
      </section>
    </section>
  );
};

export default forwardRef(UploadFromComputer);
