import {CopyToClipBoard} from "../../wrapper/CopyToClipBoard";
import {CopyWrapper} from "../../wrapper/copy-wrapper";
import {CopyActionWrapper} from "../../wrapper/copy-action-wrapper";
import {Switch} from "antd";
import messageDashes from "../../../../assets/svg/messageDashed.svg";
import {TagWrapper} from "../../wrapper/tag-wrapper";
import React, {useEffect, useState} from "react";
import instance from "../../../../lib/axiosInterceptor";
import emailValidation from "../../../../utils/emailValidation";
import {toast} from "sonner"
import noticeBlack from "assets/svg/noticeBlack.svg"
import {EditableObjectInterface, InnerPresentationInterface} from "../../../../interfaces/interfaces-data";

export const DesktopShare = ({friendlyId, presentationId, presentationDetails}: {
    friendlyId?: string,
    presentationId?: string,
    presentationDetails?: InnerPresentationInterface
}) => {
    const [users, setUsers] = useState<string[]>([]);
    console.log(presentationId, "Presentation Id");
    const [activeEmail, setActiveEmail] = useState("")
    const [loading, setLoading] = useState(true)
    const [editablePresentationObject, setEditablePresentationObject] = useState<EditableObjectInterface>()
    const getGuests = async () => {
        setLoading(true)
        try {
            const result = await instance.get(`/Presentation/Guests/${presentationId ?? ""}`)
            console.log(result, "Result value hereee")
            setUsers(result.data)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    const addGuests = async () => {
        try {
            const result = await instance.post(`/Presentation/AddGuests/${presentationId}`, [activeEmail]);
            setActiveEmail("")
            getGuests();
        } catch (e) {

        }
    }

    useEffect(() => {
        if (presentationDetails) {
            const {
                expiry,
                canDownload,
                authMode,
                title,
                passcode,
                watermark,
                questionsAllowed,
                allowedDomains,
                waitingRoom
            } = presentationDetails?.presentation
            setEditablePresentationObject({
                expiry,
                canDownload,
                authMode,
                enableWaitingRoom: waitingRoom,
                title,
                passcode,
                watermark,
                allowQuestion: questionsAllowed,
                domains: allowedDomains
            })
        }
        getGuests();
    }, [])

    const editObject = async (editableObject: EditableObjectInterface) => {
        try {
            const result = await instance.post(`/Presentation/Update/${presentationId}`, editableObject);
            toast.success("Setting Configured")
        } catch (e) {

        }
    }


    return <>
        <div>
            <CopyToClipBoard text={`https://demo.thepresenta.com/join-presentation/${friendlyId ?? ""}`}>
                <CopyWrapper text={`https://demo.thepresenta.com/join-presentation/${friendlyId ?? ""}`}/>
            </CopyToClipBoard>

            <CopyActionWrapper text={`https://demo.thepresenta.com/join-presentation/${friendlyId ?? ""}`}/>
            < div className="border p-4 border-[#EEEEEE]">
                <div className="flex items-center justify-between ">
                    <p className="text-[#3D4045] text-sm font-grotesk font-semibold">
                        Share link with anyone{" "}
                    </p>
                    <span>
                        <Switch/>
                    </span>
                </div>
                <div className="my-3 flex items-center gap-2 w-full">
                    <div className="">
                        <span className="relative ">
                            <img alt={""} src={messageDashes} className="absolute top-0 left-3"/>
                            <input
                                placeholder="Add email"
                                className="bg-[#F5F5F5] rounded-lg py-2 px-10 w-full"
                                onChange={(e) => setActiveEmail(e.target.value)}
                                value={activeEmail}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        if (emailValidation.checkEmail(activeEmail)) {
                                            addGuests()
                                        } else {
                                            toast.error("Invalid email")
                                        }
                                    }
                                }}
                            />
                        </span>
                    </div>

                    {/*<CButton*/}
                    {/*    icon={groupIcon}*/}
                    {/*    iconPosition="left"*/}
                    {/*    variant="grey"*/}
                    {/*    text="Upload Bulk"*/}
                    {/*/>*/}
                </div>
                <div className="flex flex-wrap gap-2">
                    {users.length ? users.map((item, index) => {
                        return (
                            <TagWrapper
                                click={() => {
                                    const user_ = [...users];
                                    user_.splice(index, 1);
                                    setUsers([...user_]);
                                }}
                                text={item}
                                key={item}
                            />
                        );
                    }) : <div className={"flex justify-center text-xs my-4 w-full"}>No guests added</div>}
                </div>

                <ActionWrapper
                    handleSwitchValue={(checked: boolean) => {
                        if (editablePresentationObject) {
                            setEditablePresentationObject({
                                ...editablePresentationObject,
                                enableWaitingRoom: checked
                            })
                            editObject({
                                ...editablePresentationObject,
                                enableWaitingRoom: checked
                            })
                        }
                    }}
                    switchValue={editablePresentationObject?.enableWaitingRoom ?? false}
                    text={"Enable Waiting Room"}/>
                <ActionWrapper  handleSwitchValue={(checked: boolean) => {
                    if (editablePresentationObject) {

                        setEditablePresentationObject({
                            ...editablePresentationObject,
                            authMode: checked ? 1 : 0
                        })
                        editObject({
                            ...editablePresentationObject,
                            authMode: checked ? 1 : 0
                        })
                    }
                }}
                               switchValue={editablePresentationObject?.authMode !== 1 }
                               text={"Confirm Email Address"}

                />
            </div>
        </div>
    </>
}


const ActionWrapper = ({text, switchValue, handleSwitchValue}: {
    text: string,
    switchValue: boolean,
    handleSwitchValue: (val: boolean) => void
}) => {
    console.log(switchValue, text)

    return <>
        <div className="border my-2 border-[#EEEEEE] flex items-center justify-between p-4 rounded-lg">
            <div className={"flex items-center gap-2"}>
                <p>
                    {text}
                </p>
                <img src={noticeBlack} alt=""/>
            </div>
            <Switch onChange={(checked, event) => handleSwitchValue(checked)} value={switchValue}/>
        </div>
    </>
}