import React, { useState } from "react";

//assets
import open from "assets/svg/openTab.svg";
import close from "assets/svg/closeTab.svg";

export default function QuestionTab({ detail }) {
  const { question, answer } = detail;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="py-4 border-b cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
      <div className="[ flex items-center justify-between ] [ text-[#101828] font-semibold ] ">
        <p className="[ text-sm lg:text-lg ]">{question}</p>

        {isOpen ? (
          <img src={close} alt="close tab" />
        ) : (
          <img src={open} alt="open tab" />
        )}
      </div>

      {isOpen && <div className="mt-2 text-[#475467] [ text-xs lg:text-sm ] ">{answer}</div>}
    </section>
  );
}
