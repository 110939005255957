import React from "react";

//assets
import image1 from "assets/image/loginImg3.png";

export default function ThirdSlide() {
  return (
    <div className="relative w-full bg-red-900 ">
      <div className="relative w-full bg-blue-900">
        <img src={image1} alt="login page" className="absolute " />
      </div>
    </div>
  );
}
