import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import React, { useState } from "react";
import blacky from "assets/svg/blacky.svg";
import { Chart } from "primereact/chart";
import metricsPlaceholder from "assets/svg/metricsPlaceHolder.svg";

export const SpaceMetricsCard = ({
  val_2,
  val_1,
}: {
  val_1: number;
  val_2: number;
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const style = {
    backgroundImage: `url(${blacky})`,
  };
  return (
    <section
      style={style}
      className="flex py-8 px-6 rounded-lg items-center gap-3 bg-black"
    >
      <div>
        <img src={metricsPlaceholder} alt="" />
      </div>
      <div>
        <p className="text-lg font-semibold  text-white">Attention metrics</p>
        <p className="text-[#AFAFAF] text-sm">
          Out of {val_1} attendees, {val_2} actively participated in the
          interactive poll conducted
        </p>
      </div>
    </section>
  );
};
