import { toast } from "sonner";
import {
  getConnectionId,
  localStorageGetter,
} from "utils/localStorageServices";

export const RequestPresentationAccess = (
  connection,
  presentationId,
  email,
  name
) => {
  const gottenOTP = localStorageGetter("gottenOTP");
  if (connection) {
    if (gottenOTP) {
      return;
    }

    const connectionId = getConnectionId();
    connection
      .invoke("RequestAccess", presentationId, 1, email, name, connectionId)
      .then(() => {
        console.log("in");
      })
      .catch((err) => {
        toast.error(
          "An unexpected error occurred invoking 'RequestAccess' on the server"
        );
      });

    console.log(presentationId, email, name);
  }
};
