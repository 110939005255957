import React from "react";
import { isMultiAnswer } from "utils/activePresentationUtils";

export default function OptionList({
  option,
  onOptionChange,
  questionType,
  selectedOptions,
}) {
  const { id, imgUrl, text, color } = option ?? {};

  const isSelected = selectedOptions?.includes(id);

  //functions
  const handleChange = () => {
    onOptionChange(id, text);
  };

  return (
    <section>
      <div
        key={id}
        style={{
          color: color?.includes("#fff") || !color ? "#344054" : `white`,

          background: color?.includes("#fff") ? "transparent" : color,
        }}
        className={`  flex items-center w-full px-4 py-2 font-semibold border rounded-full border-gray350 gap-x-2 
                    `}
      >
        <input
          checked={isSelected}
          type={isMultiAnswer(questionType) ? "checkbox" : "radio"}
          name="id"
          value={id}
          onChange={handleChange}
        />
        <p>{text}</p>
      </div>
    </section>
  );
}
