import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useDispatch } from "react-redux";

//libraries
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ClipLoader } from "react-spinners";

//components
import Loader from "components/UI/Loader";

//slices
import { getPointBalance } from "store/Payment/paymentSlice";
import { Modal } from "antd";
import PaymentSuccess from "./Modal/PaymentSuccess";
import { toast } from "sonner";

function CheckoutForm(props, ref) {
  const { closeModal, details, closeTopUpModal } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  // usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [elementsLoaded, setElementsLoaded] = useState(false);

  // useImperativeHandle(ref, () => ({
  //   resetModal() {
  //     console.log("here");
  //     setIsLoading(false);
  //     setIsFormValid(false);
  //     setElementsLoaded(false);
  //     if (elements) {
  //       elements.getElement(PaymentElement)?.clear(); // Reset the PaymentElement
  //     }
  //   },
  // }));

  //function
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // let routeOrigin = `${window.location.origin}/admin/dashboard`;

    setIsLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      // confirmParams: {
      //   return_url: routeOrigin,
      // },
    });
    if (result) {
      closeModal();
      closeTopUpModal && closeTopUpModal();
    }
    if (result.error) {
      setIsLoading(false);
      toast.error( result.error.message)
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      setIsLoading(false);
      setIsModalOpen(true);
      dispatch(getPointBalance());

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleChange = (e) => {
    if (e.complete) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  return (
    <section>
      {!elementsLoaded && <Loader />}{" "}
      <form
        className={`my-10 ${!elementsLoaded ? "hidden" : "block"}`}
        onSubmit={handleSubmit}
      >
        <PaymentElement
          onChange={handleChange}
          onReady={() => setElementsLoaded(true)}
        />
        {elementsLoaded && (
          <button
            disabled={isLoading || !isFormValid}
            className={`w-full py-3 mt-4 text-white bg-black rounded-lg ${
              isFormValid
                ? "opacity-100 cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
          >
            {isLoading ? <ClipLoader color="white" size={12} /> : "   Submit"}
          </button>
        )}
      </form>
      {/* Modal  */}
      <>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
          closable={false}
        >
          <PaymentSuccess
            details={details}
            closeModal={handleCancel}
            closeTopUpModal={closeTopUpModal}
          />
        </Modal>
      </>
      {/*  */}
    </section>
  );
}

export default CheckoutForm


// forwardRef(CheckoutForm);