import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//components
import Folder from '../PersonalWS/Folder';
import WorkspaceFolderTitlebar from './WorkspaceFolders/WorkspaceFolderTitlebar';

//slices
import { getFolderFiles } from 'store/Workspace/workspaceSlice';
import {
  clearOpenedFolders,
  getFileStructureState,
  pushOpenedFolder,
  setMostActiveFolder,
} from 'store/FileStructure/fileStructureSlice';

export default function MainFolder({ folder }) {
  const { id } = folder;
  const dispatch = useDispatch();

  //useselector

  //highlight
  const { openedFolders } = useSelector(getFileStructureState);

  const folderFiles = useSelector(
    (state) => state.workspace.folderFiles.data[id]?.files
  );

  //usestate
  const [activeFolder, setActiveFolder] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isFolderPresent, setIsFolderPresent] = useState(false);

  //useeffect
  useEffect(() => {
    const isExist = openedFolders?.find((folder) => {
      return folder?.id === id;
    });

    setIsFolderPresent(isExist ? true : false);
  }, [openedFolders, id]);

  //functions
  const openFolder = (folder) => {
    const { id, title } = folder ?? {};
    setActiveFolder(id);
    dispatch(setMostActiveFolder(folder));
    //highlight
    dispatch(clearOpenedFolders());
    dispatch(pushOpenedFolder({ id, title }));

    if (!openDropdown) {
      dispatch(getFolderFiles(id));

      setOpenDropdown(!openDropdown);
    } else {
      setOpenDropdown(!openDropdown);
    }
  };

  return (
    <section className='w-full'>
      {/* main folder */}

      <WorkspaceFolderTitlebar
        openDropdown={openDropdown}
        activeFolder={activeFolder}
        workspaceFolder={folder}
        openFolder={openFolder}
      />

      {/* subFolder */}

      {/* folders */}

      {
        // (openDropdown && activeFolder === id)
        // highlight condotion
        (openDropdown || isFolderPresent) && (
          <section
            className={`flex ml-3 flex-col gap-y-1 w-full ${
              openDropdown || isFolderPresent ? 'block' : 'hidden'
            }`}
          >
            <Folder folders={folderFiles} />
          </section>
        )
      }
      {/*  */}
    </section>
  );
}
