import { Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import MyBriefCase from 'views/Admin/Briefcase';
import { getBriefcases } from 'store/Briefcase/briefcaseSlice';

import {
  getTeamInfo,
  getTeamMembers,
  getTeamsState,
} from 'store/Teams/teamsSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'store/store';
// import AllBriefcases from 'views/Admin/Briefcase/Tables/AllBriefcases';

interface Props {
  isModalOpen: boolean;
  setMododalOpen: () => void;
}

export default function AddToBriefcase({ setMododalOpen, isModalOpen }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();

  const { teamInfo } = useSelector(getTeamsState);

  //useeffects
  useEffect(() => {
    if (!teamInfo?.data) {
      dispatch(getTeamInfo());
      dispatch(getTeamMembers());
    }
  }, []);

  useEffect(() => {
    const teamId = teamInfo.data?.id;

    teamId && dispatch(getBriefcases(teamId));
  }, []);

  return (
    <Modal
      closable={false}
      width='85%'
      open={isModalOpen}
      footer={null}
      centered={false}
      onCancel={setMododalOpen}
      destroyOnClose
      style={{
        top: 50,
      }}
    >
      <MyBriefCase addFilesToBriefcase={true} />
    </Modal>
  );
}
