import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useHasPresentationStarted = (connection, presentationId) => {
  const navigate = useNavigate();
  console.log("triggered hereeee in useHasPresentationStarted")
  useEffect(() => {
    const handleEventResponse = (presentationId) => {
      toast.success("Presentation started");
      // console.log(event, id, 'evvv')
      navigate(`/viewer-mode/${presentationId}`);
    };

    if (connection) {
      connection.on("PresentationStarted", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.off("PresentationStarted", handleEventResponse);
      }
    };
  }, [connection, presentationId, navigate]);
};
