// Assets
import eye2 from 'assets/svg/eye-2.svg';
import presentationIcon from 'assets/svg/presention-chart-icon.svg';
import clock from 'assets/svg/clock-icon-broken.svg';
// import downloadIcon from 'assets/svg/download-icon-arrow-down.svg';
import shareIcon from 'assets/svg/circle-share-icon.svg';
import focusIcon from 'assets/svg/focus-icon-black.svg';
import profileFlower from 'assets/image/profile-flower.png';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getInitials } from 'utils/formatter';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import moment from 'moment';
import { TableEmpty } from 'components/UI/States/table/TableEmpty';
import { DocumentVisits } from 'types/Admin/Workspace/WorkspaceTypes';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

interface Props {
  documentVisits: DocumentVisits[];
}

export default function FileVisits({ documentVisits }: Props) {
  // States

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: DocumentVisits) => {
        return (
          <section className='flex items-center gap-x-2'>
            <div
              className={`w-8 h-8 ${
                rowData.publicationType === 'shared'
                  ? 'bg-[#FF6929]'
                  : 'bg-[#0C06FF]'
              } flex items-center justify-center text-white rounded-full text-sm`}
            >
              <p>{getInitials(rowData.documentTitle)}</p>
            </div>
            <p className='text-black text-sm'>{rowData?.documentTitle}</p>
          </section>
        );
      },
    },
    {
      header: 'Links',
      field: 'link',
      body: (rowData: DocumentVisits) => {
        return (
          <section className='flex gap-x-2 items-center'>
            <img
              src={
                rowData.publicationType === 'shared'
                  ? shareIcon
                  : presentationIcon
              }
              alt='presentation'
            />
            <div className='text-sm'>
              {/* <p className='text-sm italic font-medium text-[#333333]'>
                {rowData.link}
              </p> */}
              <div className='flex items-center gap-x-1'>
                <p className='text-xs text-[#878683]'>Created by:</p>
                <img src={profileFlower} alt='profile' className='w-[14px]' />
                {/* <p className='text-xs'>{rowData.createdBy}</p> */}
                <p className='text-xs'>you</p>
              </div>
            </div>
          </section>
        );
      },
    },
    // {
    //   header: 'Date created',
    //   field: 'dateCreated',
    //   body: (rowData: DocumentVisits) => {
    //     return (
    //       <div className='flex text-sm'>
    //         <h1 className='font-medium'>{rowData.date}</h1>
    //       </div>
    //     );
    //   },
    // },

    {
      header: 'Activities',
      field: 'activities',
      body: (rowData: DocumentVisits) => (
        <section className='flex gap-x-2'>
          <div className='bg-[#F6F3EF] flex gap-x-2 px-2 rounded-3xl py-1'>
            <img src={clock} alt='time spent on file' />
            {/* <p className='text-[#333333]'>{rowData.date}</p> */}
            <p className='text-[#333333]'>
              {moment.utc(rowData.date).fromNow()}
            </p>
          </div>
          {/* {rowData.activities.download && (
            <div className='bg-[#F6F3EF] flex gap-x-2 px-2 rounded-3xl py-1'>
              <img src={downloadIcon} alt='downloads' />
            </div>
          )} */}
          <div className='bg-[#F6F3EF] flex gap-x-2 px-2 rounded-3xl py-1'>
            <img src={focusIcon} alt='focus' />
            {/* <p className='text-[#333333]'>{rowData.activities.attentionspan}</p> */}
          </div>
        </section>
      ),
    },

    {
      header: '',
      sortable: true,
      body: () => {
        return (
          <div className='flex justify-end'>
            <div className='cursor-pointer flex items-center gap-x-5'>
              <img src={eye2} alt='' className='w-5' />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* Table */}
      {/* {DocumentVisits && ( */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          value={documentVisits}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
          emptyMessage={TableEmpty}
        >
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-+[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
      {/* )} */}
    </div>
  );
}
