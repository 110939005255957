import React from "react";
// import { TfiReload } from "react-icons/tfi";
import error from "assets/svg/networkerror.svg";

export default function NetworkError() {
  return (
    <section className=" h-full flex flex-col justify-center items-center">
      <div>
        <img src={error} alt="" />
      </div>
      <div className="text-center">
        <h1 className="my-2 text-2xl font-semibold">Network error</h1>
        <p className="w-full mx-auto text-sm">Internet connection failure</p>
        <div className="flex items-center justify-center w-full my-4 ">
          <button
            className="flex items-center px-4 py-2 text-white bg-orange-600 rounded"
            onClick={() => window.location.reload()}
          >
            {/* <TfiReload className="mr-3" /> */}
            Reload Page{" "}
          </button>
        </div>
      </div>
    </section>
  );
}
