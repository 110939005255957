import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

//assets
import add from 'assets/svg/add.svg';
import bell from 'assets/svg/bell.svg';
import lock from 'assets/svg/lock.svg';
import upload from 'assets/svg/upload2.svg';
import teamIcon from 'assets/svg/people2.svg';
import arrowDown from 'assets/svg/arrowDown.svg';
import newfolder from 'assets/svg/newfolder.svg';

//components
import CreateFolder from './Modal/CreateFolder';
import FlexContainer from 'components/UI/StyleComponents/FlexContainer';
import ContentPage from 'components/Admin/Workspace/ContentPage/ContentPage';

//libraries
import { Dropdown, Modal } from 'antd';

//slices
import {
  getFolderFiles,
  getWorkspaceFolders,
  getWorkspaceState,
  getAllWorkspace,
} from 'store/Workspace/workspaceSlice';
import {
  getFileStructureState,
  pushOpenedFolder,
  setFileToggled,
  setMostActiveFolder,
  setPersonalWorkspaceId,
} from 'store/FileStructure/fileStructureSlice';

//components
import BreadCrumb from 'components/Admin/Workspace/BreadCrumb';
import UploadDocuments from '../Dashboard/Modal/UploadMultiDocument/UploadDocuments';
import PersonalWorkspace from 'components/Admin/Workspace/PersonalWS/PersonalWorkspace';
import { getMyPlans, getPlans } from 'store/Payment/paymentSlice';

export default function Workspace() {
  const dispatch = useDispatch();

  //useref
  const uploadModalRef = useRef();

  //useselector
  const { folderFiles } = useSelector(getWorkspaceState);

  const { personalWorkspaceId, mostActiveFolder, fileToggled } = useSelector(
    getFileStructureState
  );

  //usestates
  const [isUpgraded] = useState(true);
  const [toggleTeamDD, setToggleTeamDD] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [activeWorkSpace, setActiveWorkspace] = useState('personal');
  const [workSpaces, setWorkSpaces] = useState([]);

  //useeffects
  useEffect(() => {
    dispatch(getMyPlans());
    dispatch(getPlans());
  }, [dispatch]);

  //get all workspace
  useEffect(() => {
    dispatch(getAllWorkspace()).then(({ payload }) => {
      if (payload) {
        setWorkSpaces(payload);
        if (!personalWorkspaceId || fileToggled) {
          const workspace = payload.find((item) => {
            if (activeWorkSpace !== 'personal' && workSpaces.length > 1) {
              return item.title.toLowerCase() !== 'personal';
            } else {
              return item.title.toLowerCase() === 'personal';
            }
          });

          // console.log(
          //   payload[activeWorkSpace],
          //   "Active workspace value hereee"
          // );
          // const workspace = payload.filter(
          //   (el) => el?.title?.toLowerCase() === "personal"
          // )?.[0];

          dispatch(setPersonalWorkspaceId(workspace));
          dispatch(getWorkspaceFolders(workspace?.workspaceId)).then(
            ({ payload }) => {
              //hide audio
              let payload2 = payload?.filter((doc) => {
                return !doc?.title?.includes('vrecord');
              });
              if (payload2?.length > 0) {
                const firstFolder = payload2?.[0];
                dispatch(setMostActiveFolder(firstFolder));
                dispatch(getFolderFiles(firstFolder?.id));
                dispatch(
                  pushOpenedFolder({
                    id: firstFolder?.id,
                    title: firstFolder?.title,
                  })
                );
              } else {
                dispatch(
                  setMostActiveFolder({
                    id: workspace?.workspaceId,
                    title: workspace?.title,
                  })
                );
              }
            }
          );
          dispatch(setFileToggled(false));
        }
      }
    });
  }, [dispatch, personalWorkspaceId]);

  //variables
  const items = [
    {
      key: '0',
      label: (
        <div
          className='flex gap-x-2'
          onClick={(data) => {
            setIsFolderModalOpen(true);
          }}
        >
          <img src={newfolder} alt='create new folder' className='w-4' />
          <p>New folder</p>
        </div>
      ),
    },

    {
      key: '1',
      label: (
        <div>
          {mostActiveFolder && (
            <div
              className='flex gap-x-2'
              onClick={() => setIsFileUploadModalOpen(true)}
            >
              <img src={upload} alt='upload a file' className='w-4' />
              <p>Upload Files</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  //functions

  const handleFileUploadCancel = () => {
    uploadModalRef.current.resetModal();
    setIsFileUploadModalOpen(false);
  };
  const handleCloseFolderModal = () => {
    setIsFolderModalOpen(false);
  };

  return (
    <section className='relative'>
      <aside className='fixed lg:left-[18%] lg:w-[19%] md:w-[30%] h-screen px-4 py-8 border-r overflow-y-scroll hide-scrollbar'>
        {/* team workspace */}
        <section className=''>
          {/* team nav */}
          {/* <FlexContainer
            extraClass="cursor-pointer text-sm "
            onClick={() => {
              dispatch(setFileToggled(true));
              setActiveWorkspace("team");
              setToggleTeamDD(!toggleTeamDD);
              const teamWorkspace = workSpaces.find(
                (item) => item.title.toLowerCase() !== "personal"
              );
              if (teamWorkspace) {
                setMostActiveFolder({
                  title: teamWorkspace.title,
                  id: teamWorkspace.workspaceId,
                });
              } else {
                dispatch(setMostActiveFolder("team"));
              }
              // Also toggle to show that the team is the active filter
              if (workSpaces.length > 1) {
                dispatch(
                  setPersonalWorkspaceId(
                    workSpaces.find(
                      (item) => item.title.toLowerCase() !== "personal"
                    )
                  )
                );
              }
            }}
          >
            <div className="flex items-center gap-x-1">
              <img src={teamIcon} alt="team workspace" className="w-6" />{" "}
              <p>Team workspace</p>
            </div>

            {isUpgraded ? (
              <img src={arrowDown} alt="dropdown" />
            ) : (
              <img src={lock} alt="secured" />
            )}
          </FlexContainer> */}
          {/*  */}

          {/* dropdown */}

          {/* <section
            className={`flex flex-col my-4 gap-y-2 ${
              toggleTeamDD ? "block" : "hidden"
            }`}
          >
            <div className="flex items-center p-2 text-sm border rounded-md cursor-pointer bg-gray100 gap-x-2 font-grotesk">
              <img src={folder} alt="document" className="w-5" />
              <p className="font-semibold">RCCG presentation</p>
            </div>
          </section> */}
          {/*  */}
        </section>
        {/*  */}

        {/* personal workspace */}
        <section className='mt-6'>
          <PersonalWorkspace
            workSpaces={workSpaces}
            setActiveWorkSpace={setActiveWorkspace}
          />
        </section>
        {/*  */}
      </aside>

      <main className='absolute right-0 lg:w-[63%] md:w-[70%] '>
        <section className='p-6 '>
          {/* navigation */}
          <nav>
            <FlexContainer>
              {/* breadcrumb */}
              <BreadCrumb />
              {/*  */}

              {/* actions */}
              <div className='flex items-center gap-x-4 '>
                {/* <button className="flex text-gray500 items-center justify-between w-[7.5rem] px-2 py-2 text-sm border-2 rounded-lg cursor-pointer">
                  <div className="flex items-center gap-x-2">
                    <img src={lock} alt="access control" className="w-5" />{" "}
                    <p>Private</p>
                  </div>

                  <img src={arrowDown} alt="access control" />
                </button> */}

                {mostActiveFolder !== 'team' && (
                  <button className='flex text-primary items-center justify-between w-[7rem] p-2 text-sm border border-primary rounded-lg cursor-pointer'>
                    <Dropdown menu={{ items }} trigger={['click']}>
                      <div
                        className='flex items-center gap-x-2'
                        onClick={(e) => e.preventDefault()}
                      >
                        <img src={add} alt='access control' className='w-5' />{' '}
                        <p>Add new </p>
                      </div>
                    </Dropdown>
                  </button>
                )}

                <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                  <img src={bell} alt='notification' className='w-4' />
                </div>
              </div>
              {/*  */}
            </FlexContainer>
          </nav>
          {/*  */}

          {/* body */}

          <section className='my-6'>
            <ContentPage files={folderFiles} />
          </section>
          {/*  */}
        </section>
      </main>

      <>
        {/* Modals */}
        <Modal
          open={isFolderModalOpen}
          onCancel={handleCloseFolderModal}
          footer={null}
        >
          <CreateFolder closeModal={handleCloseFolderModal} />
        </Modal>

        <Modal
          open={isFileUploadModalOpen}
          onCancel={handleFileUploadCancel}
          footer={null}
        >
          <UploadDocuments
            ref={uploadModalRef}
            closeModal={handleFileUploadCancel}
          />
        </Modal>

        {/*  */}
      </>
    </section>
  );
}
