

//assets

import people from "assets/svg/people.svg";
import end from "assets/svg/endModalSVG.svg";
import messagenotif from "assets/svg/messagenotif.svg";

export default function Summary() {
  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={end} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div>
          <h1 className="text-lg font-bold lg:text-2xl">Presentation ended</h1>
          <p className="text-xs">Here is your summary</p>
        </div>

        <section className="my-4 text-xs lg:text-sm">
          <div className="flex items-center justify-center w-full py-3 my-2 font-medium rounded-lg cursor-pointer text-gray500 bg-gray30 gap-x-2">
            <img src={people} alt="upload slide" className="w-4" />
            <p>Your have 30 viewers</p>
          </div>

          <div className="flex items-center justify-center w-full py-3 my-2 font-medium rounded-lg cursor-pointer text-gray500 bg-gray30 gap-x-2">
            <img src={messagenotif} alt="upload slide" className="w-4" />
            <p>You answered 25 questions </p>
          </div>

          <div className="flex items-center justify-center w-full py-3 my-2 font-medium rounded-lg cursor-pointer text-gray500 bg-gray30 gap-x-2">
            <img src={messagenotif} alt="upload slide" className="w-4" />
            <p>You presented for 30 mins </p>
          </div>
        </section>

        <section>
          <p>More actions</p>
          <div className="flex mt-1 text-xs gap-x-4 lg:text-sm">
            <button className="w-full py-3 text-white rounded-lg cursor-pointer bg-primary gap-x-2">
              Share slide
            </button>

            <button className="w-full py-3 font-medium border rounded-lg cursor-pointer text-primary border-primary gap-x-2">
              Make public
            </button>
          </div>
        </section>
      </section>
    </section>
  );
}
