import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//assets
import logo from 'assets/svg/fullLogo.svg';
import { getToken } from 'utils/localStorageServices';

export default function Navbar() {
  const [openDD, setOpenDD] = useState(false);

  return (
    <>
      <nav className='[ flex items-center ] z-50 justify-between px-4 py-3 rounded-lg bg-black20 border border-[#D9DBE080]'>
        <div>
          <Link to='/'>
            <img src={logo} alt='presenta logo' className='w-28 ' />
          </Link>
        </div>

        <div className='[ lg:flex items-center hidden ] text-white '>
          <div className='[ flex items-center ] gap-x-10'>
            {/* <div className="[ flex items-center ] gap-x-2">
            <p>Product</p>
            <i className="pi pi-angle-down" style={{ color: "white" }}></i>
          </div> */}

            <Link to='/about-us'>
              <p>About</p>{' '}
            </Link>

            <Link to='/pricing'>
              <p>Pricing</p>{' '}
            </Link>

            <Link to='/support'>
              <p>Support</p>
            </Link>
          </div>
        </div>
        <div className='[ lg:flex items-center hidden ] gap-x-6 text-white'>
          {getToken() && (
            // <Link to="/admin/dashboard">
            <Link to='/admin/mypresentations'>
              <p className='lg:pr-4'>Dashboard</p>
            </Link>
          )}

          {!getToken() && (
            <>
              <Link to='/login'>
                <p>Login</p>
              </Link>

              <Link to='/signup'>
                <div className=' center-content w-fit [ flex items-center justify-center ]  gap-x-2 py-1 px-4 rounded-lg text-black bg-white'>
                  <p>Sign up</p>

                  <i className='pi pi-chevron-circle-right'></i>
                </div>
              </Link>
            </>
          )}
        </div>

        <div className=' [ lg:hidden block ]'>
          {!openDD ? (
            <i
              onClick={() => setOpenDD(true)}
              className='pi pi-bars'
              style={{ fontSize: '1rem', color: 'white' }}
            ></i>
          ) : (
            <i
              onClick={() => setOpenDD(false)}
              className='pi pi-times'
              style={{ fontSize: '1rem', color: 'white' }}
            ></i>
          )}
        </div>
      </nav>

      {/* dropdown */}
      {/* <section
        className={`transition-all duration-500 w-full ${
          openDD ? '  ml-0' : ' ml-[-1000px] '
        }`}
      >
        <section
          className={`rounded-md flex-col py-4 mt-1 text-center text-white bg-black20 gap-y-2 [ lg:hidden flex ]`}
        >
          <Link to='/about-us'>
            <p>About</p>{' '}
          </Link>

          <Link to='/pricing'>
            <p>Pricing</p>{' '}
          </Link>

          <Link to='/support'>
            <p>Support</p>
          </Link>

          {getToken() && (
            // <Link to="/admin/dashboard">
            <Link to='/admin/mypresentations'>
              <p className='lg:pr-4'>Dashboard</p>
            </Link>
          )}

          {!getToken() && (
            <>
              <Link to='/login'>
                <p>Login</p>
              </Link>

              <Link to='/signup'>
                <div className='w-full center-content'>
                  <div className='  w-fit [ flex items-center justify-center ]  gap-x-2 py-1 px-4 rounded-lg text-black bg-white'>
                    <p>Sign up</p>

                    <i className='pi pi-chevron-circle-right'></i>
                  </div>
                </div>
              </Link>
            </>
          )}
        </section>
      </section> */}
      <div
        className={`fixed top-[80px] left-0 w-full mx-auto ${
          openDD
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-0'
        }`}
      >
        <section
          className={`transition-all duration-500 w-full ${
            openDD
              ? 'ml-0 pointer-events-auto opacity-100'
              : 'ml-[-1000px] pointer-events-none opacity-0'
          }`}
        >
          <section
            className={`rounded-md flex-col py-4 mt-1 text-center text-white bg-black20 gap-y-2 [ lg:hidden flex ] [ lg:w-[80%] w-[90%]  mx-auto ]`}
          >
            <Link to='/about-us'>
              <p>About</p>
            </Link>

            <Link to='/pricing'>
              <p>Pricing</p>
            </Link>

            <Link to='/support'>
              <p>Support</p>
            </Link>

            {getToken() && (
              <Link to='/admin/mypresentations'>
                <p className='lg:pr-4'>Dashboard</p>
              </Link>
            )}

            {!getToken() && (
              <>
                <Link to='/login'>
                  <p>Login</p>
                </Link>

                <Link to='/signup'>
                  <div className='w-full center-content'>
                    <div className='w-fit [ flex items-center justify-center ] gap-x-2 py-1 px-4 rounded-lg text-black bg-white'>
                      <p>Sign up</p>
                      <i className='pi pi-chevron-circle-right'></i>
                    </div>
                  </div>
                </Link>
              </>
            )}
          </section>
        </section>
      </div>
      {/*  */}
    </>
  );
}
