import React, { useState } from "react";

//assets
import sm from "assets/svg/sm.svg";
import msg from "assets/svg/sms.svg";
import phone from "assets/svg/phone.svg";
import avatar from "assets/svg/avatar.svg";

export default function LeadDetailsDrawer() {
  //usestates
  const [tab, setTab] = useState(0);

  return (
    <section>
      <h3 className="text-lg font-medium text-gray600">Details</h3>

      {/* details */}
      <section className="px-2 py-2 my-4 border rounded-2xl bg-gray70">
        <div className="flex items-center justify-between w-full">
          <img src={avatar} alt="" className="w-[47px]" />
          <div className="cursor-pointer">
            <i className="pi pi-ellipsis-v"></i>
          </div>
        </div>

        <section className="pl-4">
          <h3 className="text-lg font-medium text-gray600">Johnny Cage</h3>

          <section className="flex items-center my-3 gap-x-4">
            <div className="flex items-center gap-x-1">
              <img src={msg} alt="" />
              <p>Jonny.cage@thepresenta.com</p>
            </div>

            <div className="flex items-center gap-x-1">
              <img src={phone} alt="" />
              <p>447 - 800 - 9890</p>
            </div>
          </section>
        </section>
      </section>
      {/*  */}

      <section>
        <section className="flex my-6 text-md ">
          <div
            onClick={() => setTab(0)}
            className={`pb-2 px-4 cursor-pointer ${
              tab !== 0
                ? "border-b"
                : "border-b-2 border-primary text-primary font-semibold"
            }`}
          >
            <p>Basic information</p>
          </div>

          <div
            onClick={() => setTab(1)}
            className={`pb-2 px-8 cursor-pointer ${
              tab !== 1
                ? "border-b"
                : "border-b-2 border-primary text-primary font-semibold"
            }`}
          >
            <p>Activities</p>
          </div>
        </section>

        {/* basic info  */}

        {tab === 0 && (
          <section>
            {/* company info  */}
            <section>
              <h3 className="font-semibold text-md text-gray40">Company information</h3>
              <section className="p-4 my-2 border rounded-2xl border-gray110">
                <section className="flex items-center">
                  <div className="w-1/2">
                    <h3 className=" text-md text-gray40">Company</h3>

                    <p className="mt-2 text-lg font-medium">Spotify</p>
                  </div>

                  <div className="w-1/2">
                    <h3 className=" text-md text-gray40">Role</h3>

                    <p className="mt-2 text-lg font-medium">CEO</p>
                  </div>
                </section>

                <section className="flex items-center mt-4">
                  <div className="w-1/2">
                    <h3 className=" text-md text-gray40">Gender</h3>

                    <p className="mt-2 text-lg font-medium">Male</p>
                  </div>

                  <div className="w-1/2">
                    <h3 className=" text-md text-gray40">Role</h3>

                    <p className="mt-2 text-lg font-medium">CEO</p>
                  </div>
                </section>
              </section>
            </section>
            {/*  */}

            {/* socials  */}
            <section>
              <h3 className="font-semibold text-md text-gray40">Social media</h3>
              <section className="p-4 my-2 border rounded-2xl border-gray110">
                <section className="flex items-center">
                  <div className="w-1/2">
                    <div>
                      <div className="flex items-center justify-center w-24 py-1 pl-1 text-sm border-2 rounded-full text-blue200 bg-blue100 gap-x-1 ">
                        <img src={sm} alt="" className="pt-1" />
                        LinkedIn
                      </div>
                    </div>

                    <p className="mt-2 text-gray40">linkedin.com/in/Jonny.c</p>
                  </div>

                  <div className="w-1/2">
                    <div>
                      <div className="flex items-center justify-center w-24 py-1 pl-1 text-sm border-2 rounded-full text-blue200 bg-blue100 gap-x-1 ">
                        <img src={sm} alt="" className="pt-1" />
                        LinkedIn
                      </div>
                    </div>

                    <p className="mt-2 text-gray40">linkedin.com/in/Jonny.c</p>
                  </div>
                </section>

                <section className="flex items-center mt-4">
                  <div className="w-1/2">
                    <div>
                      <div className="flex items-center justify-center w-24 py-1 pl-1 text-sm border-2 rounded-full text-blue200 bg-blue100 gap-x-1 ">
                        <img src={sm} alt="" className="pt-1" />
                        LinkedIn
                      </div>
                    </div>

                    <p className="mt-2 text-gray40">linkedin.com/in/Jonny.c</p>
                  </div>

                  <div className="w-1/2">
                    <div>
                      <div className="flex items-center justify-center w-24 py-1 pl-1 text-sm border-2 rounded-full text-blue200 bg-blue100 gap-x-1 ">
                        <img src={sm} alt="" className="pt-1" />
                        LinkedIn
                      </div>
                    </div>

                    <p className="mt-2 text-gray40">linkedin.com/in/Jonny.c</p>
                  </div>
                </section>
              </section>
            </section>
            {/*  */}
          </section>
        )}

        {/*  */}
      </section>
    </section>
  );
}
