import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Assets
import quiz1 from 'assets/svg/quiz1.svg';
import worldCloud from 'assets/svg/wordCloud.svg';
import quizzes from 'assets/svg/quiz3.svg';
import settingsIcon from 'assets/svg/settings-polls.svg';
import qrCode from 'assets/svg/qr-code-icon.svg';
import champion from 'assets/svg/champion.svg';
import participant from 'assets/svg/participant.svg';
import chevronDown from 'assets/svg/chevron-down.svg';

// Components
import MultiQuestion from './PollType/MultiQuestion';
import { Poll, PollTypes, QuestionsList } from 'interfaces/PollSetup';
import QuestionsOptionsDisplay from './QuestionsOptionsDisplay';

interface VariablePollTypeInterface {
  title: string;
  image: string;
  type: PollTypes;
}
interface Props {
  polls: Poll[];
  currentDisplayedPollIndex: number;
  setCurrentDisplayedPollIndex: Dispatch<SetStateAction<number>>;
}

export default function MainContent({
  polls,
  currentDisplayedPollIndex,
  setCurrentDisplayedPollIndex,
}: Props) {
  const [pollType, setPollType] = useState<PollTypes>('default'); //for handling poll type
  const [question, setQuestion] = useState('');
  const [questionsList, setQuestionsList] = useState<QuestionsList[]>([
    {
      tagColor: '',
      value: '',
      correctAnswer: false,
      markColour: '#333333',
    },
  ]);

  console.log(questionsList);

  // UseEffect
  useEffect(() => {
    // checks if the last input question is being typed and adds new item to the list
    if (questionsList.length > 0 && questionsList.length < 5) {
      if (questionsList[questionsList.length - 1].value.length > 0) {
        setQuestionsList((prev) => [
          ...prev,
          {
            tagColor: 'blue',
            value: '',
            correctAnswer: false,
            markColour: '#333333',
          },
        ]);
      }
    }
  }, [questionsList]);

  const variable_poll_type: VariablePollTypeInterface[] = [
    {
      title: 'Multi question',
      image: quiz1,
      type: 'multi_question',
    },
    {
      title: 'World cloud',
      image: worldCloud,
      type: 'world_cloud',
    },
    {
      title: 'Quizzes',
      image: quizzes,
      type: 'quizzes',
    },
    {
      title: 'Open ended',
      image: quizzes,
      type: 'open_ended',
    },
  ];

  return (
    <section className='mt-[80px] absolute right-0 top-0 w-[88%] min-h-fit '>
      <section className='p-3'>
        <section className='grid gap-x-3 grid-cols-10'>
          {/* Question type */}
          <section className='col-span-3'>
            <div className='w-full bg-white p-5 rounded-md '>
              <p className='text-[##667084] font-semibold text-sm'>
                SELECT QUESTION TYPE
              </p>
              {/* Questions */}
              <section className='mt-3 grid grid-cols-1 text-sm font-semibold text-[##333333] gap-y-3'>
                {variable_poll_type.map((item) => (
                  <>
                    {(pollType === 'default' || pollType === item.type) && (
                      <div
                        key={item.title}
                        className={`rounded-md border py-2 px-3 flex justify-between items-center cursor-pointer ${
                          pollType === item.type ? 'bg-[#F2F2F2]' : ''
                        }`}
                        onClick={() =>
                          setPollType((prev) =>
                            prev === 'default' ? item.type : 'default'
                          )
                        }
                      >
                        <div className='flex items-center gap-x-3'>
                          <img
                            src={item.image}
                            alt='multi question'
                            className='w-[26px] h-[26px]'
                          />
                          <p>{item.title}</p>
                        </div>

                        <img
                          src={chevronDown}
                          alt='multi question'
                          className={`
                          ${pollType === item.type ? '' : 'hidden'}
                          `}
                        />
                      </div>
                    )}
                  </>
                ))}

                {pollType === 'multi_question' && (
                  <MultiQuestion
                    setQuestion={setQuestion}
                    question={question}
                    questionsList={questionsList}
                    setQuestionsList={setQuestionsList}
                  />
                )}
              </section>
            </div>
          </section>

          {/* Question display */}
          <section className='col-span-6 min-h-screen'>
            <div className='bg-white p-3 rounded-md'>
              <p className='text-2xl font-bold'>
                {question ? question : 'Your questions will appear here'}
              </p>
              {/* Content */}
              <section className='bg-gray550 rounded-md'>
                <section className=' px-5 pt-20 py-5 grid grid-cols-5 gap-x-5 mt-5 h-full'>
                  {questionsList.map((question) => (
                    <QuestionsOptionsDisplay questionOption={question} />
                  ))}
                  {/* <div className='flex flex-col'>
                    <div className='h-[200px] rounded-md overflow-hidden'>
                      <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                      <div className='h-[5%] bg-[#FFC043] w-full'></div>
                    </div>
                    <div className='flex items-center justify-center w-full'>
                      <p className='text-sm font-medium text-[#344054]'>
                        Option 1
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div className='h-[200px] rounded-md overflow-hidden'>
                      <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                      <div className='h-[5%] bg-black w-full'></div>
                    </div>
                    <div className='flex items-center justify-center w-full'>
                      <p className='text-sm font-medium text-[#344054]'>
                        Option 1
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div className='h-[200px] rounded-md overflow-hidden'>
                      <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                      <div className='h-[5%] bg-[#7356BF] w-full'></div>
                    </div>
                    <div className='flex items-center justify-center w-full'>
                      <p className='text-sm font-medium text-[#344054]'>
                        Option 1
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div className='h-[200px] rounded-md overflow-hidden'>
                      <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                      <div className='h-[5%] bg-[#3AA76D] w-full'></div>
                    </div>
                    <div className='flex items-center justify-center w-full'>
                      <p className='text-sm font-medium text-[#344054]'>
                        Option 1
                      </p>
                    </div>
                  </div> */}
                </section>

                {/* Participant */}
                <section className='p-5'>
                  <section className='flex rounded-full gap-x-5 py-2 px-5 bg-[#E8E8E8] w-fit'>
                    <div className='flex gap-x-2'>
                      <div>
                        <img src={participant} alt='number of participant' />
                      </div>
                      <div>
                        <p className='text-sm'>No of participant</p>
                        <p className='font-semibold'>0</p>
                      </div>
                    </div>
                    <div className='flex gap-x-2'>
                      <div>
                        <img src={champion} alt='number of participant' />
                      </div>
                      <div>
                        <p className='text-sm'>No of participant</p>
                        <p className='font-semibold'>0</p>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          </section>
          {/* Settings */}
          <section className='col-span-1 text-xs text=[#667084]'>
            <div className='bg-white rounded-md w-full flex items-center flex-col pt-3 pb-3'>
              <div className='mb-2'>
                <p className='font-bold'>SETTINGS</p>
                <hr className='w-full border ' />
              </div>
              <section className='font-medium grid grid-cols-1 gap-7'>
                <div className='flex flex-col items-center gap-1'>
                  <img src={settingsIcon} alt='display result' />
                  <p>Display result</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input type='checkbox' value='' className='sr-only peer' />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
                <div className='flex flex-col items-center gap-1'>
                  <img src={settingsIcon} alt='display result' />
                  <p>Live response</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input type='checkbox' value='' className='sr-only peer' />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
                <div className='flex flex-col items-center gap-1'>
                  <img src={qrCode} alt='QR code' />
                  <p>Display result</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input type='checkbox' value='' className='sr-only peer' />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}
