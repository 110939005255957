import React, { useEffect, useState } from "react";

//libraries
import { Chart } from "primereact/chart";
export default function LineChart() {
  //usetate
  const [chartData, setChartData] = useState([]);
  const [chartOption, setChartOption] = useState([]);

  //useEffects
  useEffect(() => {
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Presentation",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: true,
          tension: 0.4,
          borderColor: "#8C3A17",
          backgroundColor: "#8C3A1710",
        },
        {
          label: "Links",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: true,

          tension: 0.4,
          borderColor: "#E86025",
          backgroundColor: "#E8602510",
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      // aspectRatio: 0.6,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            color: "black",
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: "black",
          },
          grid: {
            display: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOption(options);
  }, []);

  return (
    <section className="pb-20">
      <section className="py-4 mb-4 border-b">
        <h3 className="text-lg font-bold text-blue400">Visitors stat</h3>
      </section>

      <section className="flex justify-end w-full mb-4 font-semibold text-md gap-x-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2 h-2 rounded-full bg-[#E86025]"></div>
          <p className="text-[#475467]  pt-[0.1rem]">Presentation</p>
        </div>

        <div className="flex items-center gap-x-2">
          <div className="w-2 h-2 rounded-full bg-[#8C3A17]"></div>
          <p className="text-[#475467]  pt-[0.1rem]">Links</p>
        </div>
      </section>
      <Chart height="300" type="line" data={chartData} options={chartOption} />
    </section>
  );
}
