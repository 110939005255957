import React, { ReactNode } from "react";
import pptInActive from "assets/svg/pptxInactive.svg";
import pptActive from "assets/svg/pptActive.svg";
import pollActive from "assets/svg/pollActive.svg";
import pollInactive from "assets/svg/pollInactive.svg";
import videoIcon from "assets/svg/videoIcon.svg";
import pdfIcon from "assets/svg/pdfInactive.svg"

export const PreviewMenu = () => {
  const menuValues = [
    {
      name: "PPTX File",
      iconActive: pptActive,
      iconInActive: pptInActive,
      active: true,
    },
    {
      name: "Poll",
      iconActive: pollActive,
      iconInActive: pollInactive,
      active: false,
    },
    {
      name: "Video",
      iconActive: videoIcon,
      iconInActive: videoIcon,
      active: false,
    },
    {
      name: "Poll",
      iconActive: pollActive,
      iconInActive: pollInactive,
      active: false,
    },
    {
      name: "PDF file",
      iconActive: pdfIcon,
      iconInActive: pdfIcon,
      active: false,
    },
  ];
  return (
    <div className="border p-3 border-[#EBF1FF] rounded-2xl bg-white flex gap-2">
      {menuValues.map((menuValue) => {
        return (
          <MenuWrapper key={menuValue.name} active={menuValue.active}>
            <span>
              <img
                src={
                  menuValue.active
                    ? menuValue.iconActive
                    : menuValue.iconInActive
                }
                alt="PPt Icon"
              />
            </span>
            <p
              className={` ${
                menuValue.active ? "text-[#FF6929]" : "text-[#667084]"
              } mt-1 font-semibold font-grotesk text-xs`}
            >
              {menuValue.name}
            </p>
          </MenuWrapper>
        );
      })}
    </div>
  );
};

const MenuWrapper = ({
  active,
  children,
}: {
  active: boolean;
  children: ReactNode;
}) => {
  return (
    <>
      <div
        className={` ${
          active && "bg-[#F6F6F6] border border-[#EEEEEE]"
        }  py-2 min-w-[80px] cursor-pointer rounded-lg flex flex-col justify-center items-center `}
      >
        {children}
      </div>
    </>
  );
};
