import React from "react";
import xIcon from "assets/svg/cancelIcon.svg";

export const TagWrapper = ({
  text,
  click,
}: {
  text: string;
  click?: () => void;
}) => {
  return (
    <span
      onClick={click}
      className="border cursor-pointer bg-[#f5f5f5] items-center border-[#AFAFAF] rounded-full flex gap-2 py-2 px-4"
    >
      <p className="text-sm font-grotesk font-semibold">{text}</p>
      <span>
        <img src={xIcon} alt="" />
      </span>
    </span>
  );
};
