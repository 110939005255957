import React, {useEffect, useRef} from "react";
import {SlideInterface} from "interfaces/interfaces-data";

export const DesktopPrentationScreen = ({
                                            loading,
                                            activeSlide,
                                            gridModeActive,
                                            handleGridModeActive,
                                            slides,
                                            handleChangeSlide,
                                            activeSlideIndex
                                        }: {
    loading: boolean;
    activeSlide?: SlideInterface;
    gridModeActive: boolean;
    handleGridModeActive: (val: boolean) => void
    slides?: SlideInterface[]
    handleChangeSlide: (item: number) => void
    activeSlideIndex: number

}) => {
    const elementRef = useRef<(HTMLDivElement | null)[]>([]);
    useEffect(() => {
        if (gridModeActive) {
            elementRef.current[activeSlideIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center', // Center alignment for better view
            });
        }
    }, [gridModeActive]);
    return (
        <>
            <div
                className="w-full bg-[#F6F6F6] mt-2 mb-4 overflow-hidden rounded-xl p-4 flex-grow  min-h-[400px] h-[400px] flex items-center justify-center">
                {loading ? (
                    <div className="h-full w-full bg-[#F6F6F6] "></div>
                ) : (
                    <>
                        {gridModeActive ? <div
                                className='hide-scrollbar grid grid-cols-5 w-full max-h-full gap-3 px-5 overflow-y-auto'>
                                {slides?.length && slides.map((item, index, key) => {
                                    return <div
                                        ref={(el) => elementRef.current[index] = el}
                                        onClick={() => {
                                            handleChangeSlide(index)
                                            handleGridModeActive(false)
                                        }} className={"flex flex-col justify-center"}>
                                        <div
                                            className={`${activeSlide?.id === item.id ? "border-2 border-[#FF6929]" : ""} overflow-hidden  h-fit w-full rounded-lg cursor-pointer`}
                                            key={item.id}>
                                            <img src={item.imgUrl} alt={""}/>
                                        </div>
                                        <div className='flex w-full justify-center mt-2'>
                                            <div
                                                className={`rounded-full h-4 w-4 flex items-center justify-center ${
                                                    activeSlide?.id === item.id
                                                        ? ' bg-[#ED6E33] text-white'
                                                        : 'bg-gray-300 text-gray-600'
                                                } `}
                                            >
                                                <p className='text-xs'>{index + 1}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            : <img
                                className="min-w-full max-w-full  w-full h-full overflow-hidden rounded-2xl object-contain"
                                src={activeSlide?.imgUrl}
                                alt=""
                            />}


                    </>
                )}
            </div>
        </>
    );
};
