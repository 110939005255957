import React, {useEffect, useRef, useState} from "react";
import greyIconLeft from "assets/svg/arrow-left-circle-dark.svg";
import greyIconRight from "assets/svg/arrow-right-circle-dark.svg";
import fullScreenIcon from "assets/svg/fullScreenIcon.svg";
import pptActive from "assets/svg/pptActive.svg";
import grid from 'assets/svg/gridBackground.svg';
import {PresentationDocInterface, SlideInterface,} from "interfaces/interfaces-data";
import leftNav from "../../../../assets/svg/left-nav-arrow.svg";
import rightNav from "../../../../assets/svg/right-nav-arrow.svg";

export const DesktopControl = ({
                                   handleMoveSlide,
                                   slides,
                                   activeSlideIndex,
                                   canUpdateList,
                                   updateList,
                                   docs,
                                   activePresentations,
                                   activeDocumentId,
                                   handleActiveDocument,
                                   activeSlide,
                                   handleGridModeActive,
                                   gridModeActive,

                               }: {
    handleMoveSlide: (item: number, type: "prev" | "next") => void;
    slides: SlideInterface[];
    activeSlideIndex: number;
    canUpdateList: boolean;
    updateList: () => void;
    docs?: PresentationDocInterface[];
    activePresentations: { [key: string]: SlideInterface[] };
    activeDocumentId: string;
    handleActiveDocument: (prev: string, val: string) => void;
    activeSlide?: SlideInterface;
    handleGridModeActive: (val: boolean) => void
    gridModeActive: boolean
}) => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null)

    const handleNext = () => {
        if (activeSlideIndex < slides.length - 1) {
            console.log("got inside heree")
            const slideLength = slides.length;
            if (slideLength - activeSlideIndex === 2) {
                // update the list before the user navigates to that point
                updateList();
            }
            console.log(activeSlideIndex + 1, "active index + 1");
            handleMoveSlide(activeSlideIndex + 1, "next");
        }
    }

    const handlePrev = () => {
        if (activeSlideIndex > 0) {
            handleMoveSlide(activeSlideIndex - 1, "prev");
        }
    }

    const enterFullscreen = async () => {
        const elem = document.documentElement; // Entire document (browser fullscreen)
        if (elem.requestFullscreen) {
            await elem.requestFullscreen();
        }
    };

    const exitFullscreen = async () => {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
            prevButtonRef.current?.click()
        } else if (e.key === 'ArrowRight') {
            nextButtonRef.current?.click()
        }
    };

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            enterFullscreen();
            setIsFullScreen(true);
        } else {
            exitFullscreen();
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        // Cleanup listeners on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isFullScreen]);

    return (
        <>
            {isFullScreen && <>
                <section className="absolute top-0 left-0 w-screen h-screen z-20">
                    <section className='relative w-screen h-screen'>
                        <section
                            className=' bg-white w-full h-full'
                        >
                            {activeSlide?.imgUrl && <img
                                src={activeSlide.imgUrl}
                                alt='file'
                                className='rounded-lg object-contain'
                                style={{
                                    maxWidth: `100%`,
                                    maxHeight: `100%`,
                                    width: '100%',
                                    height: 'auto',
                                }}
                            />}

                        </section>
                    </section>
                </section>
                {slides.length > 1 && <section
                    className='flex items-center gap-x-5 absolute left-10 bottom-20 bg-[#E8E8E87A] border border-[#F5F5F5] rounded-full h-[40px] z-50 justify-between px-2'>
                    <button
                        className={`flex gap-x-3 items-center rounded-lg ${
                            activeSlideIndex === 0
                                ? 'cursor-default text-[#E2E2E2]'
                                : 'cursor-pointer text-black'
                        }`}
                        onClick={() => {
                            prevButtonRef.current?.click()
                        }}
                    >
                        <img
                            // src={slideDocumentIndex === 0 ? arrowLeftGray : arrowLeft}
                            src={leftNav}
                            alt='previous slide'
                            className='w-5'
                        />
                        {/* <p className=''>Previous slide</p> */}
                    </button>

                    <div className='rounded-[20px] text-black px-2 h-[44px] flex items-center '>
                        <p className='font-medium text-sm'>{`${
                            activeSlideIndex + 1
                        } of ${slides.length}`}</p>
                    </div>

                    <button
                        className='flex gap-x-3 items-center rounded-lg cursor-pointer'
                        onClick={() => nextButtonRef.current?.click()}
                    >
                        {/* <p>Next slide</p> */}
                        <img
                            src={rightNav}
                            // src={
                            //   slideDocumentIndex === slides.length - 1
                            //     ? arrowRightGray
                            //     : arrowRight
                            // }
                            alt='next slide'
                            className='w-5'
                        />
                    </button>
                </section>}

            </>
            }
            <div className="px-4 mb-4">
                <div className="w-full my-2  flex items-center justify-between">
                    <div className="flex items-center gap-2 bg-[#F6F6F6]  rounded-full px-4 py-2">
                        <button
                            ref={prevButtonRef}
                            onClick={(e) => {
                                if (activeSlideIndex > 0) {
                                    handlePrev()
                                    const button = e.currentTarget;
                                    const ripple = document.createElement("span");
                                    ripple.classList.add("ripple");

                                    // Calculate size and position of the ripple
                                    const rect = button.getBoundingClientRect();
                                    ripple.style.width = ripple.style.height =
                                        Math.max(rect.width, rect.height) + "px";
                                    ripple.style.left =
                                        e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                                    ripple.style.top =
                                        e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                                    // Append the ripple to the button and remove it after animation
                                    button.appendChild(ripple);
                                    ripple.addEventListener("animationend", () => ripple.remove());
                                }
                            }}
                            className="cursor-pointer"
                        >
                            <img src={greyIconLeft} alt=""/>
                        </button>
                        <p className="text-sm text-[#545454]">
                            {activeSlideIndex + 1} of {slides.length}
                        </p>
                        <button
                            ref={nextButtonRef}
                            onClick={(e) => {
                                if (activeSlideIndex < slides.length - 1) {
                                    handleNext()
                                    const button = e.currentTarget;
                                    const ripple = document.createElement("span");
                                    ripple.classList.add("ripple");

                                    // Calculate size and position of the ripple
                                    const rect = button.getBoundingClientRect();
                                    ripple.style.width = ripple.style.height =
                                        Math.max(rect.width, rect.height) + "px";
                                    ripple.style.left =
                                        e.clientX - rect.left - ripple.offsetWidth / 2 + "px";
                                    ripple.style.top =
                                        e.clientY - rect.top - ripple.offsetHeight / 2 + "px";

                                    // Append the ripple to the button and remove it after animation
                                    button.appendChild(ripple);
                                    ripple.addEventListener("animationend", () => ripple.remove());
                                }
                            }}
                            className="cursor-pointer"
                        >
                            <img src={greyIconRight} alt=""/>
                        </button>
                    </div>
                    <div className="flex items-center gap-3">

                        <button onClick={() => handleGridModeActive(!gridModeActive)}>
                            <img className="w-[40px] h-[40px]" src={grid} alt={""}/>
                        </button>
                        <button onClick={handleFullscreen}>
                            <img className="w-[40px] h-[40px]" src={fullScreenIcon} alt=""/>
                        </button>

                    </div>
                </div>
                <div
                    className="bg-[#F6F6F6] hide-scrollbar  overflow-x-scroll min-w-full max-w-full w-full   px-4 py-0 rounded-lg flex items-center min-h-[70px] h-[70px] ">
                    {Object.keys(activePresentations)?.length &&
                        Object.keys(activePresentations)?.map((item) => {
                            return (
                                <div
                                    key={item}
                                    onClick={() => handleActiveDocument(activeDocumentId, item)}
                                    className={` min-w-[100px] flex justify-center border px-3 cursor-pointer border-[#F6F6F6] rounded-lg  items-center ${
                                        activeDocumentId === item ? "bg-[#F6F6F6]" : ""
                                    }  my- 1`}
                                >
                                    <div
                                        className={`${activeDocumentId === item ? "bg-white" : ""} flex flex-col justify-center items-center px-3 rounded-lg pb-1  gap-2`}>
                                        <span className="">
                                            <img src={pptActive} alt={""}/>
                                        </span>
                                        <p
                                            className={`${
                                                activeDocumentId !== item
                                                    ? "text-[#333] w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
                                                    : "text-[#FF6929]"
                                            }  text-xs`}
                                        >
                                            {" "}
                                            {docs?.find((item_) => item_.documentId === item)?.title}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>

        </>
    )

};
