import { MutableRefObject, useEffect, useState } from 'react';
import { imageStream } from 'utils/imageStream';
import { ClipLoader } from 'react-spinners';

interface PreviewSlide {
  id: string;
  url: string;
  position: number;
  title: string;
  note: string | null;
  imgUrl?: string;
}

export interface ImageCache {
  [id: string]: string;
}

interface Props {
  slide: PreviewSlide;
  index: number;
  imageCache: MutableRefObject<ImageCache>;
  slideDocumentIndex: number;
  showImage: boolean;
  isGridDisplay: boolean;
  fromPresentation?: boolean;
}

export default function SlidesPreview({
  slide,
  index,
  imageCache,
  slideDocumentIndex,
  showImage,
  isGridDisplay,
  fromPresentation = false,
}: Props) {
  // States
  const [displayImage, setDisplayImage] = useState('');

  useEffect(() => {
    if (!fromPresentation) {
      const fetchImageStream = async (id: string) => {
        const cachedImg = imageCache.current[id];

        if (cachedImg) {
          setDisplayImage(cachedImg);
        } else {
          const url = await imageStream(id);
          if (url) {
            imageCache.current[id] = url;
            setDisplayImage(url);
          }
        }
      };

      fetchImageStream(slide.id);
    }
  }, [slide.id, imageCache]);

  return (
    <div
      className={`cursor-pointer w-full relative overflow-hidden ${
        isGridDisplay && slideDocumentIndex === index ? '' : ' h-full'
      } `}
    >
      <div
        className={`w-full h-full ${
          showImage ? 'opacity-100' : 'opacity-0 group-hover:opacity-100 '
        } 
        ${
          isGridDisplay && slideDocumentIndex === index
            ? 'border-2 border-primary'
            : ' '
        }
        `}
      >
        {fromPresentation ? (
          <img
            src={slide.imgUrl}
            alt='presentation slide'
            className={`w-full object-cover  ${isGridDisplay ? '' : 'h-full'}`}
          />
        ) : displayImage ? (
          <img
            src={displayImage}
            alt='presentation slide'
            className={`w-full object-cover  ${isGridDisplay ? '' : 'h-full'}`}
          />
        ) : (
          <div
            className={`w-full cursor-pointer flex justify-center items-center bg-white h-full`}
          >
            <ClipLoader size={12} color='gray' />
          </div>
        )}
      </div>

      {!isGridDisplay ? (
        <div
          className={`h-1 w-full cursor-pointer absolute bottom-0 left-0 ${
            slideDocumentIndex === index ? 'bg-primary' : 'bg-gray10'
          }`}
        ></div>
      ) : (
        <div className='flex w-full justify-center mt-2'>
          <div
            className={`rounded-full h-4 w-4 flex items-center justify-center ${
              slideDocumentIndex === index
                ? ' bg-[#ED6E33] text-white'
                : 'bg-gray-300 text-gray-600'
            } `}
          >
            <p className='text-xs'>{index + 1}</p>
          </div>
        </div>
      )}
    </div>
  );
}
//   return (
//     <div className='cursor-pointer w-full h-fit relative aspect-[16/9]'>
//       <div
//         className={`w-full min-h-[65.25px] ${
//           showImage ? 'opacity-100' : 'opacity-0 group-hover:opacity-100 '
//         }`}
//       >
//         {displayImage ? (
//           <img
//             src={displayImage}
//             alt='presentation slide'
//             className={`w-full object-cover aspect-[16/9] `}
//           />
//         ) : (
//           <div
//             className={`w-full rounded-2xl cursor-pointer flex justify-center items-center bg-white`}
//           >
//             <ClipLoader size={12} color='gray' />
//           </div>
//         )}
//       </div>

//       <div
//         className={`h-1 w-full cursor-pointer absolute bottom-0 left-0 ${
//           slideDocumentIndex === index ? 'bg-primary' : 'bg-gray10'
//         }`}
//       ></div>
//     </div>
//   );
// }
