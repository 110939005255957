import { useState } from 'react';
import {
  getPresentationDetailsAuth,
  updatePresentation,
} from 'store/Briefcase/briefcaseSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { toast } from 'sonner';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

// Types
interface IsPrivateProps {
  isActive: boolean;
  spaceId: string;
  presentationId: string;
}

export default function IsActive({
  isActive,
  spaceId,
  presentationId,
}: IsPrivateProps) {
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [active, setActive] = useState(isActive);

  //  Functions
  const handleActive = async () => {
    setActive(!active);
    dispatch(getPresentationDetailsAuth(presentationId)).then(
      ({ type, payload }) => {
        console.log(payload);
        if (type.includes('fulfilled') && payload) {
          const presentationDetails = payload.presentation;

          if (presentationDetails) {
            console.log('presentationDetails', presentationDetails);
            const shareLinkItems = {
              title: presentationDetails.title,
              spaceId: presentationDetails.spaceId,
              startTime: presentationDetails?.startTime ?? '',
              expiry: presentationDetails?.expiry ?? '',
              canDownload: presentationDetails.canDownload,
              type: presentationDetails.type,
              openLink: false,
              domains: presentationDetails.allowedDomains?.split(',') ?? [],
              passcode: presentationDetails.passcode,
              authMode: 1,
              watermark: '',
            };
            interface UpdatePresentation {
              expiry?: string;
              canDownload?: number;
              authMode?: number;
              title?: string;
              startTime?: string;
              passcode?: string;
              watermark?: string;
              allowQuestion?: boolean;
            }
            const data: UpdatePresentation = {
              title: shareLinkItems.title,
            };
            if (active) {
              data.expiry = dayjs
                .utc(`${new Date()}`)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            }

            if (shareLinkItems.canDownload === 1) {
              data.canDownload = 1;
            } else if (shareLinkItems.canDownload === 0) {
              data.canDownload = 0;
            }
            if (shareLinkItems.authMode) {
              data.authMode = shareLinkItems.authMode;
            }
            if (shareLinkItems.startTime) {
              data.startTime = shareLinkItems.startTime;
            }
            if (shareLinkItems.passcode) {
              data.passcode = shareLinkItems.passcode;
            }
            if (shareLinkItems.watermark) {
              data.watermark = shareLinkItems.watermark;
            }
            dispatch(
              updatePresentation({
                presentationId,
                data,
              })
            ).then((response) => {
              const { type } = response;
              if (type.includes('fulfilled')) {
                toast.success('Updated successfully!');
                //   dispatch(getShareHistory(spaceId));
              } else {
                setActive(!active);
                toast.error('Update failed!');
              }
            });
          }
        }
      }
    );
  };

  return (
    <label className='inline-flex items-center cursor-pointer'>
      <input
        type='checkbox'
        value=''
        className='sr-only peer'
        checked={active}
        onChange={handleActive}
      />
      <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
    </label>
  );
}
