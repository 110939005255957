import Axios from 'lib/axiosInterceptor';

//get

export const _getPresentation = (id: string) => {
  return Axios.get(`/Presentation/${id}`);
};

export const _getPresentationDetails = (presentationId : string, connectionId: string) => {
  return Axios.get(
    `/presentation/${presentationId}?presentaConnectionId=${
      connectionId ?? null
    }`
  );
};

// Post

export const _requestSharedAccess = () => {
  return Axios.post('/hubs/PresenterService/RequestSharedAccess');
};
